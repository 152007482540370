import styled from '@emotion/styled';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { SPACING } from '../../styles/spacing';

const Styled = {
  ButtonSet: styled.div<ButtonSetProps>`
    display: flex;
    justify-content: ${({ align }) => (align === 'start' ? 'flex-start' : 'flex-end')};
    flex-wrap: wrap;
    gap: ${SPACING.md};
    padding: calc(${SPACING.md} - 0.4em) calc(${SPACING.md} - 0.6em); // 0.4em and 0.6em is TableCell padding - to match gap between buttons to spacing around them

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  `,
};

export const ButtonSetSelector = Styled.ButtonSet;

export type ButtonSetProps = JSX.IntrinsicElements['div'] & {
  align?: 'start' | 'end';
};

export default function ButtonSet({ align = 'start', ...rest }: ButtonSetProps) {
  return <Styled.ButtonSet align={align} {...rest} />;
}
