import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '../../gql';
import {
  ShareDownloadUrlByBatchQuery,
  ShareDownloadUrlByBatchQueryVariables,
} from '../../gql/graphql';

export const shareDownloadUrlByBatchQuery = gql(/* GraphQL */ `
  query ShareDownloadUrlByBatchQuery($batchId: ID!) {
    shareDownloadUrlByBatch(batchId: $batchId)
  }
`);

export const useShareDownloadUrlByBatchLazyQuery = (
  options: QueryHookOptions<ShareDownloadUrlByBatchQuery, ShareDownloadUrlByBatchQueryVariables>,
) => useLazyQuery(shareDownloadUrlByBatchQuery, options);
