import styled from '@emotion/styled';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { SPACING } from '../../styles/spacing';

const Main = styled.main`
  min-height: 100vh;
  box-sizing: border-box;
  flex: 1;
  overflow: hidden;
  padding-bottom: calc(${SPACING.xl} * 5);

  @media (max-width: ${MEDIA_QUERY.xsMax}) {
    min-height: calc(100vh - ${SPACING.xl} * 3);
  }
  @media print {
    padding-bottom: ${SPACING.none};
  }
`;

export default Main;
