import { useRef } from 'react';

export default function useDebouncedCallback(callback: (...args: any[]) => void, delay: number) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  // return a function that starts the timeout and resets it
  // if it was already started
  return (...args: any[]) => {
    const timeout = timeoutRef.current;

    if (timeout) clearTimeout(timeout);
    timeoutRef.current = setTimeout(() => callback(...args), delay);
  };
}
