import { IntlCurrencyFormatFn, IntlCurrencyFormatProps } from './types';

const createIntlCurrencyFormatInstance = ({
  fractionDigits = 2,
  locale = 'en-US',
  currency = 'USD',
}: IntlCurrencyFormatProps = {}) =>
  new Intl.NumberFormat(locale, {
    style: currency !== false ? 'currency' : undefined,
    currency: currency || undefined,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

// Let's create some global INTL instances to reduce instances over all in the app
export const formatCurrency: IntlCurrencyFormatFn = createIntlCurrencyFormatInstance().format;
export const formatCurrencyNoUnit: IntlCurrencyFormatFn = createIntlCurrencyFormatInstance({
  currency: false,
}).format;
export const formatCurrencyNoFractionDigits: IntlCurrencyFormatFn =
  createIntlCurrencyFormatInstance({
    fractionDigits: 0,
  }).format;
export const formatCurrencyNoFractionDigitsNorUnit: IntlCurrencyFormatFn =
  createIntlCurrencyFormatInstance({ fractionDigits: 0, currency: false }).format;
