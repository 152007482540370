import formatWeight from '../../../utils/formatWeight';
import { FilterTagContext } from '../types';

export type WeightFilterTagSettings = {
  precision?: number;
};

// filterTags are rendered in the ColumnMenu with the column passed as prop
export default function WeightFilterTag<TData, TValue>(
  filterTagContext: FilterTagContext<TData, TValue>,
) {
  const { filterValue } = filterTagContext;
  const filterTagSettings = (filterTagContext.settings ?? {
    precision: 2,
  }) as WeightFilterTagSettings;

  return formatWeight({ pounds: 0, ounces: parseFloat(filterValue) }, filterTagSettings.precision);
}
