import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { GREYSCALE } from '../styles/colors';
import { TYPOGRAPHY } from '../styles/typography';
import { BORDER_RADIUS, BORDER_WIDTH } from '../styles/borders';
import Icon from './Icon';

const Styled = {
  RemoveIcon: styled(Icon)`
    position: absolute;
    top: 0;
    right: 4px;
    padding: 6px;
    color: ${GREYSCALE.black};
    opacity: 0.2;
    text-shadow: 0 1px 0 ${GREYSCALE.white};
    cursor: pointer;

    :focus,
    :hover {
      opacity: 0.5;
    }
  `,
  TagWrapper: styled.div<{ removable?: boolean }>`
    position: relative;
    display: inline-block;
    border-radius: ${BORDER_RADIUS.sm};
    padding: 6px 10px;
    background-color: ${GREYSCALE.grey20};
    border: ${BORDER_WIDTH.xs} solid ${GREYSCALE.grey30};
    color: ${GREYSCALE.grey50};
    text-shadow: 0 1px 0 ${GREYSCALE.white};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    line-height: 1;
    padding-right: ${(props) => (props.removable ? '30px' : '10px')};
  `,
};

export const TagBaseSelector = Styled.TagWrapper;

type TagProps = {
  children: ReactNode;
  removable?: boolean;
  onRemoveClick?: () => void;
};

export default function Tag({ children, removable, onRemoveClick }: TagProps) {
  return (
    <Styled.TagWrapper removable={removable} data-testid="tag">
      {children}

      {removable && <Styled.RemoveIcon icon="times" onClick={onRemoveClick} aria-label="remove" />}
    </Styled.TagWrapper>
  );
}
