import loggingService from '../services/logging';

const logger = loggingService.getLogger();

/**
 * https://support.google.com/tagmanager/answer/7679219?hl=en
 */
export default function triggerGoogleTagManagerCustomEvent(eventName: string, variables?: object) {
  logger.debug(`[GTM Event] ${eventName}`, variables);
  window.dataLayer?.push({
    event: eventName,
    ...variables,
  });
}
