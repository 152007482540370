import { useRef, useState } from 'react';
import { Row } from '@tanstack/react-table';
import ConnectedDataGrid from '../../dataGrid/ConnectedDataGrid';
import { Col } from '../../layout/Grid';
import ProgressButton from '../../form/ProgressButton';
import { DataGridImperativeHandleRef } from '../../dataGrid/DataGrid';

type OriginalRow = {
  id: string;
  title: string;
  transaction_type: string;
  payment_id: string;
  batch_id: string;
  shipment_id: string;
  reconciliation_run_id: string;
};

export default function TransactionsGrid() {
  const imperativeHandleRef = useRef<DataGridImperativeHandleRef>(null);
  const [isExporting, setIsExporting] = useState(false);

  const makeLinkToTransaction = (row: Row<any>) => {
    const original = row.original as OriginalRow;

    switch (original.transaction_type) {
      case 'Label':
      case 'Labels':
        return `/ship/batch?id=${original.batch_id}`;
      case 'Shipment':
        return `/ship/shipment?id=${original.shipment_id}`;
      case 'Carrier Adjustment':
        return `/reports/carrieradjustment?run=${original.reconciliation_run_id}`;
      case 'Payment':
      case 'Payment Refunded':
      case 'Fee':
        return `/reports/receipt?id=${original.payment_id}`;
      default:
        return '';
    }
  };

  return (
    <ConnectedDataGrid
      imperativeHandleRef={imperativeHandleRef}
      onRowClick={makeLinkToTransaction}
      queryName="transactionHistory"
      rightSideSlot={
        <Col xs={12} md={3} spaceBelow>
          <ProgressButton
            progress={isExporting}
            disabled={isExporting}
            fullWidth
            size="medium"
            variant="info"
            onClick={async () => {
              setIsExporting(true);
              await imperativeHandleRef.current?.exportData();
              setIsExporting(false);
            }}
          >
            Export
          </ProgressButton>
        </Col>
      }
    />
  );
}
