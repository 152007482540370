import { Form, Formik } from 'formik';
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { PlaidEmbeddedLink } from 'react-plaid-link';
import { PaymentApiMethodTitle } from '../../gql/graphql';
import LoadingModal from '../loading/LoadingModal';
import Modal from './Modal';
import Alert from '../Alert';
import convertBbcode from '../../utils/convertBbcode';
import SecurityBox from '../SecurityBox';
import CreditCardSubform, {
  CreditCardSubformRef,
  CreditCardSubformValues,
} from '../subforms/CreditCardSubform';
import {
  creditCardValidationSchema,
  INITIAL_CREDIT_CARD_SUBFORM_VALUES,
} from '../subforms/creditCardSubformUtils';
import PayPalButton from '../PayPalButton';
import { SPACING } from '../../styles/spacing';
import { batchQuery } from '../../operations/queries/batch';
import { usePaymentModalQuery } from '../../operations/queries/paymentModal';
import { Col, Row } from '../layout/Grid';
import { useCreateCcPaymentSourceMutation } from '../../operations/mutations/createCcPaymentSource';
import pciDssLogo from '../../assets/settingsIcons/pci-dss-logo.svg';
import Button from '../form/Button';
import ProgressButton from '../form/ProgressButton';
import Icon from '../Icon';
import { BORDER_RADIUS } from '../../styles/borders';
import { TYPOGRAPHY } from '../../styles/typography';
import { BottomRowActions, SecondaryActionProps } from '../form/BottomRowActions';
import { useCreatePlaidConsentMutation } from '../../operations/mutations/createPlaidConsentMutation';
import Label from '../form/Label';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import BaseLoading from '../loading/BaseLoading';
import { ageFlashMessages, setFlashMessage } from '../../apollo/cache/flashMessages';
import useLogger from '../../hooks/useLogger';
import { addRumCustomAction } from '../../utils/addRumCustomAttributes';
import VenmoButton from '../VenmoButton';
import { LinkButton } from '../Link';

import amex from '../../assets/settingsIcons/amex.svg';
import visa from '../../assets/settingsIcons/visa.svg';
import mc from '../../assets/settingsIcons/mastercard.svg';
import paypal from '../../assets/settingsIcons/paypal.svg';
import discover from '../../assets/settingsIcons/discover.svg';
import venmo from '../../assets/settingsIcons/venmo.svg';
import { PlaidAccessTokenProps, PlaidSuccessCallbackProps } from '../../hooks/usePlaidAccessToken';
import { PlaidAccessAndLinkImperativeHandleRef, PlaidHookProps } from '../../hooks/usePlaid';
import { PlaidActivationPayloadProps, PlaidLinkTokenProps } from '../../hooks/usePlaidLinkToken';
import usePlaidStandalone from '../../hooks/usePlaidStandalone';
import PageLoading from '../loading/PageLoading';
import triggerGoogleTagManagerCustomEvent from '../../utils/triggerGoogleTagManagerCustomEvent';

// eslint-disable-next-line react-refresh/only-export-components
export const Styled = {
  ModalContainer: styled('div')`
    display: flex;
    gap: ${SPACING.xl};
    flex-direction: column;
    @media (min-width: ${MEDIA_QUERY.smMin}) {
      justify-content: space-between;
    }
  `,
  PlaidWrapper: styled(Col)`
    height: 350px;
  `,
  Form: styled(Form)`
    display: flex;
    gap: ${SPACING.xxl};
    height: 555px;
    flex-direction: column;
    @media (min-width: ${MEDIA_QUERY.smMin}) {
      justify-content: space-between;
    }
  `,
  BtnContents: styled('div')`
    display: flex;
    place-content: center;
    align-items: center;
  `,
  Icon: styled(Icon)`
    margin-right: ${SPACING.sm};
    font-size: ${TYPOGRAPHY.fontSize.xxl};
    margin-right: ${SPACING.md};
  `,
  Img: styled('img')`
    height: 50px;
    width: 100px;
    object-fit: cover;
    margin-right: ${SPACING.md};
  `,
  CenteredCol: styled(Col)`
    display: flex;
    place-items: center;
    place-content: center;
  `,
  Account: styled('div')`
    display: flex;
    font-size: ${TYPOGRAPHY.fontSize.lg};
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
    gap: ${SPACING.md};
    border-radius: ${BORDER_RADIUS.xs};
    padding: ${SPACING.none} ${SPACING.none} ${SPACING.xl};
    & svg {
      font-size: ${SPACING.xxl};
      margin-top: ${SPACING.xs};
    }
  `,
  Notices: styled('div')`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: ${SPACING.xl};
    align-items: center;
    margin: ${SPACING.xl} ${SPACING.md};
    & p {
      margin: 0;
      font-size: ${TYPOGRAPHY.fontSize.lg};
    }
    & b {
      font-weight: ${TYPOGRAPHY.fontWeight.medium};
    }
    & span {
      font-size: calc(${SPACING.xxl} + ${SPACING.sm});
      text-align: center;
      line-height: ${TYPOGRAPHY.fontSize.xxxl};
      & img {
        height: 100%;
      }
    }
  `,
  P: styled('p')`
    margin-bottom: 0;
    font-size: ${TYPOGRAPHY.fontSize.sm};
  `,
  Center: styled('div')`
    display: grid;
    height: 225px;
    place-items: center;
    & .bigEmoji {
      font-size: calc(${TYPOGRAPHY.fontSize.xxxxl} * 2);
    }
  `,
  CenterText: styled('p')`
    text-align: center;
    font-size: ${TYPOGRAPHY.fontSize.lg};
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
    margin: ${SPACING.none};
    padding-bottom: ${SPACING.md};

    > button {
      > div > img {
        margin-top: ${SPACING.md};
        + img {
          margin-left: ${SPACING.sm};
        }
      }
      font-size: ${TYPOGRAPHY.fontSize.md};
    }
  `,
  SelectMethod: styled('div')`
    margin-top: ${SPACING.xl};
    @media (min-width: ${MEDIA_QUERY.smMin}) {
      margin-top: 0;
    }
  `,
};

type AddPaymentMethodModalValues = {
  creditCard: CreditCardSubformValues;
};

type ModalScreen =
  | 'select'
  | 'otherMethods'
  | 'inputcc'
  | 'plaidOnly' // new plaid screen after redesign
  | 'consent'
  | 'loading'
  | 'confirmExit'
  | 'error'
  | 'plaidThreePercent';

const titles = new Map();
titles.set('select', "How'd you like to pay?");
titles.set('otherMethods', "How'd you like to pay?");
titles.set('consent', 'Confirm your bank account');
titles.set('inputcc', 'Add new card');
titles.set('plaidOnly', "How'd you like to pay?");
titles.set('loading', 'Loading....');
titles.set('confirmExit', 'Are you sure?');
titles.set('error', 'Error');
titles.set('plaidThreePercent', 'You can now pay by bank!');

export type AddCreditCardResult = {
  cp: boolean;
  ccReference: string;
};

export type AddPaymentMethodModalProps = {
  open: boolean;
  origin: string;
  onClose: () => void;
  onManualClose?: () => void;
  onError?: (message: string) => void;
  onSuccess?: (paymentSourceId: string) => void;
  buttonText?: string;
  promotion?: string;
};

export default function AddPaymentMethodModal({
  origin,
  open,
  onClose,
  onManualClose,
  onError,
  onSuccess,
  buttonText,
  promotion,
}: AddPaymentMethodModalProps) {
  const [initialValues, setInitialValues] = useState<AddPaymentMethodModalValues>({
    creditCard: INITIAL_CREDIT_CARD_SUBFORM_VALUES,
  });
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<AddPaymentMethodModalValues | undefined>
  >(yup.object<AddPaymentMethodModalValues>({ creditCard: creditCardValidationSchema.required() }));

  const { data } = usePaymentModalQuery();

  const instanceRef = useRef<CreditCardSubformRef>();
  const [prevScreen, setPrevScreen] = useState<ModalScreen>('select');
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const [title, setTitle] = useState<string | null>();
  const [plaidActivationPayload, setPlaidActivationPayload] =
    useState<PlaidActivationPayloadProps | null>();
  const [forceLoading, setForceLoading] = useState<boolean>(false);

  const [plaidAccountName, setPlaidAccountName] = useState<string | null>();
  const [plaidAccountMask, setPlaidAccountMask] = useState<string | null>();
  const [plaidPaymentSourceId, setPlaidPaymentSourceId] = useState<string | null>();
  const plaidAccessAndLinkImperativeHandleRef = useRef<PlaidAccessAndLinkImperativeHandleRef>(null);
  const hasVenmoPaymentsFeature = useRef<boolean>(false);
  const [currentScreen, setCurrentScreen] = useState<ModalScreen | undefined>();
  const [createCcPaymentSource, { loading: creatingCcPaymentSource }] =
    useCreateCcPaymentSourceMutation();

  const [createPlaidConsent, { loading: creatingPlaidConsent }] = useCreatePlaidConsentMutation();

  const logger = useLogger();

  const ccMethod = data?.paymentApiMethods.find((e) => e.code === 'cc');
  const paypalMethod = data?.paymentApiMethods.find((e) => e.code === 'paypal');
  const achPlaidMethod = data?.paymentApiMethods.find((e) => e.code === 'plaidach');
  const venmoMethod = data?.paymentApiMethods.find((e) => e.code === 'venmo');

  const onSubformMount = useCallback((instance: CreditCardSubformRef) => {
    if (instance) {
      instanceRef.current = instance;
      setInitialValues({ creditCard: instance.initialValues });
      setValidationSchema(
        yup.object<AddPaymentMethodModalValues>({
          creditCard: instance.validationSchema.required(),
        }),
      );
    } else {
      instanceRef.current = undefined;
    }
  }, []);

  const switchScreen = useCallback(
    (to: ModalScreen) => {
      if (currentScreen) {
        setPrevScreen(currentScreen);
      }

      setCurrentScreen(to);

      setTitle(titles.get(to));
    },
    [currentScreen],
  );

  const performClose = useCallback(() => {
    onClose();
    setErrorMessage('');
    setPlaidPaymentSourceId(null);
    setPlaidAccountName(null);
    setPlaidAccountMask(null);
  }, [onClose]);

  const getInitialScreen = useCallback((): ModalScreen => {
    if (promotion === 'plaidThreePercent') {
      return 'plaidThreePercent';
    }

    if (data?.paymentApiMethods.length === 1) {
      switch (data?.paymentApiMethods[0].code) {
        // paypal is handled outside
        case 'cc':
          return 'inputcc';
        case 'plaidach':
          return 'plaidOnly';
        default:
      }
    }

    if (!achPlaidMethod) {
      return 'otherMethods';
    }

    return 'select';
  }, [data, achPlaidMethod, promotion]);

  const onPlaidSuccess = (payload: PlaidSuccessCallbackProps) => {
    setPlaidPaymentSourceId(payload.paymentSourceId);
    setPlaidAccountName(payload.accountName);
    setPlaidAccountMask(payload.accountMask);
  };

  const onPlaidEvent = (event: string) => {
    const loggingIgnoredEvents = ['TRANSITION_VIEW'];

    if (!loggingIgnoredEvents.includes(event)) {
      const e = `PLAID_${event}`;
      logger.info(e);
      addRumCustomAction(e, { event: e });
    }

    switch (event) {
      case 'EXIT':
        plaidAccessAndLinkImperativeHandleRef.current?.setLoading?.(false);
        switchScreen(getInitialScreen());
        break;
      default:
    }
  };

  const onPlaidError = (msg: string) => {
    switchScreen('error');
    setErrorMessage(msg);
  };

  const onPlaidTokenReady = (payload: PlaidActivationPayloadProps) => {
    setPlaidActivationPayload(payload);
  };

  const {
    getPlaidLinkToken,
    creatingPlaidActivation,
    creatingPlaidAccessToken,
    plaidAccessTokenError,
    plaidActivationErrorMessage,
  } = usePlaidStandalone({
    onSuccessCallback: onPlaidSuccess,
    onErrorCallback: onPlaidError,
    onTokenCallback: onPlaidTokenReady,
    plaidAccessAndLinkImperativeHandleRef,
  } as PlaidAccessTokenProps & PlaidLinkTokenProps & PlaidHookProps);

  const confirmClose = () => {
    switch (currentScreen) {
      case 'consent':
      case 'loading':
        if (creatingPlaidActivation) {
          onManualClose?.();
          return false;
        }
        switchScreen('confirmExit');
        return true;
      default:
        performClose();
        onManualClose?.();
        return false;
    }
  };

  const getSecondaryActions = (): [SecondaryActionProps] | undefined => {
    if (data?.paymentApiMethods.length === 1) {
      return undefined;
    }

    return [
      {
        title: 'Return to Payment Methods',
        variant: 'danger',
        datadogAction: 'return_to_payment_methods_btn_click',
        onClick: () => {
          ageFlashMessages();
          setErrorMessage(null);
          switchScreen('otherMethods');
        },
      },
    ];
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    // if there is only 1 active payment method the initialScreen
    // will be determined by the active payment method's code
    if (!currentScreen) {
      switchScreen(getInitialScreen());
    }
  }, [switchScreen, currentScreen, getInitialScreen, data]);

  useEffect(() => {
    if (plaidActivationErrorMessage) {
      setErrorMessage(plaidActivationErrorMessage);
    }
  }, [
    switchScreen,
    performClose,
    creatingPlaidAccessToken,
    plaidAccessTokenError,
    currentScreen,
    plaidActivationErrorMessage,
  ]);

  const getIsLoading = () =>
    forceLoading || creatingPlaidActivation || creatingPlaidConsent || creatingCcPaymentSource;

  const getConfirmCallback = () => {
    if (getIsLoading()) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return () => confirmClose();
  };

  const resetCurrentScreen = useCallback(() => {
    switchScreen(getInitialScreen());
  }, [getInitialScreen, switchScreen]);

  useEffect(() => {
    if (data) {
      hasVenmoPaymentsFeature.current =
        data?.company?.features?.find((f) => f?.key === 'Venmo.feature.payments_with_venmo')
          ?.value || false;

      if (open) {
        getPlaidLinkToken(origin);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open]);

  const renderConfirmExit = () => (
    <Styled.ModalContainer id="modalBody">
      <Row>
        <Col>
          <Styled.Center>
            <div className="bigEmoji">🤔</div>
            <p>Your progress will be lost.</p>
          </Styled.Center>
        </Col>
      </Row>
      <BottomRowActions
        primaryAction={{
          title: 'Yes, I want to exit',
          type: 'submit',
          variant: 'danger',
          onClick: () => {
            performClose();
            onManualClose?.();
          },
        }}
        secondaryActions={[
          {
            title: 'No, I want to continue',
            variant: 'primary',
            onClick: () => switchScreen(prevScreen),
          },
        ]}
        noSpaceAbove
      />
    </Styled.ModalContainer>
  );

  const renderError = () => (
    <Styled.ModalContainer id="modalBody">
      {errorMessage && <Alert variant="danger">{convertBbcode(errorMessage)}</Alert>}
      <Row>
        <Col>
          <Styled.Center>
            <p>Please try again.</p>
          </Styled.Center>
        </Col>
      </Row>
      <BottomRowActions
        primaryAction={{
          title: 'Close',
          type: 'submit',
          variant: 'danger',
          onClick: () => performClose(),
        }}
        secondaryActions={[
          {
            title: 'Return to Payment Methods',
            variant: 'primary',
            onClick: () => switchScreen('select'),
          },
        ]}
        noSpaceAbove
      />
    </Styled.ModalContainer>
  );

  const renderPlaidACHConsent = () => (
    <Styled.ModalContainer id="modalBody">
      <Row>
        <Col>
          <SecurityBox>
            {!creatingPlaidAccessToken && (
              <Styled.Account>
                <Styled.Icon icon="university" />
                {plaidAccountName}
                <br />
                ending in {plaidAccountMask}
              </Styled.Account>
            )}
            {creatingPlaidAccessToken && (
              <Styled.Account>
                <PageLoading height="54px" />
              </Styled.Account>
            )}
          </SecurityBox>
          <Styled.Notices>
            <span>📜</span>
            <p>Your bank account will be charged when you buy labels or add credit.</p>
            <span>📦</span>
            <p>
              You&apos;ll also be charged if carriers notice your shipment is different than you
              said it was when buying the label.
            </p>
            <span>💻</span>
            <p>You can always look at your receipts and transaction history 👍</p>
          </Styled.Notices>
          <Styled.P>
            By confirming your bank account you are saving it as a payment method (standing
            authorization) for subsequent payments. As a saved payment method, you authorize Pirate
            Ship to electronically debit (or credit in the case of refunds) this bank account until
            you delete it in your account settings.{' '}
            <LinkButton onClick={() => window.print()} disabled={creatingPlaidAccessToken}>
              Print this page for your records.
            </LinkButton>
          </Styled.P>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProgressButton
            type="button"
            size="large"
            variant="success"
            disabled={creatingPlaidAccessToken}
            progress={creatingPlaidConsent}
            fullWidth
            data-dd-action-name="confirm_bank_account_btn_click"
            onClick={async () => {
              if (!plaidPaymentSourceId) {
                return;
              }
              const consent = await createPlaidConsent({
                variables: {
                  paymentSourceId: plaidPaymentSourceId,
                },
              });
              const paymentSource = consent.data?.createPlaidConsent.paymentSource;
              if (paymentSource?.hasConsented) {
                const event = 'PLAID_CONSENT';
                logger.info(event);

                addRumCustomAction(event, { event });

                setFlashMessage('Your bank account was added successfully.', 'success');
                performClose();
                onSuccess?.(paymentSource.id);
              }
            }}
          >
            {buttonText ?? 'Confirm Account'}
          </ProgressButton>
        </Col>
      </Row>
    </Styled.ModalContainer>
  );

  const renderCreditCardForm = () => {
    if (!ccMethod) {
      throw new Error('Credit card payment method was not found');
    }

    const ccApiKey = ccMethod.apiKey;
    const paymentApi: PaymentApiMethodTitle = ccMethod.apiTitle;

    return (
      <Styled.ModalContainer id="modalBody">
        {errorMessage && <Alert variant="danger">{convertBbcode(errorMessage)}</Alert>}
        <Formik<AddPaymentMethodModalValues>
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (instanceRef.current) {
              setForceLoading(true);

              try {
                const ccReference = await instanceRef.current.createToken();
                if (ccReference === null) {
                  throw new Error('Invalid CC reference');
                }

                const response = await createCcPaymentSource({
                  variables: {
                    ccReference,
                    cp: values.creditCard.cp,
                  },
                });
                const paymentSource = response.data?.createCcPaymentSource.paymentSource;
                if (!paymentSource) {
                  return;
                }
                const message = paymentSource?.resultMessage || '';
                setFlashMessage(message, 'success');
                triggerGoogleTagManagerCustomEvent('Adds Credit Card');
                performClose();
                onSuccess?.(paymentSource.id);
              } catch (error) {
                setErrorMessage((error as Error).message);
              } finally {
                setForceLoading(false);
              }
            }
          }}
        >
          <Styled.Form>
            {ccApiKey && (
              <SecurityBox>
                <CreditCardSubform<keyof AddPaymentMethodModalValues>
                  namespace="creditCard"
                  ref={onSubformMount}
                  paymentApi={paymentApi}
                  apiKey={ccApiKey}
                  userEmail={data?.user.email || ''}
                  onError={onError}
                />
              </SecurityBox>
            )}
            <BottomRowActions
              primaryAction={{
                title: buttonText ?? 'Add Card',
                type: 'submit',
                variant: 'success',
                datadogAction: 'add_new_card_add_card_btn_click',
                progress: getIsLoading(),
                disabled: getIsLoading(),
              }}
              secondaryActions={getSecondaryActions()}
              noSpaceAbove
            />
          </Styled.Form>
        </Formik>
      </Styled.ModalContainer>
    );
  };

  const renderPayOtherMethods = () => (
    <Styled.ModalContainer id="modalBody">
      <Styled.SelectMethod>
        {!!paypalMethod && paypalMethod.apiKey && (
          <Row>
            <Col spaceBelow>
              <PayPalButton
                apiKey={paypalMethod?.apiKey}
                fullWidth
                onSuccess={(paymentSourceId: string) => {
                  setFlashMessage("Y'er new PayPal account has been added", 'success');
                  triggerGoogleTagManagerCustomEvent('Connects PayPal');
                  performClose();
                  onSuccess?.(paymentSourceId);
                }}
                refetchQueryOnApprove={batchQuery}
              />
            </Col>
          </Row>
        )}
        {!!venmoMethod && hasVenmoPaymentsFeature.current && (
          <Row>
            <Col>
              <VenmoButton
                onSuccessCallback={(paymentSourceId: string) => {
                  setFlashMessage("Y'er new Venmo account has been added", 'success');
                  triggerGoogleTagManagerCustomEvent('Connects Venmo');
                  performClose();
                  onSuccess?.(paymentSourceId);
                }}
                onErrorCallback={(message) => {
                  performClose();
                  setFlashMessage(message, 'danger');
                }}
              />
            </Col>
          </Row>
        )}
        {!!ccMethod && (
          <Row>
            <Col>
              <Button
                style={{ borderRadius: BORDER_RADIUS.xs }}
                fullWidth
                variant="primary"
                onClick={() => switchScreen('inputcc')}
                data-dd-action-name="add_debit_cc_btn_click"
              >
                <Styled.BtnContents>
                  <Styled.Icon icon="credit-card" />
                  <span>Debit or Credit Card</span>
                </Styled.BtnContents>
              </Button>
            </Col>
          </Row>
        )}
      </Styled.SelectMethod>
      {!!achPlaidMethod && (
        <Row>
          <Col>
            <Styled.CenterText>- or -</Styled.CenterText>
            <Styled.CenterText>
              <LinkButton
                onClick={() => switchScreen('select')}
                data-dd-action-name="pay_by_bank_btn_click"
              >
                Pay by Bank
              </LinkButton>
            </Styled.CenterText>
          </Col>
        </Row>
      )}
      <Row>
        <Styled.CenteredCol>
          <Styled.Img src={pciDssLogo} alt="PCI DSS Compliant" />
          <Label secondary="Your data is kept secure & private" />
        </Styled.CenteredCol>
      </Row>
    </Styled.ModalContainer>
  );

  const renderSelectPaymentMethod = () => (
    <Styled.ModalContainer id="modalBody">
      {errorMessage && <Alert variant="danger">{convertBbcode(errorMessage)}</Alert>}
      <Styled.SelectMethod>
        {!!achPlaidMethod && (
          <>
            <Row>
              <Col>
                <Styled.CenterText>Pay by Bank &mdash; free & instant</Styled.CenterText>
              </Col>
            </Row>
            <Row>
              <Styled.PlaidWrapper spaceBelow>
                {!!plaidActivationPayload?.token && (
                  <PlaidEmbeddedLink
                    token={plaidActivationPayload.token}
                    // forward the PlaidEmbeddedLink onSuccess event to the usePlaid hook,
                    // the usePlaid hook will then handle the onSuccess like in the normal (not redesign) flow
                    onSuccess={(token) => {
                      triggerGoogleTagManagerCustomEvent('Connects Bank');

                      // switch screen to consent to avoid loading gap, show loading spinner
                      switchScreen('consent');
                      // call success handler which loads the access token
                      plaidAccessAndLinkImperativeHandleRef.current?.onSuccess({
                        token,
                        handle: plaidActivationPayload.handle,
                      } as PlaidActivationPayloadProps);
                    }}
                    style={{
                      minHeight: '350px',
                      height: '350px',
                    }}
                    onEvent={(event: string) => {
                      const loggingIgnoredEvents = ['TRANSITION_VIEW'];

                      if (!loggingIgnoredEvents.includes(event)) {
                        const e = `PLAID_${event}`;
                        logger.info(e);
                        addRumCustomAction(e, { event: e });
                      }
                    }}
                  />
                )}
              </Styled.PlaidWrapper>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <Styled.CenterText>- or -</Styled.CenterText>
            <Styled.CenterText>
              <LinkButton
                onClick={() => {
                  switchScreen('otherMethods');
                }}
                data-dd-action-name="pay_a_different_way_btn_click"
              >
                Pay a different way
                <div>
                  <img src={paypal} alt="PayPal" />
                  <img src={visa} alt="VISA" />
                  <img src={mc} alt="Mastercard" />
                  <img src={amex} alt="American Express" />
                  <img src={discover} alt="Discover" />
                  <img src={venmo} alt="Venmo" />
                </div>
              </LinkButton>
            </Styled.CenterText>
          </Col>
        </Row>
      </Styled.SelectMethod>
      <Row>
        <Styled.CenteredCol>
          <Styled.Img src={pciDssLogo} alt="PCI DSS Compliant" />
          <Label secondary="Your data is kept secure & private" />
        </Styled.CenteredCol>
      </Row>
    </Styled.ModalContainer>
  );

  const renderPlaidThreePercent = () => (
    <Styled.ModalContainer id="modalBody">
      {errorMessage && <Alert variant="danger">{convertBbcode(errorMessage)}</Alert>}
      <Styled.SelectMethod>
        {!!achPlaidMethod && (
          <>
            <Row>
              <Col>
                <Styled.CenterText>
                  Get 3% cash back on your next purchase when switching to Pay by Bank 🤑
                </Styled.CenterText>
              </Col>
            </Row>
            <Row>
              <Styled.PlaidWrapper spaceBelow>
                {!!plaidActivationPayload?.token && (
                  <PlaidEmbeddedLink
                    token={plaidActivationPayload.token}
                    // forward the PlaidEmbeddedLink onSuccess event to the usePlaid hook,
                    // the usePlaid hook will then handle the onSuccess like in the normal (not redesign) flow
                    onSuccess={(token) => {
                      // switch screen to consent to avoid loading gap, show loading spinner
                      switchScreen('consent');
                      // call success handler which loads the access token
                      plaidAccessAndLinkImperativeHandleRef.current?.onSuccess({
                        token,
                        handle: plaidActivationPayload.handle,
                      } as PlaidActivationPayloadProps);
                    }}
                    style={{
                      minHeight: '350px',
                      height: '350px',
                    }}
                    onEvent={(event: string) => {
                      const loggingIgnoredEvents = ['TRANSITION_VIEW'];

                      if (!loggingIgnoredEvents.includes(event)) {
                        const e = `PLAID_${event}`;
                        logger.info(e);
                        addRumCustomAction(e, { event: e });
                      }
                    }}
                  />
                )}
              </Styled.PlaidWrapper>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <Styled.CenterText>- or -</Styled.CenterText>
            <Styled.CenterText>
              <LinkButton
                onClick={() => {
                  performClose();
                }}
              >
                Keep my current payment method
              </LinkButton>
            </Styled.CenterText>
          </Col>
        </Row>
      </Styled.SelectMethod>
      <Row>
        <Styled.CenteredCol>
          <Styled.Img src={pciDssLogo} alt="PCI DSS Compliant" />
          <Label secondary="Your data is kept secure & private" />
        </Styled.CenteredCol>
      </Row>
    </Styled.ModalContainer>
  );

  const renderSelectPaymentMethodPlaidOnly = () => (
    <Styled.ModalContainer id="modalBody">
      <Styled.SelectMethod>
        {!!achPlaidMethod && (
          <>
            <Row>
              <Col>
                <Styled.CenterText>Pay by Bank &mdash; free & instant</Styled.CenterText>
              </Col>
            </Row>
            <Row>
              <Styled.PlaidWrapper spaceBelow>
                {!!plaidActivationPayload?.token && (
                  <PlaidEmbeddedLink
                    token={plaidActivationPayload.token}
                    // forward the PlaidEmbeddedLink onSuccess event to the usePlaid hook,
                    // the usePlaid hook will then handle the onSuccess like in the normal (not redesign) flow
                    onSuccess={(token) => {
                      // switch screen to consent to avoid loading gap, show loading spinner
                      switchScreen('consent');
                      // call success handler which loads the access token
                      plaidAccessAndLinkImperativeHandleRef.current?.onSuccess({
                        token,
                        handle: plaidActivationPayload.handle,
                      } as PlaidActivationPayloadProps);
                    }}
                    onExit={() => {}}
                    style={{
                      minHeight: '350px',
                      height: '350px',
                    }}
                    onEvent={(event: string) => onPlaidEvent(event)}
                  />
                )}
              </Styled.PlaidWrapper>
            </Row>
          </>
        )}
      </Styled.SelectMethod>
      <Row>
        <Styled.CenteredCol>
          <Styled.Img src={pciDssLogo} alt="PCI DSS Compliant" />
          <Label secondary="Your data is kept secure & private" />
        </Styled.CenteredCol>
      </Row>
    </Styled.ModalContainer>
  );

  const renderPlaidAccessTokenLoading = () => (
    <Styled.ModalContainer id="modalBody">
      <Row>
        <Col>
          <Styled.Center>
            <BaseLoading size={60} />
            <p>Data loading...</p>
          </Styled.Center>
        </Col>
      </Row>
    </Styled.ModalContainer>
  );

  return (
    <Suspense
      fallback={
        <LoadingModal
          width={520}
          open={open}
          onConfirm={getConfirmCallback()}
          closable={!getIsLoading()}
          onAfterClose={resetCurrentScreen}
        />
      }
    >
      <Modal
        title={title || ''}
        width={520}
        closable={!getIsLoading()}
        open={open}
        onOpen={() => {
          addRumCustomAction('add_payment_modal_open', { event: 'add_payment_modal_open' });
          triggerGoogleTagManagerCustomEvent('View Payment Modal');
        }}
        onConfirm={getConfirmCallback()}
        onAfterClose={resetCurrentScreen}
        datadogActionClose="add_payment_method_btn_cancel"
        datadogActionBackdrop="add_payment_method_background_cancel"
      >
        {currentScreen === 'select' && renderSelectPaymentMethod()}

        {currentScreen === 'plaidOnly' && renderSelectPaymentMethodPlaidOnly()}

        {currentScreen === 'consent' && renderPlaidACHConsent()}

        {currentScreen === 'inputcc' && renderCreditCardForm()}

        {currentScreen === 'otherMethods' && renderPayOtherMethods()}

        {currentScreen === 'confirmExit' && renderConfirmExit()}

        {currentScreen === 'error' && renderError()}

        {currentScreen === 'plaidThreePercent' && renderPlaidThreePercent()}

        {!plaidAccessTokenError && currentScreen === 'loading' && renderPlaidAccessTokenLoading()}
      </Modal>
    </Suspense>
  );
}
