import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const refundCreditFromPaymentSourceMutation = gql(/* GraphQL */ `
  mutation RefundCreditFromPaymentSource($refundCreditAmount: Float!, $paymentSourceId: ID!) {
    refundCreditFromPaymentSource(
      refundCreditAmount: $refundCreditAmount
      paymentSourceId: $paymentSourceId
    ) {
      id
      accountBalance
    }
  }
`);

export const useRefundCreditFromPaymentSource = () =>
  useMutation(refundCreditFromPaymentSourceMutation);
