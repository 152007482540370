import { gql } from '../../gql';

export const batchStepQuery = gql(/* GraphQL */ `
  query BatchStepQuery($id: ID!) {
    batch(id: $id) {
      id
      step
      shipments {
        id
      }
    }
  }
`);
