import { useQuery, useLazyQuery } from '@apollo/client';
import { gql } from '../../gql';

export const mailTemplatesQuery = gql(/* GraphQL */ `
  query MailTemplatesQuery {
    company {
      id
      settings {
        defaultTrackingEmailsDelay
        defaultTrackingEmailsEnabled
        defaultEmailTemplateId
      }
      mailTemplates {
        ...MailTemplateFragment
      }
    }
  }
`);

export const useMailTemplatesQuery = () => useQuery(mailTemplatesQuery);
export const useMailTemplatesLazyQuery = () => useLazyQuery(mailTemplatesQuery);
