import styled from '@emotion/styled';
import { GREYSCALE } from '../../styles/colors';
import { Row, Col } from '../layout/Grid';
import Collapsible from '../layout/Collapsible';
import { SPACING } from '../../styles/spacing';
import { CarrierIcon, PlatformIcon } from '../Icon';
import { ExternalLink } from '../Link';
import { PlatformKey } from '../../assets/platformIcons';
import RecipientDetailsSection from './RecipientDetailsSection';
import OrderInformationSection from './OrderInformationSection';
import { BatchCustomsFormSummaryProps } from './BatchCustomsFormSection';
import ShipmentCustomsFormSection from './ShipmentCustomsFormSection';
import PackageDetailsSection, { PackageSummary, SpreadsheetMapping } from './PackageDetailsSection';
import ShipFromAddressSection from './ShipFromAddressSection';
import ReturnAddressSection from './ReturnAddressSection';
import LabelDetailsSection from './LabelDetailsSection';
import { RubberStampsBatchSectionProps } from './RubberStampsBatchSection';
import OrderItemsSection, { OrderItemsSectionProps } from './OrderItemsSection';
import getShipmentOrderLink, { Order } from '../../utils/shipmentLink';
import ShipmentStatusText from '../shipmentDetailsCollapsible/ShipmentStatusText';
import ClipboardButton from '../form/ClipboardButton';
import useIsMobile from '../../hooks/useIsMobile';
import { CountryMap } from '../../utils/createCountryMap';
import ShipmentPassthroughDataSection from './ShipmentPassthroughDataSection';
import { Address } from '../../utils/formatAddress';
import { BatchSource, ShipmentStatus } from '../../gql/graphql';
import RubberStampsShipmentSection from './RubberStampsShipmentSection';

const Styled = {
  Container: styled.div`
    margin-bottom: ${SPACING.xxl};
  `,
  IconWrapper: styled.span`
    line-height: ${SPACING.none};
  `,
  PlatformIconHeader: styled.div`
    display: flex;
    margin-left: ${SPACING.sm};
    align-items: center;
  `,
  Title: styled.div`
    color: ${GREYSCALE.grey50};
  `,
  TrackingNumberWrapper: styled.div`
    display: flex;
    gap: ${SPACING.sm};
    padding-left: ${SPACING.md};
  `,
};

type ShipmentDetailsPageShipmentDetailsBoxProps = {
  batch: {
    customsFormSummary: BatchCustomsFormSummaryProps['customsFormSummary'];
    spreadsheetMapping: SpreadsheetMapping;
    dataSource: BatchSource;
    packageSummary: PackageSummary;
    rubberStamps: RubberStampsBatchSectionProps['rubberStamps'];
    shipments: ReadonlyArray<{
      status: ShipmentStatus;
      totalClientPrice: number | null;
      carrierTitle: string;
      errorMessage: string;
      additionalRefundNotice: string;
      foreignOrderId: string;
      trackingNr: string | null;
      carrierKey: string;
      mailClassKey: string | null;
      trackingLink: string;
      recipientAddress: Address & { email?: string | null; phone?: string | null };
      order:
        | ({
            platform: { title: string };
            platformKey: string;
            foreignOrderId: string;
            orderItems: OrderItemsSectionProps['orderItems'];
          } & Order)
        | null;
      packageType: {
        title: string;
      };
      packageTypeOriginal: {
        title: string;
      };
      dimensionXOriginal: number;
      dimensionYOriginal: number;
      dimensionZOriginal: number | null;
      dimensionX: number;
      dimensionY: number;
      dimensionZ: number | null;
      rubberStamp1: string | null;
      rubberStamp2: string | null;
      rubberStamp3: string | null;
      weight: number;
      weightOriginal: number;
      passthroughFields: ReadonlyArray<{
        fieldName: string;
        value: string;
      }>;
      customsFormEnabled: boolean;
      customsSigner: string;
      customsContentType: string;
      exporterTaxId: string | null;
      customsItems: ReadonlyArray<string | null>;
    }>;
    warehouse: {
      shy: boolean;
      title: string;
      originAddress: Address & { id: string };
      returnAddress: Address & { id: string };
    };
    labelSize: string;
    labelFileFormat: string;
  };
  countryMap: CountryMap;
  accountBalance: number;
};

export default function ShipmentDetailsPageShipmentDetailsBox({
  batch,
  countryMap,
  accountBalance,
}: ShipmentDetailsPageShipmentDetailsBoxProps) {
  const { isMobile } = useIsMobile();
  const shipment = batch.shipments[0];
  const renderSupplementaryHeader = () => {
    const isPlatform = batch.dataSource === 'INTEGRATION';
    const currentLink = getShipmentOrderLink(shipment.order);
    const platformKey = shipment.order?.platformKey;
    const platformTitle = shipment.order?.platform.title;
    const hideCarrierIcon = isPlatform && isMobile;
    const renderTrackingNumber = () =>
      shipment.trackingNr && (
        <Styled.TrackingNumberWrapper>
          {!hideCarrierIcon && (
            <CarrierIcon carrierKey={shipment.carrierKey} mailClassKey={shipment.mailClassKey} />
          )}
          <ExternalLink data-dd-action-name="tracking number link" href={shipment.trackingLink}>
            {shipment.trackingNr}
          </ExternalLink>
          <ClipboardButton value={shipment.trackingNr} ariaTitle="tracking number" />
        </Styled.TrackingNumberWrapper>
      );

    const renderPlatformOrderNumber = () =>
      isPlatform &&
      !!platformKey && (
        <Styled.PlatformIconHeader>
          <Styled.IconWrapper>
            <PlatformIcon icon={platformKey as PlatformKey} height={30} width={30} />
          </Styled.IconWrapper>
          {!currentLink?.url ? (
            <Styled.Title>Imported from {platformTitle}</Styled.Title>
          ) : (
            <ExternalLink href={currentLink.url} target="_blank" rel="noopener noreferrer">
              View Order on {platformTitle}
            </ExternalLink>
          )}
        </Styled.PlatformIconHeader>
      );

    return (
      <>
        {renderTrackingNumber()}
        {renderPlatformOrderNumber()}
      </>
    );
  };

  const { recipientAddress } = shipment;

  return (
    <Styled.Container id="shipment-info">
      <Collapsible label="Shipment Details" customContent={renderSupplementaryHeader()}>
        <Row>
          <Col md={4}>
            <RecipientDetailsSection recipientDetails={recipientAddress} />
            <ShipFromAddressSection
              isWarehouseShy={batch.warehouse.shy}
              title={batch.warehouse.title}
              address={batch.warehouse.originAddress}
              countryMap={countryMap}
            />
            <ReturnAddressSection
              hideIf={batch.warehouse.returnAddress.id === batch.warehouse.originAddress.id}
              address={batch.warehouse.returnAddress}
              countryMap={countryMap}
            />
          </Col>
          <Col md={4}>
            <PackageDetailsSection
              packageSummary={batch.packageSummary}
              dataSource={batch.dataSource}
              spreadsheetMapping={batch.spreadsheetMapping}
              singleShipmentCountryCode={shipment.recipientAddress.countryCode}
              hideOverrideNotes
              adjusted={{
                packageType: {
                  current: shipment.packageType.title,
                  original: shipment.packageTypeOriginal.title,
                },
                weight: {
                  current: shipment.weight,
                  original: shipment.weightOriginal,
                },
                dimensions: {
                  current: {
                    x: shipment.dimensionX,
                    y: shipment.dimensionY,
                    z: shipment.dimensionZ,
                  },
                  original: {
                    x: shipment.dimensionXOriginal,
                    y: shipment.dimensionYOriginal,
                    z: shipment.dimensionZOriginal,
                  },
                },
              }}
            />
            <ShipmentCustomsFormSection
              customsFormEnabled={batch.shipments[0].customsFormEnabled}
              customsSigner={batch.shipments[0].customsSigner}
              customsContentType={batch.shipments[0].customsContentType}
              exporterTaxId={batch.shipments[0].exporterTaxId}
              customsItems={batch.shipments[0].customsItems}
            />
          </Col>
          <Col md={4}>
            <LabelDetailsSection
              labelSize={batch.labelSize}
              labelFileFormat={batch.labelFileFormat}
            />
            <OrderInformationSection
              hideIf={batch.dataSource !== 'INTEGRATION'}
              order={shipment.order}
              shipmentForeignOrderId={shipment.foreignOrderId}
            />
            <ShipmentPassthroughDataSection
              hideIf={batch.dataSource !== 'SPREADSHEET' || !shipment.passthroughFields?.length}
              passthroughFields={shipment.passthroughFields}
            />
            <RubberStampsShipmentSection
              rubberStamps={{
                rubberStamp1: shipment.rubberStamp1,
                rubberStamp2: shipment.rubberStamp2,
                rubberStamp3: shipment.rubberStamp3,
              }}
            />
            <OrderItemsSection
              hideIf={batch.dataSource !== 'INTEGRATION'}
              orderItems={shipment.order?.orderItems}
            />
          </Col>
        </Row>
      </Collapsible>
      <ShipmentStatusText shipment={shipment} accountBalance={accountBalance} />
    </Styled.Container>
  );
}
