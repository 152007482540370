import NumberFormat from 'react-number-format';
import TextField, { TextFieldProps } from './TextField';

function CurrencyTextField({ value, defaultValue, ...others }: TextFieldProps) {
  const internalValue = value === '' ? '0.00' : value;
  return (
    <NumberFormat
      {...others}
      isNumericString
      allowNegative={false}
      thousandsGroupStyle="thousand"
      type="text"
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      customInput={TextField}
      value={internalValue as string | number | null | undefined}
    />
  );
}

export default CurrencyTextField;
