import { InsuranceDetails } from './ShipmentServiceTypes';
import ClaimInsureShieldInsuranceLink from './ClaimInsureShieldInsuranceLink';
import IntercomArticleLink from '../IntercomArticleLink';
import { INTERCOM_ARTICLE_LINKS } from '../../constants';
import { formatCurrencyNoFractionDigits } from '../../utils/currency';

type CarrierLiabilityHintProps = {
  shipmentId: string;
  insuranceDetails: InsuranceDetails;
  isInsureShieldInsuranceActive?: boolean;
};

export default function CarrierLiabilityHint({
  shipmentId,
  insuranceDetails,
  isInsureShieldInsuranceActive,
}: CarrierLiabilityHintProps) {
  if (insuranceDetails.hasOnlyCarrierLiabilityInsurance) {
    return (
      <span>
        {insuranceDetails.insuredValue ? (
          <span>
            {formatCurrencyNoFractionDigits(insuranceDetails.insuredValue)} carrier liability
          </span>
        ) : (
          <span>Includes carrier liability</span>
        )}{' '}
        (
        {isInsureShieldInsuranceActive && insuranceDetails.insuredValueType === 'UPS' ? (
          <ClaimInsureShieldInsuranceLink shipmentId={shipmentId}>
            File claim
          </ClaimInsureShieldInsuranceLink>
        ) : (
          <IntercomArticleLink href={INTERCOM_ARTICLE_LINKS.fileInsurance}>
            File claim
          </IntercomArticleLink>
        )}
        )
      </span>
    );
  }

  return null;
}
