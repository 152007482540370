import { StoreObject, useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const deleteBatchMutation = gql(/* GraphQL */ `
  mutation DeleteBatchMutation($id: ID!) {
    deleteBatch(id: $id) {
      id
    }
  }
`);

export const useDeleteBatchMutation = () =>
  useMutation(deleteBatchMutation, {
    // Remove entry from list on delete
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      cache.evict({ id: cache.identify(data.deleteBatch as unknown as StoreObject) });
      cache.gc();
    },
  });
