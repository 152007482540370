import ServiceSummaryTable from './StyledServiceSummaryTable';
import { formatCurrency } from '../../utils/currency';
import { ShipmentStatus } from '../../gql/graphql';

type ServiceSummaryPricesProps = {
  currentPrice: number | null;
  previousPrice?: number | null;
  status?: ShipmentStatus;
};

export default function ServiceSummaryPrices({
  currentPrice,
  previousPrice,
  status,
}: ServiceSummaryPricesProps) {
  // if there is no previous price, just render the given price
  if (previousPrice == null) {
    return (
      <ServiceSummaryTable.Price data-testid="total-cost">
        {formatCurrency(currentPrice || 0)}
      </ServiceSummaryTable.Price>
    );
  }

  // otherwise, render strikethrough price if current differs from previous
  const shouldRenderOld = previousPrice !== currentPrice;
  return (
    <>
      {shouldRenderOld && (
        <>
          <ServiceSummaryTable.Strikethrough>
            {formatCurrency(previousPrice)}
          </ServiceSummaryTable.Strikethrough>{' '}
        </>
      )}
      <ServiceSummaryTable.Price
        red={currentPrice != null && currentPrice < 0}
        data-testid="total-cost"
      >
        {status?.includes('RETURN')
          ? `${
              status === 'RETURN_UNUSED' || status === 'RETURN_EXCEEDED'
                ? formatCurrency(0)
                : formatCurrency(currentPrice || 0)
            } of ${formatCurrency(currentPrice || 0)} max`
          : formatCurrency(currentPrice || 0)}
      </ServiceSummaryTable.Price>
    </>
  );
}
