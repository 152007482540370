import { ReactiveVar } from '@apollo/client';
import { currentUserVar as reactiveVar, CurrentUser } from '../../apollo/cache';

function createCurrentUser(currentUserVar: ReactiveVar<CurrentUser | null>) {
  return (user: CurrentUser | null) => {
    currentUserVar(user);
  };
}

export default createCurrentUser(reactiveVar);
