import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createPlaidActivationMutation = gql(/* GraphQL */ `
  mutation CreatePlaidActivationMutation($origin: String!) {
    createPlaidActivation(origin: $origin) {
      handle
      linkToken
    }
  }
`);

export const useCreatePlaidActivationMutation = () => useMutation(createPlaidActivationMutation);
