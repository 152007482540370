import styled from '@emotion/styled';
import { Column } from '@tanstack/react-table';
import clsx from 'clsx';
import { SPACING } from '../../../styles/spacing';
import Icon from '../../Icon';

const Styled = {
  TableHeader: styled.th<{ sortable: boolean }>`
    overflow: hidden;

    cursor: ${({ sortable }) => (sortable ? 'pointer' : 'auto')};
  `,
  TableHeaderLabel: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    gap: ${SPACING.md};
  `,
};

type SimpleColumnHeaderProps<TData, TValue> = {
  sortable?: boolean;
  headerId: string;
  column: Column<TData, TValue>;
};

export default function SimpleColumnHeader<TData, TValue>({
  sortable = false,
  headerId,
  column,
}: SimpleColumnHeaderProps<TData, TValue>) {
  return (
    <Styled.TableHeader
      key={headerId}
      onClick={sortable ? column.getToggleSortingHandler() : undefined}
      className={clsx({ sortingActive: column.getIsSorted() })}
      style={{ width: column.columnDef.meta?.width }}
      sortable={sortable && column.getCanSort()}
    >
      <Styled.TableHeaderLabel>
        {`${column.columnDef.header}`}
        <span className="icons">
          {(() => {
            const getSortIcon = () => {
              if (!column.getIsSorted()) {
                return 'sort';
              }
              return column.getIsSorted() === 'desc' ? 'sort-down' : 'sort-up';
            };
            return (
              sortable && column.getCanSort() && <Icon className="icon" icon={getSortIcon()} />
            );
          })()}
        </span>
      </Styled.TableHeaderLabel>
    </Styled.TableHeader>
  );
}
