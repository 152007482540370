import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { SPACING } from '../../styles/spacing';
import PageTitle from './PageTitle';

const Styled = {
  PageHeader: styled.header`
    display: flex;
    justify-content: center;
    padding-bottom: ${SPACING.xxl};
  `,
};

type CenteredPageHeaderProps = {
  title: ReactNode; // string, but node needed for for inline formatting
};

export default function CenteredPageHeader({ title }: CenteredPageHeaderProps) {
  return (
    <Styled.PageHeader>
      <PageTitle center>{title}</PageTitle>
    </Styled.PageHeader>
  );
}
