import { useImperativeHandle, useRef } from 'react';
import usePlaidLinkToken, {
  PlaidActivationPayloadProps,
  PlaidLinkTokenImperativeHandleRef,
  PlaidLinkTokenProps,
} from './usePlaidLinkToken';
import usePlaidAccessToken, {
  PlaidAccessTokenImperativeHandleRef,
  PlaidAccessTokenProps,
} from './usePlaidAccessToken';
import { PlaidHookProps } from './usePlaid';

export default function usePlaidStandalone({
  onSuccessCallback,
  onTokenCallback,
  onErrorCallback,
  plaidAccessAndLinkImperativeHandleRef,
}: PlaidAccessTokenProps & PlaidLinkTokenProps & PlaidHookProps) {
  const accessTokenImperativeHandleRef = useRef<PlaidAccessTokenImperativeHandleRef>(null);
  const linkTokenImperativeHandleRef = useRef<PlaidLinkTokenImperativeHandleRef>(null);

  // using refs here bc of plaid iframe
  const linkToken = useRef<string>('');
  const handle = useRef<string>('');

  const onPlaidTokenReady = (payload: PlaidActivationPayloadProps) => {
    linkToken.current = payload.token;
    handle.current = payload.handle;

    onTokenCallback?.(payload);
  };

  const {
    getPlaidLinkToken,
    plaidLinkTokenLoading,
    creatingPlaidActivation,
    creatingPlaidValidationActivation,
    plaidActivationErrorMessage,
    successMessage, // pass through to usePlaidAccessToken
  } = usePlaidLinkToken({
    onTokenCallback: onPlaidTokenReady,
  });

  const {
    getPlaidAccessToken,
    plaidAccessTokenLoading,
    creatingPlaidAccessToken,
    plaidAccessTokenError,
  } = usePlaidAccessToken({
    onSuccessCallback,
    onErrorCallback,
    successMessage, // used in BuyPage, passed via onSuccessCallback
  } as PlaidAccessTokenProps);

  const setLoading = (loading: boolean) => {
    accessTokenImperativeHandleRef.current?.setLoading(loading);
    linkTokenImperativeHandleRef.current?.setLoading(loading);
  };

  useImperativeHandle(plaidAccessAndLinkImperativeHandleRef, () => ({
    onSuccess: getPlaidAccessToken,
    setLoading,
  }));

  return {
    getPlaidLinkToken,
    plaidIsLoading: plaidLinkTokenLoading || plaidAccessTokenLoading,
    creatingPlaidActivation,
    creatingPlaidValidationActivation,
    creatingPlaidAccessToken,
    plaidAccessTokenError,
    plaidActivationErrorMessage,
  };
}
