import { css, Global } from '@emotion/react';
import PageSectionDescription from '../components/layout/PageSectionDescription';
import { MEDIA_QUERY } from '../styles/breakpoints';
import { COLOR, GREYSCALE } from '../styles/colors';
import { SPACING } from '../styles/spacing';
import { TYPOGRAPHY } from '../styles/typography';
import { BORDER_WIDTH } from '../styles/borders';

function BridgeStyles() {
  return (
    <Global
      styles={css`
        .wrapper {
          .nextGen {
            margin: ${SPACING.none};
          }
        }
        .nextGen {
          * {
            box-sizing: initial;
          }
          * > p:not(${PageSectionDescription}),
          label {
            margin-bottom: ${SPACING.none};
          }
          input:not([type='button'], [type='submit'], [type='text'], [type='checkbox']),
          textarea,
          table {
            box-sizing: border-box;
            background: transparent !important;
          }
          input[type='checkbox']:focus {
            outline: none;
          }
          input[type='checkbox']:focus-visible {
            outline: ${COLOR.blue} solid ${BORDER_WIDTH.sm};
            outline-offset: 2px;
          }
          select {
            box-sizing: border-box;
          }
          code {
            padding: ${SPACING.none};
            white-space: normal;
            background-color: transparent;
            color: ${GREYSCALE.grey80};
            font-size: ${TYPOGRAPHY.fontSize.md};
            font-family: monospace;
          }
          &:not(#reportsOverview)
            :is(.timePickerWrapper, .rdrYearPicker, .rdrMonthPicker)
            > select {
            background: black !important;
          }

          /* Bootstrap overrides */
          .modal-content {
            box-shadow: none;
            background: inherit;
            color: inherit;
            .form-group,
            .adjustment-description {
              margin-bottom: ${SPACING.md} !important;
            }
          }
          .modal-header {
            min-height: 0;
            padding: ${SPACING.none};
            margin: ${SPACING.md} ${SPACING.md} ${SPACING.md} ${SPACING.none};
          }
          .modal-title {
            margin: ${SPACING.xs} ${SPACING.none} ${SPACING.none} ${SPACING.none};
          }
          .modal-body {
            padding: ${SPACING.none};
          }
          .modal-footer {
            padding: ${SPACING.none};
          }
          .btn {
            box-sizing: border-box;
          }

          /* Print modal fixes */
          .print-modal {
            height: 100%;
            margin: 0 ${SPACING.xl};
          }
          @media (max-width: ${MEDIA_QUERY.xsMax}) {
            .print-modal {
              margin: ${SPACING.none} ${SPACING.md};
            }
          }
          .print-modal * {
            box-sizing: border-box;
          }
          .print-modal input {
            background: white !important;
          }
          p + form {
            margin-top: ${SPACING.none};
          }

          input[type='checkbox'].medium {
            width: calc(${SPACING.md} + 2 * ${BORDER_WIDTH.xs});
            height: calc(${SPACING.md} + 2 * ${BORDER_WIDTH.xs});
          }
          input[type='checkbox'].large {
            width: calc(${SPACING.lg} + 2 * ${BORDER_WIDTH.xs});
            height: calc(${SPACING.lg} + 2 * ${BORDER_WIDTH.xs});
          }
        }
        body:not(.logged-in) .nextGen:is(#pp-legal-dpa, #pp-legal-privacy, #pp-legal-terms) {
          margin-inline: ${SPACING.md};
        }
      `}
    />
  );
}

export default BridgeStyles;
