import Section, { SectionEntries, SHOW_KEY_ONLY, SHOW_VALUE_ONLY } from './Section';

export type ShipmentCustomsFormSummaryProps = {
  customsFormEnabled: boolean;
  customsSigner: string;
  customsContentType: string;
  exporterTaxId: string | null;
  customsItems: ReadonlyArray<string | null>;
};

function ShipmentCustomsFormSection({
  customsFormEnabled,
  customsSigner,
  customsContentType,
  exporterTaxId,
  customsItems,
}: ShipmentCustomsFormSummaryProps) {
  if (!customsFormEnabled) return null;

  const itemSummaryEntries: SectionEntries = customsItems.map((line) => [SHOW_VALUE_ONLY, line]);

  const entries: SectionEntries = [
    ['Signer', customsSigner],
    ['Content Type', customsContentType],
    ['Tax Id', exporterTaxId],
    ['Items:', customsItems.length ? SHOW_KEY_ONLY : null],
    ...itemSummaryEntries,
  ];
  return <Section title="Customs Form" entries={entries} />;
}

export default ShipmentCustomsFormSection;
