import styled from '@emotion/styled';
import { useApplicationInformationQuery } from '../../../operations/queries/applicationInformation';
import { GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import { TYPOGRAPHY } from '../../../styles/typography';

const Styled = {
  ApplicationInformationWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${TYPOGRAPHY.fontSize.xxs};
    color: ${GREYSCALE.grey50};
    padding: ${SPACING.lg};
  `,
  ApplicationInformation: styled.span`
    text-align: center;
  `,
};

export default function SidebarApplicationInformation() {
  const { data } = useApplicationInformationQuery();
  return (
    <Styled.ApplicationInformationWrapper>
      <Styled.ApplicationInformation>
        Served by: <strong>{data?.applicationHostname}</strong>
      </Styled.ApplicationInformation>
      <Styled.ApplicationInformation>
        App env: <strong>{data?.applicationEnvironment}</strong>
      </Styled.ApplicationInformation>
    </Styled.ApplicationInformationWrapper>
  );
}
