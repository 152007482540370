import { DATE_FORMAT } from '../../constants';
import ServiceSummaryTable from './StyledServiceSummaryTable';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';

type ShipDateRowProps = { shipDate: string };

export default function ShipDateRow({ shipDate }: ShipDateRowProps) {
  const { formatDate } = useDateInUserTimezone();
  return (
    <ServiceSummaryTable.Row>
      <ServiceSummaryTable.Col>
        <ServiceSummaryTable.Header>Ship Date</ServiceSummaryTable.Header>
      </ServiceSummaryTable.Col>
      <ServiceSummaryTable.Col alignRight>
        {formatDate('local', shipDate, `${DATE_FORMAT.dayOfWeek}, ${DATE_FORMAT.usDate}`)}
      </ServiceSummaryTable.Col>
    </ServiceSummaryTable.Row>
  );
}
