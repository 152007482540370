import { useQuery, QueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '../../gql';
import {
  ShipmentTrackingNumbersQuery,
  ShipmentTrackingNumbersQueryVariables,
} from '../../gql/graphql';

export const shipmentTrackingNumbersQuery = gql(/* GraphQL */ `
  query ShipmentTrackingNumbersQuery($datetimeRange: DatetimeRangeInput!) {
    shipmentTrackingNumbers(datetimeRange: $datetimeRange) {
      shipmentsTransit
      shipmentsDelivered
      shipmentsNew
      shipmentsDeliveryIssue
    }
  }
`);

export const useShipmentTrackingNumbersQuery = (
  options: QueryHookOptions<ShipmentTrackingNumbersQuery, ShipmentTrackingNumbersQueryVariables>,
) => useQuery(shipmentTrackingNumbersQuery, options);

export const useShipmentTrackingNumbersLazyQuery = (
  options: QueryHookOptions<ShipmentTrackingNumbersQuery, ShipmentTrackingNumbersQueryVariables>,
) => useLazyQuery(shipmentTrackingNumbersQuery, options);
