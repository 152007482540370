export default function secondsToString(seconds: number) {
  let secs = seconds;
  const numberEnding = (num: number) => (num > 1 ? 's' : '');
  const years = Math.floor(secs / 31536000);
  const days = Math.floor((secs %= 31536000) / 86400);
  const hours = Math.floor((secs %= 86400) / 3600);
  const minutes = Math.floor((secs %= 3600) / 60);

  if (years) {
    return `${years} year${numberEnding(years)}`;
  }
  if (days) {
    return `${days} day${numberEnding(days)}`;
  }
  if (hours) {
    return `${hours} hour${numberEnding(hours)}`;
  }
  if (minutes) {
    return `${minutes} minute${numberEnding(minutes)}`;
  }
  return `${seconds} second${numberEnding(seconds)}`;
}
