import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createUspsMerchantAccountMutation = gql(/* GraphQL */ `
  mutation CreateUspsMerchantAccountMutation {
    createUspsMerchantAccount {
      ...MerchantAccountFragment
    }
  }
`);

export const useCreateUspsMerchantAccountMutation = () =>
  useMutation(createUspsMerchantAccountMutation, {
    // Add UsPS to the list of created merchant accounts after creation
    update: (cache, { data }) => {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query AddUspsToMerchantAccountsAfterCreateQuery {
              company {
                id
                merchantAccounts {
                  ...MerchantAccountFragment
                }
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          const hasMerchantAccount =
            cacheResult.company.merchantAccounts.findIndex(
              (merchantAccount) => merchantAccount.id === data.createUspsMerchantAccount.id,
            ) !== -1;
          const addedMerchantAccounts = [];

          if (!hasMerchantAccount) {
            addedMerchantAccounts.push(data.createUspsMerchantAccount);
          }

          return {
            company: {
              ...cacheResult.company,
              merchantAccounts: [...cacheResult.company.merchantAccounts, ...addedMerchantAccounts],
            },
          };
        },
      );
    },
  });
