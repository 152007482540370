import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { appVersionVar } from '../apollo/cache';

const bundleAppVersion = import.meta.env.REACT_APP_VERSION;

export default function AppVersionReloader() {
  const location = useLocation();
  const prevLocation = useRef(location);

  // For version mismatches, reload the page on next navigation
  useEffect(() => {
    const backendAppVersion = appVersionVar();

    if (
      backendAppVersion !== null &&
      backendAppVersion !== '' &&
      bundleAppVersion !== backendAppVersion &&
      location !== prevLocation.current
    ) {
      const alreadyReloaded =
        window.localStorage.getItem('appVersionReloaded') === backendAppVersion;

      // Just to be extra safe we check that we have not reloaded for this backend app version before to avoid
      // reload loops in the unlikely event that a reload does not yield the new frontend bundle version.
      if (!alreadyReloaded) {
        window.localStorage.setItem('appVersionReloaded', backendAppVersion);
        window.location.reload();
      }
    }

    prevLocation.current = location;
  }, [location]);

  return null;
}
