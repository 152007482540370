import { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import 'react-date-range/dist/styles.css';
import useOutsideClickEffect from '../../../hooks/useOutsideClickEffect';
import usePortalContainer from '../../../hooks/usePortalContainer';
import TimePicker from './TimePicker';
import Calendar from './Calendar';
import useFormatDatePickerText from '../../../hooks/useFormatDatePickerText';
import useIsMobile from '../../../hooks/useIsMobile';
import { LocalOrUTC, Weekday } from '../../../constants';
import roundToQuarterHour from '../../../utils/roundToQuarterHour';

import {
  DatePickerLinkWrapper,
  DatePickerLinkToggleWrapper,
  DatePickerLinkToggle,
  DatePickerLinkCalendarIcon,
  DatePickerLinkDateAndCaret,
  DatePickerLinkDate,
  DatePickerLinkCaretIcon,
} from './DatePickerLinkSharedComponents';

export type DatePickerLinkProps = {
  value?: Date;
  localOrUTC: LocalOrUTC;
  maxDate?: Date;
  minDate?: Date;
  larger?: boolean;
  enabledDates?: Date[];
  disabledWeekdays?: Weekday[];
  timePicker?: boolean;
  disabled?: boolean;
  onChange?: (date: Date) => void;
};

function DatePickerLink({
  minDate,
  maxDate,
  enabledDates,
  disabledWeekdays = [],
  timePicker = false,
  disabled = false,
  onChange,
  larger,
  value,
  localOrUTC,
  ...others
}: DatePickerLinkProps) {
  const [selectedDate, setSelectedDate] = useState<Date>(roundToQuarterHour(value));
  const formatDatePickerText = useFormatDatePickerText(localOrUTC);
  const [open, setOpen] = useState(false);
  const toggleRef = useRef<HTMLDivElement>(null);
  const calendarWrapperRef = useRef<HTMLDialogElement>(null);
  const portalContainer = usePortalContainer();
  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (value) setSelectedDate(roundToQuarterHour(value));
  }, [value]);

  useOutsideClickEffect([toggleRef, calendarWrapperRef], () => setOpen(false));

  return (
    <DatePickerLinkWrapper disabled={disabled}>
      <DatePickerLinkToggleWrapper disabled={disabled} ref={toggleRef} data-testid="datePickerLink">
        <DatePickerLinkToggle {...others} onClick={() => setOpen(!open)} larger={larger}>
          <DatePickerLinkCalendarIcon icon="calendar" />
          <DatePickerLinkDateAndCaret>
            <DatePickerLinkDate>
              {formatDatePickerText(selectedDate, timePicker, isMobile)}
            </DatePickerLinkDate>
            <DatePickerLinkCaretIcon icon="caret-down" />
          </DatePickerLinkDateAndCaret>
        </DatePickerLinkToggle>
      </DatePickerLinkToggleWrapper>
      {portalContainer &&
        open &&
        createPortal(
          <Calendar
            ref={calendarWrapperRef}
            toggleRef={toggleRef}
            closeOnSelect={!timePicker}
            minDate={minDate}
            maxDate={maxDate}
            enabledDates={enabledDates}
            disabledWeekdays={disabledWeekdays}
            value={selectedDate}
            localOrUTC={localOrUTC}
            onChange={(date: Date) => {
              setSelectedDate(date);
              onChange?.(date);
            }}
            onClose={() => setOpen(false)}
            secondaryPicker={
              timePicker && (
                <TimePicker
                  data-testid="timePicker"
                  onChange={(date) => {
                    setSelectedDate(date);
                    onChange?.(date);
                  }}
                  value={selectedDate}
                  localOrUTC={localOrUTC}
                />
              )
            }
          />,
          portalContainer,
        )}
    </DatePickerLinkWrapper>
  );
}

export default DatePickerLink;
