import { QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { FailedShipmentsExportQuery, FailedShipmentsExportQueryVariables } from '../../gql/graphql';

export const failedShipmentsExportQuery = gql(/* GraphQL */ `
  query FailedShipmentsExportQuery($batchId: ID!) {
    batch(id: $batchId) {
      id
      failedShipmentsExportStatus {
        exportId
        url
      }
    }
  }
`);

export const useFailedShipmentsExportQuery = (
  options?: QueryHookOptions<FailedShipmentsExportQuery, FailedShipmentsExportQueryVariables>,
) => useQuery(failedShipmentsExportQuery, options);

export const useFailedShipmentsExportLazyQuery = (
  options?: QueryHookOptions<FailedShipmentsExportQuery, FailedShipmentsExportQueryVariables>,
) => useLazyQuery(failedShipmentsExportQuery, options);
