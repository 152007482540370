import { GridQueryVariables } from '../operations/queries/connectedGridRows';

/**
 * @description a pure function that builds the body for connected data grid query strings (for rows, config, multicheck)
 * @param path a colon-separated string to the field we need to query to get our grid result. E.g 'reportsGrid' or 'batch:trackingGrid'
 * @param gridQueryVariables if the fields in the path require variables, define them here
 * @param innerQuery what fields on the grid you require, written as a string in gql syntax
 * @param returnString only used in the function's recursion, should not be defined in the first invocation of the function
 * @returns the body of the query as a gql string
 */
export default function constructGridQueryBody(
  path: string,
  gridQueryVariables: GridQueryVariables | undefined,
  innerQuery: string,
  returnString: string = '',
): string {
  const pathArray = path.split(':');
  if (!path.length) return returnString.replace('@', innerQuery); // we have hit the end of the path, insert the inner query and return the whole thing
  // given a field, build a string of args for that field, e.g. batch(batchId: $batchId, foo: $foo)
  const argsForField = (fieldName: string, qvs?: GridQueryVariables): string => {
    if (!qvs?.length) return '';
    const filteredArgEntries = qvs.filter(({ forField }) => forField === fieldName);
    if (!filteredArgEntries.length) return '';
    const str = filteredArgEntries
      .map(({ variableName }) => `${variableName}: $${variableName}`)
      .join(', ');
    return `(${str})`;
  };
  const [field, ...restPath] = pathArray;
  const fieldWithArgs = `${field}${argsForField(field, gridQueryVariables!)} { id @ }`; // use the @ as a cursor to insert into the return string
  const newReturnString = !returnString ? fieldWithArgs : returnString.replace('@', fieldWithArgs); // insert the new field into the return string
  return constructGridQueryBody(
    restPath.join(':'),
    gridQueryVariables,
    innerQuery,
    newReturnString,
  );
}
