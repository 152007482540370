import styled from '@emotion/styled';

const ButtonBar = styled.div`
  display: flex;

  button {
    flex: 1;
    margin: 0.5em 16px 0 0;

    :last-of-type {
      margin-right: 0;
    }
  }
`;

export default ButtonBar;
