import UserEmailVerificationSucceededModal from './modals/UserEmailVerificationSucceededModal';
import UserEmailVerificationFailedModal from './modals/UserEmailVerificationFailedModal';

export default function GlobalUserEmailVerificationModals() {
  return (
    <>
      <UserEmailVerificationSucceededModal />
      <UserEmailVerificationFailedModal />
    </>
  );
}
