import { useQuery, QueryHookOptions, useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import { gql } from '../../gql';
import {
  CountryDistributionChartQuery,
  CountryDistributionChartQueryVariables,
} from '../../gql/graphql';

export const countryDistributionChartQuery = gql(/* GraphQL */ `
  query CountryDistributionChartQuery($datetimeRange: DatetimeRangeInput!) {
    countryDistributionChart(datetimeRange: $datetimeRange) {
      totalCount
      countryDistributions {
        key
        count
        name
        percent
        price
      }
    }
  }
`);

export const useCountryDistributionChartQuery = (
  options: QueryHookOptions<CountryDistributionChartQuery, CountryDistributionChartQueryVariables>,
) => useQuery(countryDistributionChartQuery, options);

export const useCountryDistributionChartLazyQuery = (
  options: LazyQueryHookOptions<
    CountryDistributionChartQuery,
    CountryDistributionChartQueryVariables
  >,
) => useLazyQuery(countryDistributionChartQuery, options);
