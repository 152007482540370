import { RateSummary } from '../components/form/RateSummarySelect';
import { RateGroupSortOrder } from '../gql/graphql';
import deepCopyGraphqlData from './deepCopyGraphqlData';

const UPS_STANDARD_MAIL_CLASS_KEY = '11';

function carrierSort(a: RateSummary, b: RateSummary, carrierKey: RateGroupSortOrder) {
  // rateSummary carrier keys are lower case, so ignore case when comparing to our enums
  const carrierKeyRegex = new RegExp(carrierKey, 'i');
  // all deactivated services are sorted to the bottom, no matter which carrier
  if (a.totalPrice === 0 && b.totalPrice !== 0) return 1;
  if (b.totalPrice === 0 && a.totalPrice !== 0) return -1;

  // if the service is not deactivated, sort for carrier
  if (!!a.carrier.carrierKey.match(carrierKeyRegex) && !b.carrier.carrierKey.match(carrierKeyRegex))
    return -1;
  if (!!b.carrier.carrierKey.match(carrierKeyRegex) && !a.carrier.carrierKey.match(carrierKeyRegex))
    return 1;

  // best price is sorted to the top of the section of the associated carrier
  if (a.best) return -1;
  if (b.best) return 1;

  // sort for price inside the carrier groups
  return a.totalPrice - b.totalPrice;
}

function priceSort(a: RateSummary, b: RateSummary) {
  // deactivated services to the bottom
  if (a.totalPrice === 0 && b.totalPrice !== 0) return 1;
  if (b.totalPrice === 0 && a.totalPrice !== 0) return -1;

  // sort with price for all services that are not deactivated
  return a.totalPrice - b.totalPrice;
}

function bestSort(a: RateSummary, b: RateSummary) {
  // deactivated services to the bottom
  if (a.totalPrice === 0 && b.totalPrice !== 0) return 1;
  if (b.totalPrice === 0 && a.totalPrice !== 0) return -1;

  // best services to the top
  if (a.best) return -1;
  if (b.best) return 1;

  // sort with price for all services that are not deactivated and not 'best'
  return a.totalPrice - b.totalPrice;
}

export default function sortRateSummaries(
  rateSummaries: RateSummary[],
  sortOrder: RateGroupSortOrder,
): RateSummary[] {
  const returnRateSummaries = [...deepCopyGraphqlData(rateSummaries)];
  if (sortOrder === 'UPS' || sortOrder === 'USPS') {
    returnRateSummaries.sort((a: RateSummary, b: RateSummary) => carrierSort(a, b, sortOrder));
    const [firstRateSummary] = returnRateSummaries;

    // a list of predicates determining if a rate summary is suboptimal,
    // i.e. one we don't want to feature at the top of the sorting
    // because it is not great for PS's margins
    const isSuboptimalPredicates = [
      (rs: RateSummary) =>
        rs.firstMailClass.mailClassKey === 'GroundAdvantage' && rs.maxWeightOz >= 16.0,
      (rs: RateSummary) =>
        rs.firstMailClass.mailClassKey === 'GroundAdvantage_Cubic' && rs.maxWeightOz >= 16.0,
      (rs: RateSummary) => rs.firstMailClass.mailClassKey === UPS_STANDARD_MAIL_CLASS_KEY,
      (rs: RateSummary) =>
        rs.firstMailClass.mailClassKey === '93' &&
        rs.maxWeightOz >= 16.0 /* UPS Ground Saver === '93' */,
    ];

    // check to see if the first rate summary is suboptimal
    const firstRateSummaryIsSuboptimal = isSuboptimalPredicates.some((predicate) =>
      predicate(firstRateSummary),
    );

    if (!firstRateSummaryIsSuboptimal) {
      return returnRateSummaries;
    }

    // find the first rate summary that is not suboptimal, i.e. is a candidate to be
    // swapped with the suboptimal first option
    const betterRateSummaryIndex = returnRateSummaries.findIndex(
      (rateSummary) => !isSuboptimalPredicates.some((predicate) => predicate(rateSummary)),
    );

    // if no rate summary does not satisfy at least one of the predicates, do nothing
    if (!returnRateSummaries[betterRateSummaryIndex]) {
      return returnRateSummaries;
    }

    // if the swap candidate is ineligible (i.e. has no price attached to it), don't swap
    if (returnRateSummaries[betterRateSummaryIndex].totalPrice === 0) {
      return returnRateSummaries;
    }

    // if the two summaries are from different carriers, don't swap
    if (
      firstRateSummary.carrier.carrierKey !==
      returnRateSummaries[betterRateSummaryIndex].carrier.carrierKey
    ) {
      return returnRateSummaries;
    }

    // otherwise, switch the two options to feature the better service
    returnRateSummaries[0] = returnRateSummaries[betterRateSummaryIndex];
    returnRateSummaries[betterRateSummaryIndex] = firstRateSummary;

    return returnRateSummaries;
  }

  if (sortOrder === 'CHEAPEST') {
    // sort by price, sort deactivated services to the bottom
    returnRateSummaries.sort((a: RateSummary, b: RateSummary) => priceSort(a, b));
    return returnRateSummaries;
  }

  // sort by price, but with best service on top, sort deactivated services to the bottom
  returnRateSummaries.sort((a: RateSummary, b: RateSummary) => bestSort(a, b));
  return returnRateSummaries;
}
