export default function uriEncode(value: string | number | boolean): string {
  if (typeof value === 'boolean') {
    return value ? '1' : '0';
  }

  if (value === undefined) {
    return '';
  }

  return encodeURIComponent(value);
}
