import { DATE_FORMAT } from '../../constants';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';
import { FakeLink } from '../Link';
import ServiceSummaryTable from './StyledServiceSummaryTable';
import convertBbcode from '../../utils/convertBbcode';

type BatchEmailNotificationRowProps = {
  batch: {
    notifyRecipientsDate: string | null;
    emailNotificationPossible: boolean;
    dataSource: string;
    emailNotificationSent: boolean;
    status: string;
  };
  additionalTrackingEmailCopies?: string[];
  onClickDate: () => void;
};

export default function BatchEmailNotificationRow({
  additionalTrackingEmailCopies,
  onClickDate,
  batch,
}: BatchEmailNotificationRowProps) {
  const { formatDate, createDate } = useDateInUserTimezone();
  const {
    notifyRecipientsDate,
    emailNotificationPossible,
    dataSource,
    emailNotificationSent,
    status,
  } = batch;
  const utcDate = createDate(notifyRecipientsDate ?? 'now');
  const formattedDate = formatDate(
    'UTC',
    utcDate,
    `${DATE_FORMAT.dayOfWeek}, ${DATE_FORMAT.usDate} ${DATE_FORMAT.time12NoLeadingZero} ${DATE_FORMAT.timezone}`,
  );
  const notificationDateHasPassed = utcDate < createDate('now');

  const asModalLink = (linkText: string) => (
    <FakeLink onClick={onClickDate} data-testid="scheduled-date">
      {linkText}
    </FakeLink>
  );

  const renderContent = () => {
    // we are in a "refunding" state AND no notification has been sent
    if ((status === 'REFUNDED' || status === 'REFUND_PENDING') && !emailNotificationSent) {
      return '-';
    }

    // if the email notification is possible
    if (emailNotificationPossible) {
      // and we have a date
      if (notifyRecipientsDate) {
        return notificationDateHasPassed ? formattedDate : asModalLink(formattedDate);
      }
      // notification is possible and we have no date
      return asModalLink('Not scheduled');
    }
    // if we have a platform specific message, such as "Pirate Ship's emails are turned off in Settings / Integrations / Woocommerce" or "X does not allow emails to be sent outside of X"
    if (dataSource === 'INTEGRATION') {
      if (additionalTrackingEmailCopies?.length) {
        return additionalTrackingEmailCopies.map((copy: string) => (
          <div key={copy}>{convertBbcode(copy)}</div>
        ));
      }
      // no additional information was provided by the integrations, show a standard message here.
      return (
        <div>
          {convertBbcode(
            "Pirate Ship's emails are turned off in [link=/settings/integrations]Settings / Integrations",
          )}
        </div>
      );
    }

    // all other cases
    return 'No Email imported';
  };

  return (
    <ServiceSummaryTable.Row>
      <ServiceSummaryTable.Col>
        <ServiceSummaryTable.Header>Email Recipients</ServiceSummaryTable.Header>
      </ServiceSummaryTable.Col>
      <ServiceSummaryTable.Col alignRight>{renderContent()}</ServiceSummaryTable.Col>
    </ServiceSummaryTable.Row>
  );
}
