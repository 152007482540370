import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const billingAddressQuery = gql(/* GraphQL */ `
  query BillingAddressQuery {
    company {
      id
      address {
        id
        company
        fullName
        address1
        address2
        city
        postcode
        countryCode
        regionCode
        valid
        phone
      }
    }
  }
`);

export const useBillingAddressQuery = () => useQuery(billingAddressQuery);
