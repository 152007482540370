import SingleUpLeft from './singleUpLeft.svg';
import SingleUpRight from './singleUpRight.svg';
import DoubleUp from './doubleUp.svg';
import FourSix from './fourSix.svg';
import TwoSeven from './twoSeven.svg';

const printerTypeIcons = {
  SingleUpLeft,
  SingleUpRight,
  DoubleUp,
  FourSix,
  TwoSeven,
};

export default printerTypeIcons;
