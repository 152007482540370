import styled from '@emotion/styled';
import { GREYSCALE } from '../../styles/colors';
import { Row, Col } from '../layout/Grid';
import Collapsible from '../layout/Collapsible';
import { SPACING } from '../../styles/spacing';
import { PlatformIcon } from '../Icon';
import { ExternalLink } from '../Link';
import { PlatformKey } from '../../assets/platformIcons';
import type { BatchQuery } from '../../gql/graphql';
import { CountryMap } from '../../utils/createCountryMap';
import RecipientDetailsSection from './RecipientDetailsSection';
import OrderInformationSection from './OrderInformationSection';
import BatchCustomsFormSection from './BatchCustomsFormSection';
import PackageDetailsSection from './PackageDetailsSection';
import ShipFromAddressSection from './ShipFromAddressSection';
import ReturnAddressSection from './ReturnAddressSection';
import ShipmentSummarySection from './ShipmentSummarySection';
import LabelDetailsSection from './LabelDetailsSection';
import BatchPassthroughDataSection from './BatchPassthroughDataSection';
import RubberStampsBatchSection from './RubberStampsBatchSection';
import OrderItemsSection from './OrderItemsSection';
import getShipmentOrderLink from '../../utils/shipmentLink';

const Styled = {
  Container: styled.div`
    margin-bottom: ${SPACING.xxl};
  `,
  IconWrapper: styled.span`
    margin: ${SPACING.none} ${SPACING.sm};
    line-height: ${SPACING.none};
  `,
  PlatformIconHeader: styled.div`
    display: flex;
    margin-left: ${SPACING.sm};
  `,
  Title: styled.div`
    color: ${GREYSCALE.grey50};
    position: relative;
    top: 2px;
  `,
  Link: styled(ExternalLink)``,
};

type Batch = BatchQuery['batch'];

type BuyPageShipmentDetailsBoxProps = {
  batch: Batch;
  countryMap: CountryMap;
};

export default function BuyPageShipmentDetailsBox({
  batch,
  countryMap,
}: BuyPageShipmentDetailsBoxProps) {
  const isMultiple = batch.numShipments > 1;

  const renderSupplementaryHeader = () => {
    const isPlatform = batch.dataSource === 'INTEGRATION';
    const currentLink = getShipmentOrderLink(batch.shipments[0].order);
    const platformTitle = batch.shipments[0].order?.platform.title;
    const platformKey = batch.shipments[0].order?.platformKey;

    if (isMultiple) {
      return (
        <Col>
          <Styled.Title>
            <strong>Multiple Labels ({batch.numShipments}) /</strong> {batch.stepText}
          </Styled.Title>
        </Col>
      );
    }

    return (
      isPlatform &&
      !!platformKey && (
        <Styled.PlatformIconHeader>
          <Styled.IconWrapper>
            <PlatformIcon icon={platformKey as PlatformKey} height={20} width={20} />
          </Styled.IconWrapper>
          {!currentLink?.url ? (
            <Styled.Title>Imported from {platformTitle}</Styled.Title>
          ) : (
            <Styled.Link href={currentLink.url} target="_blank" rel="noopener noreferrer">
              View Order on {platformTitle}
            </Styled.Link>
          )}
        </Styled.PlatformIconHeader>
      )
    );
  };

  const { recipientAddress } = batch.shipments[0];

  return (
    <Styled.Container id="shipment-info">
      <Collapsible label="Shipment Details" customContent={renderSupplementaryHeader()}>
        <Row>
          <Col md={4}>
            <RecipientDetailsSection hideIf={isMultiple} recipientDetails={recipientAddress} />
            <ShipFromAddressSection
              isWarehouseShy={batch.warehouse.shy}
              title={batch.warehouse.title}
              address={batch.warehouse.originAddress}
              countryMap={countryMap}
            />
            <ReturnAddressSection
              hideIf={batch.warehouse.returnAddress.id === batch.warehouse.originAddress.id}
              address={batch.warehouse.returnAddress}
              countryMap={countryMap}
            />
            <ShipmentSummarySection
              hideIf={!isMultiple || batch.shipmentStatusSummary.errorCount === 0}
              shipmentStatusSummary={batch.shipmentStatusSummary}
            />
          </Col>
          <Col md={4}>
            <PackageDetailsSection
              packageSummary={batch.packageSummary}
              dataSource={batch.dataSource}
              spreadsheetMapping={batch.spreadsheetMapping}
              singleShipmentCountryCode={
                isMultiple ? undefined : batch.shipments[0].recipientAddress.countryCode
              }
            />
            <BatchCustomsFormSection customsFormSummary={batch.customsFormSummary} />
          </Col>
          <Col md={4}>
            <LabelDetailsSection
              labelSize={batch.labelSize}
              labelFileFormat={batch.labelFileFormat}
            />
            <OrderInformationSection
              hideIf={isMultiple || batch.dataSource !== 'INTEGRATION'}
              order={batch.shipments[0].order}
            />
            <BatchPassthroughDataSection
              hideIf={batch.dataSource !== 'SPREADSHEET' || !batch.spreadsheetMapping.length}
              spreadsheetMapping={batch.spreadsheetMapping}
            />
            <RubberStampsBatchSection rubberStamps={batch.rubberStamps} />
            <OrderItemsSection
              hideIf={batch.dataSource !== 'INTEGRATION' || isMultiple}
              orderItems={batch.shipments[0].order?.orderItems}
            />
          </Col>
        </Row>
      </Collapsible>
    </Styled.Container>
  );
}
