import { REFUND_MESSAGES } from '../../constants';
import convertBbcode from '../../utils/convertBbcode';
import { Row, Col } from '../layout/Grid';
import ConfirmationModal from './ConfirmationModal';

type ShipmentRefundModalProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmationButtonProgress: boolean;
  canInstantRefundShipment: boolean;
  isUpsShipment: boolean;
};

export default function ShipmentRefundModal({
  open,
  onCancel,
  onConfirm,
  confirmationButtonProgress,
  canInstantRefundShipment,
  isUpsShipment,
}: ShipmentRefundModalProps) {
  const getRefundTitle = () => {
    if (canInstantRefundShipment) {
      return REFUND_MESSAGES.shipment.instant.title;
    }
    if (isUpsShipment) {
      return REFUND_MESSAGES.shipment.ups.title;
    }
    return REFUND_MESSAGES.shipment.usps.title;
  };

  const getRefundContent = () => {
    if (canInstantRefundShipment) {
      return (
        <Col md={12} spaceBelow>
          {convertBbcode(REFUND_MESSAGES.shipment.instant.description)}
        </Col>
      );
    }
    return isUpsShipment ? (
      <Col md={12} spaceBelow>
        {convertBbcode(REFUND_MESSAGES.shipment.ups.description)}
      </Col>
    ) : (
      <Col md={12} spaceBelow>
        {convertBbcode(REFUND_MESSAGES.shipment.usps.description)}
      </Col>
    );
  };

  return (
    <ConfirmationModal
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmationButtonLabel="Continue"
      confirmationButtonProgress={confirmationButtonProgress}
      title={getRefundTitle()}
    >
      <Row>{getRefundContent()}</Row>
      <Row>
        <Col md={12}>Do you want to continue?</Col>
      </Row>
    </ConfirmationModal>
  );
}
