import { useLocation } from 'react-router';
import { DATE_FORMAT } from '../../constants';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';
import Link, { FakeLink } from '../Link';
import ServiceSummaryTable from './StyledServiceSummaryTable';

import convertBbcode from '../../utils/convertBbcode';

type EmailNotificationRowProps = {
  batch: {
    notifyRecipientsDate: string | null;
    numShipments: number;
    emailNotificationPossible: boolean;
    dataSource: string;
    emailNotificationSent: boolean;
  };
  shipment: { status: string };
  additionalTrackingEmailCopy?: string;
  onClickDate: () => void;
};

export default function ShipmentEmailNotificationRow({
  additionalTrackingEmailCopy,
  onClickDate,
  batch,
  shipment,
}: EmailNotificationRowProps) {
  const { formatDate, createDate } = useDateInUserTimezone();
  const { pathname } = useLocation();
  const {
    notifyRecipientsDate,
    numShipments,
    emailNotificationPossible,
    dataSource,
    emailNotificationSent,
  } = batch;
  const utcDate = createDate(notifyRecipientsDate ?? 'now');
  const formattedDate = formatDate(
    'UTC',
    utcDate,
    `${DATE_FORMAT.dayOfWeek}, ${DATE_FORMAT.usDate} ${DATE_FORMAT.time12NoLeadingZero} ${DATE_FORMAT.timezone}`,
  );
  const notificationDateHasPassed = utcDate < createDate('now');

  const linkToBatch = () => (
    <Link bridgeHref={pathname.split('/shipment')[0]} to={pathname.split('/shipment')[0]}>
      Please Schedule Tracking Emails in Batch
    </Link>
  );

  const asModalLink = (linkText: string) => (
    <FakeLink onClick={onClickDate} data-testid="scheduled-date">
      {linkText}
    </FakeLink>
  );

  const renderContent = () => {
    // if we are in not in the PURCHASED state for the shipment and no notification has been sent
    if (shipment.status !== 'PURCHASED' && !emailNotificationSent) {
      return '-';
    }
    // notification is possible
    if (emailNotificationPossible) {
      if (notifyRecipientsDate && notificationDateHasPassed) {
        return formattedDate;
      }
      if (numShipments !== 1) {
        return linkToBatch();
      }
      return asModalLink(notifyRecipientsDate ? formattedDate : 'Not scheduled');
    }

    // notification is not possible
    if (dataSource === 'FORM') {
      return 'No Email Address set';
    }
    if (dataSource === 'SPREADSHEET') {
      return 'No Email Address imported';
    }
    if (dataSource === 'INTEGRATION' && additionalTrackingEmailCopy) {
      return convertBbcode(additionalTrackingEmailCopy);
    }
    return 'Not set / Not available';
  };

  return (
    <ServiceSummaryTable.Row>
      <ServiceSummaryTable.Col>
        <ServiceSummaryTable.Header>Email Recipients</ServiceSummaryTable.Header>
      </ServiceSummaryTable.Col>
      <ServiceSummaryTable.Col alignRight>{renderContent()}</ServiceSummaryTable.Col>
    </ServiceSummaryTable.Row>
  );
}
