import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const refundBatchMutation = gql(/* GraphQL */ `
  mutation RefundBatchtMutation($id: ID!) {
    refundBatch(id: $id) {
      id
    }
  }
`);

export const useRefundBatchMutation = () => useMutation(refundBatchMutation);
