import styled from '@emotion/styled';
import { GREYSCALE } from '../../styles/colors';

type BurgerIconProps = {
  dark?: boolean;
  small?: boolean;
  open?: boolean;
};

const Styled = {
  Wrapper: styled('div')<BurgerIconProps>`
    position: relative;
    width: ${({ small }) => (small ? 30 : 44)}px;
    height: ${({ small }) => (small ? 26 : 33)}px;
    margin: 0 ${({ small }) => (small ? 8 : 15)}px;
    cursor: pointer;

    & span {
      display: block;
      position: absolute;
      height: ${({ small }) => (small ? 2 : 4)}px;
      background-color: ${({ dark }) => (dark ? GREYSCALE.black : GREYSCALE.white)};
      transition: 0.25s ease-in-out;
    }
  `,
  BottomLine: styled.span<BurgerIconProps>`
    top: 0;
    width: ${({ open }) => (open ? '0' : '100%')};
    left: ${({ open }) => (open ? '50%' : '0')};
  `,
  MiddleLineOne: styled.span<BurgerIconProps>`
    width: 100%;
    top: ${({ small }) => (small ? 8 : 13)}px;
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0deg)')};
    left: 0;
  `,
  MiddleLineTwo: styled.span<BurgerIconProps>`
    width: 100%;
    top: ${({ small }) => (small ? 8 : 13)}px;
    transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0deg)')};
    left: 0;
  `,
  TopLine: styled.span<BurgerIconProps>`
    top: ${({ small }) => (small ? 16 : 26)}px;
    width: ${({ open }) => (open ? '0' : '100%')};
    left: ${({ open }) => (open ? '50%' : '0')};
  `,
};

function BurgerIcon({ dark = false, small = false, open = false }: BurgerIconProps) {
  return (
    <Styled.Wrapper dark={dark} small={small}>
      <Styled.BottomLine open={open} small={small} />
      <Styled.MiddleLineOne open={open} small={small} />
      <Styled.MiddleLineTwo open={open} small={small} />
      <Styled.TopLine open={open} small={small} />
    </Styled.Wrapper>
  );
}

export default BurgerIcon;
