import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { BraintreeError, Venmo, VenmoTokenizePayload } from 'braintree-web';
import { MEDIA_QUERY } from '../styles/breakpoints';
import useVenmo from '../hooks/useVenmo';
import VenmoLogo from '../assets/settingsIcons/venmo-logo.svg';
import { useCreateVenmoPaymentSourceMutation } from '../operations/mutations/createVenmoPaymentSource';
import useLogger from '../hooks/useLogger';
import { addRumCustomAction } from '../utils/addRumCustomAttributes';
import { SPACING } from '../styles/spacing';
import ProgressButton from './form/ProgressButton';

export type VenmoButtonProps = {
  onSuccessCallback?: (paymentSourceId: string) => void;
  onErrorCallback?: (msg: string) => void;
};

const Styled = {
  Wrapper: styled.div`
    width: 100%;

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      flex-wrap: nowrap;
      flex-direction: column;
      width: 100%;
    }
  `,
  VenmoButton: styled(ProgressButton)`
    width: 100%;
    background-color: #008cff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${SPACING.xl};
  `,
  LogoImage: styled.img`
    height: 30%;
  `,
};

export default function VenmoButton({ onSuccessCallback, onErrorCallback }: VenmoButtonProps) {
  const logger = useLogger();
  const [createVenmoPaymentSource] = useCreateVenmoPaymentSourceMutation();
  const [venmoInstance, setVenmoInstance] = useState<Venmo | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onCreateVenmoInstanceSuccess = (instance: Venmo) => {
    setVenmoInstance(instance);
  };

  const { createVenmoInstance } = useVenmo({ onSuccessCallback: onCreateVenmoInstanceSuccess });
  useMemo(() => {
    createVenmoInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVenmoSuccess = async (payload: VenmoTokenizePayload) => {
    if (!payload.nonce || payload.nonce.length === 0) {
      logger.error('No Venmo nonce returned');
      onErrorCallback?.(
        'Your Venmo account could not be added. Please try again later or choose a different payment method.',
      );
      return;
    }

    try {
      const response = await createVenmoPaymentSource({
        variables: {
          nonce: payload.nonce,
        },
      });

      const paymentSource = response.data?.createVenmoPaymentSource.paymentSource;
      if (!paymentSource) {
        return;
      }

      addRumCustomAction('venmo_account_added', { payload });
      onSuccessCallback?.(paymentSource.id);
    } catch (error) {
      onErrorCallback?.('already added');
      return;
    } finally {
      setLoading(false);
    }
  };

  /**
   * @see https://braintree.github.io/braintree-web/3.37.0/venmo_shared_errors.js.html
   */
  const onVenmoError = (error: BraintreeError) => {
    switch (error.code) {
      case 'VENMO_NOT_ENABLED':
      case 'VENMO_INVALID_PROFILE_ID':
        logger.error(error.message, undefined, error);
        onErrorCallback?.('There was an error trying to initialize Venmo. Please try again.');
        break;
      case 'VENMO_TOKENIZATION_REQUEST_ACTIVE':
        logger.debug(error.message, undefined, error);
        onErrorCallback?.(
          'Another Venmo flow is already active. Please close it to open another one.',
        );
        break;
      case 'VENMO_APP_FAILED':
        logger.error(error.message, undefined, error);
        onErrorCallback?.('The Venmo integration encountered an unknown error. Please try again.');
        break;
      case 'VENMO_APP_CANCELED':
        addRumCustomAction('VENMO_CLOSED_IN_MODAL', error);
        break;
      case 'VENMO_CANCELED':
        addRumCustomAction('VENMO_CLOSED_BACKDROP', error);
        break;
      default:
        logger.error(error.message, undefined, error);
        break;
    }
    setLoading(false);
  };

  const onVenmoClick = async () => {
    setLoading(true);

    venmoInstance?.tokenize({}, async (error?: BraintreeError, payload?: VenmoTokenizePayload) => {
      if (error) {
        onVenmoError(error);
      }
      if (payload) {
        await onVenmoSuccess(payload);
      }
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.VenmoButton
        onClick={onVenmoClick}
        progress={loading}
        data-dd-action-name="add_venmo_btn_click"
      >
        <Styled.LogoImage src={VenmoLogo} alt="Venmo" />
      </Styled.VenmoButton>
    </Styled.Wrapper>
  );
}
