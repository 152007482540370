import Modal, { ModalProps } from '../modals/Modal';
import ContentLoading from './ContentLoading';

export default function LoadingModal(props: Omit<ModalProps, 'children'>) {
  return (
    <Modal {...props}>
      <div style={{ height: 200 }}>
        <ContentLoading />
      </div>
    </Modal>
  );
}
