import { useState, useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Column, ColumnFiltersState, HeaderContext } from '@tanstack/react-table';
import TextField from '../../form/TextField';
import Button from '../../form/Button';
import ButtonBar from '../controls/ButtonBar';
import { GREYSCALE } from '../../../styles/colors';
import withOpacity from '../../../utils/withOpacity';
import { BORDER_WIDTH, BORDER_RADIUS } from '../../../styles/borders';
import ContentLoading from '../../loading/ContentLoading';
import Checkbox, { CheckboxSize } from '../../form/Checkbox';
import { SPACING } from '../../../styles/spacing';

export interface MultiCheckOption {
  value: string;
  label: string;
  count: number;
  disabled: boolean;
}

export type FetchMultiCheckOptionsCallbackType<TData, TValue> = (optionsInfo: {
  column: Column<TData, TValue>;
  columnFilters: ColumnFiltersState;
  globalFilter: string;
  allColumns: Column<TData, unknown>[];
}) => Promise<MultiCheckOption[]>;

export interface MultiCheckFilterInterfaceSettings<TData, TValue> {
  fetchMultiCheckOptions?: FetchMultiCheckOptionsCallbackType<TData, TValue>;
}

const MultiCheckFilterBase = styled.div`
  position: relative;
`;

const SearchField = styled(TextField)`
  position: relative;
  z-index: 1;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 2;
  top: ${SPACING.none};
  right: ${SPACING.none};
  bottom: ${SPACING.none};
  left: ${SPACING.none};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${withOpacity(GREYSCALE.white, 0.5)};
`;

const MultiCheckList = styled.div`
  max-height: 250px;
  margin: -4px ${SPACING.none} ${SPACING.none}; // -4 needed to create nice border overlap of search bar and list
  padding-top: ${SPACING.md};
  padding-bottom: ${SPACING.sm};

  overflow: auto;
  overflow-x: hidden;

  background-color: ${GREYSCALE.white};
  border: ${BORDER_WIDTH.xs} solid ${GREYSCALE.grey30};
  border-top: ${BORDER_WIDTH.none};
  border-bottom-left-radius: ${BORDER_RADIUS.xs};
  border-bottom-right-radius: ${BORDER_RADIUS.xs};
`;

const MultiCheckItemWrapper = styled.div`
  margin-top: ${SPACING.sm};
  margin-bottom: ${SPACING.sm};
`;

interface MultiCheckItemProps {
  value: string;
  label: string;
  checked: boolean;
  disabled: boolean;
  hidden: boolean;
  checkboxSize: CheckboxSize;
  onChange: () => void;
}
function MultiCheckItem({
  value,
  label,
  checked,
  disabled,
  hidden,
  onChange,
  checkboxSize,
}: MultiCheckItemProps) {
  return (
    <MultiCheckItemWrapper hidden={hidden}>
      <Checkbox
        type="checkbox"
        checkboxSize={checkboxSize}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        label={label}
        value={value}
      />
    </MultiCheckItemWrapper>
  );
}

export default function MultiCheckFilterInterface<TData, TValue extends unknown>(
  headerContext: HeaderContext<TData, TValue>,
) {
  const { column, table } = headerContext;
  const gridLoading = table.options.meta?.loading;
  const allColumns = table.getAllLeafColumns();
  const { columnFilters, globalFilter } = table.getState();
  const filterValue = column.getFilterValue() as string[];
  const { setFilterValue } = column;
  const { fetchMultiCheckOptions } = (column.columnDef.meta?.filterInterfaceSettings ?? {
    fetchMultiCheckOptions: undefined,
  }) as MultiCheckFilterInterfaceSettings<TData, TValue>;
  const [options, setOptions] = useState<MultiCheckOption[]>([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [search, setSearch] = useState('');
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const matchesSearch = useCallback(
    (label: string) => search === '' || label.toLowerCase().includes(search.toLowerCase()),
    [search],
  );
  const selectedOptions: string[] = filterValue || [];
  const setSelectedOptions = useCallback(
    (opts: string[]) => setFilterValue(opts),
    [setFilterValue],
  );
  const clear = useCallback(() => setFilterValue(undefined), [setFilterValue]);

  // Load options based on current filters and use cache when available
  useEffect(() => {
    let cancelled = false;

    if (!fetchMultiCheckOptions) {
      throw new Error('MultiCheckFilter is missing fetchMultiCheckOptions setting');
    }

    setIsLoadingOptions(true);
    fetchMultiCheckOptions({ column, columnFilters, globalFilter, allColumns }).then((opts) => {
      if (!cancelled) {
        setIsLoadingOptions(false);
        setOptions(opts);
        searchFieldRef.current?.focus();
      }
    });

    return () => {
      cancelled = true;
    };
  }, [column, globalFilter, allColumns, fetchMultiCheckOptions, columnFilters]);

  return (
    <MultiCheckFilterBase>
      {isLoadingOptions && (
        <LoadingOverlay>
          <ContentLoading />
        </LoadingOverlay>
      )}
      <SearchField
        ref={searchFieldRef}
        value={search || ''}
        placeholder="Search"
        onChange={(event) => {
          setSearch(event.currentTarget.value);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.currentTarget.form?.submit();
          }
        }}
        autoFocus
        small
        disabled={isLoadingOptions}
      />
      <MultiCheckList>
        {options.length > 0 && (
          <MultiCheckItem
            value=""
            label="(Select All)"
            checkboxSize="medium"
            checked={selectedOptions.length === options.length}
            disabled={gridLoading || isLoadingOptions}
            hidden={false}
            onChange={() => {
              if (selectedOptions.length === options.length) {
                if (options.length > 0) {
                  // when (Select All) is deselected, its initial value is not 0
                  clear();
                } else {
                  setSelectedOptions([]);
                }
              } else {
                setSelectedOptions(
                  options
                    .filter((option) => matchesSearch(option.label))
                    .map((option) => option.value),
                );
              }
            }}
          />
        )}
        {options.map((option) => (
          <MultiCheckItem
            key={option.value}
            value={option.value}
            label={option.count !== undefined ? `${option.label} (${option.count})` : option.label}
            checkboxSize="medium"
            checked={selectedOptions.includes(option.value)}
            disabled={gridLoading || option.disabled || isLoadingOptions}
            hidden={!matchesSearch(option.label)}
            onChange={() => {
              if (selectedOptions.includes(option.value)) {
                if (selectedOptions.length === 1) {
                  // this is the last filter still checked, before none are selected
                  clear();
                } else {
                  setSelectedOptions(
                    selectedOptions.filter((selectedOption) => selectedOption !== option.value),
                  );
                }
              } else {
                setSelectedOptions(selectedOptions.concat(option.value));
              }
            }}
          />
        ))}
      </MultiCheckList>
      <ButtonBar>
        <Button size="small" onClick={clear}>
          Clear
        </Button>
      </ButtonBar>
    </MultiCheckFilterBase>
  );
}
