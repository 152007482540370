// Common
import diver from './batch/diver-head.svg';
import footer from './batch/ship-footer.svg';
import wave from './batch/wave.svg';
// Boxes
import box1 from './batch/box-1.svg';
import box2 from './batch/box-2.svg';
import box3 from './batch/box-3.svg';
// Bubbles
import bubble1 from './batch/bubble-1.svg';
import bubble2 from './batch/bubble-2.svg';
import bubble3 from './batch/bubble-3.svg';
// Clouds
import cloud1 from './batch/cloud-1.svg';
import cloud2 from './batch/cloud-2.svg';
import cloud3 from './batch/cloud-3.svg';
// Fishes
import fish1 from './batch/fish-1.svg';
import fish2 from './batch/fish-2.svg';
import fish3 from './batch/fish-3.svg';

const loadingIcons = {
  diver,
  footer,
  wave,
  box1,
  box2,
  box3,
  bubble1,
  bubble2,
  bubble3,
  cloud1,
  cloud2,
  cloud3,
  fish1,
  fish2,
  fish3,
};

export default loadingIcons;
