import { useCallback } from 'react';

// Use 'polite' for most messages. Use 'assertive' when
// visually you choose 'danger' as the message variant.
// It interrupts the user, so is for urgent errors only.
type AriaLiveTypes = 'polite' | 'assertive';

const id = 'ariaLiveRegion';

// Copypasta to hide an element without hiding it for screen readers
// Another example: https://benmyers.dev/blog/native-visually-hidden/
export const visuallyHiddenStyle: React.CSSProperties = {
  position: 'absolute',
  visibility: 'visible',
  overflow: 'hidden',
  display: 'block',
  width: '1px',
  height: '1px',
  margin: '-1px',
  border: '0',
  padding: '0',
  clip: 'rect(0px, 0px, 0px, 0px)',
  clipPath: 'polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px)',
  whiteSpace: 'nowrap',
};

export default function useAriaAnnouncer() {
  let element = document.getElementById(id);

  if (!element) {
    element = document.createElement('div');
    element.id = id;
    element.ariaAtomic = 'true';
    element.ariaLive = 'off';
    Object.assign(element.style, visuallyHiddenStyle);
    document.body.appendChild(element);
  }

  const announce = useCallback(
    (message: string, type: AriaLiveTypes = 'polite', retain: boolean = false) => {
      if (element) {
        element.ariaLive = type;
        element.innerText = message;
      }
      // If retain = false, the message is removed after 5 seconds, so that
      // when another error comes through with the same text, it is announced.
      // Otherwise it sees no change in text, and the live region stays quiet.
      if (!retain) {
        setTimeout(() => {
          if (element) {
            element.innerText = '';
          }
        }, 5000);
      }
    },
    [element],
  );

  return announce;
}
