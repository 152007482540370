import { GridQueryVariable } from '../operations/queries/connectedGridRows';
import variableNamesAreAllUnique from './gridQueryVariablesAreAllUnique';

type GridQueryDefinitionVariables = (Pick<GridQueryVariable, 'variableName' | 'type'> &
  Partial<Pick<GridQueryVariable, 'value' | 'forField'>>)[];

// format an object as a string with correct gql query definition syntax. e.g: "$pirateName: String!"
function constructGridQueryDefinition(queryVariables?: GridQueryDefinitionVariables): string {
  if (!queryVariables?.length) return '';

  if (!variableNamesAreAllUnique(queryVariables)) {
    throw Error('not all variable names are unique');
  }

  return `(${queryVariables
    .map(({ variableName, type }) => `$${variableName}: ${type}`)
    .join('\n')})`;
}

export default constructGridQueryDefinition;
