import add from 'date-fns/add';
import sub from 'date-fns/sub';
import { useCallback } from 'react';
import useDateInUserTimezone from './useDateInUserTimezone';
import { LocalOrUTC } from '../constants';

type OffsetDatesType = {
  applyOffset: (d: Date) => Date;
  revertOffset: (d: Date) => Date;
};

// ONLY USE THESE FUNCTIONS TO PASS ALTERED DATES TO UI COMPONENTS //
export default function useOffsetDates(localOrUTC: LocalOrUTC): OffsetDatesType {
  const { userTimezoneMinutesOffset } = useDateInUserTimezone();

  // JS is weird: offset is negative where it should be positive, so multiply by -1
  // const browserTimezoneMinutesOffset = useMemo(() => new Date().getTimezoneOffset() * -1, []);
  const browserTimezoneMinutesOffset = useCallback(
    (date: Date) => new Date(date).getTimezoneOffset() * -1,
    [],
  );

  // given a UTC date, apply an offset (user timezone - browser timezone) such that it appears as local time to the user
  // OR: given a local date, apply an offset ( - browser timezone) such that it appears local to the user
  const applyOffset = useCallback(
    (d: Date): Date =>
      add(sub(d, { minutes: browserTimezoneMinutesOffset(d) }), {
        minutes: localOrUTC === 'UTC' ? userTimezoneMinutesOffset : 0,
      }),
    [browserTimezoneMinutesOffset, userTimezoneMinutesOffset, localOrUTC],
  );

  // this reverts the operation applied above.
  const revertOffset = useCallback(
    (d: Date): Date =>
      sub(add(d, { minutes: browserTimezoneMinutesOffset(d) }), {
        minutes: localOrUTC === 'UTC' ? userTimezoneMinutesOffset : 0,
      }),
    [browserTimezoneMinutesOffset, userTimezoneMinutesOffset, localOrUTC],
  );
  return { applyOffset, revertOffset };
}
