import { useEffect } from 'react';
import { useCountryDistributionChartLazyQuery } from '../../../../operations/queries/countryDistributionChart';
import { DatetimeRangeInput } from '../../../../gql/graphql';
import ReportsTop5 from './ReportsTop5';
import { Col } from '../../../layout/Grid';
import WorldMapChart from '../../../charts/WorldMapChart';
import { ReportsOverviewStyles } from '../ReportsOverviewPage';

export type ReportsCountryDistributionProps = {
  dateFormatter: () => DatetimeRangeInput;
};

export default function ReportsCountryDistribution({
  dateFormatter,
}: ReportsCountryDistributionProps) {
  const [
    loadStateDistributionChart,
    { loading: countryDistributionChartLoading, data: countryDistributionChartData },
  ] = useCountryDistributionChartLazyQuery({
    variables: {
      datetimeRange: dateFormatter(),
    },
  });

  useEffect(() => {
    loadStateDistributionChart({
      variables: {
        datetimeRange: dateFormatter(),
      },
    });
  }, [dateFormatter, loadStateDistributionChart]);

  return (
    <>
      <Col md={4}>
        <ReportsOverviewStyles.Top5Tile
          title="Top International Countries"
          fullHeight
          loading={countryDistributionChartLoading}
          labelId="reports-country-distribution-headline"
        >
          {!countryDistributionChartLoading && (
            <ReportsTop5
              entities="Countries"
              distributions={
                countryDistributionChartData?.countryDistributionChart.countryDistributions ?? []
              }
            />
          )}
        </ReportsOverviewStyles.Top5Tile>
      </Col>
      <Col md={8}>
        <WorldMapChart
          data={countryDistributionChartData?.countryDistributionChart.countryDistributions ?? []}
          customOptions={{
            chart: {
              className: 'screen-chart',
            },
          }}
        />
        <WorldMapChart
          data={countryDistributionChartData?.countryDistributionChart.countryDistributions ?? []}
          customOptions={{
            chart: {
              className: 'print-chart',
              width: 600,
              height: 350,
            },
            mapNavigation: {
              enabled: false,
            },
          }}
        />
      </Col>
    </>
  );
}
