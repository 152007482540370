import { useCallback, useState, KeyboardEvent } from 'react';
import styled from '@emotion/styled';
import { HeaderContext } from '@tanstack/react-table';
import TextField from '../../form/TextField';
import Button from '../../form/Button';
import ButtonBar from '../controls/ButtonBar';
import { TYPOGRAPHY } from '../../../styles/typography';

const Styled = {
  FilterTitle: styled.div``,
  FilterContent: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
  `,
};

export default function NumberRangeFilterInterface<TData, TValue>(
  headerContext: HeaderContext<TData, TValue>,
) {
  const { column, table } = headerContext;
  const loading = table.options.meta?.loading;
  const filterValue = column.getFilterValue() as number[];
  const [initialStartValue, initialEndValue] = filterValue || [];
  const [startValue, setStartValue] = useState(initialStartValue);
  const [endValue, setEndValue] = useState(initialEndValue);
  const submit = useCallback(() => {
    column.setFilterValue([startValue, endValue]);
  }, [column, startValue, endValue]);
  const clear = useCallback(() => column.setFilterValue(undefined), [column]);
  const submitOnEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      submit();
    }
  };

  return (
    <>
      <Styled.FilterTitle>Select range</Styled.FilterTitle>
      <Styled.FilterContent>
        <TextField
          type="number"
          value={startValue || ''}
          onChange={(event) => {
            setStartValue(Number(event.currentTarget.value));
          }}
          onKeyDown={submitOnEnter}
          autoFocus
          small
          disabled={loading}
        />
        —
        <TextField
          type="number"
          value={endValue || ''}
          onChange={(event) => {
            setEndValue(Number(event.currentTarget.value));
          }}
          onKeyDown={submitOnEnter}
          small
          disabled={loading}
        />
      </Styled.FilterContent>
      <ButtonBar>
        <Button variant="primary" size="small" onClick={submit}>
          Filter
        </Button>
        <Button size="small" onClick={clear}>
          Clear
        </Button>
      </ButtonBar>
    </>
  );
}
