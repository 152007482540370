import { useIntercom } from 'react-intercom-hook';
import { ReactNode, useMemo, useState, useEffect } from 'react';
import { FakeLink } from '../components/Link';
import { clearFlashMessages, setFlashMessage } from '../apollo/cache/flashMessages';

export default function useVerificationCodeErrorHandler() {
  const { show: showIntercom } = useIntercom();
  const [error, setError] = useState<string | undefined>(undefined);

  const errorMessages: Record<string, string | ReactNode> = useMemo(
    () => ({
      general_error: 'An error occurred. Please try again.',
      invalid_code: (
        <>
          The verification code you entered is invalid. Please try again, or{' '}
          <FakeLink onClick={showIntercom}>chat with our support</FakeLink> if you&apos;re having
          any trouble!
        </>
      ),
    }),
    [showIntercom],
  );

  useEffect(() => {
    if (error) {
      setFlashMessage(errorMessages[error] ?? errorMessages.general_error, 'danger');
    } else {
      clearFlashMessages();
    }
  }, [error, errorMessages]);

  return {
    setError: (errorCode?: string) => setError(errorCode),
    clearError: () => setError(undefined),
  };
}
