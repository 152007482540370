import { useNavigate, useLocation } from 'react-router-dom';
import LegalTextsModal from './modals/LegalTextsModal';

export default function GlobalLegalTextsModal() {
  const navigate = useNavigate();
  const { hash, pathname, search } = useLocation();
  return (
    <LegalTextsModal
      open={hash.startsWith('#modal=')}
      modalType={hash.replace('#modal=', '')}
      onClose={() => navigate(`${pathname}${search}`)}
      onLocationChange={(modalType) => navigate(`#modal=${modalType}`)}
    />
  );
}
