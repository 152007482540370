type TwoCharIso = string;
type Country = {
  id: string;
  name: string;
  countryCode: TwoCharIso;
  parentCountryId?: string;
};
type CountryData = ReadonlyArray<Country>;

export type CountryMap = Record<TwoCharIso, Country>;

function createCountryMap(countryData: CountryData): CountryMap {
  return Object.fromEntries(countryData.map((c) => [c.countryCode, c]));
}

export default createCountryMap;
