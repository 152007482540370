import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const regionsQuery = gql(/* GraphQL */ `
  query RegionsQuery($countryCode: String!) {
    regions(countryCode: $countryCode) {
      id
      regionCode
      countryCode
      name
    }
  }
`);

export const useRegionsQuery = (countryCode: string) =>
  useQuery(regionsQuery, { variables: { countryCode } });
