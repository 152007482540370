import { REFUND_MESSAGES } from '../../constants';
import convertBbcode from '../../utils/convertBbcode';
import { Row, Col } from '../layout/Grid';
import ConfirmationModal from './ConfirmationModal';

type BatchRefundModalProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmationButtonProgress: boolean;
  canInstantRefundBatch?: boolean;
  isUpsOnlyBatch?: boolean;
  isUspsOnlyBatch?: boolean;
};

export default function BatchRefundModal({
  open,
  onCancel,
  onConfirm,
  confirmationButtonProgress,
  canInstantRefundBatch,
  isUpsOnlyBatch,
  isUspsOnlyBatch,
}: BatchRefundModalProps) {
  const getRefundTitel = () => {
    if (canInstantRefundBatch) {
      return REFUND_MESSAGES.batch.instant.title;
    }
    if (isUpsOnlyBatch) {
      return REFUND_MESSAGES.batch.ups.title;
    }
    if (isUspsOnlyBatch) {
      return REFUND_MESSAGES.batch.usps.title;
    }
    return REFUND_MESSAGES.batch.default.title;
  };

  const getRefundContent = () => {
    if (canInstantRefundBatch) {
      return (
        <Col md={12} spaceBelow>
          {convertBbcode(REFUND_MESSAGES.batch.instant.description)}
        </Col>
      );
    }
    if (isUpsOnlyBatch) {
      return (
        <Col md={12} spaceBelow>
          {convertBbcode(REFUND_MESSAGES.batch.ups.description)}
        </Col>
      );
    }
    if (isUspsOnlyBatch) {
      return (
        <Col md={12} spaceBelow>
          {convertBbcode(REFUND_MESSAGES.batch.usps.description)}
        </Col>
      );
    }
    return (
      <>
        <Col md={12} spaceBelow>
          <strong>{REFUND_MESSAGES.batch.ups.title}</strong>
        </Col>
        <Col md={12} spaceBelow>
          {convertBbcode(REFUND_MESSAGES.batch.ups.description)}
        </Col>
        <Col md={12} spaceBelow>
          <strong>{REFUND_MESSAGES.batch.usps.title}</strong>
        </Col>
        <Col md={12} spaceBelow>
          {convertBbcode(REFUND_MESSAGES.batch.usps.description)}
        </Col>
      </>
    );
  };

  return (
    <ConfirmationModal
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmationButtonProgress={confirmationButtonProgress}
      confirmationButtonLabel="Continue"
      title={getRefundTitel()}
    >
      <Row>{getRefundContent()}</Row>
      <Row>
        <Col md={12}>Do you want to continue?</Col>
      </Row>
    </ConfirmationModal>
  );
}
