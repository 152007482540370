import { MouseEvent, useId, useState } from 'react';
import styled from '@emotion/styled';
import { COLOR, GREYSCALE } from '../../styles/colors';
import Tooltip from './Tooltip';
import Icon from '../Icon';
import { SPACING } from '../../styles/spacing';

const Styled = {
  Wrapper: styled.div`
    position: relative;
    display: inline-block;
  `,
  Button: styled.button`
    color: ${GREYSCALE.grey50};
    background: transparent;
    border: none;
    cursor: pointer;

    :focus-visible {
      outline-color: ${COLOR.blue};
      outline-offset: ${SPACING.sm};
    }
    :hover {
      color: ${COLOR.darkBlue};
    }
  `,
};

export type ClipboardButtonProps = {
  value: string;
  title?: string;
  ariaTitle: string; // name of thing being copied
  toolTip?: boolean;
};

export default function ClipboardButton({
  value,
  title = 'Copy to clipboard',
  ariaTitle,
  toolTip = true,
}: ClipboardButtonProps) {
  const [isCopied, setIsCopied] = useState(false);
  const icon = isCopied ? 'check' : 'paste';
  const iconColor = isCopied ? COLOR.green : '';
  const buttonId = useId();

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <Styled.Wrapper>
      <Styled.Button
        id={buttonId}
        onClick={handleClick}
        aria-label={`copy ${ariaTitle} to clipboard`}
        data-testid="clipboardButton"
      >
        <Icon icon={icon} color={iconColor} />
      </Styled.Button>
      {toolTip && (
        <Tooltip anchorSelector={`#${CSS.escape(buttonId)}`} placement="bottom">
          {title}
        </Tooltip>
      )}
    </Styled.Wrapper>
  );
}
