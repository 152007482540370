import { useState } from 'react';
import { useCreateOrUpdateUpsMerchantAccountMutation } from '../../operations/mutations/createOrUpdateUpsMerchant';
import UpsAcceptTermsModal from './UpsAcceptTermsModal';
import UpsAddressCorrectionModal from './UpsAddressCorrectionModal';

type UpsAccountModalProps = {
  open: boolean;
  buttonText: string;
  headline: string;
  onCancel: () => void;
  onAccountCreatedOrUpdated: () => void;
};

export default function UpsAccountModals({
  open,
  buttonText,
  headline,
  onCancel,
  onAccountCreatedOrUpdated,
}: UpsAccountModalProps) {
  const [
    createOrUpdateUpsMerchantAccount,
    { loading: merchantAccountSubmitting, data: merchantData },
  ] = useCreateOrUpdateUpsMerchantAccountMutation();

  const submitMerchantAccount = async () => {
    const { data } = await createOrUpdateUpsMerchantAccount();
    if (!data) return;
    const {
      createOrUpdateUpsMerchantAccount: { accountStatus, errorMessage },
    } = data;
    if (!errorMessage && accountStatus === 'OK') {
      onAccountCreatedOrUpdated();
    }
  };

  const [isAddressRetry, setIsAddressRetry] = useState(false);

  const merchantCreationError = merchantData?.createOrUpdateUpsMerchantAccount.errorMessage;
  const errorCategory = merchantData?.createOrUpdateUpsMerchantAccount.errorCategory ?? null;

  /**
   * We want to show the address modal on every error that we did not identify as being an outage of the UPS account service
   * even if we are not sure it is an address error, because most of the time changing the address helps
   * When there was a retry in the address modal, we want to keep showing the address modal
   */
  const showAddressForm =
    (merchantCreationError && errorCategory !== 'OUTAGE' && errorCategory !== 'MAINTENANCE') ||
    isAddressRetry;

  /**
   * On address errors, we only want to show the address modal and hide the error from the user to make it seem like everyting is ok
   * We show the error on retry to make it clear to the user that the changed address was not accepted and it is not just a broken
   * modal that does not do anything
   * If the error is not an address error, we want to show the error message, so it is clear that there could be something else
   * going wrong and it could be that changing the address does not help
   */
  const hasAddressError =
    errorCategory === 'BILLING_ADDRESS' ||
    errorCategory === 'INVALID_STREET_OR_STATE_OR_POSTCODE' ||
    errorCategory === 'ADDRESS_IS_PO_BOX' ||
    errorCategory === 'MILITARY_ADDRESS';

  const renderModal = () => {
    if (showAddressForm) {
      return (
        <UpsAddressCorrectionModal
          open={open}
          onCancel={onCancel}
          merchantCreationError={
            merchantCreationError && (isAddressRetry || !hasAddressError)
              ? merchantCreationError
              : undefined
          }
          onUpdateAddress={() => {
            submitMerchantAccount();
            setIsAddressRetry(true);
          }}
          merchantAccountSubmitting={merchantAccountSubmitting}
        />
      );
    }

    return (
      <UpsAcceptTermsModal
        open={open}
        onCancel={onCancel}
        buttonText={buttonText}
        headline={headline}
        submitMerchantAccount={submitMerchantAccount}
        merchantAccountSubmitting={merchantAccountSubmitting}
      />
    );
  };

  return renderModal();
}
