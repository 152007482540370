import { ReactNode } from 'react';
import { FakeLink } from '../components/Link';

export default function withIntercomLink(message: string, callback: () => void): ReactNode {
  const tags = ['[intercom]', '[/intercom]'];
  const [openTag, closeTag] = tags;

  if (!message.includes(openTag)) {
    return message;
  }

  const [linkStartIndex, linkEndIndex] = tags.map((tag) => message.indexOf(tag));

  const [firstPart, linkPart, lastPart] = [
    message.slice(0, linkStartIndex),
    message.slice(linkStartIndex + openTag.length, linkEndIndex),
    message.slice(linkEndIndex + closeTag.length),
  ];

  return (
    <>
      {firstPart}
      <FakeLink onClick={callback}>{linkPart}</FakeLink>
      {lastPart}
    </>
  );
}
