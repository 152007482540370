import { useReactiveVar } from '@apollo/client';
import { flashMessagesVar } from '../apollo/cache';
import convertBbcode from '../utils/convertBbcode';
import Alert from './Alert';
import { PageContainer } from './layout/Grid';

export default function FlashMessages() {
  const flashMessages = useReactiveVar(flashMessagesVar);

  return (
    <PageContainer data-testid="flash-messages">
      {flashMessages.map((flashMessage) => (
        <Alert
          key={flashMessage.id}
          variant={flashMessage.variant}
          data-testid={flashMessage.variant}
        >
          <strong>
            {typeof flashMessage.text === 'string'
              ? convertBbcode(flashMessage.text)
              : flashMessage.text}
          </strong>
        </Alert>
      ))}
    </PageContainer>
  );
}
