import { useState, CSSProperties, MouseEvent, RefObject, ReactNode, useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { flexRender, HeaderContext, SortDirection } from '@tanstack/react-table';
import usePortalContainer from '../../../hooks/usePortalContainer';
import { GREYSCALE } from '../../../styles/colors';
import { TYPOGRAPHY } from '../../../styles/typography';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../../styles/borders';
import isColumnTypeNumeric from '../utils/isColumnTypeNumeric';
import { BREAKPOINT } from '../../../styles/breakpoints';
import ColumnMenuItem from './ColumnMenuItem';
import Checkbox, { StyledCheckboxWrapper } from '../../form/Checkbox';
import { SPACING } from '../../../styles/spacing';
import useElementResizeObserver from '../../../hooks/useElementResizeObserver';

const Styled = {
  ColumnMenu: styled.div`
    font-size: ${TYPOGRAPHY.fontSize.sm};
    border: ${BORDER_WIDTH.xs} solid ${GREYSCALE.grey30};
    border-bottom-left-radius: ${BORDER_RADIUS.sm};
    border-bottom-right-radius: ${BORDER_RADIUS.sm};
    background: ${GREYSCALE.grey00};
    color: ${GREYSCALE.grey60};

    ${StyledCheckboxWrapper} {
      padding-left: ${SPACING.md};
    }
  `,
  ColumnMenuGroup: styled.div`
    max-height: 250px;
    margin: ${SPACING.xs} ${SPACING.md} ${SPACING.md};
    padding: ${SPACING.sm} ${SPACING.md};

    overflow: auto;
    overflow-x: hidden;
    white-space: nowrap;

    background-color: ${GREYSCALE.white};
    border: ${BORDER_WIDTH.xs} solid ${GREYSCALE.grey30};
    border-radius: ${BORDER_RADIUS.sm};

    ${StyledCheckboxWrapper} {
      padding: ${SPACING.xs} 0;
    }
  `,

  FilterMenu: styled.div`
    padding: ${SPACING.xs} ${SPACING.md} ${SPACING.md};
  `,
  ColumnMenuItemMenuGroupContainer: styled(ColumnMenuItem)`
    overflow: hidden; /* contain all margins */
  `,
};

type Section = 'filter' | 'columns';

export type ColumnMenuProps<TData, TValue> = {
  forwardedRef?: RefObject<HTMLDivElement>; // this ref, created at the DataGrid-level, is set here to help determine outside click events
  overlayRef?: RefObject<HTMLDivElement>; // this ref may be bound to an overlay in a filter interface
  headerContext: HeaderContext<TData, TValue>; // the table
  gridRef?: RefObject<HTMLDivElement>; // the grid wrapper ref, needed for the width of the grid

  onClick?: (event: MouseEvent) => void;
  onClose?: () => void;
};

export default function ColumnMenu<TData, TValue>({
  forwardedRef,
  overlayRef,
  headerContext,
  gridRef,
  onClick,
  onClose,
}: ColumnMenuProps<TData, TValue>) {
  const container = usePortalContainer();
  const { column, table } = headerContext;
  const allColumns = table.getAllColumns();
  const defaultMenu: Section = column.getCanFilter() ? 'filter' : 'columns';
  const [openSection, setOpenSection] = useState<Section>(defaultMenu);
  const [gridResizedWidth] = useElementResizeObserver(gridRef?.current as HTMLDivElement);
  const toggleSorting = (direction: SortDirection) => {
    if (column.getIsSorted() === direction) {
      column.clearSorting();
    } else {
      column.toggleSorting(direction === 'desc', false);
    }
  };
  // determine width and positioning of menu
  const positionCSS = useMemo<CSSProperties | undefined>(() => {
    const headerDiv = document.getElementById(headerContext.header.id);

    if (!gridRef?.current || !headerDiv) return undefined;
    const {
      left: gridLeftEdge,
      right: gridRightEdge,
      width: gridWidth,
    } = gridRef.current.getBoundingClientRect();

    const MENU_WIDTH_DESKTOP = 450;
    const {
      left: headerLeftEdge,
      right: headerRightEdge,
      top: headerTopEdge,
      height: headerHeight,
    } = headerDiv.getBoundingClientRect();
    const top = window.scrollY + headerTopEdge + headerHeight;
    let left = 0;
    let width = 0;
    if (gridWidth < BREAKPOINT.md) {
      // mobile: full width of grid parent
      left = gridLeftEdge;
      width = gridWidth - 2; // remove the width of the left and right borders
    } else {
      // desktop: relative to header button
      left = (() => {
        if (
          headerRightEdge > gridRightEdge &&
          headerLeftEdge + MENU_WIDTH_DESKTOP > gridRightEdge
        ) {
          return gridRightEdge - MENU_WIDTH_DESKTOP - 2; // align menu to right edge of grid
        }
        if (headerLeftEdge < gridLeftEdge && headerRightEdge - gridLeftEdge < MENU_WIDTH_DESKTOP) {
          return gridLeftEdge; // align menu to left edge of grid
        }
        if (headerLeftEdge + MENU_WIDTH_DESKTOP > gridRightEdge || headerLeftEdge < gridLeftEdge) {
          return headerRightEdge - MENU_WIDTH_DESKTOP; // align menu to right edge of header box
        }
        return headerLeftEdge; // align menu to left edge of header box
      })();
      width = MENU_WIDTH_DESKTOP;
    }

    return {
      position: 'absolute',
      left,
      top,
      width,
      display: 'block',
      zIndex: 2, // this needs to be set here! PP-11988
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gridRef,
    headerContext,
    gridResizedWidth, // we use this not for its inherent value, but as a trigger to listen for changes
  ]);

  if (!gridRef || !container || !positionCSS || !gridResizedWidth || !headerContext) {
    return null;
  }

  return ReactDOM.createPortal(
    <Styled.ColumnMenu
      ref={forwardedRef}
      style={positionCSS}
      onClick={onClick}
      role="menu"
      tabIndex={0}
    >
      {column.getCanSort() && (
        <ColumnMenuItem
          label="Sort Ascending"
          icon={
            isColumnTypeNumeric(column.columnDef.meta?.dataType || 'default')
              ? 'sort-numeric-up'
              : 'sort-alpha-up'
          }
          onClick={() => {
            toggleSorting('asc');
            onClose?.();
          }}
        />
      )}
      {column.getCanSort() && (
        <ColumnMenuItem
          label="Sort Descending"
          icon={
            isColumnTypeNumeric(column.columnDef.meta?.dataType || 'default')
              ? 'sort-numeric-down-alt'
              : 'sort-alpha-down-alt'
          }
          onClick={() => {
            toggleSorting('desc');
            onClose?.();
          }}
        />
      )}
      {column.getCanFilter() && (
        <ColumnMenuItem
          label="Filter Data"
          icon="filter"
          expandable
          onClick={() => setOpenSection('filter')}
          onKeyDown={(event) =>
            (event.key === 'Return' || event.key === ' ' || event.key === 'Spacebar') &&
            setOpenSection('filter')
          }
          open={openSection === 'filter'}
        >
          {() => (
            <Styled.FilterMenu>
              {flexRender(column.columnDef.meta?.filterInterface, {
                ...headerContext,
                overlayRef,
              } as HeaderContext<TData, TValue> & { overlayRef?: RefObject<any> })}
            </Styled.FilterMenu>
          )}
        </ColumnMenuItem>
      )}
      <Styled.ColumnMenuItemMenuGroupContainer
        label="Columns"
        icon="columns"
        expandable
        onClick={() => setOpenSection('columns')}
        onKeyDown={(event) =>
          (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') &&
          setOpenSection('columns')
        }
        open={openSection === 'columns'}
      >
        {() => {
          const visibleColumnCount = allColumns.filter((col) => col.getIsVisible()).length;

          return (
            <Styled.ColumnMenuGroup
              // Do not bubble scroll event as it would lead to the column menu being closed
              onScroll={(event) => event.stopPropagation()}
            >
              {allColumns.map((col) => (
                <div key={col.id}>
                  <Checkbox
                    type="checkbox"
                    checkboxSize="medium"
                    disabled={(visibleColumnCount === 1 && col.getIsVisible()) || !col.getCanHide()}
                    checked={col.getIsVisible()}
                    onClick={(event: unknown) => {
                      col.getToggleVisibilityHandler()(event);
                    }}
                    label={col.columnDef.header as ReactNode}
                  />
                </div>
              ))}
            </Styled.ColumnMenuGroup>
          );
        }}
      </Styled.ColumnMenuItemMenuGroupContainer>
    </Styled.ColumnMenu>,
    container,
  );
}
