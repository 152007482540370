import { useRef, useState } from 'react';
import type { Row as TableRow } from '@tanstack/react-table';
import { PageContainer, Col, Row } from '../../layout/Grid';
import ConnectedDataGrid from '../../dataGrid/ConnectedDataGrid';
import PageHeader from '../../layout/PageHeader';
import { OriginalRow } from './ShipmentsPage';
import { DataGridImperativeHandleRef } from '../../dataGrid/DataGrid';
import ProgressButton from '../../form/ProgressButton';

function ReturnsPage() {
  const [isExporting, setIsExporting] = useState(false);
  const imperativeHandleRef = useRef<DataGridImperativeHandleRef>(null);

  const makeLinkToShipment = (row: TableRow<any>) => {
    const id = row.original.id as OriginalRow;
    const batch = row.original.batch_id as OriginalRow;
    return `/batch/${batch}/shipment/${id}`;
  };

  return (
    <PageContainer>
      <PageHeader title="Return Labels" />
      <Row>
        <Col md={12}>
          <ConnectedDataGrid
            imperativeHandleRef={imperativeHandleRef}
            queryName="returnLabelsReport"
            onRowClick={makeLinkToShipment}
            rightSideSlot={
              <Col xs={12} md={3} spaceBelow>
                <ProgressButton
                  progress={isExporting}
                  disabled={isExporting}
                  fullWidth
                  size="medium"
                  variant="info"
                  onClick={async () => {
                    setIsExporting(true);
                    await imperativeHandleRef.current?.exportData();
                    setIsExporting(false);
                  }}
                >
                  Export
                </ProgressButton>
              </Col>
            }
          />
        </Col>
      </Row>
    </PageContainer>
  );
}

export default ReturnsPage;
