import printerTypeIcons from '../assets';
import { DropdownSelectOption } from '../../components/form/DropdownSelect';
import { PageLayout } from '../../gql/graphql';

export type LabelSelectOption = DropdownSelectOption<PageLayout | 'loading'>; // we use type to determine description based on settings below

export const labelOptionsPdf2x7: LabelSelectOption[] = [
  {
    value: 'LAYOUT_2x7',
    title: '2x7" Shipping Label',
    description: 'Brother QL 800 series, Dymo 450 / 99019, and other small label printers',
    iconUrl: printerTypeIcons.TwoSeven,
  },
];

export const labelOptionsPdf4x6: LabelSelectOption[] = [
  {
    value: 'LAYOUT_4x6',
    title: '4x6" Shipping Label',
    description: 'Formatted for Thermal Label Printers',
    iconUrl: printerTypeIcons.FourSix,
  },
  {
    value: 'LAYOUT_85x11_2UP',
    title: '8.5x11" - 2 Shipping Labels per Page',
    description: 'Two 4x6" labels per letter-sized page',
    iconUrl: printerTypeIcons.DoubleUp,
  },
  {
    value: 'LAYOUT_85x11_1UP_LEFT',
    title: '8.5x11" - 1 Shipping Label per Page - Left Side',
    description: 'One 4x6" label on the left side of a letter-sized page',
    iconUrl: printerTypeIcons.SingleUpLeft,
  },
  {
    value: 'LAYOUT_85x11_1UP_RIGHT',
    title: '8.5x11" - 1 Shipping Label per Page - Right Side',
    description: 'One 4x6" label on the right side of a letter-sized page',
    iconUrl: printerTypeIcons.SingleUpRight,
  },
];

export const labelOptionsZpl4x6: LabelSelectOption[] = [
  {
    value: 'LAYOUT_4x6',
    title: '4x6" Shipping Label',
    description: 'Formatted for Thermal Label Printers',
    iconUrl: printerTypeIcons.FourSix,
  },
];
