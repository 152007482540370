import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import 'react-date-range/dist/styles.css';
import { DateRangePicker } from 'react-date-range';
import { TYPOGRAPHY } from '../styles/typography';
import { COLOR, GREYSCALE } from '../styles/colors';
import withOpacity from '../utils/withOpacity';
import { BORDER_RADIUS, BORDER_WIDTH } from '../styles/borders';
import useOffsetDates from '../hooks/useOffsetDates';
import roundToQuarterHour from '../utils/roundToQuarterHour';
import { LocalOrUTC } from '../constants';

const Styled = {
  DateRangeWrapper: styled.div`
    .rdrCalendarWrapper {
      color: ${GREYSCALE.black};
      font-size: ${TYPOGRAPHY.fontSize.xs};
    }

    .rdrDateDisplayWrapper {
      background-color: ${GREYSCALE.grey10};
    }

    .rdrDateDisplay {
      margin: 0.833em;
    }

    .rdrDateDisplayItem {
      border-radius: ${BORDER_RADIUS.sm};
      background-color: ${GREYSCALE.white};
      box-shadow: 0 5px 15px ${withOpacity(GREYSCALE.black, 0.5)};
      border: ${BORDER_WIDTH.sm} solid transparent;
      input {
        cursor: pointer;
        height: 2.5em;
        line-height: 2.5em;
        border: 0px;
        background: transparent;
        width: 100%;
        color: ${GREYSCALE.grey50};
      }
    }

    .rdrDateDisplayItemActive {
      border-color: ${COLOR.blue};
    }

    .rdrDateDisplayItemActive {
      input {
        color: ${GREYSCALE.grey50};
      }
    }

    .rdrMonthAndYearWrapper {
      align-items: center;
      height: 60px;
      padding-top: 10px;
      button,
      select {
        &:focus-visible {
          outline: ${BORDER_WIDTH.sm} solid ${COLOR.blue};
        }
      }
    }

    .rdrMonthAndYearPickers {
      font-weight: ${TYPOGRAPHY.fontWeight.medium};
      select {
        appearance: none;
        -webkit-appearance: none;
        border: 0;
        background: transparent;
        padding: 10px 30px 10px 10px;
        border-radius: ${BORDER_RADIUS.sm};
        outline: 0;
        color: ${GREYSCALE.grey80};
        background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>")
          no-repeat;
        background-position: right 8px center;
        cursor: pointer;
        text-align: center;
        &:hover,
        &:focus-visible {
          background-color: ${withOpacity(GREYSCALE.black, 0.07)};
        }
      }
    }

    .rdrMonthPicker,
    .rdrYearPicker {
      margin: 0 5px;
    }

    .rdrNextPrevButton {
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 0.833em;
      padding: 0;
      border: 0;
      border-radius: ${BORDER_RADIUS.sm};
      background: ${GREYSCALE.grey10};
      &:hover {
        background: ${GREYSCALE.grey10};
      }
      i {
        display: block;
        width: 0;
        height: 0;
        padding: 0;
        text-align: center;
        border-style: solid;
        margin: auto;
        transform: translate(-3px, 0px);
      }
    }

    .rdrPprevButton {
      i {
        border-width: 4px 6px 4px 4px;
        border-color: transparent ${COLOR.darkBlue} transparent transparent;
        transform: translate(-3px, 0px);
      }
    }

    .rdrNextButton {
      i {
        margin: 0 0 0 7px;
        border-width: 4px 4px 4px 6px;
        border-color: transparent transparent transparent ${COLOR.darkBlue};
        transform: translate(3px, 0px);
      }
    }

    .rdrWeekDays {
      padding: 0 0.833em;
    }

    .rdrMonth {
      padding: 0 0.833em 1.666em 0.833em;
      width: 220px;
      .rdrWeekDays {
        padding: 0;
      }
    }

    .rdrMonths.rdrMonthsVertical .rdrMonth:first-of-type .rdrMonthName {
      display: none;
    }

    .rdrWeekDay {
      font-weight: ${TYPOGRAPHY.fontWeight.regular};
      line-height: 2.667em;
      color: ${GREYSCALE.grey50};
    }

    .rdrDay {
      background: transparent;
      user-select: none;
      border: 0;
      padding: 0;
      line-height: 3em;
      height: 3em;
      text-align: center;
      color: ${GREYSCALE.grey90};
      &:focus {
        outline: 0;
      }
    }

    .rdrDayNumber {
      outline: 0;
      font-weight: ${TYPOGRAPHY.fontWeight.regular};
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      top: 5px;
      bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rdrDayToday .rdrDayNumber span {
      font-weight: ${TYPOGRAPHY.fontWeight.medium};
      &:after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 18px;
        height: 2px;
        border-radius: ${BORDER_RADIUS.xxs};
        background: ${COLOR.blue};
      }
    }

    .rdrDayToday:not(.rdrDayPassive) {
      .rdrInRange,
      .rdrStartEdge,
      .rdrEndEdge,
      .rdrSelected {
        & ~ .rdrDayNumber span:after {
          background: transparent;
        }
      }
    }

    .rdrDay:not(.rdrDayPassive) {
      .rdrInRange,
      .rdrStartEdge,
      .rdrEndEdge,
      .rdrSelected {
        & ~ .rdrDayNumber {
          span {
            color: ${withOpacity(GREYSCALE.white, 0.85)};
          }
        }
      }
    }

    .rdrSelected,
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      background: ${COLOR.blue};
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      bottom: 5px;
    }

    .rdrSelected {
      left: 2px;
      right: 2px;
    }

    .rdrInRange {
    }

    .rdrStartEdge {
      border-top-left-radius: ${BORDER_RADIUS.sm};
      border-bottom-left-radius: ${BORDER_RADIUS.sm};
      left: 2px;
    }

    .rdrEndEdge {
      border-top-right-radius: ${BORDER_RADIUS.sm};
      border-bottom-right-radius: ${BORDER_RADIUS.sm};
      right: 2px;
    }

    .rdrSelected {
      border-radius: ${BORDER_RADIUS.sm};
    }

    .rdrDayStartOfMonth,
    .rdrDayStartOfWeek {
      .rdrInRange,
      .rdrEndEdge {
        border-top-left-radius: ${BORDER_RADIUS.sm};
        border-bottom-left-radius: ${BORDER_RADIUS.sm};
        left: 2px;
      }
    }

    .rdrDayEndOfMonth,
    .rdrDayEndOfWeek {
      .rdrInRange,
      .rdrStartEdge {
        border-top-right-radius: ${BORDER_RADIUS.sm};
        border-bottom-right-radius: ${BORDER_RADIUS.sm};
        right: 2px;
      }
    }

    .rdrDayStartOfMonth,
    .rdrDayStartOfWeek {
      .rdrDayInPreview,
      .rdrDayEndPreview {
        border-top-left-radius: ${BORDER_RADIUS.sm};
        border-bottom-left-radius: ${BORDER_RADIUS.sm};
        border-left-width: ${BORDER_WIDTH.xs};
        left: 0px;
      }
    }

    .rdrDayEndOfMonth,
    .rdrDayEndOfWeek {
      .rdrDayInPreview,
      .rdrDayStartPreview {
        border-top-right-radius: ${BORDER_RADIUS.sm};
        border-bottom-right-radius: ${BORDER_RADIUS.sm};
        border-right-width: ${BORDER_WIDTH.xs};
        right: 0px;
      }
    }

    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
      background: ${withOpacity(GREYSCALE.white, 0.9)};
      position: absolute;
      top: 3px;
      left: 0px;
      right: 0px;
      bottom: 3px;
      pointer-events: none;
      border: 0px solid ${COLOR.blue};
      z-index: 1;
    }

    .rdrDayStartPreview {
      border-top-width: ${BORDER_WIDTH.xs};
      border-left-width: ${BORDER_WIDTH.xs};
      border-bottom-width: ${BORDER_WIDTH.xs};
      border-top-left-radius: ${BORDER_RADIUS.sm};
      border-bottom-left-radius: ${BORDER_RADIUS.sm};
      left: 0px;
    }

    .rdrDayInPreview {
      border-top-width: ${BORDER_WIDTH.xs};
      border-bottom-width: ${BORDER_WIDTH.xs};
    }

    .rdrDayEndPreview {
      border-top-width: ${BORDER_WIDTH.xs};
      border-right-width: ${BORDER_WIDTH.xs};
      border-bottom-width: ${BORDER_WIDTH.xs};
      border-top-right-radius: ${BORDER_RADIUS.sm};
      border-bottom-right-radius: ${BORDER_RADIUS.sm};
      right: 2px;
      right: 0px;
    }

    .rdrDefinedRangesWrapper {
      display: none;
      font-size: ${TYPOGRAPHY.fontSize.xs};
      width: 226px;
      border-right: solid ${BORDER_WIDTH.sm} ${GREYSCALE.grey10};
      background: ${GREYSCALE.white};
      .rdrStaticRangeSelected {
        color: ${COLOR.blue};
        font-weight: ${TYPOGRAPHY.fontWeight.medium};
      }
    }

    .rdrStaticRange {
      border: 0;
      cursor: pointer;
      display: block;
      outline: 0;
      border-bottom: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey10};
      padding: 0;
      background: #fff;
      &:hover,
      &:focus {
        .rdrStaticRangeLabel {
          background: ${GREYSCALE.grey10};
        }
      }
    }

    .rdrStaticRangeLabel {
      display: block;
      outline: 0;
      line-height: 18px;
      padding: 10px 20px;
      text-align: left;
    }

    .rdrInputRanges {
      padding: 10px 0;
    }

    .rdrInputRange {
      align-items: center;
      padding: 5px 20px;
    }

    .rdrInputRangeInput {
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: ${BORDER_RADIUS.sm};
      text-align: center;
      border: solid ${BORDER_WIDTH.sm} ${GREYSCALE.grey20};
      margin-right: 10px;
      color: ${GREYSCALE.grey60};
      &:focus,
      &:hover {
        border-color: ${GREYSCALE.grey30};
        outline: 0;
        color: ${GREYSCALE.grey80};
      }
    }

    .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
      content: '';
      border: ${BORDER_WIDTH.sm} solid ${COLOR.blue};
      border-radius: ${BORDER_RADIUS.sm};
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: 0px;
      right: 0px;
      background: transparent;
    }

    .rdrDayPassive {
      pointer-events: none;
      .rdrDayNumber span {
        color: ${GREYSCALE.grey20};
      }
      .rdrInRange,
      .rdrStartEdge,
      .rdrEndEdge,
      .rdrSelected,
      .rdrDayStartPreview,
      .rdrDayInPreview,
      .rdrDayEndPreview {
        display: none;
      }
    }

    .rdrDayDisabled {
      background-color: ${GREYSCALE.grey10};
      .rdrDayNumber span {
        color: ${GREYSCALE.grey30};
      }
      .rdrInRange,
      .rdrStartEdge,
      .rdrEndEdge,
      .rdrSelected,
      .rdrDayStartPreview,
      .rdrDayInPreview,
      .rdrDayEndPreview {
        filter: grayscale(100%) opacity(60%);
      }
    }

    .rdrMonthName {
      text-align: left;
      font-weight: ${TYPOGRAPHY.fontWeight.medium};
      color: ${GREYSCALE.grey50};
      padding: 0.833em;
    }

    .rdrDateDisplayWrapper {
      display: none;
    }
  `,
};

type DateRangeProps = {
  startDate: Date;
  endDate: Date;
  setStartDatetime(startDatetime: Date): void;
  setEndDatetime(endDatetime: Date): void;
  localOrUTC: LocalOrUTC;
};

function DatetimeRange({
  startDate,
  endDate,
  setStartDatetime,
  setEndDatetime,
  localOrUTC,
}: DateRangeProps) {
  const { applyOffset, revertOffset } = useOffsetDates(localOrUTC);
  // these dates are the values passed in from above (the true form values)
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(roundToQuarterHour(startDate));
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(roundToQuarterHour(endDate));

  // if start date changes from above
  useEffect(() => {
    if (startDate) {
      setSelectedStartDate(roundToQuarterHour(startDate));
    }
  }, [applyOffset, startDate]);

  // if end date changes from above
  useEffect(() => {
    if (endDate) {
      setSelectedEndDate(roundToQuarterHour(endDate));
    }
  }, [applyOffset, endDate]);

  // NOTE: the external calendar library will show any dates given to it in BROWSER time. We want it to show in USER time.
  // this is why we apply() the correct offset when giving the calendar a date and revert() it when saving the value(s).
  // the form value will still always be in true UTC.
  return (
    <Styled.DateRangeWrapper>
      <DateRangePicker
        ranges={[
          { startDate: applyOffset(selectedStartDate), endDate: applyOffset(selectedEndDate) },
        ]}
        onChange={({ range1: offsetRange }) => {
          // internal
          setSelectedEndDate(revertOffset(offsetRange.endDate as Date));
          setSelectedStartDate(revertOffset(offsetRange.startDate as Date));
          // external
          setStartDatetime(revertOffset(offsetRange.startDate as Date));
          setEndDatetime(revertOffset(offsetRange.endDate as Date));
        }}
        // showSelectionPreview
        moveRangeOnFirstSelection={false}
        months={2}
        direction="horizontal"
      />
    </Styled.DateRangeWrapper>
  );
}

export default DatetimeRange;
