import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { PageContainer } from '../../layout/Grid';
import PageHeader from '../../layout/PageHeader';
import { SPACING } from '../../../styles/spacing';
import { BORDER_RADIUS } from '../../../styles/borders';
import { COLOR, GREYSCALE } from '../../../styles/colors';
import useIsMobile from '../../../hooks/useIsMobile';
import useInModal from '../../../hooks/useInModal';
import { TYPOGRAPHY } from '../../../styles/typography';
import linkStyles from '../../linkStyles';

type PrivacyProps = {
  requireAcceptance?: boolean;
  clickHandler?: (elem: HTMLDivElement | null) => void;
};

const Styled = {
  PageContainer: styled(PageContainer)<{ modal?: boolean }>`
    max-width: 750px;
    ${({ modal }) =>
      !modal &&
      css`
        header {
          padding-bottom: ${SPACING.xl};
        }
      `}
  `,
  Scrollbox: styled.div<{ requireAcceptance: boolean }>`
    height: ${({ requireAcceptance }) => (requireAcceptance ? '400px' : '500px')};
    max-width: 80ch;
    overflow: auto;
    border: ${SPACING.xxs} solid ${GREYSCALE.grey30};
    border-radius: ${BORDER_RADIUS.sm};
    padding: ${SPACING.lg};
    margin-top: -3px;
  `,
  Error: styled('p')`
    position: absolute;
    margin: 0;
    font-size: smaller;
    color: ${COLOR.red};
  `,
  Content: styled.div<{ mobile?: boolean; modal?: boolean }>`
    ${({ modal }) =>
      !modal &&
      css`
        h2 {
          margin-block: 1.4em 1em;
        }
        p,
        li {
          font-size: ${TYPOGRAPHY.fontSize.sm};
        }
      `}
    p, li {
      margin-bottom: ${SPACING.md} !important;
    }
    a {
      ${linkStyles};
    }
    td,
    th {
      border: 1px solid #cccccc;
      padding: 4px;
    }
    table {
      display: grid;
      width: fit-content;
      gap: 0;
      grid-template-columns: repeat(3, auto);
      margin: 10px 0;
    }
    thead,
    tbody,
    tfoot,
    tr {
      display: contents;
    }
    .underline {
      text-decoration: underline;
    }
    margin-bottom: ${({ mobile }) => (mobile ? '140px' : '0')}; // 140px = Accept Terms btn height
  `,
};
const TableHeadColSpan2 = styled.th`
  grid-column: span 2;
`;
const TableHeadRowSpan2 = styled.th`
  grid-row: span 2;
`;
function PrivacyContent() {
  const { isMobile } = useIsMobile();
  const { inModal } = useInModal();
  return (
    <Styled.Content mobile={isMobile} modal={inModal}>
      <p>Last Modified: March 31st, 2024</p>
      <p>
        <strong>Captain&apos;s Summary:</strong> A common saying on the internet is, “if it&apos;s
        free, you&apos;re the product.“ While that&apos;s generally true for social media and lots
        of websites, it&apos;s not the case for Pirate Ship. Our service is free only because the
        carriers pay us commission when we make a sale&mdash;that&apos;s why it&apos;s free for you.
        We&apos;re not a data broker, we don&apos;t make money from selling data, and we only use
        any data we get to improve our services and advertise. We describe all those purposes here,
        and let you know what to do if you want to opt-out of them.
      </p>
      <p>
        Pirate Ship LLC and our affiliates (“<strong>Pirate Ship</strong>”, “<strong>we</strong>” or
        “<strong>us</strong>”) are committed to ensuring your privacy and to treating your personal
        information with care and respect. This privacy policy (this “
        <strong>Privacy Policy</strong>”) is designed to help you understand our privacy practices.
        It describes how Pirate Ship collects, uses, shares and secures the Personal Information you
        provide. It also describes your choices and the rights you have with respect to use, access
        and correction of your Personal Information.
      </p>
      <p>
        This Privacy Policy includes, by reference, the <a href="/dpa">Data Processing Addendum</a>.
        The Data Processing Addendum defines our specific legal obligations under certain European
        data protection laws, including (i) Regulation (EU) 2016/679 of the European Parliament and
        of the Council of 27 April 2016 as applied, modified, added to, limited, widened,
        substituted, replaced or repealed by law or regulation (“<strong>EU GDPR</strong>”) and (ii)
        the Data Protection Act 2018, as well as the GDPR as it forms part of the law of England and
        Wales, Scotland and Northern Ireland by virtue of section 3 of the European Union
        (Withdrawal) Act 2018 and as amended by the Data Protection, Privacy and Electronic
        Communications (Amendments etc.) (EU Exit) Regulations 2019 (SI 2019/419) (“
        <strong>UK GDPR</strong>” and, together with EU GDPR, “<strong>GDPR</strong>”), and your
        agreement with respect to the terms governing the processing of your Personal Information
        (as defined herein).
      </p>
      <p>
        If you have questions or complaints regarding our Privacy Policy or practices, please
        contact us as detailed under the “How to Contact Us” heading below.
      </p>
      <h2>1. WHEN THIS PRIVACY POLICY APPLIES.</h2>
      <p>
        This Privacy Policy applies to you if you fall into one of the following categories: (i) a
        Pirate Ship customer; (ii) an end recipient of services provided by Pirate Ship; (iii) a
        Pirate Ship supplier; (iv) a customer of a third-party service that links to our website or
        this Privacy Policy; or (v) if you contact us via our website, social media, or email.
        Generally, this Privacy Policy applies to our sites, products and services (collectively, “
        <strong>Services</strong>”), including Services that link to this Privacy Policy. It also
        applies when you interact with our advertisements and applications on third-party websites
        and services if those applications or advertising include links to this Privacy Policy.
      </p>
      <p>
        We are not responsible for any of the content or privacy practices of any third-party sites
        or services. Any data that is collected by any linked third-party site will be governed by
        that third party’s applicable privacy notices and its terms of use. We encourage you to read
        them.
      </p>
      <p>
        We may supplement this Privacy Policy through one or more supplemental privacy notices,
        which apply to a particular interaction with us or our Services. These supplemental privacy
        notices may be either embedded in this Privacy Policy or made available to you separately.
        We will tell you when a supplemental privacy notice applies.
      </p>
      <p>
        For legal purposes, including under GDPR, Pirate Ship may be a controller or processor of
        all Personal Information subject to this Privacy Policy for our Services.
      </p>
      <h2>2. WHAT INFORMATION WE COLLECT, AND WHAT WE DO WITH IT.</h2>
      <p>
        Here we discuss what information we collect and how we use it to deliver our Services to
        you, operate our business, and help make our Services useful.
      </p>
      <ol>
        <li>
          <span className="underline">Types of Information We Collect</span>. The types of
          information we collect depends on how you interact with our Services.
          <ul>
            <li>
              We may collect information that you provide to us. In connection with accessing our
              Services, we may collect information from you and your household that can be used to
              identify you (all such personal and household information, collectively, “
              <strong>Personal Information</strong>”). Personal Information includes, but is not
              limited to, your name, shipping/billing address, email address, phone number, credit
              card/bank account number, demographic information, username and password. We also
              collect information when you register or open an account, sign in, request price
              quotes, pay fees, purchase or otherwise use a Service, call us for support, or give us
              feedback. We may collect content or other information that you may provide or create
              when you interact with our Services. It is always your choice whether to provide this
              Personal Information and other data. However, certain Personal Information and other
              data must be provided in order to participate in certain portions of the Services.
              (For example, we require your email address in order to send you our email
              newsletter.) This means your decision not to provide certain Personal Information and
              other data could limit or eliminate your ability to participate in certain portions of
              the Services, or may otherwise limit the Services’ functionality.
            </li>
            <li>
              We may get information from other companies or third parties, such as when you sync a
              third-party account or service with your Pirate Ship Account, or when we may use
              service providers, public or commercially available sources to supplement the Personal
              Information you give us (<em>e.g.</em>, validate your mailing address) to help us
              maintain the accuracy of your data and provide you with better service. We may receive
              additional information from our analytics and marketing service providers and
              implement targeted advertising using the collected information. We may combine or link
              any information we collect about you or your device through the Services with any of
              this information. We may also combine this information with any information about you
              that we have collected separately offline.
            </li>
            <li>
              Depending on how you access or use our Services and your device settings, we may also
              automatically collect certain usage information when you access our Services (“
              <strong>Usage Data</strong>”), such as Internet Protocol (“<strong>IP</strong>”)
              addresses, log files, unique device identifiers, pages viewed, browser type, any links
              you click on to leave or interact with our Services, and other usage information
              collected from cookies and other tracking technologies. For example, we may collect IP
              addresses to track and aggregate to monitor the regions from which users navigate to
              our Services. If you visit our website, in order to optimize your user experience and
              the delivery of our service, we will track your usage of the site. For instance, we
              may track the pages you visited, the time spent on each page, the time spent engaged
              in particular tasks on each page, the key strokes necessary to perform a function on
              the site, etc. We may also collect IP addresses from users when they log into the
              Services as part of our log-in and security features. Our Services may change over
              time and we may introduce new features that may collect new or different types of
              information.
            </li>
            <li>
              <span className="underline">Aggregate Data.</span> Pirate Ship may collect, use, and
              distribute statistical information related to the usage of the website, Services, or
              traffic patterns in aggregate and de-identified form (“
              <strong>Aggregated Data</strong>”). Aggregated Data does not contain any Personal
              Information about you; it contains information like: the busiest shipping date of the
              year is December 18th and the top shipping destination in the U.S. is the metro NYC
              area. Pirate Ship owns all Aggregated Data.
            </li>
          </ul>
        </li>
        <li>
          <span className="underline">How We Use Your Information</span>. We may use your
          information, including your Personal Information, as described in this Privacy Policy, or
          as otherwise communicated to you (when we collect your Personal Information, or before we
          begin using any Personal Information for those newly communicated purposes). For example:
          <ul>
            <li>
              <em>Account Registration</em>. We will use your Personal Information, including your
              name, address, phone number, billing information and email address, to register your
              account for certain Services we provide and to communicate important information to
              you. If you set up an administrator account that may be accessed by people other than
              you, please note that they may see and have the ability to access, change or delete
              your Personal Information. Please be careful who you give administrator access to, as
              you are responsible for their actions.
            </li>
            <li>
              <em>Providing Our Services and Operate Our Business</em>. We may use your Personal
              Information to operate our business, including providing Services you requested,
              provide you with support related to our Services and to help us protect our Services,
              including to monitor their usage and activity, to combat fraud, illegal activity or
              other misuse of the Services and to protect your Personal Information.
            </li>
            <li>
              <em>Providing Customer Service and Technical Support</em>. We may use Personal
              Information, including your name, address, phone number, email address, how you
              interact with our Services, and information about your computer configuration, to
              resolve questions you may have about our Services and to follow up with you about your
              experience. We also offer various chat services, for example, to speak with a Pirate
              Ship customer support representative. Chat transmissions are encrypted, but you should
              not supply more Personal Information than is required to address your specific issue.
              A transcript of the session is retained to resolve questions or issues related to our
              Services.
            </li>
            <li>
              <em>Communicating with You</em>. We may use your Personal Information to communicate
              with you about our Services. Please see below under “What You Can Do to Manage Your
              Privacy” for the choices you have regarding these communications.
            </li>
            <li>
              <em>Improving Our Services and Developing New Services</em>. We will use your Personal
              Information to personalize or customize your experience and our Services, develop new
              features or services (including conducting related research and analytics), improve
              the quality and safety of the Services, identify and repair errors with respect to the
              Services and to otherwise improve the overall quality of the Services.
            </li>
            <li>
              <em>Fulfilling Our Obligations</em>. We will use your Personal Information to fulfill
              legal, security and safety obligations (including the rights, safety, health and
              security of us, our users, our employees and the general public), and to establish,
              exercise or defend a legal claim. We will also use it to protect us, our employees or
              the public (including for fraud prevention), or where necessary to protect rights or
              property of us or others (including to enforce our agreements, terms and policies) and
              to prevent illegal or unauthorized activities.
            </li>
          </ul>
        </li>
        <li>
          <span className="underline">When and How We Share Your Personal Information</span>. From
          time to time, we may need to share your Personal Information with others. We only share
          your Personal Information as described in this Privacy Policy, or as otherwise
          communicated to you when we collect your Personal Information. We disclose your Personal
          Information to provide the Services and to fulfill orders or inquiries that you have
          requested or authorized. We may also disclose your Personal Information for other
          purposes. For example:
          <ul>
            <li>
              <em>Third-Party Service Providers</em>. We may share your information, including
              Personal Information and Usage Data, with vendors, professional advisers (such as
              legal representatives, auditors and insurance brokers) and third-party service
              providers that perform various functions to enable us to provide our Services and help
              us operate our business, such as website design, sending email communications,
              telephone communication, SMS (text messaging), advertising, fraud detection and
              prevention, customer care, third-party surveys or performing analytics. These
              companies are authorized to use your Personal Information only as necessary to provide
              these services to us. Our contracts with these third parties require them to maintain
              the confidentiality of the Personal Information we provide to them, only act on our
              behalf and under our instructions and not use Personal Information for purposes other
              than the product or service they’re providing to us or on our behalf.
            </li>
            <li>
              <em>United States Postal Service (USPS)</em>. Pirate Ship and its partners are
              regulated by the USPS. As part of the regulatory relationship, Pirate Ship collects
              certain information about you on behalf of the USPS. The USPS also may send us
              tracking data from their scans of your packages in the mailstream. Our uses for the
              data include informing you about the status of your packages through options like USPS
              Tracking, fraud detection and aggregating the data to help the USPS and us provide you
              with better service. Information collected on behalf of USPS is used for their
              regulatory oversight function and is not used for other purposes. As part of Pirate
              Ship providing services of the USPS, the USPS requires that you agree to the USPS
              Privacy Act Statement and to provide information about yourself that will be
              maintained in a Privacy Act System of Records by the Postal Service. Privacy Act
              Statement: Your information will be used to facilitate the purchase of USPS postage
              and fulfill transactional reporting requirements for USPS postage systems. Collection
              is authorized by 39 U.S.C. 401, 403, and 404. Providing the information is voluntary,
              but if not provided, your transaction may not be processed. The Postal Service does
              not disclose your information to third parties without your consent, except to
              facilitate the transaction, to act on your behalf or request, or as legally required.
              This includes the following limited circumstances: to a congressional office on your
              behalf; to financial entities regarding financial transaction issues; to a USPS
              auditor; to entities, including law enforcement, as required by law or in legal
              proceedings; and to contractors and other entities aiding us to fulfill the Services
              (service providers). For more information regarding our privacy policies, visit{' '}
              <a href="https://www.usps.com/privacypolicy">www.usps.com/privacypolicy</a>. For more
              information regarding other specific carrier you may use via the Pirate Ship platform,
              please see the privacy policies provided by each specific carrier you select.
            </li>
            <li>
              <em>United Parcel Service (UPS)</em>. As a part of its partnership with UPS, Pirate
              Ship collects Personal Information about you that may be shared with UPS in order to
              provide you with Services, including, but not limited to, your consideration,
              purchase, and use of UPS shipping and related services through Pirate Ship. UPS
              processes such Personal Information for the purposes set forth in the UPS Privacy
              Notice published at{' '}
              <a href="https://www.ups.com/content/us/en/resources/ship/terms/privacy.html">
                https://www.ups.com/content/us/en/resources/ship/terms/privacy.html
              </a>
              , which may be updated by UPS from time to time.
            </li>
            <li>
              <em>Third-Party Carriers</em>. Use of carrier services via the Pirate Ship platform is
              at your own risk. Pirate Ship is not responsible for your use of carrier services.
              Your use of carrier services is as a direct customer of the specific carrier of your
              choosing and you agree to be bound by the terms and conditions of that carrier for use
              of services. By providing information to certain carrier services either directly or
              through Pirate Ship, you understand and consent to the collection, use, processing
              disclosure and transfer of such information and acknowledge that the carrier services
              may have different data protection practices than Pirate Ship. Your interactions with
              these service carriers are governed by each carrier’s privacy policy.
            </li>
            <li>
              <em>Response to Subpoenas and Other Legal Requests</em>. We may share your information
              with courts, law enforcement agencies, or other government bodies when we have a good
              faith belief we’re required or permitted to do so by law, including to meet national
              security or law enforcement requirements, to protect our company, or to respond to a
              court order, subpoena, search warrant, or other law enforcement request.
            </li>
            <li>
              <em>Protection of Pirate Ship and Others</em>. We may share account information,
              Personal Information and Usage Data when we believe it is appropriate to enforce or
              apply our products’ Terms of Use and other agreements; or protect the rights,
              property, or safety of Pirate Ship, our Services, our users, or others (including to
              enforce our agreements, terms and policies and for purposes of fraud protection and
              credit risk reduction). This does not include selling, renting, sharing, or otherwise
              disclosing Personal Information of our customers for commercial purposes in violation
              of the commitments set forth in this Privacy Policy.
            </li>
            <li>
              <em>Information Sharing Between Pirate Ship Entities</em>. We share your information,
              including your Personal Information, with and among our subsidiaries, affiliates and
              entities that belong to our corporate group, except where prohibited by law.
              “Subsidiaries” means companies related to us by common ownership or control. We share
              your information to use in ways that are consistent with this Privacy Policy.
            </li>
            <li>
              <em>Sharing with Third Parties Generally</em>. If we sell, merge, or transfer any part
              of our business, or are subject to insolvency or bankruptcy proceedings, then we may
              be required to share your information.
            </li>
            <li>
              <em>Aggregated Data</em>. Aggregated Data may be shared with third parties, but is
              stripped of all Personal Information.
            </li>
          </ul>
        </li>
        <li>
          <span className="underline">
            Linking Other Third Party Services with Your Pirate Ship Service
          </span>
          . We work with other companies to enhance our Services, such as integrations to ecommerce
          platforms, and you may choose to sync, link or connect other third-party services with
          your Pirate Ship Account. Sometimes Pirate Ship may let you know about the service or
          product, or another company may let you know about a Pirate Ship service or product. If
          you choose to accept these services, providing your consent to either the third party or
          to us, we may exchange your information, including your Personal Information, as well as
          information about how you interact with each company’s service or product. This exchange
          of information is necessary to maintain business operations and to provide the ongoing
          service you’ve requested. By requesting or accepting these products or services, you are
          permitting us to provide your information, including your Personal Information, to the
          other party.
        </li>
      </ol>
      <h2>3. WHAT YOU CAN DO TO MANAGE YOUR PRIVACY.</h2>
      <p>
        You have a choice about the use of your Personal Information, including information that
        identifies you, marketing communications you receive from us, and our use of cookies and
        other tracking technologies. For more information about our use of cookies and other
        tracking technologies, please visit our separate{' '}
        <a href="https://www.pirateship.com/cookies">Cookies Notice</a>.
      </p>
      <p>
        How you can access and control information that identifies you will depend on which Services
        you use. You can view and edit information that identifies you online through your Pirate
        Ship Account.
      </p>
      <ol>
        <li>
          <span className="underline">Updating Your Personal Information</span>. In connection with
          your right to manage your Personal Information you provide to us, you may access, update,
          change, correct or request delivery or deletion of your information either through the
          Service or through our customer support. You can reach our customer support by using the
          contact information provided in the “How to Contact Us” section of this Privacy Policy. We
          will respond to your request within a reasonable time frame, but we may not accommodate a
          request to update any such data if we believe it would violate any law or legal
          requirement, or would cause the data we hold to be incorrect.
        </li>
        <li>
          <span className="underline">Managing Marketing Communications From Us</span>. If you have
          signed up for our commercial emails or newsletters, we will honor your choices when it
          comes to your communications preferences. You have the following choices if you have been
          receiving marketing communications from us that you no longer wish to receive:
        </li>
        <ul>
          <li>
            If you have signed up for commercial emails or newsletters, you may unsubscribe by
            accessing the opt-out link included at the bottom of the email or by contacting us.
          </li>
          <li>
            If you have signed up to receive text messages from us, you may opt out of these alerts
            any time by replying “STOP” or any alternative keyword we share with you.
          </li>
        </ul>
        <p>
          Please allow sufficient time for your preferences to be processed. Please also remember
          that even if you choose not to receive marketing communications from us, we may contact
          you for other purposes (such as responding to your requests, sending you mandatory service
          or transactional communications, or for other administrative purposes).
        </p>
        <li>
          <span className="underline">Social Media Features</span>. Our Services may use social
          media features, such as Facebook sharing (“<strong>Social Media Features</strong>”). These
          features may collect your IP address and which page you are visiting within our Service,
          and may set a cookie to enable the feature to function properly. Social Media Features are
          either hosted by a third party or hosted directly on our Services. Your interactions with
          these features are governed by the privacy policy of the company providing the relevant
          Social Media Features.
        </li>
      </ol>
      <p>
        Residents in certain jurisdictions may have additional rights with respect to accessing and
        to having amended, delivered or deleted any Personal Information. To exercise these rights,
        residents of these jurisdictions should contact us as provided under “How to Contact Us.”
        Please refer to “Your U.S. State Privacy Rights” and “Your European Union Privacy Rights”
        for more information.
      </p>
      <h2>4. DATA RETENTION.</h2>
      <p>
        In accordance with and as permitted by this Privacy Policy, applicable law and regulations,
        we will retain your information as long as necessary to carry out the purposes outlined in
        this Privacy Policy (or any other notice provided to you at the time your information is
        collected), including to serve you, to maintain your account for as long as your account is
        active, or as otherwise needed to operate our business. When you close your Pirate Ship
        Account, we may continue to communicate with you as permitted by this Privacy Policy, unless
        you have opted out of receiving marketing communications. We may also retain and continue to
        use some of your information as permitted by this Privacy Policy, including for business
        purposes and to improve our offerings or to develop new ones, and as required by applicable
        law and regulations and Pirate Ship’s records and information management policies to comply
        with our legal and reporting obligations, resolve disputes, enforce our agreements, complete
        any outstanding transactions, and for the detection and prevention of fraud.
      </p>
      <h2>5. SECURITY OF YOUR INFORMATION.</h2>
      <p>
        Keeping your information safe is important to us. We provide reasonable and appropriate
        security measures in connection with securing Personal Information we collect, including
        physical, administrative and technical safeguards, for the type of data that we collect.
        These measures are designed to protect the Personal Information you provide to us through
        the Services from unauthorized access, use, disclosure, alteration and destruction. No
        website, system or method of transmission over the Internet is 100% secure, so we cannot
        guarantee the security of your Personal Information and encourage you to use caution when
        using the Internet.{' '}
      </p>
      <p>
        If you have any questions about the security of your personal information, you can contact
        our <a href="https://www.pirateship.com/support">customer support</a> via chat or email.
      </p>
      <h2>6. INTERNATIONAL DATA TRANSFERS.</h2>
      <p>
        In accordance with and as permitted by applicable law and regulations, we reserve the right
        to transfer your information, including your Personal Information, process and store it
        outside your country of residence to wherever we or our third-party service providers
        operate. As provided in this Privacy Policy, we may also allow employees and service
        providers to access your Personal Information globally. By using the Services or providing
        Personal Information for any of the purposes described in this Privacy Policy, you
        acknowledge that your Personal Information may be transferred to or stored in such
        locations.
      </p>
      <h2>7. HOW TO CONTACT US.</h2>
      <p>
        If you have questions or comments about this Privacy Policy, please contact us. We want your
        feedback and comments.
      </p>
      <p>
        Via the Internet: Contact our{' '}
        <a href="https://www.pirateship.com/support">customer support</a> via chat or email.
      </p>
      <p>
        Via phone: Toll-free: <a href="tel:+18444455854">1-844-445-5854</a>
      </p>
      <p>
        Via mail:
        <br />
        Pirate Ship LLC
        <br />
        Attn: Privacy Team
        <br />
        PO Box 9149
        <br />
        Jackson, WY 83002
      </p>
      <h2>8. CHANGES TO OUR PRIVACY POLICY.</h2>
      <p>
        From time to time, we may change or update our Privacy Policy as we engage in new practices
        or policies with respect to Personal Information. We reserve the right to make changes or
        updates at any time. More information about how we will notify you is below.
      </p>
      <p>
        If we make material changes to the way we process your Personal Information, we will provide
        you appropriate notice, including via our Service or by other communication channels, such
        as by email or posting on our Site. We encourage you to periodically review this page for
        the latest information on our privacy practices. Please review any changes carefully. If you
        object to any of the changes and no longer wish to use our Services, you may close your
        account(s). All changes are effective immediately upon posting and your use of our Services
        after a notice of material change or posting of an updated Privacy Policy constitutes your
        acknowledgment that you have read and understand the updated Privacy Policy, and that you
        understand and consent to the ways that we will collect, use and share data, including
        Personal Information, as stated therein.
      </p>
      <h2>9. COLLECTION AND USE OF CHILDREN’S PERSONAL INFORMATION.</h2>
      <p>
        Our Services are intended for and directed to adults. Our Services are not directed to
        minors (people under the age of 13 or the equivalent minimum age, depending on jurisdiction)
        and we do not knowingly collect any information, including Personal Information, from
        minors. IF YOU ARE UNDER 16 YEARS OLD, YOU ARE A MINOR IN MANY JURISDICTIONS.
      </p>
      <p>
        If you are the parent or guardian of a minor and believe that we have collected any
        information about your child, or if you believe your child has created an account using a
        false age, please contact our{' '}
        <a href="https://www.pirateship.com/support">customer support</a> via chat or email to
        remove Personal Information collected from them.
      </p>
      <p>
        We recommend that parents routinely monitor their children’s online presence. To learn more
        about how to safeguard your child on the Internet, please visit the FTC resource{' '}
        <a href="http://www.onguardonline.gov/articles/0031-protecting-your-childs-privacy-online">
          Protecting Your Child’s Privacy Online
        </a>
        .
      </p>
      <h2>10. YOUR U.S. STATE PRIVACY RIGHTS.</h2>
      <p>
        Depending on the U.S. state where you reside, you may have certain additional privacy
        rights, subject to statutory limitations.
      </p>
      <ul>
        <li>
          <strong>Right to Know and Access</strong>: You may have the right to request information
          about the Personal Information that we collect, use, disclose, share and sell. This
          includes the ability to request the specific pieces of Personal Information we have
          collected about you. If you would like to access your Personal Information or receive
          information about the categories of Personal Information about you that we have shared as
          described in this Privacy Policy, please contact us via the chat on our website or email
          as listed on{' '}
          <a href="https://www.pirateship.com/support">https://www.pirateship.com/support</a>, with
          your request. We will use your email address to verify your request and communicate with
          you regarding your request.
        </li>
        <li>
          <strong>Right to Delete</strong>: You may have the right to request that we delete certain
          Personal Information that we have collected from you, under certain circumstances. If you
          would like us to delete your Personal Information, please log into your account and submit
          a deletion request via the Support chat. If you do not have an account with us, then
          please contact us via the chat on our website or email as listed on{' '}
          <a href="https://www.pirateship.com/support">https://www.pirateship.com/support</a>. You
          will need to provide us with certain Personal Information, including your email address,
          and any additional information requested by our team so that we may verify your request
          and communicate with you regarding your request.
        </li>
        <li>
          <strong>Right to Correct</strong>: You may have the right to request that we correct any
          inaccurate Personal Information that we hold about you.
        </li>
        <li>
          <strong>Right to Opt Out of Sales and Sharing or Using for Targeted Advertising</strong>:
          You may have the right to direct us not to sell or share your Personal Information and to
          opt out of certain targeted advertising where information is processed across unaffiliated
          or differently branded websites and applications.
        </li>
        <li>
          <strong>Right to Limit the Use of Sensitive Information</strong>: You may have the right
          to request that we limit the use and disclosure of sensitive information to certain
          purposes permissible under applicable law. We do not collect any sensitive information.
        </li>
        <li>
          <strong>Right to Opt Out of Profiling that Produces Legal or Similar Effects</strong>: We
          do not profile individuals for purposes to which this right applies.
        </li>
        <li>
          <strong>Right to Non-Discrimination</strong>: Should you wish to request the exercise of
          your rights with respect to Personal Information, we will not discriminate against you by
          offering you different pricing for products or services, or by providing you with a
          different level or quality of products or service, based solely upon this request and
          subject to certain applicable exceptions. We are permitted to change our service level or
          change the rate we charge if that change is reasonably related to the value of your
          information. Please note that we need to retain certain types of Personal Information to
          continue providing our services (<em>e.g.</em>, name, address). Without your Personal
          Information, we will not be able to provide you with the requested services.
        </li>
      </ul>
      <p>
        These rights are not absolute, are subject to exceptions and limitations and may not be
        afforded to residents of all states. If any of the above rights are available to you under
        applicable law, you may submit a request to exercise those rights through any of the methods
        described under the “How to Contact Us” heading of the Privacy Policy, or you may contact us
        by chatting with a representative via our website for assistance. If your request is denied,
        applicable law may provide you the right to lodge an appeal with us. The response to your
        rights request will inform you of any appeal rights you may have and tell you how you may
        exercise them.
      </p>
      <p>
        <span className="underline">California Privacy Rights</span>
      </p>
      <p>
        If you reside in California, we are required to provide additional information to you about
        how we use and disclose your Personal Information. You may also have additional rights with
        regard to how we use your Personal Information. We have included this information below.
      </p>
      <p>
        <strong>California’s “Shine the Light” Law</strong>
      </p>
      <p>
        If you are a California resident, you may request that we disclose a list of all third
        parties with whom we have shared your “personal information” (as defined by California’s
        “Shine the Light” law) during the previous calendar year, along with the type of personal
        information that we disclosed. Please contact us through any of the methods under the “How
        to Contact Us” heading of the Privacy Policy to make this request. When making this request,
        please reference “California Shine the Light Request” and sufficient information to
        determine whether the law applies to you (including that you are a California resident).
      </p>
      <p>
        <strong>CCPA</strong>
      </p>
      <p>
        The California Consumer Privacy Act, as amended by the California Privacy Rights Act
        (collectively, the “<strong>CCPA</strong>”) provides California residents with specific
        privacy rights in certain circumstances.
      </p>
      <p>
        <em>Uses and Disclosure of your information</em>. Consistent with the “Types of Information
        We Collect” section above, we collect certain categories and specific pieces of information
        about individuals that are considered “Personal Information.” As detailed in other sections
        of this Privacy Policy, we may collect this Personal Information from you and other third
        parties. We collect, share and disclose Personal Information for the business and commercial
        purposes described elsewhere in this Privacy Policy.
      </p>

      <table>
        <tr>
          <th>
            <p>Category</p>
          </th>
          <th>
            <p>Examples</p>
          </th>
          <th>
            <p>Collected</p>
          </th>
        </tr>
        <tr>
          <td>
            <p>A. Identifiers.</p>
          </td>
          <td>
            <p>
              A real name, alias, postal address, unique personal identifier, online identifier,
              Internet Protocol address, email address, account name, driver&rsquo;s license number,
              passport number, or other similar identifiers.
            </p>
          </td>
          <td>
            <p>YES</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              B. Personal information categories listed in the California Customer Records statute
              (Cal. Civ. Code &sect; 1798.80(e)).
            </p>
          </td>
          <td>
            <p>
              A name, address, telephone number, passport number, driver&rsquo;s license or state
              identification card number, bank account number, credit card number, debit card
              number, or any other financial information.
            </p>
            <p>
              Some personal information included in this category may overlap with other categories.
            </p>
          </td>
          <td>
            <p>YES</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>C. Protected classification characteristics under California or federal law.</p>
          </td>
          <td>
            <p>
              Age (40 years or older), race, color, ancestry, national origin, citizenship, religion
              or creed, marital status, medical condition, physical or mental disability, sex
              (including gender, gender identity, gender expression, pregnancy or childbirth and
              related medical conditions), sexual orientation, veteran or military status, genetic
              information (including familial genetic information).
            </p>
          </td>
          <td>
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>D. Commercial information.</p>
          </td>
          <td>
            <p>
              Records of personal property, products or services purchased, obtained, or considered,
              or other purchasing or consuming histories or tendencies.
            </p>
          </td>
          <td>
            <p>YES</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>E. Biometric information.</p>
          </td>
          <td>
            <p>
              Genetic, physiological, behavioral, and biological characteristics, or activity
              patterns used to extract a template or other identifier or identifying information,
              such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke,
              gait, or other physical patterns, and sleep, health, or exercise data.
            </p>
          </td>
          <td>
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>F. Internet or other similar network activity.</p>
          </td>
          <td>
            <p>
              Browsing history, search history, information on a consumer&rsquo;s interaction with a
              website, application, or advertisement.
            </p>
          </td>
          <td>
            <p>YES</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>G. Geolocation data.</p>
          </td>
          <td>
            <p>Physical location or movements.</p>
          </td>
          <td>
            <p>YES</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>H. Sensory data.</p>
          </td>
          <td>
            <p>Audio, electronic, visual, thermal, olfactory, or similar information.</p>
          </td>
          <td>
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>I. Professional or employment-related information.</p>
          </td>
          <td>
            <p>Current or past job history or performance evaluations.</p>
          </td>
          <td>
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              J. Non-public education information (per the Family Educational Rights and Privacy Act
              (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).
            </p>
          </td>
          <td>
            <p>
              Education records directly related to a student maintained by an educational
              institution or party acting on its behalf, such as grades, transcripts, class lists,
              student schedules, student identification codes, student financial information, or
              student disciplinary records.
            </p>
          </td>
          <td>
            <p>NO</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>K. Inferences drawn from other personal information.</p>
          </td>
          <td>
            <p>
              Profile reflecting a person&rsquo;s preferences, characteristics, psychological
              trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
            </p>
          </td>
          <td>
            <p>YES</p>
          </td>
        </tr>
      </table>

      <table>
        <tr>
          <TableHeadRowSpan2>
            <p>Personal Information Category</p>
          </TableHeadRowSpan2>
          <TableHeadColSpan2>
            <p>Category of Third-Party Recipients</p>
          </TableHeadColSpan2>
        </tr>
        <tr>
          <th>
            <p>Business Purpose Disclosures</p>
          </th>
          <th>
            <p>Sharing for Advertising or Sales</p>
          </th>
        </tr>
        <tr>
          <td>
            <p>A: Identifiers.</p>
          </td>
          <td>
            <p>
              Affiliates, partners, parent/subsidiary organizations, service providers, advertising
              networks, and government entities
            </p>
          </td>
          <td>
            <p>Affiliates, partners, and advertising networks</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>B: California Customer Records personal information categories.</p>
          </td>
          <td>
            <p>
              Affiliates, partners, parent/subsidiary organizations, service providers, advertising
              networks, and government entities
            </p>
          </td>
          <td>
            <p>Affiliates, partners, and advertising networks</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>C: Protected classification characteristics under California or federal law.</p>
          </td>
          <td>
            <p>None</p>
          </td>
          <td>
            <p>None</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>D: Commercial information.</p>
          </td>
          <td>
            <p>
              Affiliates, partners, parent/subsidiary organizations, service providers, advertising
              networks, and government entities
            </p>
          </td>
          <td>
            <p>Affiliates, partners, and advertising networks</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>E: Biometric information.</p>
          </td>
          <td>
            <p>None</p>
          </td>
          <td>
            <p>None</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>F: Internet or other similar network activity.</p>
          </td>
          <td>
            <p>
              Affiliates, partners, parent/subsidiary organizations, service providers, advertising
              networks, and government entities
            </p>
          </td>
          <td>
            <p>Affiliates, partners, and advertising networks</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>G: Geolocation data.</p>
          </td>
          <td>
            <p>
              Affiliates, partners, parent/subsidiary organizations, service providers, advertising
              networks, and government entities
            </p>
          </td>
          <td>
            <p>Affiliates, partners, and advertising networks</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>H: Sensory data.</p>
          </td>
          <td>
            <p>None</p>
          </td>
          <td>
            <p>None</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>I: Professional or employment-related information.</p>
          </td>
          <td>
            <p>None</p>
          </td>
          <td>
            <p>None</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>J: Non-public education information.</p>
          </td>
          <td>
            <p>None</p>
          </td>
          <td>
            <p>None</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>K: Inferences drawn from other personal information.</p>
          </td>
          <td>
            <p>
              Affiliates, partners, parent/subsidiary organizations, service providers, advertising
              networks, and government entities
            </p>
          </td>
          <td>
            <p>Affiliates, partners, and advertising networks</p>
          </td>
        </tr>
      </table>

      <p>
        <em>Exercising Your Opt-Out Rights </em>(
        <em>Do Not Sell or Share My Personal Information</em>). Subject to certain exceptions, you
        may have the right to opt out of the “sale” of your personal information collected from
        cookies and similar tracking technologies or “sharing” your personal information as it
        relates to cross-context behavioral advertising. To opt out of the “sale” or “sharing” of
        your personal information as described in this section, please follow the steps outlined in
        our <a href="https://www.pirateship.com/cookies">Cookies Notice</a>. You may also opt out of
        certain “sales” of advertising by visiting{' '}
        <a href="https://optout.networkadvertising.org/?c=1">NAI Consumer Opt Out</a>. Keep in mind
        that, if you opt out of interest-based advertising, you may still see customized advertising
        displayed by advertising networks from which you have not opted out. In addition, your opt
        out will not prevent any advertising network from displaying advertising that is not
        customized to you and your interests and, consequently, may be less relevant to you.{' '}
      </p>
      <p>
        You may also contact us by via the chat on our website or email as listed on{' '}
        <a href="https://www.pirateship.com/support">https://www.pirateship.com/support</a> for
        assistance in exercising your right to opt out of the “sale” or “sharing” of personal
        information.{' '}
      </p>
      <p>
        Finally, if your browser supports it, you can turn on the{' '}
        <a href="https://globalprivacycontrol.org/">Global Privacy Control</a> to opt-out of the
        “sale” or “sharing” of personal information.{' '}
      </p>
      <p>
        <span className="underline">Nevada Privacy Rights</span>
      </p>
      <p>
        If you are a Nevada resident, you have the right to opt out of the sale of certain
        categories of Personal Information to other companies that sell or license your information
        to others. If you would like more information about this practice, please contact us through
        any of the methods described under the “How to Contact Us” heading of the Privacy Policy.
      </p>
      <h2>11. YOUR EUROPEAN UNION PRIVACY RIGHTS.</h2>
      <p>
        If you are located in the European Economic Area (“<strong>EEA</strong>”), Switzerland or
        the United Kingdom (“<strong>UK</strong>”), you have rights with respect to the Personal
        Information that we process as a controller. These rights specifically include:
      </p>
      <ul>
        <li>
          <strong>Right of access and portability</strong>: You may access any Personal Information
          that we hold about you. In certain circumstances, you may have that data provided to you
          so that you may provide that data to another data processor.
        </li>
        <li>
          <strong>Right to erasure</strong>: In certain circumstances, you may have Personal
          Information that we hold about you erased.
        </li>
        <li>
          <strong>Right to object to our processing</strong>: In certain circumstances, you may
          request that we cease processing your Personal Information or cease sending you any
          marketing communications.
        </li>
        <li>
          <strong>Right to rectification</strong>: You may require that we correct inaccurate or
          incomplete Personal Information that we hold about you.
        </li>
        <li>
          <strong>Right to restrict our processing</strong>: In certain circumstances, you may
          request that we restrict processing of your Personal Information.
        </li>
      </ul>
      <p>
        Should you wish to exercise any of these rights, please contact us through any of the
        methods described under the “How to Contact Us” heading of the Privacy Policy. We may
        require additional Personal Information from you in order to confirm your identity and your
        ability to exercise the requested right.{' '}
      </p>
      <p>
        You also have the right to lodge a complaint with a data protection authority. Please
        contact your local data protection authority for more information about the complaint
        process.
      </p>
      <p>
        <span className="underline">Legal Basis for Processing Personal Information</span>
      </p>
      <p>
        When we process Personal Information in our capacity as a data controller, we rely on the
        following legal bases for doing so (as applicable in your jurisdiction, including the EEA,
        Switzerland and the UK):
      </p>
      <ul>
        <li>
          <strong>With your specific, revocable consent</strong>: We rely on your consent, including
          to receive certain marketing communications, to utilize cookies as described in our{' '}
          <a href="https://www.pirateship.com/cookies">Cookies Notice</a> and to engage in
          advertising. Please refer to our{' '}
          <a href="https://www.pirateship.com/cookies">Cookies Notice</a> for more information.
          Please note that you have the right to withdraw your consent at any time, as described in
          our <a href="https://www.pirateship.com/cookies">Cookies Notice</a>.
        </li>
        <li>
          <strong>As necessary to complete our contract with you</strong>: We process your Personal
          Information in order to prepare and enter into our contract with you, and to perform and
          manage that contract. This includes billing, compliance with contractual obligations and
          related contract administration. We may not be able to provide you with all of our
          products and services if we do not process your Personal Information for these purposes.
        </li>
        <li>
          <strong>As necessary to comply with our legal obligations</strong>: We process your
          Personal Information to comply with legal obligations to which we are subject (including
          detecting, investigating and preventing fraud, complying with applicable privacy laws and
          other regulations, guidelines and rules applicable to us and communicating with competent
          government, judicial and regulatory authorities).
        </li>
        <li>
          <strong>
            As necessary for the legitimate interests of us or others, to the extent those interests
            are not overridden by your interests or fundamental rights and freedoms, which require
            protection of Personal Information
          </strong>
          : We process your Personal Information to provide you with the Services, improve the
          Services and our products, send certain marketing communications and to protect us, our
          employees or the public (including for fraud prevention), or where necessary to protect
          rights or property of us or others (including to enforce our agreements, terms and
          policies and to prevent illegal or unauthorized activities).
        </li>
      </ul>
      <p>
        <span className="underline">International Data Transfers</span>
      </p>
      <p>
        Your Personal Information may be transferred, stored or processed in your jurisdiction of
        residence, or in any other country where we (or our service providers) are located. As
        provided in this Privacy Policy, we may also allow employees and service providers to access
        your Personal Information globally. By using the Services or providing Personal Information
        for any of the purposes described in this Privacy Policy, you acknowledge that your Personal
        Information may be transferred to or stored in such locations. In particular, you
        acknowledge that your Personal Information may be transferred to or stored in the United
        States where we are established, as well as in other countries outside of the EEA,
        Switzerland and the UK. Such countries may have data protection rules that are different and
        less protective than those of the country in which you are located.
      </p>
      <p>
        We will comply with any applicable laws regarding storage and transfer of Personal
        Information where your Personal Information may be processed. Where Personal Information of
        EEA, Swiss or UK users is transferred to a recipient in a country that has not been
        recognized as having an adequate level of data protection, that the transfer is governed by
        the relevant standard contractual clauses or we use another legally recognized transfer
        mechanism in the applicable jurisdiction.
      </p>
      <p>
        If you have any questions, you can contact our{' '}
        <a href="https://www.pirateship.com/support">customer support</a> via telephone, chat or
        email.
      </p>
    </Styled.Content>
  );
}

function PrivacyPage({ requireAcceptance = false, clickHandler }: PrivacyProps) {
  const { isMobile } = useIsMobile();
  const { inModal } = useInModal();

  return (
    <Styled.PageContainer>
      <PageHeader title="Privacy Policy" />

      {!isMobile && inModal && (
        <Styled.Scrollbox ref={clickHandler} requireAcceptance={requireAcceptance}>
          <PrivacyContent />
        </Styled.Scrollbox>
      )}

      {isMobile && inModal && (
        <div ref={clickHandler}>
          <PrivacyContent />
        </div>
      )}

      {!inModal && <PrivacyContent />}
    </Styled.PageContainer>
  );
}

export default PrivacyPage;
