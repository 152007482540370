import { useCallback, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal, { ModalButtonBar } from './Modal';
import { AutoGridRow } from '../layout/Grid';
import useCurrentUser from '../../hooks/useCurrentUser';

/**
 * Exported for usage in component tests
 */
// eslint-disable-next-line react-refresh/only-export-components
export const REASONS: Record<string, string> = {
  success: 'Your email has been verified',
  already: 'Your email has already been verified',
};

export const verifyUserEmailSucceededModalTriggerFragment = '#user-email-verified';

/**
 * This Modal is used after the user email verification has succeeded
 */
export default function UserEmailVerificationSucceededModal() {
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState(REASONS.success);

  useEffect(() => {
    const hashMatches = hash.startsWith(verifyUserEmailSucceededModalTriggerFragment);

    if (hashMatches) {
      const hashParts = hash.split('=', 2);
      setReason(
        hashParts.length === 2 && Object.keys(REASONS).includes(hashParts[1])
          ? REASONS[hashParts[1]]
          : REASONS.success,
      );
    }

    const emailVerified = currentUser ? currentUser.emailVerified : true;

    setOpen(hashMatches && emailVerified);
  }, [hash, currentUser]);

  const handleClose = useCallback(() => {
    navigate(`${pathname}${search}`, {
      replace: true,
    });
  }, [navigate, pathname, search]);

  return (
    <Modal
      width={800}
      open={open}
      title={`${reason} ${reason === REASONS.already ? '👍' : '🥳'}`}
      data-testid="user-email-verification-succeeded-modal"
      onClose={handleClose}
    >
      <AutoGridRow>
        <p>Happy shippin’ matey!</p>
      </AutoGridRow>

      <ModalButtonBar
        buttonText="Continue Shipping"
        buttonOnClick={handleClose}
        buttonVariant="success"
      />
    </Modal>
  );
}
