import getYear from 'date-fns/getYear';
import { PickupRequestPackageLocation } from './gql/graphql';
import { Option } from './components/form/Select';
import newDate from './utils/newDate';

export const SIDEBAR_COLLAPSIBLE_DURATION = '150ms';
export const SIDEBAR_COLLAPSIBLE_TIMING_FUNCTION = 'ease-out';
export const SIDEBAR_WIDTH = { expanded: '215px', collapsed: '95px' };
export const SIDEBAR_MENU_ITEM_HEIGHT = '75px';
export const SIDEBAR_MENU_ITEM_ICON_SIZE = { width: '70px', height: '55px' };
export const ALL_LOGO_ANIMATIONS = [
  'Smile',
  'SmileBeard',
  'SmileHat',
  'PartyCelebration',
  'Bubble',
  'Twinkle',
  'Cry',
  'LaughTears',
  'Party',
  'Satisfied',
  'July4th',
  'Starfish',
  'HatFlip',
  'Hearts',
  'Ghost',
  'Dracula',
  'JackOLantern',
  'Present',
] as const;

export const HORIZONTAL_SCROLLBAR_HEIGHT = '14px';

export const REPORT_TILE_SIZE = {
  Screen: '190px',
  Print: '140px',
};

export const TALK_LIKE_A_PIRATE_DAY_DATE = '2022-09-19 13:37:01:000Z';
export const COPYRIGHT_LINE = `© 2014-${getYear(newDate('now'))} - All Rights Reserved`;
export const EXTERNAL_SUPPORT_URL = 'https://support.pirateship.com/';
export const GRID_PAGE_SIZE_OPTIONS: ReadonlyArray<number> = [10, 20, 50, 100, 200, 500, 1000];
export const MINIMUM_CC_PROVIDER_AMOUNT = 1;
export const COMPATIBILITY_REDIRECTS: ReadonlyArray<{ path: string; to: string }> = [
  { path: '/login', to: '/' },
  { path: '/reports/credit', to: '/reports/addcredit' },
  { path: '/payment/refund', to: '/reports/refundcredit' },
];
export const DEFAULT_COUNTRY_CODE = 'US';

export const STORAGE_KEYS = {
  loggedInStorageKey: 'psLoggedIn',
  clientTrackedStorageKey: 'psClientTracked',
  sidebarCollapsedStorageKey: 'psSidebarCollapsed',
  showShipmentExtraServiceStorageKey: 'psShowShipmentExtraServices',
  showRubberStampContainerStorageKey: 'psShowRubberStampContainer',
  showBatchPaymentDetailsStorageKey: 'psShowBatchPaymentDetails',
  showShipmentInfoStorageKey: 'psShowShipmentInfo',
  daterangePickerStateStorageKey: 'psDateRangePickerState',
  gridFilterStateStorageKey: 'psGridFilterState',
  adminBarVisibleStorageKey: 'psAdminBarVisible',
  reportOverviewDatetimeRangeFilterStorageKey: 'psReportOverviewDatetimeRangeFilter',
  showPaymentSettingsStorageKey: 'psShowPaymentSettings',
  packageLocationStorageKey: 'psPackageLocation',
  specialInstructionsStorageKey: 'psspecialInstructions',
  preselectedSummaryIdsStorageKey: 'preselectedSummaryIds',
  filePageLayout: 'psFilePaperFormat',
} as const;

export const REFUND_MESSAGES = {
  shipment: {
    instant: {
      title: 'THIS LABEL QUALIFIES FOR AN INSTANT REFUND',
      description:
        'Since you have not clicked the [b]Print Label[/b] button yet, it will instantly be refunded. The credit will immediately be returned to your Pirate Ship account balance.',
      success:
        'This label was refunded instantly. Your Pirate Ship account balance has been credited, which you can see in your Reports page.',
    },
    ups: {
      title: 'UPS REFUNDS TAKE UP TO 1 HOUR',
      description:
        'This label will be submitted for a refund and cannot be used. The refund will be returned to your Pirate Ship account balance within one hour. If you use this label, you will be charged.',
      success:
        'This label was submitted for a refund. Your Pirate Ship account balance will be credited within 1 hour.',
    },
    usps: {
      title: 'USPS REFUNDS TAKE UP TO 30 DAYS',
      description:
        "This label will be submitted for a refund and cannot be used. USPS waits up to 30 days to make sure the label is not used before they'll credit your Pirate Ship account balance.",
      success:
        'This label was submitted for a refund. Your Pirate Ship account balance will be credited as soon as USPS approves the refund.',
    },
  },
  batch: {
    instant: {
      title: 'THESE LABELS QUALIFY FOR INSTANT REFUNDS',
      description:
        'Since you have not clicked the [b]Print Labels[/b] button yet, they will instantly be refunded. The credit will immediately be returned to your Pirate Ship account balance.',
      success:
        'These labels were refunded instantly. Your Pirate Ship account balance has been credited, which you can see in your Reports page.',
    },
    ups: {
      title: 'UPS REFUNDS TAKE UP TO 1 HOUR',
      description:
        'These labels will be submitted for a refund and cannot be used. The refund will be returned to your Pirate Ship account balance within one hour. If you use these labels, you will be charged.',
      success:
        'This label was submitted for a refund. Your Pirate Ship account balance will be credited within 1 hour.',
    },
    usps: {
      title: 'USPS REFUNDS TAKE UP TO 30 DAYS',
      description:
        "These labels will be submitted for a refund and cannot be used. USPS waits up to 30 days to make sure the labels are not used before they'll credit your Pirate Ship account balance.",
      success:
        'These labels were submitted for a refund. Your Pirate Ship account balance will be credited as soon as USPS approves the refund.',
    },
    default: {
      title: 'REFUND LABELS FROM MULTIPLE CARRIERS',
      success:
        'These labels were submitted for a refund. Your Pirate Ship account balance will be credited as soon as USPS approves the refund and for UPS it will be credited within 1 hour.',
    },
  },
} as const;

export type DataGridFilterStorageKey = `psDataGridFilters_${string}`;
export type CollapsibleCollapsedStorageKey = `collapsibleCollapsed_${string}`;

export type StorageKey =
  | (typeof STORAGE_KEYS)[keyof typeof STORAGE_KEYS]
  | DataGridFilterStorageKey
  | CollapsibleCollapsedStorageKey;

// a generic type to constrain string union types to a subset of said union
export type Subset<T, U extends T> = U;

// reference: https://date-fns.org/v2.28.0/docs/format
// these are common date formats we use in the app, used in combination with the date-fns format function
export const DATE_FORMAT = {
  date: 'yyyy-MM-dd', // we refer to date as year, month, day. 2022-01-30
  dateTime24: 'yyyy-MM-dd HH:mm', // year, month, day, 24 hour hour, minutes, e.g. 2022-01-30 00:30
  dateTime12: 'yyyy-MM-dd hh:mm a', // year, month, day, 12 hour hour, minutes, AM/PM, e.g. 2022-01-30 12:30 PM
  time24: 'HH:mm', // 24 hour time, e.g. 22:30
  time12NoLeadingZero: 'h:mm a', // 12 hour time with no leading 0 and AM/PM, e.g. 4:30 AM
  usDate: 'MM/dd/yyyy', // month/day/year, e.g. 12/30/2022
  usDateNoLeadingZero: 'M/d/yyyy', // month/day/year with no leading 0, e.g. 5/8/2022
  usDateNoLeadingZeroShortYear: 'M/d/yy', // month/day/year with no leading 0, e.g. 5/8/22
  usVerboseDate: `MMMM dd, yyyy`, // month as unabbreviated word, day, four digit year, e.g. January 06, 2022
  usSemiVerboseDate: 'MMM dd, yyyy', // month as three-letter abbreviated word, then the rest as above: e.g Jan 02, 2022
  dayOfWeek: 'cccc', // unabbreviated day of week, e.g Monday, Tuesday
  timezone: 'zzz',
  dateWithWeekday: 'cccc, MM/dd/yyyy',
} as const;

// enumeration of weekdays as the Date API sees them, i.e. 0 - 6 = Sun - Sat
export enum Weekday {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

// order of steps we go through before buying a batch / purchasing a pickup
export enum SubmitStep {
  Start,
  PaymentGood,
  UspsGood,
  UpsGood,
}

export const POST_CODE_OPTIONAL_COUNTRIES: ReadonlyArray<string> = ['CA', 'MX']; // countries for which adding a postcode is possible but does not lead to an error if omitted

export enum UiEvents {
  BatchPurchased = 'batch_purchased',
}

// Show logo animation every few minutes (+- jitter). E.g. an interval of 5 min
// and a jitter of 1 min can result in pauses ranging from 4 to 6 minutes.
export const LOGO_ANIMATION_INTERVAL = 5 * 60 * 1000; // 5 minutes
export const LOGO_ANIMATION_INTERVAL_JITTER = 1 * 60 * 1000; // 1 minute
export const LOGO_ANIMATION_DO_NOT_REPEAT_LAST_X = 3; // keep track of 3 most recent animations to not play again
export const BATCH_PROCESS_POLL_INTERVAL = 200;

export type PaymentMethodModalVia = 'promo-link' | 'link' | 'submit'; // user path to the payment method modal on the buy page
export type PaymentMethodPromotion = 'plaidThreePercent' | 'none';

// used for the pickup pages
export const NEW_WAREHOUSE_VALUE = '0';
export const NEW_SHIPMENT_PRESET_VALUE = '0';
export const PICKUP_PACKAGE_LOCATION_OPTIONS: Option<PickupRequestPackageLocation>[] = [
  {
    value: 'FRONT_DOOR',
    title: 'Front Door',
  },
  {
    value: 'BACK_DOOR',
    title: 'Back Door',
  },
  {
    value: 'SIDE_DOOR',
    title: 'Side Door',
  },
  {
    value: 'KNOCK_OR_RING',
    title: 'Knock on Door/Ring Bell',
  },
  {
    value: 'MAIL_ROOM',
    title: 'Mail Room',
  },
  {
    value: 'OFFICE',
    title: 'Office',
  },
  {
    value: 'RECEPTION',
    title: 'Reception',
  },
  {
    value: 'MAILBOX',
    title: 'In/At Mailbox',
  },
  {
    value: 'OTHER',
    title: 'Other',
  },
];

export enum CarrierInfoOption {
  UpsRateLimited = 'ups-rate-limited',
  NoUsps = 'no-usps',
  NoUps = 'no-ups',
}

export type LocalOrUTC = 'UTC' | 'local';

export const INTERCOM_ARTICLE_LINKS = {
  fileInsurance:
    'https://support.pirateship.com/en/articles/1068439-how-do-i-file-an-insurance-claim',
};

export type BatchAndShipmentIds = {
  batchId?: string;
  shipmentId?: string;
};
