import { useLocation, useNavigate } from 'react-router-dom';
import environment from '../utils/environment';

export const validModalTypes = ['terms', 'dpa', 'privacy'] as const;
export type ModalType = (typeof validModalTypes)[number];

export const isValidModalType = (x: any): x is ModalType => validModalTypes.includes(x);

export default function useModal() {
  const navigate = useNavigate();
  const location = useLocation();

  if (environment.isBridge()) {
    return (type?: ModalType) => {
      const target: string = type ? `${location.search}#modal=${type}` : location.search;
      window.location.href = target;
    };
  }
  return (type?: ModalType) => {
    const target: string = type ? `${location.search}#modal=${type}` : location.search;
    navigate(target);
  };
}
