import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const buyBatchMutation = gql(/* GraphQL */ `
  mutation BuyBatchMutation(
    $id: ID!
    $rateSelection: [RateSelectionInput!]!
    $paymentSourceId: ID!
    $shipDate: DateTime!
    $totalCharge: Float!
    $mailTemplateId: ID
    $notifyRecipientsDate: String
  ) {
    buyBatch(
      id: $id
      rateSelection: $rateSelection
      paymentSourceId: $paymentSourceId
      shipDate: $shipDate
      totalCharge: $totalCharge
      mailTemplateId: $mailTemplateId
      notifyRecipientsDate: $notifyRecipientsDate
    ) {
      id
      stepText
      shipDate
    }
  }
`);

export const useBuyBatchMutation = () => useMutation(buyBatchMutation);
