import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { SPACING } from '../../../styles/spacing';
import LandlubberContent from '../../layout/LandlubberContent';
import FormControl from '../../form/FormControl';
import OneTimePassword from '../../form/OneTimePassword';
import Label from '../../form/Label';
import { Col, Row } from '../../layout/Grid';
import PageTitle from '../../layout/PageTitle';
import ProgressButton from '../../form/ProgressButton';
import convertObjectToUrlSearchParams from '../../../utils/convertObjectToUrlSearchParams';
import useNavigateOrHref from '../../../hooks/useNavigateOrHref';
import useVerificationCodeErrorHandler from '../../../hooks/useVerificationCodeErrorHandler';
import OneTimePasswordSchema from '../../../validation/OneTimePasswordSchema';

export default function TwoFactorAuthenticationChallengePage() {
  const form = useRef<HTMLFormElement | null>(null);
  const [loading, setLoading] = useState(false);
  const navigateOrHref = useNavigateOrHref();
  const { setError, clearError } = useVerificationCodeErrorHandler();

  const Styled = {
    PageTitle: styled(PageTitle)`
      margin-bottom: ${SPACING.xl};
    `,
  };

  return (
    <LandlubberContent>
      <Formik
        initialValues={{ code: '' }}
        validationSchema={() =>
          yup.object({
            code: OneTimePasswordSchema,
          })
        }
        onSubmit={async ({ code }) => {
          clearError();
          setLoading(true);
          const response = await fetch('/user/two-factor-authentication/confirm', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'X-Requested-With': 'XMLHttpRequest', // make it an ajax request
            },
            body: convertObjectToUrlSearchParams({ code }),
          });
          setLoading(false);

          if (!response.ok) {
            setError('general_error');
            return;
          }

          const responseData = (await response.json()) as { redirect?: string; error?: string };

          if (responseData.redirect) {
            navigateOrHref(responseData.redirect);
          } else {
            setError(responseData.error ?? 'general_error');
          }
        }}
      >
        <Form method="POST" action="/user/two-factor-authentication/confirm" ref={form}>
          <Row spaceBelow>
            <Col>
              <Styled.PageTitle>Check your email</Styled.PageTitle>
              <p>
                Please confirm access to your account by entering the verification code emailed to
                you.
              </p>
            </Col>
          </Row>
          <Row spaceBelow justify="center">
            <Col>
              <Label htmlFor="one-time-password-field">Verification Code</Label>
              <FormControl id="one-time-password-field" name="code" as={OneTimePassword} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProgressButton
                type="submit"
                fullWidth
                noWrap
                size="xLarge"
                progress={loading}
                disabled={loading}
              >
                Yarrrr, log me in
              </ProgressButton>
            </Col>
          </Row>
        </Form>
      </Formik>
    </LandlubberContent>
  );
}
