import { DATE_FORMAT, LocalOrUTC } from '../constants';
import useDateInUserTimezone from './useDateInUserTimezone';

export default function useFormatDatePickerText(localOrUTC: LocalOrUTC) {
  const { formatDate } = useDateInUserTimezone();

  return (date: Date, withTime: boolean, forceShortDate?: boolean) => {
    if (forceShortDate) {
      return `${formatDate(localOrUTC, date, DATE_FORMAT.usSemiVerboseDate)}`;
    }

    if (withTime) {
      return `${formatDate(localOrUTC, date, DATE_FORMAT.usVerboseDate)} ${formatDate(
        localOrUTC,
        date,
        DATE_FORMAT.time12NoLeadingZero,
      )}`;
    }

    return `${formatDate(localOrUTC, date, DATE_FORMAT.usVerboseDate)}`;
  };
}
