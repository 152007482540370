import capitalize, { capsWords } from './capitalize';

/**
 * Insert a space before all caps and capitalize the first letter
 */
export default function decamelize(s: string) {
  const capsWordsExploded = capsWords.map((w) => w.replace(/(.)/g, ' $1').trim());
  const spaced = s.replace(/([A-Z])/g, ' $1');
  const cappedAndSpaced = capitalize(spaced).trim();
  return capsWordsExploded.reduce(
    (str, capWordExploded, index) => str.replace(capWordExploded, capsWords[index]),
    cappedAndSpaced,
  );
}
