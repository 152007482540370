import { ShipmentKey } from '../../gql/graphql';
import Section, { SectionEntries } from './Section';

type BatchPassthroughDataSectionProps = {
  hideIf?: boolean;
  spreadsheetMapping: readonly {
    id?: string | null;
    shipmentKey: ShipmentKey;
    valueOrMappingKey: string;
  }[];
};
function BatchPassthroughDataSection({
  hideIf,
  spreadsheetMapping,
}: BatchPassthroughDataSectionProps) {
  const entries: SectionEntries = spreadsheetMapping
    .filter((mapping) => mapping.shipmentKey.toLowerCase().includes('passthrough'))
    .map((ssm) => [ssm.shipmentKey.toLowerCase(), ssm.valueOrMappingKey]);

  return <Section title="Passthrough Data" hideIf={hideIf} entries={entries} />;
}

export default BatchPassthroughDataSection;
