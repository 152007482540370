import {
  Ref,
  useLayoutEffect,
  useRef,
  useState,
  forwardRef,
  CSSProperties,
  ReactNode,
  MouseEvent,
  isValidElement,
} from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import clsx from 'clsx';
import useOutsideClickEffect from '../../hooks/useOutsideClickEffect';
import useWindowSize from '../../hooks/useWindowSize';
import Button, { ButtonProps } from './Button';
import useForwardedRef from '../../hooks/useForwardedRef';
import usePortalContainer from '../../hooks/usePortalContainer';
import { TYPOGRAPHY } from '../../styles/typography';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { SPACING } from '../../styles/spacing';
import { OPACITY } from '../../styles/opacities';
import Z_INDEX from '../../styles/zindex';

import Icon from '../Icon';

export type DropdownButtonOption<T> = {
  value: T;
  title?: ReactNode;
  disabled?: boolean;
};

export type DropdownButtonProps<T> = Omit<ButtonProps, 'onSelect'> & {
  title: string;
  options: DropdownButtonOption<T>[];
  onSelect: (value: T, event: MouseEvent<HTMLButtonElement>) => void;
  onOpen?: () => void;
  onClose?: () => void;
};

type DropdownOptionWrapperProps = Required<Pick<ButtonProps, 'variant'>>;
type DropdownOptionProps = Pick<ButtonProps, 'disabled'>;

const Styled = {
  Button: styled(Button)<{ open: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: ${({ open }) => (open ? BORDER_RADIUS.none : BORDER_RADIUS.sm)};
    border-bottom-right-radius: ${({ open }) => (open ? BORDER_RADIUS.none : BORDER_RADIUS.sm)};
  `,
  Title: styled.div`
    margin-right: 5px;
  `,
  DropdownOptionsWrapper: styled.div<DropdownOptionWrapperProps>`
    position: absolute;
    z-index: ${Z_INDEX.dropdown};
    padding: 0;
    overflow: hidden;
    border-top: ${BORDER_WIDTH.none};
    box-shadow: none;
    font-size: ${TYPOGRAPHY.fontSize.md};
    border: ${BORDER_WIDTH.none};
    border-bottom-left-radius: ${BORDER_RADIUS.sm};
    border-bottom-right-radius: ${BORDER_RADIUS.sm};
  `,
  DropdownOptionWrapper: styled.div<DropdownOptionProps>`
    ${Button} {
      border: none;
      text-align: left;
      padding-left: ${SPACING.md};
      border-radius: ${SPACING.none};
    }
    opacity: ${({ disabled }) => (disabled ? OPACITY.disabled : 1)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  `,
};

function DropdownButtonComponent<T>(props: DropdownButtonProps<T>, ref: Ref<HTMLButtonElement>) {
  const {
    title,
    options,
    onSelect,
    className,
    onClick,
    onOpen,
    onClose,
    variant = 'secondary',
    disabled,
    ...others
  } = props;
  const forwardedRef = useForwardedRef(ref);
  const [positioning, setPositioning] = useState<CSSProperties>();
  const [open, setOpen] = useState(false);
  const windowSize = useWindowSize();
  const portalContainer = usePortalContainer();

  useLayoutEffect(() => {
    if (forwardedRef.current && open) {
      const target = forwardedRef.current;
      const {
        x: targetLeft,
        y: targetTop,
        height: targetHeight,
        width: targetWidth,
      } = target.getBoundingClientRect();

      setPositioning({
        top: window.scrollY + targetTop + targetHeight,
        left: targetLeft,
        minWidth: targetWidth,
      });
    }
  }, [forwardedRef, open, windowSize]);

  const dropdownOptionsRef = useRef<HTMLDivElement>(null);
  useOutsideClickEffect([forwardedRef, dropdownOptionsRef], () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  });

  return (
    <>
      <Styled.Button
        ref={forwardedRef}
        open={open}
        className={clsx(className, { open })}
        disabled={disabled}
        variant={variant}
        onClick={(event) => {
          if (onClick) {
            onClick(event);
          }

          if (disabled) {
            return;
          }

          setOpen(!open);
          if (!open && onOpen) {
            onOpen();
          } else if (open && onClose) {
            onClose();
          }
        }}
        {...others}
      >
        <Styled.Title>{title}</Styled.Title>
        <Icon icon="caret-down" />
      </Styled.Button>

      {portalContainer &&
        open &&
        createPortal(
          <Styled.DropdownOptionsWrapper
            ref={dropdownOptionsRef}
            variant={variant}
            style={positioning}
          >
            {options.map((option) => (
              <Styled.DropdownOptionWrapper key={`${option.value}`}>
                <Button
                  fullWidth
                  variant={variant}
                  disabled={option.disabled}
                  onClick={(event) => {
                    onSelect(option.value, event);
                    setOpen(false);
                    if (onClose) {
                      onClose();
                    }
                  }}
                >
                  {option.title ??
                    (isValidElement(option.value) ? option.value : String(option.value))}
                </Button>
              </Styled.DropdownOptionWrapper>
            ))}
          </Styled.DropdownOptionsWrapper>,
          portalContainer,
        )}
    </>
  );
}

const DropdownButton = forwardRef(DropdownButtonComponent);
export default DropdownButton;
