import styled from '@emotion/styled';
import { GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';

type PageTitleProps = {
  center?: boolean;
} & JSX.IntrinsicElements['h1'];

const Styled = {
  PageTitle: styled('h1')<PageTitleProps>`
    margin: ${SPACING.none};
    font-size: ${TYPOGRAPHY.heading.xxl.fontSize};
    line-height: ${TYPOGRAPHY.heading.xxl.lineHeight};
    font-weight: ${TYPOGRAPHY.heading.xxl.fontWeight};
    font-style: normal;
    color: ${GREYSCALE.black};
    text-align: ${(props: PageTitleProps) => (props.center ? 'center' : 'start')};
  `,
};

export default function PageTitle(props: PageTitleProps) {
  return <Styled.PageTitle {...props} />;
}
