import type { CellContext } from '@tanstack/react-table';
import formatWeight from '../../../utils/formatWeight';

export type WeightCellSettings = {
  precision?: number;
};

export default function WeightCell<TData, TValue extends unknown>(
  props: CellContext<TData, TValue>, // weight cells expect the number value in ounces
) {
  const settings = (props.column.columnDef.meta?.cellSettings ?? {}) as WeightCellSettings;
  return formatWeight({ pounds: 0, ounces: props.getValue() as number }, settings.precision);
}
