import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const latestTermsQuery = gql(/* GraphQL */ `
  query LatestTermsQuery {
    latestTerms {
      id
      terms
    }
  }
`);

export const useLatestTermsQuery = () => useQuery(latestTermsQuery);
