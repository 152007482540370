import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { currentUserVar, CurrentUser } from '../apollo/cache';
import { useUserLazyQuery } from '../operations/queries/user';
import setCurrentUser from '../operations/mutations/setCurrentUser';
import { useUserUnawareLogger } from './useLogger';
import environment from '../utils/environment';
import loggedInService from '../services/loggedInService';

type UseCurrentUserResult = [
  CurrentUser | null,
  {
    loading: boolean;
    loadCurrentUser: () => void;
  },
];

// BRIDGE ONLY
declare global {
  interface Window {
    isLoggedIn?: boolean;
  }
}

let userLoadingMutex = false;

export default function useCurrentUser(): UseCurrentUserResult {
  const logger = useUserUnawareLogger();
  const navigate = useNavigate();
  const [checkComplete, setCheckComplete] = useState(false);
  const [
    loadUserData,
    {
      data: remoteUserData,
      error: remoteUserError,
      loading: remoteUserLoading,
      called: remoteUserCalled,
    },
  ] = useUserLazyQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });
  const currentUser = useReactiveVar(currentUserVar);
  const loading = !checkComplete || remoteUserLoading;

  const loadCurrentUser = () => {
    if (currentUser || userLoadingMutex) {
      return;
    }

    if ((environment.isBridge() && window.isLoggedIn) || loggedInService.isLoggedIn()) {
      logger.debug('Current user data unset, but authenticated. Loading user data.');

      userLoadingMutex = true;
      loadUserData().finally(() => {
        userLoadingMutex = false;
      });
    } else {
      setCheckComplete(true);
    }
  };

  // Load current user data
  useEffect(loadCurrentUser, [logger, currentUser, navigate, loadUserData]);

  // Handle loaded user data
  useEffect(() => {
    if (!remoteUserCalled || remoteUserLoading) {
      return;
    }

    setCheckComplete(true);

    if (remoteUserData) {
      setCurrentUser(remoteUserData.user);
    }
  }, [remoteUserCalled, remoteUserLoading, remoteUserError, remoteUserData, navigate]);

  return [currentUser, { loading, loadCurrentUser }];
}
