import { lazy, ForwardedRef, forwardRef } from 'react';
import * as yup from 'yup';
import { NamespacedSubform } from './types';
import { PaymentApiMethodTitle } from '../../gql/graphql';

const SpreedlyCreditCardSubform = lazy(() => import('./creditCard/SpreedlyCreditCardSubform'));
const StripeCreditCardSubform = lazy(() => import('./creditCard/StripeCreditCardSubform'));

export type CreditCardSubformValues = {
  cp: boolean; // Indicates if cc values were pasted
  [key: string]: any; // Any other property a provider-specific subform may use
};

export type CreditCardSubformProps<NS extends string> = {
  // paymentApi: 'stripe' | 'spreedly' | 'offline';
  paymentApi: PaymentApiMethodTitle; // TODO: tighter! this is now capitalised (maybe wrong)
  apiKey: string | null;
  userEmail: string;
  onError?: (message: string) => void;
} & NamespacedSubform<NS>;

export type CreditCardSubformRef = {
  initialValues: CreditCardSubformValues;
  validationSchema: yup.ObjectSchema<CreditCardSubformValues | undefined>;
  createToken: () => Promise<string>;
};

export type ChildCreditCardSubformProps<NS extends string> = Omit<
  CreditCardSubformProps<NS>,
  'paymentApi'
> & {
  namespace: string; // Make it required
};

export type ChildCreditCardSubformRef = CreditCardSubformRef;

function CreditCardSubformComponent<NS extends string>(
  props: CreditCardSubformProps<NS>,
  ref: ForwardedRef<CreditCardSubformRef>,
) {
  const { paymentApi, namespace, ...others } = props;
  let ChildCreditCardSubform: ForwardRefComponent<
    ChildCreditCardSubformRef,
    ChildCreditCardSubformProps<NS>
  >;

  // Pick correct subform based on active payment API
  switch (paymentApi) {
    case 'Spreedly':
      ChildCreditCardSubform = SpreedlyCreditCardSubform;
      break;

    case 'Stripe':
      ChildCreditCardSubform = StripeCreditCardSubform;
      break;

    default:
      throw new Error(
        `Rendering credit card subform for payment API '${paymentApi}' is not supported`,
      );
  }

  return <ChildCreditCardSubform ref={ref} namespace={namespace} {...others} />;
}

const CreditCardSubform = forwardRef(CreditCardSubformComponent);
export default CreditCardSubform;
