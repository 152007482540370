import styled from '@emotion/styled';
import BaseLoading, { BaseLoadingProps } from './BaseLoading';

type ContentLoadingProps = BaseLoadingProps & {
  height?: number;
};

const Styled = {
  Wrapper: styled.div<{ height?: number }>`
    width: 100%;
    height: ${({ height }) => `${height}px` ?? '100%'};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

function ContentLoading({ size = 30, height }: ContentLoadingProps) {
  return (
    <Styled.Wrapper height={height}>
      <BaseLoading size={size} />
    </Styled.Wrapper>
  );
}

export default ContentLoading;
