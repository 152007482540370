import Section, { SectionEntries, SHOW_KEY_ONLY, SHOW_VALUE_ONLY } from './Section';

export type BatchCustomsFormSummaryProps = {
  customsFormSummary: {
    signer: string;
    title: string;
    numCustomsForms: number;
    contentType: string;
    exporterTaxIdSummary: string;
    itemSummary: string[] | readonly string[]; // TODO: readonly just needed for now because taking type from gql
  };
};

function BatchCustomsFormSection({ customsFormSummary }: BatchCustomsFormSummaryProps) {
  if (customsFormSummary.numCustomsForms === 0) return null;

  const itemSummaryEntries: SectionEntries = customsFormSummary.itemSummary.map((line) => [
    SHOW_VALUE_ONLY,
    line,
  ]);

  const entries: SectionEntries = [
    ['Signer', customsFormSummary.signer],
    ['Content Type', customsFormSummary.contentType],
    ['Tax Id', customsFormSummary.exporterTaxIdSummary],
    ['Items:', customsFormSummary.itemSummary.length ? SHOW_KEY_ONLY : null],
    ...itemSummaryEntries,
  ];
  return <Section title={customsFormSummary.title} entries={entries} />;
}

export default BatchCustomsFormSection;
