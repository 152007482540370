import styled from '@emotion/styled';
import { getIn, useFormikContext } from 'formik';
import { StyledLabel } from '../../form/Label';
import {
  StyledPurchaseAlignRightCol,
  StyledPurchaseCol,
  StyledPurchaseRow,
  StyledPurchaseSubCol,
} from '../../layout/PurchaseGrid';
import { Row } from '../../layout/Grid';
import FormControl from '../../form/FormControl';
import Checkbox from '../../form/Checkbox';
import DatePickerLink from '../../form/dateTimePickers/DatePickerLink';
import Select from '../../form/Select';
import { NamespacedSubform } from '../types';
import useDateInUserTimezone from '../../../hooks/useDateInUserTimezone';

const Styled = {
  NotifyRecipientsWrapper: styled.div`
    display: flex;
    align-items: center;
    // overwrite for smaller input with 14px font size
    ${StyledLabel} {
      padding-top: 1px;
    }
  `,
};

export type NotifyRecipientsSettingRowValues = {
  enabled: boolean;
  emailTemplate?: string; // optional, 'cause user should be able to buy without template
  date: Date;
};

export type NotifyRecipientsSettingRowProps<NS extends string> = NamespacedSubform<NS> & {
  defaultEmailTemplateId: string;
  mailTemplates: ReadonlyArray<{
    id: string;
    name: string;
  }>;
};

export default function NotifyRecipientsSettingRow<NS extends string>({
  namespace,
  defaultEmailTemplateId,
  mailTemplates,
}: NotifyRecipientsSettingRowProps<NS>) {
  const { createDate, getUserTimezoneCode } = useDateInUserTimezone();
  const { values } = useFormikContext();
  const { enabled } = getIn(values, namespace) as NotifyRecipientsSettingRowValues;

  const todayLocalNow = createDate('now', 'localFromUTC');

  return (
    <StyledPurchaseRow align="center">
      <StyledPurchaseCol xs={5}>
        <Styled.NotifyRecipientsWrapper>
          <FormControl
            name={`${namespace}.enabled`}
            as={Checkbox}
            label={`Email recipients (${getUserTimezoneCode()})?`}
            type="checkbox"
          />
        </Styled.NotifyRecipientsWrapper>
      </StyledPurchaseCol>
      <StyledPurchaseAlignRightCol xs={7}>
        <Row align="center" justify="end">
          <StyledPurchaseSubCol sm={12} md={5}>
            {mailTemplates.length > 1 && (
              <FormControl
                name={`${namespace}.emailTemplate`}
                as={Select}
                disabled={!enabled}
                options={mailTemplates.map((template) => ({
                  title: `${template.name} ${
                    template.id === defaultEmailTemplateId ? '(Default)' : ''
                  }`,
                  value: template.id,
                }))}
              />
            )}
          </StyledPurchaseSubCol>
          <StyledPurchaseSubCol sm={12} md="content">
            <FormControl
              name={`${namespace}.date`}
              minDate={todayLocalNow}
              as={DatePickerLink}
              timePicker
              disabled={!enabled}
              localOrUTC="local"
            />
          </StyledPurchaseSubCol>
        </Row>
      </StyledPurchaseAlignRightCol>
    </StyledPurchaseRow>
  );
}
