import Section, { SectionEntries } from './Section';

export type RubberStampsShipmentSectionProps = {
  rubberStamps: {
    rubberStamp1: string | null;
    rubberStamp2: string | null;
    rubberStamp3: string | null;
  };
};

function RubberStampsShipmentSection({ rubberStamps }: RubberStampsShipmentSectionProps) {
  const entries: SectionEntries = [
    ['Rubber Stamp 1', rubberStamps.rubberStamp1],
    ['Rubber Stamp 2', rubberStamps.rubberStamp2],
    ['Rubber Stamp 3', rubberStamps.rubberStamp3],
  ];

  if (!rubberStamps.rubberStamp1 && !rubberStamps.rubberStamp2 && !rubberStamps.rubberStamp3) {
    return null;
  }

  return <Section title="Rubber Stamps" entries={entries} />;
}
export default RubberStampsShipmentSection;
