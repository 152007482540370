import { MutationHookOptions, useMutation } from '@apollo/client';
import { gql } from '../../gql';
import { UpdateBatchTitleMutation, UpdateBatchTitleMutationVariables } from '../../gql/graphql';

export const updateBatchTitleMutation = gql(/* GraphQL */ `
  mutation UpdateBatchTitleMutation($id: ID!, $title: String!) {
    updateBatchTitle(id: $id, title: $title) {
      id
      title
    }
  }
`);

export const useUpdateBatchTitleMutation = (
  options?: MutationHookOptions<UpdateBatchTitleMutation, UpdateBatchTitleMutationVariables>,
) => useMutation(updateBatchTitleMutation, options);
