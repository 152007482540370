import { AnchorHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { ButtonStyleProps } from './Button';
import { SPACING } from '../../styles/spacing';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { TYPOGRAPHY } from '../../styles/typography';
import { OPACITY } from '../../styles/opacities';
import withOpacity from '../../utils/withOpacity';
import Link from '../Link';

// a link element, styled as a button, for use in the connected data grid in the actions column.
const ButtonLink = styled(Link)<ButtonStyleProps & AnchorHTMLAttributes<HTMLAnchorElement>>`
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  width: ${(props) => (props.fullWidth ? '100%' : '')};
  margin: ${SPACING.none};
  border: ${BORDER_WIDTH.sm} solid transparent;
  text-align: center;
  vertical-align: middle;
  white-space: ${(props) => (props.noWrap ? 'nowrap' : 'normal')};
  text-overflow: ellipsis;
  user-select: none;
  @media print {
    display: ${({ hideForPrint }) => (hideForPrint ? 'none' : 'inline')};
  }
  :focus-within {
    outline-color: ${COLOR.blue};
  }
  padding: 2px 9px ${SPACING.none};
  background-color: ${COLOR.blue};
  border-color: ${COLOR.blueButtonAccent};
  color: ${GREYSCALE.white};
  :hover,
  :active,
  :focus {
    background-color: ${COLOR.blueButtonAccent};
    color: white;
  }

  font-size: ${TYPOGRAPHY.fontSize.sm};
  font-weight: ${TYPOGRAPHY.fontWeight.regular};
  border-width: ${BORDER_WIDTH.xs};
  border-radius: ${BORDER_RADIUS.xs};
  :active {
    box-shadow: inset ${SPACING.none} ${SPACING.xs} ${SPACING.sm}
      ${withOpacity(GREYSCALE.black, 0.125)};
  }

  :disabled {
    opacity: ${OPACITY.disabled};
    cursor: not-allowed;
  }
`;

export default ButtonLink;
