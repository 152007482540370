import { Routes, Route } from 'react-router-dom';
import { lazy } from 'react';

const ImportGridPage = lazy(() => import('./integrations/ImportGridPage'));

export default function ImportRoutes() {
  return (
    <Routes>
      <Route index element={<ImportGridPage autoSync autoPoll />} />
      <Route path="ignored" element={<ImportGridPage gridMode="IGNORED" />} />
    </Routes>
  );
}
