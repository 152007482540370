import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createPlaidAccessTokenMutation = gql(/* GraphQL */ `
  mutation CreatePlaidAccessTokenMutation($publicToken: String!, $handle: String!) {
    createPlaidAccessToken(publicToken: $publicToken, handle: $handle) {
      handle
      paymentSource {
        ...PaymentSourceFragment
      }
      mask
      name
    }
  }
`);

export const useCreatePlaidAccessTokenMutation = () => useMutation(createPlaidAccessTokenMutation);
