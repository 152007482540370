import { forwardRef, Ref, TextareaHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { GREYSCALE, COLOR } from '../../styles/colors';
import { TYPOGRAPHY } from '../../styles/typography';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { SPACING } from '../../styles/spacing';

const StyledTextArea = styled('textarea')`
  display: block;
  outline: 0;
  border: ${BORDER_WIDTH.sm} solid ${GREYSCALE.grey30};
  border-radius: ${BORDER_RADIUS.sm};
  padding: ${SPACING.sm} ${SPACING.md};
  width: 100%;
  height: auto;
  font-family: ${TYPOGRAPHY.body.fontFamily};
  font-size: ${TYPOGRAPHY.body.fontSize};
  line-height: ${TYPOGRAPHY.body.lineHeight};
  box-sizing: border-box;
  resize: none;

  &:focus {
    border-color: ${COLOR.blue};
  }
`;

function TextAreaComponent(
  { children, ...others }: TextareaHTMLAttributes<HTMLTextAreaElement>,
  ref: Ref<HTMLTextAreaElement>,
) {
  return (
    <StyledTextArea ref={ref} {...others}>
      {children}
    </StyledTextArea>
  );
}

const TextArea = forwardRef(TextAreaComponent);
export default TextArea;
