import { FilterTagContext } from '../types';
import useDateInUserTimezone from '../../../hooks/useDateInUserTimezone';
import { DATE_FORMAT } from '../../../constants';

// TODO: implement local/utc possibility, for now we assume the value is UTC and the user will see dates in their local time
export type DateTimeFilterTagSettings = {
  timezone?: string;
};

// this is rendered with the flexRender function
export default function DateTimeFilterTag<TData, TValue>(
  filterTagContext: FilterTagContext<TData, TValue>,
) {
  const { formatDate } = useDateInUserTimezone();
  const { filterValue } = filterTagContext;
  if (!filterValue) return '';
  return formatDate('UTC', filterValue, `${DATE_FORMAT.usDate} ${DATE_FORMAT.time12NoLeadingZero}`);
}
