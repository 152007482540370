import FormControl from '../../form/FormControl';
import DatePickerLink from '../../form/dateTimePickers/DatePickerLink';
import {
  PurchaseDescriptionLabel,
  StyledPurchaseAlignRightCol,
  StyledPurchaseCol,
  StyledPurchaseRow,
} from '../../layout/PurchaseGrid';
import { NamespacedSubform } from '../types';

export type ShipDateSettingRowValues = {
  date: Date;
};

export type ShipDateSettingRowProps<NS extends string> = NamespacedSubform<NS> & {
  availableShipDates: Date[];
  onShipDateChange?: (shipDate: Date) => void;
};

export default function ShipDateSettingRow<NS extends string>({
  namespace,
  availableShipDates,
  onShipDateChange,
}: ShipDateSettingRowProps<NS>) {
  return (
    <StyledPurchaseRow align="center">
      <StyledPurchaseCol xs={5} md={8}>
        <PurchaseDescriptionLabel>Ship Date</PurchaseDescriptionLabel>
      </StyledPurchaseCol>
      <StyledPurchaseAlignRightCol xs={7} md={4}>
        <FormControl
          name={`${namespace}.date`}
          minDate={availableShipDates[0]}
          localOrUTC="local"
          maxDate={availableShipDates[availableShipDates.length - 1]}
          enabledDates={availableShipDates}
          as={DatePickerLink}
          onChange={onShipDateChange}
        />
      </StyledPurchaseAlignRightCol>
    </StyledPurchaseRow>
  );
}
