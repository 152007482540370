import styled from '@emotion/styled';
import { COLOR, GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { formatCurrency } from '../../utils/currency';

const Styled = {
  FreeWrapper: styled.span`
    color: ${COLOR.red};
    padding-right: ${SPACING.md};
  `,
  LineThroughWrapper: styled.span`
    color: ${COLOR.red};
    text-decoration: line-through;
  `,
  PriceDisplayWrapper: styled.span`
    color: ${GREYSCALE.black};
  `,
};

type CrossedOutPriceProps = {
  compareWithFreePrice?: boolean;
  crossedOutPrice: number;
};

function CrossedOutPrice({ compareWithFreePrice, crossedOutPrice }: CrossedOutPriceProps) {
  return (
    <div>
      {compareWithFreePrice && <Styled.FreeWrapper>FREE</Styled.FreeWrapper>}
      <Styled.LineThroughWrapper>
        <Styled.PriceDisplayWrapper>{formatCurrency(crossedOutPrice)}</Styled.PriceDisplayWrapper>
      </Styled.LineThroughWrapper>
    </div>
  );
}

export default CrossedOutPrice;
