import { useEffect } from 'react';
import { DatetimeRangeInput } from '../../../../gql/graphql';
import { useShipmentChartLazyQuery } from '../../../../operations/queries/shipmentChart';
import { GREYSCALE } from '../../../../styles/colors';
import LineChart from '../../../charts/LineChart';
import { Col } from '../../../layout/Grid';
import { ReportsOverviewStyles } from '../ReportsOverviewPage';

type ReportsShipmentChartProps = {
  currentFilter: string;
  dateFormatter: () => DatetimeRangeInput;
  netLabelCount: number | undefined;
};

export default function ReportsShipmentChart({
  currentFilter,
  dateFormatter,
  netLabelCount,
}: ReportsShipmentChartProps) {
  const [loadShipmentChart, { data: shipmentChartData }] = useShipmentChartLazyQuery({
    variables: {
      datetimeRange: dateFormatter(),
    },
  });

  useEffect(() => {
    loadShipmentChart({
      variables: {
        datetimeRange: dateFormatter(),
      },
    });
  }, [dateFormatter, loadShipmentChart]);

  return (
    <Col md={8}>
      <ReportsOverviewStyles.ShortChart
        title="Total Shipments"
        loading={!shipmentChartData || netLabelCount === undefined}
        link="View All Shipments"
        linkTo="/reports/shipment"
        shipments={netLabelCount}
        fullHeight
        labelId="reports-shipments-chart-headline"
      >
        <LineChart
          currentFilter={currentFilter}
          data={shipmentChartData?.shipmentChart.charts ?? []}
          labels={shipmentChartData?.shipmentChart.labels ?? []}
          customOptions={{
            chart: {
              className: 'screen-chart',
            },
            yAxis: {
              accessibility: {
                description: 'number of shipments',
                rangeDescription: '',
              },
            },
          }}
        />
        <LineChart
          data={shipmentChartData?.shipmentChart.charts ?? []}
          labels={shipmentChartData?.shipmentChart.labels ?? []}
          customOptions={{
            accessibility: {
              enabled: false,
            },
            chart: {
              className: 'print-chart',
              height: 160,
              width: 600,
              marginBottom: 45,
              marginLeft: 45,
            },
            xAxis: {
              labels: {
                rotation: -35,
                style: {
                  fontSize: '0.9em',
                },
              },
            },
            yAxis: {
              title: {
                text: '',
              },
              labels: {
                enabled: true,
                style: {
                  color: GREYSCALE.black,
                  fontSize: '0.9em',
                  fontWeight: '600',
                },
                y: 1,
              },
              gridLineWidth: 1,
            },
          }}
        />
      </ReportsOverviewStyles.ShortChart>
    </Col>
  );
}
