import * as yup from 'yup';

const OneTimePasswordSchema = yup
  .string()
  .required()
  .min(6)
  .max(6)
  .matches(/^[0-9]{6}$/, 'Only numbers are allowed');

export default OneTimePasswordSchema;
