import Button from '../../components/form/Button';
import CopyGroup from '../../components/form/CopyGroup';

export type ShareLinkProps = {
  onClick: () => void;
  url?: string;
};

export default function ShareLink({ onClick, url }: ShareLinkProps) {
  if (!url) {
    return (
      <Button data-testid="create-share-link" variant="info" fullWidth onClick={onClick}>
        Create Share Link
      </Button>
    );
  }

  return <CopyGroup value={url} label="" />;
}
