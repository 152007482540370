import { gql } from '../../gql';

const shipmentDetailsPageQuery = gql(/* GraphQL */ `
  query ShipmentDetailsPageQuery($batchId: ID!, $shipmentId: String!) {
    batch(id: $batchId) {
      id
      title
      labelFileFormat
      labelSize
      warehouse {
        id
        title
        shy
        originAddress {
          id
          ...AddressFragment
        }
        returnAddress {
          id
          ...AddressFragment
        }
      }
      numShipments
      shipDate
      dataSource
      spreadsheetMapping {
        id
        shipmentKey
        valueOrMappingKey
      }
      packageSummary {
        maxWeight
        minWeight
        maxInsured
        minInsured
        dimensionsSummary
        packageTypeSummary
        deliveryConfirmationSummary
        packagePreset {
          ...PackageFragment
        }
      }
      customsFormSummary {
        numCustomsForms
        signer
        title
        contentType
        itemSummary
        exporterTaxIdSummary
      }
      rubberStamps {
        shipmentKey
        valueOrMappingKey
        userFriendlyShipmentKey
      }
      createdAt
      cleanupComplete
      notifyRecipientsDate
      emailNotificationTemplate {
        id
      }
      emailNotificationPossible
      emailNotificationSent
      shipments(shipmentId: $shipmentId) {
        id
        trackingNr
        trackingLink
        isReturnLabel
        foreignOrderId
        order {
          id
          foreignOrderId
          orderLink {
            url
            text
          }
          shipmentLink {
            url
            text
          }
          subscriptionLink {
            url
            text
          }
          platformKey
          platform {
            title
            additionalTrackingEmailCopy
          }
          orderItems {
            quantity
            title
            sku
          }
        }
        duplicateShipmentLink
        carrierTitle
        carrierKey
        errorMessage
        dimensionX
        dimensionY
        dimensionZ
        dimensionXOriginal
        dimensionYOriginal
        dimensionZOriginal
        rubberStamp1
        rubberStamp2
        rubberStamp3
        passthroughFields {
          fieldName
          value
        }
        weight
        weightOriginal
        disputableAdjustmentsCount
        adjustments {
          id
          createdAt
          dimensionXOriginal
          dimensionX
          dimensionYOriginal
          dimensionY
          dimensionZOriginal
          dimensionZ
          disputedAt
          disputeStatus
          disputeReason
          eligibilityIssueMessage
          processedAt
          disputeProcessedAt
          notes
          weightOriginal
          weight
        }
        status
        printed
        canPrint
        stageDots {
          title
          status
          message
        }
        recipientAddress {
          fullName
          company
          address1
          address2
          city
          regionCode
          postcode
          zip4
          countryCode
          address3
          email
          phone
        }
        deliveryEstimation {
          arrivalLocalDateTime
          pickupLocalDateTime
        }
        baseClientPrice
        baseClientPriceOriginal
        totalClientPrice
        totalClientPriceOriginal
        packageTypeOriginal {
          title
        }
        packageType {
          title
        }
        mailClassOriginal {
          title
        }
        mailClass {
          title
        }
        mailClassKey
        zone
        zoneOriginal
        surcharges {
          isIgnored
          title
          apiKey
          clientPrice
          clientPriceOriginal
          isOriginalSurcharge
          helpLink
        }
        additionalRefundNotice
        insuranceDetails {
          insuredValueRequested
          insuredValueType
          insuredValue
          hasOnlyCarrierLiabilityInsurance
          insuranceSurcharge {
            isIgnored
            title
            apiKey
            clientPrice
            clientPriceOriginal
            isOriginalSurcharge
            helpLink
          }
        }
        isShipmentRefundable
        canInstantRefundShipment
        customsFormEnabled
        customsSigner
        customsContentType
        exporterTaxId
        customsItems
      }
    }
    company {
      id
      accountBalance
    }
  }
`);

export default shipmentDetailsPageQuery;
