import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import { DocumentNode } from 'graphql';
import usePayPal from '../hooks/usePayPal';
import { MEDIA_QUERY } from '../styles/breakpoints';
import Shimmer from './loading/Shimmer';
import { useCreatePayPalPaymentSourceMutation } from '../operations/mutations/createPayPalPaymentSource';
import { setFlashMessage } from '../apollo/cache/flashMessages';
import useObfuscatedErrorLogger from '../hooks/useObfuscatedErrorLogger';

const [buttonWidth, buttonHeight] = [200, 50];

type ButtonStatus =
  | 'removed' // the div container we render the iframe into is completely removed from the DOM
  | 'loading' // the div container is rendered but the instance has not yet poured the iframe in yet
  | 'loaded'; // the button is loaded and clickable

type WrapperProps = {
  fullWidth?: boolean;
  height?: number;
};

const Styled = {
  Wrapper: styled.div<WrapperProps>`
    width: ${(props: WrapperProps) => (props.fullWidth ? '100%' : `${buttonWidth}px`)};
    height: ${(props: WrapperProps) => (props.height ? `${props.height}px` : `${buttonHeight}px`)};

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      flex-wrap: nowrap;
      flex-direction: column;
      width: 100%;
    }
  `,
  PayPalButtonIframe: styled.div``,
};

export const PayPalButtonSelector = Styled.Wrapper;

export type PayPalButtonProps = {
  apiKey: string;
  fullWidth?: boolean;
  height?: number;
  onSuccess?: (paymentSourceId: string) => void;
  refetchQueryOnApprove: DocumentNode;
};

function PayPalButton({
  apiKey,
  fullWidth,
  height,
  onSuccess,
  refetchQueryOnApprove,
}: PayPalButtonProps) {
  const logError = useObfuscatedErrorLogger();
  const instance = usePayPal(apiKey);
  const [createPayPalPaymentSource] = useCreatePayPalPaymentSourceMutation();
  const [billingToken, setBillingToken] = useState<string>();
  const [payPalButtonInstance, setPayPalButtonInstance] = useState<any>();
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('loading');

  useEffect(() => {
    if (payPalButtonInstance && buttonStatus === 'loading') {
      payPalButtonInstance.render('#payPalButton');
      setButtonStatus('loaded');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payPalButtonInstance]);

  useEffect(() => {
    (async () => {
      try {
        if (!billingToken && instance?.paypalCheckoutInstance) {
          const billingTokenResponse = await instance.paypalCheckoutInstance.createPayment({
            flow: 'vault',
          } as any);
          setBillingToken(billingTokenResponse);
        }
      } catch (error) {
        logError('Get PayPal Billing Token error', error as Error);
      }
    })();
  }, [billingToken, instance, logError]);

  useMemo(() => {
    if (
      instance?.payPalInstance &&
      instance?.paypalCheckoutInstance &&
      billingToken &&
      !payPalButtonInstance
    ) {
      const { paypalCheckoutInstance: payPalCheckoutInstance, payPalInstance } = instance;
      const onApprove = async (data: any) => {
        setButtonStatus('removed');
        const payload = await payPalCheckoutInstance.tokenizePayment(data);
        if (payload?.nonce) {
          try {
            const response = await createPayPalPaymentSource({
              variables: {
                nonce: payload.nonce,
              },
              refetchQueries: [refetchQueryOnApprove],
              awaitRefetchQueries: true,
            });

            const paymentSource = response.data?.createPayPalPaymentSource.paymentSource;
            if (!paymentSource) {
              return;
            }

            setFlashMessage("Y'er new PayPal account has been added", 'success');
            // completely reset the button in case the user wants to use it again
            setBillingToken(undefined);
            setPayPalButtonInstance(undefined);
            setButtonStatus('loading');

            if (onSuccess) {
              onSuccess(paymentSource.id);
            }
          } catch (e: any) {
            // because this function is enclosed by the payPalInstance.Buttons function
            // that is in a try catch block, we need to prevent that block from
            // catching any errors, as graphQL errors are logged from the BE anyway,
            // however we do need to stil show the error to the user
            setFlashMessage(e.message, 'danger');
          }
        }
      };
      (async () => {
        try {
          const payPalButtonInstanceResponse = await payPalInstance.Buttons({
            fundingSource: payPalInstance.FUNDING.PAYPAL,
            style: {
              layout: 'vertical',
              color: 'gold',
              shape: 'rect',
              label: 'pay',
              height: height ?? buttonHeight,
            },
            createBillingAgreement: () => billingToken,
            onApprove,
            onError: (error: Error) => {
              logError('PayPal Button onError', error);
            },
          });
          setPayPalButtonInstance(payPalButtonInstanceResponse);
        } catch (error) {
          logError('Set PayPal Button Instance Error', error as Error);
        }
      })();
    }
  }, [
    refetchQueryOnApprove,
    instance,
    billingToken,
    payPalButtonInstance,
    createPayPalPaymentSource,
    logError,
    onSuccess,
    height,
  ]);

  return (
    <Styled.Wrapper
      fullWidth={fullWidth}
      height={height}
      data-dd-action-name="pay_with_paypal_btn_click"
    >
      {buttonStatus !== 'removed' && <Styled.PayPalButtonIframe id="payPalButton" />}
      {buttonStatus !== 'loaded' && <Shimmer.RoundedRectangle />}
    </Styled.Wrapper>
  );
}

export default PayPalButton;
