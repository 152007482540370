import { useApolloClient } from '@apollo/client';
import { loggingOutVar, currentUserVar } from '../apollo/cache';
import loggedInService from '../services/loggedInService';

export default function useLogout(): () => Promise<void> {
  const apolloClient = useApolloClient();

  return async () => {
    if (loggingOutVar()) {
      return;
    }

    loggingOutVar(true);

    try {
      // Log out the user by telling the backend to invalidate the auth token
      // and then removing it locally while also clearing the current user state.
      const response = await fetch('/logout', {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      if (!response.ok) {
        throw new Error('Logout was not successful');
      }

      loggedInService.setLoggedOut();
      currentUserVar(null);

      // Clear the Apollo cache
      await apolloClient.clearStore();
    } finally {
      loggingOutVar(false);
    }
  };
}
