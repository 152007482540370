import styled from '@emotion/styled';
import { BORDER_WIDTH } from '../styles/borders';
import { COLOR, PirateShipColor } from '../styles/colors';
import { SPACING } from '../styles/spacing';
import { TYPOGRAPHY } from '../styles/typography';
import { ButtonVariant } from './form/Button';
import linkStyles from './linkStyles';

const variantColorMap = new Map<ButtonVariant, PirateShipColor>([
  ['primary', COLOR.blue],
  ['danger', COLOR.red],
]);

type Variant = 'primary' | 'danger';

const BottomRowSecondaryButton = styled.button<{
  variant: Variant;
}>`
  ${linkStyles}
  font-size: ${TYPOGRAPHY.fontSize.sm};
  appearance: none;
  border: ${BORDER_WIDTH.none};
  padding: ${SPACING.md} ${SPACING.none};
  line-height: unset;
  background-color: transparent;
  color: ${({ variant }) => (variant ? variantColorMap.get(variant) : 'inherit')};
`;

export default BottomRowSecondaryButton;
