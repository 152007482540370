import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import Modal from '../../components/modals/Modal';
import FlashMessages from '../../components/FlashMessages';
import { flashMessagesVar } from '../../apollo/cache';
import { clearFlashMessages } from '../../apollo/cache/flashMessages';
import { useDownloadLabelsByShipmentsLazyQuery } from '../operations/downloadLabelsByShipments.query';
import { Row, Col, Container } from '../../components/layout/Grid';
import ProgressButton from '../../components/form/ProgressButton';
import PageLayoutSelect from './PageLayoutSelect';
import PageLoading from '../../components/loading/PageLoading';
import useWindowSize from '../../hooks/useWindowSize';
import downloadUrl from '../utils/downloadUrl';
import { PageLayout } from '../../gql/graphql';
import PdfPreview from './PdfPreview';

type ShipmentsPrintModalProps = {
  open: boolean;
  onClose: () => void;
  shipmentIds: string[];
};

export default function ShipmentsPrintModal({
  open,
  onClose,
  shipmentIds,
}: ShipmentsPrintModalProps) {
  const [, windowHeight] = useWindowSize();
  const [polling, setPolling] = useState(false);
  const [pageLayout, setPageLayout] = useState<PageLayout>();
  const [downloadLabelsByShipments, { data, loading, error, startPolling, stopPolling }] =
    useDownloadLabelsByShipmentsLazyQuery({
      variables: {
        shipmentIds,
      },
    });

  useEffect(() => {
    if (open) {
      setPolling(true);
    } else {
      stopPolling();
    }
  }, [open, stopPolling]);

  // once we get the data, we initialise the filePageLayout
  useEffect(() => {
    if (data && !pageLayout) {
      setPageLayout(data.downloadLabelsByShipments.pageLayout ?? undefined);
    }
  }, [data, pageLayout]);

  useEffect(() => {
    if (polling) {
      downloadLabelsByShipments({
        variables: {
          shipmentIds,
          pageLayout, // the 1st time: this is undefined, and so will retrieve the setting from the db. 2nd time on: UI, send the changed value and update it in the db at the same time!
        },
      });
      startPolling(1000);
    }
    if (
      error ||
      data?.downloadLabelsByShipments?.status === 'FINISHED' ||
      data?.downloadLabelsByShipments?.status === 'ERROR'
    ) {
      setPolling(false);
      stopPolling();
    }
  }, [
    error,
    data,
    downloadLabelsByShipments,
    pageLayout,
    polling,
    shipmentIds,
    startPolling,
    stopPolling,
  ]);

  const flashMessages = useReactiveVar(flashMessagesVar);

  const onModalClose = () => {
    clearFlashMessages();
    onClose?.();
  };

  const renderPreview = () => {
    if (flashMessages.length) {
      return null;
    }
    if (
      !loading &&
      pageLayout &&
      // correct status to show the print preview
      data?.downloadLabelsByShipments.fileFormat &&
      data.downloadLabelsByShipments.url &&
      data?.downloadLabelsByShipments?.status === 'FINISHED'
    ) {
      const { fileFormat, url } = data.downloadLabelsByShipments;
      return (
        <>
          <Row>
            <Col md={12}>
              <PageLayoutSelect
                pageLayout={pageLayout}
                fileFormat={fileFormat}
                onPageLayoutChange={(layout) => {
                  setPageLayout(layout);
                  setPolling(true);
                }}
              />
            </Col>
            <PdfPreview shipmentLabelFileFormat={fileFormat} url={url} />
          </Row>
          <Row>
            <Col md={12} spaceBelow>
              <ProgressButton
                data-testid="download-label-button"
                variant="success"
                fullWidth
                onClick={(event) => {
                  event.preventDefault();
                  downloadUrl(url);
                }}
              >
                Download Label
              </ProgressButton>
            </Col>
          </Row>
        </>
      );
    }
    return <PageLoading height={`${windowHeight / 2}px`} color="white" />;
  };

  return (
    <Modal theme="dark" open={open} width={950} onClose={onModalClose}>
      <Container noGutter>
        <Row>
          <Col md={12}>
            <FlashMessages />
          </Col>
        </Row>
        {renderPreview()}
      </Container>
    </Modal>
  );
}
