import { useQuery, useLazyQuery } from '@apollo/client';
import { gql } from '../../gql';

export const reportsPageQuery = gql(/* GraphQL */ `
  query ReportsPageQuery {
    company {
      id
      createdAt
      accountBalance
      paymentSources {
        id
        paymentMethodType
      }
    }
  }
`);

export const useReportsPageQuery = () => useQuery(reportsPageQuery, { partialRefetch: true });
export const useReportsPageLazyQuery = () => useLazyQuery(reportsPageQuery);
