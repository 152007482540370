import Button from '../../form/Button';

type BatchDetailsPagePrintButtonProps = {
  batch: {
    cleanupComplete: boolean;
    canPrint: boolean;
    shipments: ReadonlyArray<{ canPrint: boolean; printed: boolean }>;
  };
  onClick: () => void;
  batchLoading: boolean;
};

export default function BatchDetailsPagePrintButton({
  batch,
  onClick,
  batchLoading,
}: BatchDetailsPagePrintButtonProps) {
  // true if each label is either not printable, or already printed
  const allPrintableLabelsPrinted = batch.shipments.every(
    (shipment) => !shipment.canPrint || shipment.printed,
  );

  const buttonLabel = (() => {
    if (batch.cleanupComplete) {
      return 'Labels Expired';
    }
    if (allPrintableLabelsPrinted) {
      return 'Reprint Labels';
    }
    return 'Print Labels';
  })();

  return (
    batch.canPrint && (
      <Button
        key="printbutton"
        size="xLarge"
        disabled={batch.cleanupComplete || batchLoading}
        variant={allPrintableLabelsPrinted ? 'secondary' : 'success'}
        onClick={onClick}
      >
        {buttonLabel}
      </Button>
    )
  );
}
