import { ChangeEvent, useState } from 'react';
import styled from '@emotion/styled';
import { COLOR, GREYSCALE, PirateShipColor } from '../../styles/colors';
import withOpacity from '../../utils/withOpacity';
import { TYPOGRAPHY } from '../../styles/typography';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { SPACING } from '../../styles/spacing';
import useHighlightColor from '../../hooks/useHighlightColor';

import Icon from '../Icon';

type StyledSelectProps = {
  highlightColor: PirateShipColor;
  prefill?: boolean;
};

const Styled = {
  SelectWrapper: styled.div`
    position: relative;
  `,
  Select: styled.select<StyledSelectProps>`
    width: 100%;
    height: 50px;
    font-size: ${TYPOGRAPHY.fontSize.md};
    line-height: normal;
    background: ${({ prefill }) =>
      prefill
        ? `${GREYSCALE.grey20} linear-gradient(to bottom, ${GREYSCALE.white} 24%, ${COLOR.lightBlue} 100%)`
        : `${GREYSCALE.grey20} linear-gradient(to bottom, ${GREYSCALE.white} 24%, ${GREYSCALE.grey20} 100%)`};
    color: ${GREYSCALE.black};
    border: ${BORDER_WIDTH.sm} solid;
    border-color: ${({ highlightColor, prefill }) => (prefill ? COLOR.blue : highlightColor)};
    border-radius: ${BORDER_RADIUS.sm};
    box-shadow: inset 0 0 0 ${withOpacity(GREYSCALE.black, 0.075)};
    transition: background-color 0.15s ease-in-out;
    padding-left: ${SPACING.md};
    padding-right: calc(${SPACING.md} + 14px + 18px);
    appearance: none; // this hides the default caret icon
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    :focus-within {
      outline: none;
      background: ${COLOR.lightBlue};
    }
  `,
  Icon: styled(Icon)`
    position: absolute;
    pointer-events: none;
    top: 13px;
    right: 18px;
  `,
};

export type Option<V extends string | number = string | number> = {
  value: V;
  title: string;
  disabled?: boolean;
};

export type SelectProps = Omit<JSX.IntrinsicElements['select'], 'placeholder'> & {
  options?: Option[];
  placeholder?: string;
  error?: boolean;
  prefill?: boolean;
};

export default function Select({
  options,
  placeholder,
  children,
  error = false,
  prefill,
  onBlur,
  onFocus,
  onChange,
  ...others
}: SelectProps) {
  const [focused, setFocused] = useState(false);
  const highlightColor = useHighlightColor(error, focused);

  return (
    <Styled.SelectWrapper>
      <Styled.Select
        highlightColor={highlightColor}
        prefill={prefill}
        onFocus={(event) => {
          setFocused(true);
          if (onFocus) onFocus(event);
        }}
        onBlur={(event) => {
          setFocused(false);
          if (onBlur) onBlur(event);
        }}
        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
          if (!onChange) {
            return;
          }
          onChange(event);
        }}
        {...others}
      >
        {placeholder && (
          <option value="" hidden>
            {placeholder}
          </option>
        )}
        {options &&
          options.map((option) => (
            <option
              key={option.value || option.title}
              value={option.value}
              disabled={option.disabled}
            >
              {option.title}
            </option>
          ))}
        {!options && children}
      </Styled.Select>
      <Styled.Icon icon="caret-down" size="lg" />
    </Styled.SelectWrapper>
  );
}
