import { GridQueryVariable } from '../operations/queries/connectedGridRows';
import variableNamesAreAllUnique from './gridQueryVariablesAreAllUnique';

type GridQueryVariablesWithNameAndValue = (Pick<GridQueryVariable, 'variableName' | 'value'> &
  Partial<Pick<GridQueryVariable, 'forField' | 'type'>>)[];

// create an object with variables and their values from a GridQueryVariables object
function constructGridQueryVariables(
  queryVariables?: GridQueryVariablesWithNameAndValue,
): Record<string, unknown> {
  if (!queryVariables?.length) return {};

  // if two gridQueryVariables have the same variableName, one would be overwritten in the variables object when querying, so catch that here
  if (!variableNamesAreAllUnique(queryVariables)) {
    throw new Error('not all variable names are unique');
  }
  return Object.fromEntries(queryVariables.map(({ variableName, value }) => [variableName, value]));
}

export default constructGridQueryVariables;
