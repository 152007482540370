import { RateGroup } from '../components/form/RateSummarySelect';
import { RateGroupKey } from '../gql/graphql';

type RateSummary = RateGroup['rateSummaries'][0];
type RateSummaryWithGroupKey = RateSummary & { groupKey: RateGroupKey };

export default function getSelectedRateSummaries(
  selectedSummaryIds: Record<string, string>,
  rateGroups: ReadonlyArray<RateGroup>,
): RateSummaryWithGroupKey[] {
  const isSummary = (item: RateSummaryWithGroupKey | undefined): item is RateSummaryWithGroupKey =>
    !!item;

  return rateGroups
    .map(({ rateSummaries, groupKey }, index) => {
      const selectedSummary = rateSummaries.find(
        (summary) => summary.uniqueId === Object.values(selectedSummaryIds)[index],
      );

      if (selectedSummary) {
        return { ...selectedSummary, groupKey } as RateSummaryWithGroupKey;
      }

      return undefined;
    })
    .filter(isSummary);
}
