import { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SPACING } from '../../styles/spacing';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { useLazyIntercomArticleQuery } from '../../operations/queries/intercomArticle';
import Modal, { ModalRef } from './Modal';
import Alert from '../Alert';
import linkStyles from '../linkStyles';
import { TYPOGRAPHY } from '../../styles/typography';
import { GREYSCALE } from '../../styles/colors';
import PageLoading from '../loading/PageLoading';
import convertBbcode from '../../utils/convertBbcode';

const tableHeadings = css`
  font-weight: ${TYPOGRAPHY.fontWeight.medium};
  padding: ${SPACING.lg} ${SPACING.md};
  background-color: ${GREYSCALE.grey20};
  p {
    color: ${GREYSCALE.grey60};
  }
`;

const Styled = {
  IntercomArticle: styled.div`
    .article__meta header {
      font-size: ${TYPOGRAPHY.heading.xxl.fontSize};
      font-weight: ${TYPOGRAPHY.heading.xxl.fontWeight};
      line-height: ${TYPOGRAPHY.heading.xxl.lineHeight};
      margin: ${SPACING.none} ${SPACING.xl} ${SPACING.xl} ${SPACING.none};
    }

    .article__desc {
      margin-bottom: 1em;
      color: ${GREYSCALE.grey50};
    }

    .flex {
      display: flex;
    }

    .flex-col {
      flex-direction: column;
    }

    .avatar {
      font-size: ${TYPOGRAPHY.fontSize.sm};
      margin: 16px 0;
    }
    .avatar .avatar__photo {
      margin-top: -6px;
      margin-right: 10px;
      float: left;
    }
    .avatar .avatar__photo img {
      width: 32px;
      height: 32px;
      background-color: inherit; /* reverts .bg-primary style */
    }
    .avatar .avatar__info {
      line-height: 1.4;
      color: ${GREYSCALE.grey50};
    }

    .intercom-h2b-video > iframe {
      width: 100%;
      height: 480px;
    }

    header {
      font-size: ${TYPOGRAPHY.fontSize.xxl};
      font-weight: ${TYPOGRAPHY.fontWeight.bold};
    }

    img {
      max-width: 100%;
      height: auto;
    }

    p {
      margin: 1em 0;
      font-size: ${TYPOGRAPHY.fontSize.md};
    }

    a {
      ${linkStyles}
    }

    .intercom-interblocks-table-container {
      border-style: solid;
      border-color: ${GREYSCALE.grey30};
      border-width: ${BORDER_WIDTH.sm};
      border-radius: ${BORDER_RADIUS.sm};

      table {
        width: 100%;
        border-collapse: collapse;
        font-size: ${TYPOGRAPHY.fontSize.sm};
      }

      td {
        padding: ${SPACING.md};
        border-color: ${GREYSCALE.grey30};
        border-style: solid;
        border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs}${BORDER_WIDTH.none};
        vertical-align: middle;

        :last-of-type {
          border-right-width: ${BORDER_WIDTH.none};
        }
      }
      p {
        margin: ${SPACING.none};
      }
      ul {
        margin-block-end: ${SPACING.none};
      }

      tr:last-of-type td {
        border-bottom-width: ${BORDER_WIDTH.none};
      }

      td:nth-last-of-type(2):first-of-type {
        ${tableHeadings}
        border-right-width: ${BORDER_WIDTH.sm};
      }
      @supports selector(:has(b)) {
        td:has(b) {
          ${tableHeadings}
          border-bottom-width: ${BORDER_WIDTH.sm};
        }
      }
    }
  `,
};

export type IntercomArticleModalProps = {
  url: string;
  open: boolean;
  onClose?: () => void;
  onLocationChange?: (url: string) => void;
};

export default function IntercomArticleModal({
  url,
  open,
  onClose,
  onLocationChange,
}: IntercomArticleModalProps) {
  const modalRef = useRef<ModalRef>(null);
  const [articleNode, setArticleNode] = useState<HTMLDivElement | null>(null);
  const [currentUrl, setCurrentUrl] = useState(url);
  const [fetchArticle, { data, error, loading }] = useLazyIntercomArticleQuery({
    errorPolicy: 'all',
    onCompleted: () => modalRef.current?.scrollToTop(),
  });

  useEffect(() => setCurrentUrl(url), [url]);

  useEffect(() => {
    const linkClickHandler = (event: MouseEvent) => {
      const targetLink = (event.target as HTMLElement | null)?.closest('a') || null;

      if (
        targetLink &&
        targetLink.target !== '_blank' &&
        targetLink.hostname === new URL(currentUrl).hostname
      ) {
        event.preventDefault();
        setCurrentUrl(targetLink.href);

        if (onLocationChange) {
          onLocationChange(targetLink.href);
        }
      }
    };

    articleNode?.addEventListener('click', linkClickHandler);

    return () => {
      articleNode?.removeEventListener('click', linkClickHandler);
    };
  }, [articleNode, currentUrl, onLocationChange]);

  useEffect(() => {
    if (open && currentUrl !== '') {
      fetchArticle({
        variables: { url: new URL(currentUrl).pathname },
      });
    }
  }, [open, currentUrl, fetchArticle]);

  return (
    <Modal
      ref={modalRef}
      width={900}
      open={open}
      onClose={() => {
        if (onClose) onClose();

        // Reset to initial url
        setCurrentUrl(url);
      }}
    >
      <div style={{ minHeight: 330 }}>
        {loading && <PageLoading height="330px" />}
        {!loading && error && <Alert variant="danger">{convertBbcode(error.message)}</Alert>}
        {!loading && data?.intercomArticle && (
          <Styled.IntercomArticle
            ref={setArticleNode}
            dangerouslySetInnerHTML={{ __html: data.intercomArticle }}
          />
        )}
      </div>
    </Modal>
  );
}
