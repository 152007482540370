// given a value and an array, toggle the value in/out of the array
// i.e. append the value to the end of the array if it does not exist, or
// remove the value from the array if it does
// returns a copy of the array
export default function toggleInArray<T>(value: T, array: T[]): T[] {
  if (!array || array.length === 0) {
    return [value];
  }
  if (array.includes(value)) {
    return array.filter((item) => item !== value);
  }
  return [...array, value];
}
