import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const refundShipmentMutation = gql(/* GraphQL */ `
  mutation RefundShipmentMutation($shipmentId: ID!) {
    refundShipment(shipmentId: $shipmentId) {
      id
      canPrint
      canRefund
      canInstantRefundBatch
      shipmentStatusSummary {
        refundableCount
      }
      shipments {
        id
        status
        printed
        canPrint
        canInstantRefundShipment
        additionalRefundNotice
        stageDots {
          title
          status
          message
        }
      }
    }
  }
`);

export const useRefundShipmentMutation = () => useMutation(refundShipmentMutation);
