type BlacklistReason = 'dangerous' | 'generic';

export const blacklistErrorMessage: Record<BlacklistReason, string> = {
  dangerous:
    'This item is prohibited from being shipped internationally. [link=https://support.pirateship.com/en/articles/4882871-international-shipping-guide]Review the rules & regulations[/link]',
  generic:
    'This is too generic of a description and would be returned undelivered. Customs requires an exact list of what’s in your shipment. [link=https://support.pirateship.com/en/articles/4882871-international-shipping-guide]Learn more[/link]',
};

export const descriptionBlacklist = new Map<string, BlacklistReason>([
  ['accessories', 'generic'],
  ['acid', 'dangerous'],
  ['adapter', 'generic'],
  ['adhesives', 'dangerous'],
  ['aerosols', 'dangerous'],
  ['agricultural products', 'generic'],
  ['aid consignment', 'generic'],
  ['alcohol', 'dangerous'],
  ['all kind of cargo', 'generic'],
  ['all kind of goods', 'generic'],
  ['ammo', 'dangerous'],
  ['ammunition', 'dangerous'],
  ['animals', 'generic'],
  ['apparel', 'generic'],
  ['appliances', 'generic'],
  ['art', 'generic'],
  ['articles', 'generic'],
  ['artwork', 'generic'],
  ['as ordered', 'generic'],
  ['as per attached', 'generic'],
  ['attached', 'generic'],
  ['auto parts', 'generic'],
  ['auto', 'generic'],
  ['automobiles', 'generic'],
  ['awb/ hawb', 'generic'],
  ['bacteria', 'dangerous'],
  ['baggage', 'generic'],
  ['bags', 'generic'],
  ['battery', 'generic'],
  ['bazaar goods', 'generic'],
  ['beer', 'dangerous'],
  ['bengal lights', 'dangerous'],
  ['biohazard', 'dangerous'],
  ['biological substances', 'generic'],
  ['birthday gifts', 'generic'],
  ['bits', 'generic'],
  ['blast', 'dangerous'],
  ['blaster', 'dangerous'],
  ['blasting', 'dangerous'],
  ['bleach', 'dangerous'],
  ['bleaching agents', 'dangerous'],
  ['boards', 'generic'],
  ['book', 'generic'],
  ['books', 'generic'],
  ['box', 'generic'],
  ['butane', 'dangerous'],
  ['cables', 'generic'],
  ['camping equipment', 'generic'],
  ['camping gas', 'dangerous'],
  ['caps', 'generic'],
  ['carbon dioxide', 'dangerous'],
  ['card', 'generic'],
  ['cars', 'generic'],
  ['cartons', 'generic'],
  ['case', 'generic'],
  ['caustic', 'dangerous'],
  ['cbd', 'dangerous'],
  ['cell phone', 'generic'],
  ['cellphone', 'generic'],
  ['charcoal', 'dangerous'],
  ['charity', 'generic'],
  ['chemicals', 'dangerous'],
  ['cigarette', 'dangerous'],
  ['cigarettes', 'dangerous'],
  ['cleaning product', 'generic'],
  ['clothes', 'generic'],
  ['clothing', 'generic'],
  ['cnsl', 'generic'],
  ['coal', 'dangerous'],
  ['collected items', 'generic'],
  ['collectibles', 'generic'],
  ['combustible', 'dangerous'],
  ['commodity', 'generic'],
  ['company names', 'generic'],
  ['component', 'generic'],
  ['compressed air', 'dangerous'],
  ['compressed', 'dangerous'],
  ['con', 'generic'],
  ['consol', 'generic'],
  ['consolidated cargo', 'generic'],
  ['consolidated goods', 'generic'],
  ['consolidated', 'generic'],
  ['consolidation', 'generic'],
  ['consumer', 'generic'],
  ['contagious', 'dangerous'],
  ['corrosive', 'dangerous'],
  ['cosmetic', 'generic'],
  ['cosmetics', 'generic'],
  ['courier bags', 'generic'],
  ['courier goods', 'generic'],
  ['courier material', 'generic'],
  ['courier', 'generic'],
  ['cuttings of rosemary in pots', 'generic'],
  ['cylinder', 'dangerous'],
  ['dangerous goods', 'dangerous'],
  ['dangerous', 'dangerous'],
  ['decorations', 'generic'],
  ['decorative crafts', 'generic'],
  ['decorative items', 'generic'],
  ['docs', 'generic'],
  ['documentations', 'generic'],
  ['documents', 'generic'],
  ['dox', 'generic'],
  ['drugs', 'generic'],
  ['dry ice', 'dangerous'],
  ['e-commerce', 'generic'],
  ['electronic equipment', 'generic'],
  ['electronic goods', 'generic'],
  ['electronic items', 'generic'],
  ['electronics', 'generic'],
  ['emergency flare', 'dangerous'],
  ['emergency signals', 'dangerous'],
  ['engines', 'dangerous'],
  ['epoxy', 'dangerous'],
  ['equipment', 'generic'],
  ['exhibition goods', 'generic'],
  ['explosives', 'dangerous'],
  ['express', 'generic'],
  ['fire extinguishers', 'dangerous'],
  ['firearm', 'dangerous'],
  ['fireworks', 'dangerous'],
  ['fish', 'generic'],
  ['flammable', 'dangerous'],
  ['food', 'generic'],
  ['foodstuff', 'generic'],
  ['footwear', 'generic'],
  ['freight all kinds', 'generic'],
  ['fresh fruit', 'generic'],
  ['fresh herbs', 'generic'],
  ['fresh plants', 'generic'],
  ['fresh vegetable', 'generic'],
  ['from upu postal', 'generic'],
  ['fruit products', 'generic'],
  ['fruit pulp', 'generic'],
  ['fruit', 'generic'],
  ['fuel paste', 'dangerous'],
  ['fuel', 'dangerous'],
  ['garments', 'generic'],
  ['gas', 'dangerous'],
  ['gasoline', 'dangerous'],
  ['general cargo', 'generic'],
  ['general goods', 'generic'],
  ['general merchandise', 'generic'],
  ['gift box', 'generic'],
  ['gift', 'generic'],
  ['gifts', 'generic'],
  ['gizmos', 'generic'],
  ['glass', 'generic'],
  ['glue', 'generic'],
  ['goods', 'generic'],
  ['granulate', 'generic'],
  ['gunpowder', 'dangerous'],
  ['guns', 'dangerous'],
  ['hair accessories', 'generic'],
  ['handcraft', 'generic'],
  ['handling codes', 'generic'],
  ['hardware for gift', 'generic'],
  ['health item', 'generic'],
  ['health product', 'generic'],
  ['herbs', 'generic'],
  ['home goods', 'generic'],
  ['household goods', 'generic'],
  ['hydrochloric acid', 'dangerous'],
  ['i.t. goods', 'generic'],
  ['industrial products', 'generic'],
  ['instruments', 'generic'],
  ['invoice', 'generic'],
  ['iron', 'generic'],
  ['iso tanks', 'generic'],
  ['item', 'generic'],
  ['items', 'generic'],
  ['jewellery', 'generic'],
  ['jewelry', 'generic'],
  ['jewels', 'generic'],
  ['joint shipping', 'generic'],
  ['label', 'generic'],
  ['leather articles', 'generic'],
  ['lighter fuel', 'dangerous'],
  ['lighter', 'dangerous'],
  ['likewise', 'generic'],
  ['line', 'generic'],
  ['liquids', 'generic'],
  ['lithium batteries', 'dangerous'],
  ['lithium', 'dangerous'],
  ['live animals', 'generic'],
  ['live plants', 'generic'],
  ['live', 'generic'],
  ['machine guns', 'dangerous'],
  ['machine parts', 'generic'],
  ['machinery', 'generic'],
  ['machines', 'generic'],
  ['magazine', 'generic'],
  ['mail', 'generic'],
  ['makeup', 'generic'],
  ['manicure', 'dangerous'],
  ['matches', 'dangerous'],
  ['materials', 'generic'],
  ['mdf', 'generic'],
  ['meat', 'generic'],
  ['medical equipment', 'generic'],
  ['medicines', 'generic'],
  ['merchandise', 'generic'],
  ['mercury', 'dangerous'],
  ['metal', 'generic'],
  ['minwax waterbased', 'dangerous'],
  ['misc', 'generic'],
  ['miscellaneous products', 'generic'],
  ['mix', 'generic'],
  ['mixed', 'generic'],
  ['model', 'generic'],
  ['mount', 'generic'],
  ['mucolytic', 'generic'],
  ['nail polish remover', 'dangerous'],
  ['nail polish', 'dangerous'],
  ['non-woven', 'generic'],
  ['none', 'generic'],
  ['odd parts', 'generic'],
  ['oil paints', 'dangerous'],
  ['oil', 'dangerous'],
  ['ore', 'generic'],
  ['others', 'generic'],
  ['oxidizing', 'dangerous'],
  ['oxygen', 'dangerous'],
  ['package numbers', 'generic'],
  ['pad', 'generic'],
  ['paints', 'dangerous'],
  ['pallets', 'generic'],
  ['parcel', 'generic'],
  ['parts', 'generic'],
  ['party accessories', 'generic'],
  ['pces', 'generic'],
  ['perfume', 'dangerous'],
  ['perishable', 'generic'],
  ['perishables', 'generic'],
  ['peroxides', 'dangerous'],
  ['personal address', 'generic'],
  ['personal effects', 'generic'],
  ['personal item', 'generic'],
  ['personal items', 'generic'],
  ['pesticides', 'dangerous'],
  ['pharmaceutical', 'generic'],
  ['pieces', 'generic'],
  ['pipes', 'generic'],
  ['pistols', 'dangerous'],
  ['plants', 'generic'],
  ['plastic goods', 'generic'],
  ['plates', 'generic'],
  ['plt', 'generic'],
  ['poisonous', 'dangerous'],
  ['polyurethane', 'generic'],
  ['pots', 'generic'],
  ['powder', 'generic'],
  ['power bank', 'dangerous'],
  ['pre-addressed parcel', 'generic'],
  ['pressurized', 'dangerous'],
  ['printed material', 'generic'],
  ['private things', 'generic'],
  ['produce', 'generic'],
  ['products', 'generic'],
  ['propellant', 'generic'],
  ['pvc items', 'generic'],
  ['quilts', 'generic'],
  ['radioactive', 'dangerous'],
  ['raw material', 'generic'],
  ['resin', 'dangerous'],
  ['retured item', 'generic'],
  ['returing item', 'generic'],
  ['return', 'generic'],
  ['returned goods', 'generic'],
  ['returns', 'generic'],
  ['rifle', 'dangerous'],
  ['road flare', 'dangerous'],
  ['rocket', 'dangerous'],
  ['rod', 'generic'],
  ['rubber articles', 'generic'],
  ['rum', 'dangerous'],
  ['said to contain', 'generic'],
  ['sample', 'generic'],
  ['samples', 'generic'],
  ['sanitary goods', 'generic'],
  ['scrap', 'generic'],
  ['see attached invoice', 'generic'],
  ['see invoice', 'generic'],
  ['several goods', 'generic'],
  ['several', 'generic'],
  ['shoes', 'generic'],
  ['skincare', 'generic'],
  ['snickers', 'generic'],
  ['solid carbon dioxide', 'dangerous'],
  ['souvenirs', 'generic'],
  ['spare parts', 'generic'],
  ['spare', 'generic'],
  ['sporting goods', 'generic'],
  ['spray cans', 'dangerous'],
  ['steel', 'generic'],
  ['stuff', 'generic'],
  ['subscription', 'generic'],
  ['substance', 'generic'],
  ['tablet accessories', 'generic'],
  ['tablets', 'generic'],
  ['technology', 'generic'],
  ['test', 'generic'],
  ['textile goods', 'generic'],
  ['textiles', 'generic'],
  ['thc', 'dangerous'],
  ['things', 'generic'],
  ['tires', 'generic'],
  ['tool', 'generic'],
  ['tools', 'generic'],
  ['toxic', 'dangerous'],
  ['toy', 'generic'],
  ['toys', 'generic'],
  ['trade', 'generic'],
  ['trading card', 'generic'],
  ['tubing', 'generic'],
  ['uniform', 'generic'],
  ['vaccine', 'generic'],
  ['vape', 'dangerous'],
  ['various goods', 'generic'],
  ['various products', 'generic'],
  ['various', 'generic'],
  ['vegetables', 'generic'],
  ['vehicles', 'generic'],
  ['viruses', 'dangerous'],
  ['vodka', 'dangerous'],
  ['waste', 'generic'],
  ['wearing apparel', 'generic'],
  ['wet-cell batteries', 'dangerous'],
  ['wet-cell', 'dangerous'],
  ['whiskey', 'dangerous'],
  ['white goods', 'generic'],
  ['wine', 'dangerous'],
  ['wires', 'generic'],
  ['wooden articles', 'generic'],
]);

// HS Codes starting with these digits are blacklisted
export const hsCodeBlacklist = new Map<string, BlacklistReason>([
  ['2106901', 'dangerous'],
  ['2204', 'dangerous'],
  ['2205', 'dangerous'],
  ['220600', 'dangerous'],
  ['2207', 'dangerous'],
  ['2208', 'dangerous'],
  ['251990', 'dangerous'],
  ['262021', 'dangerous'],
  ['262060', 'dangerous'],
  ['271012', 'dangerous'],
  ['271112', 'dangerous'],
  ['271113', 'dangerous'],
  ['28044000', 'dangerous'],
  ['280540', 'dangerous'],
  ['281121', 'dangerous'],
  ['281530', 'dangerous'],
  ['282520', 'dangerous'],
  ['283110', 'dangerous'],
  ['290389', 'dangerous'],
  ['290399', 'dangerous'],
  ['2905', 'dangerous'],
  ['2907', 'dangerous'],
  ['2908', 'dangerous'],
  ['2909', 'dangerous'],
  ['291411', 'dangerous'],
  ['291440', 'dangerous'],
  ['29153947', 'dangerous'],
  ['292090', 'dangerous'],
  ['293020', 'dangerous'],
  ['293090', 'dangerous'],
  ['293190', 'dangerous'],
  ['293220', 'dangerous'],
  ['293319', 'dangerous'],
  ['293349', 'dangerous'],
  ['293420', 'dangerous'],
  ['330210', 'dangerous'],
  ['330300', 'dangerous'],
  ['330430', 'dangerous'],
  ['350520', 'dangerous'],
  ['35061050', 'dangerous'],
  ['360100', 'dangerous'],
  ['360200', 'dangerous'],
  ['360200', 'dangerous'],
  ['3604', 'dangerous'],
  ['360500', 'dangerous'],
  ['360610', 'dangerous'],
  ['382100', 'dangerous'],
  ['391110', 'dangerous'],
  ['731100', 'dangerous'],
  ['850650', 'dangerous'],
  ['850710', 'dangerous'],
  ['850760', 'dangerous'],
  ['853932', 'dangerous'],
  ['930190', 'dangerous'],
  ['930200', 'dangerous'],
  ['9306', 'dangerous'],
  ['990100', 'dangerous'],
  ['990211', 'dangerous'],
]);
