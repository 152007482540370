import { Table } from '@tanstack/react-table';
import { CSSProperties } from 'react';
import { DEFAULT_COLUMN_WIDTH } from '../DataGrid';

// this animates the header positions while dragging and once on complete
function adjustHeaderStyle<TData>(
  index: number, // index from all visible columns
  isBeingDragged: boolean, // if true, _this_ header is being dragged
  draggableStyles: CSSProperties | undefined,
  rowSelectEnabled: boolean,
  grabbedHeaderIndex: number,
  draggingOverIndex: number,
  table: Table<TData>,
  grabbedHeaderIndexId?: string,
): CSSProperties | undefined {
  // if no dragging is occurring, don't move OR if rowSelect is enabled don't move the leftmost column
  if ((rowSelectEnabled && index === 0) || grabbedHeaderIndex === -1) {
    return {
      ...draggableStyles,
      transform: 'none',
    };
  }
  const wasToRightOfGrabbedOnPickup = index > grabbedHeaderIndex;
  const isToRightOfGrabbed =
    grabbedHeaderIndex <= draggingOverIndex
      ? index > draggingOverIndex
      : index >= draggingOverIndex;

  if (!isBeingDragged && grabbedHeaderIndexId) {
    const grabbedHeaderWidth =
      table.getState().columnSizing[grabbedHeaderIndexId] || DEFAULT_COLUMN_WIDTH;

    let transform = 'translateX(0px)';

    // this header is now left of the dragged header (and it wasn't on pickup), shift it left!
    if (!isToRightOfGrabbed && wasToRightOfGrabbedOnPickup) {
      transform = `translateX(-${grabbedHeaderWidth}px)`;
    }

    // this header is now right of the dragged header (and it wasn't on pickup), shift it right!
    if (isToRightOfGrabbed && !wasToRightOfGrabbedOnPickup) {
      transform = `translateX(${grabbedHeaderWidth}px)`;
    }

    // this header should stay in its 'neutral' position (i.e. where it was when the drag started)
    return {
      ...draggableStyles,
      transform,
    };
  }

  // if the header is the one being dragged
  return {
    ...draggableStyles,
    display: 'block',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
}

export default adjustHeaderStyle;
