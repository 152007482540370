import { useQuery, QueryHookOptions, useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import { gql } from '../../gql';
import {
  StateDistributionChartQuery,
  StateDistributionChartQueryVariables,
} from '../../gql/graphql';

export const stateDistributionChartQuery = gql(/* GraphQL */ `
  query StateDistributionChartQuery($datetimeRange: DatetimeRangeInput!) {
    stateDistributionChart(datetimeRange: $datetimeRange) {
      totalCount
      stateDistributions {
        key
        count
        name
        percent
        price
      }
    }
  }
`);

export const useStateDistributionChartQuery = (
  options: QueryHookOptions<StateDistributionChartQuery, StateDistributionChartQueryVariables>,
) => useQuery(stateDistributionChartQuery, options);

export const useStateDistributionChartLazyQuery = (
  options: LazyQueryHookOptions<StateDistributionChartQuery, StateDistributionChartQueryVariables>,
) => useLazyQuery(stateDistributionChartQuery, options);
