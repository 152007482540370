import { useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useParams } from 'react-router';
import { Row, Col } from '../../../layout/Grid';
import FormControl from '../../../form/FormControl';
import TextField from '../../../form/TextField';
import InputGroup from '../../../form/InputGroup';
import { setFlashMessage } from '../../../../apollo/cache/flashMessages';
import ProgressButton from '../../../form/ProgressButton';
import environment from '../../../../utils/environment';
import useNavigateOrHref from '../../../../hooks/useNavigateOrHref';
import useCloudflareChallengeHandlingXhr from '../../../../hooks/useCloudflareChallengeHandlingXhr';

type ChangeValues = {
  password_new: string;
  password_repeat: string;
};

type ResponseValues = {
  success: boolean;
  error?: string;
};

const initialValues: ChangeValues = {
  password_new: '',
  password_repeat: '',
};

const initChangeValidationSchema = () =>
  yup.object<ChangeValues>({
    password_new: yup
      .string()
      .required()
      .min(12, 'This value is too short. It should have 12 characters or more.')
      .max(200, 'This value is too long. It should have 200 characters or less.'),
    password_repeat: yup
      .string()
      .required()
      .test('passwords-match', 'Passwords do not match', function passwordsMatch(value) {
        return this.parent.password_new === value;
      }),
  });

function useHash() {
  const { hash } = useParams<'hash'>();

  if (environment.isBridge()) {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('hash')) {
      return searchParams.get('hash');
    }

    const [hashValue] = window.location.pathname.split('/').reverse();

    return hashValue;
  }

  return hash;
}

type FormLabelProps = {
  password?: string;
  confirmPassword?: string;
  submit?: string;
};

const defaultLabels: FormLabelProps = {
  password: 'Password',
  confirmPassword: 'Confirm Password',
  submit: 'Change Password',
};

export default function ChangePasswordForm({
  onSuccess,
  labels = {},
}: {
  onSuccess: () => void;
  labels?: FormLabelProps;
}) {
  const { handler: changePassword, loading } = useCloudflareChallengeHandlingXhr<ResponseValues>();

  const hash = useHash();
  const navigate = useNavigateOrHref();
  const changeValidationSchema = useMemo(initChangeValidationSchema, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={changeValidationSchema}
      onSubmit={async ({ password_new }) => {
        try {
          const response = await changePassword({
            method: 'POST',
            url: '/password/update',
            attrs: { hash, password: password_new },
          });

          if (response.error) {
            throw new Error(response.error);
          }

          onSuccess();

          // Redirect to login page
          navigate('/');
        } catch (error) {
          if (error instanceof Error) {
            setFlashMessage(error.message, 'danger');
          }
        }
      }}
    >
      <Form>
        <Row>
          <Col spaceBelow md={12}>
            <InputGroup prefixIcon="asterisk">
              <FormControl
                data-test="password"
                name="password_new"
                type="password"
                as={TextField}
                label={labels.password ?? defaultLabels.password}
              />
            </InputGroup>
          </Col>
          <Col spaceBelow md={12}>
            <InputGroup prefixIcon="asterisk">
              <FormControl
                data-test="passwordConfirm"
                name="password_repeat"
                type="password"
                as={TextField}
                label={labels.confirmPassword ?? defaultLabels.confirmPassword}
              />
            </InputGroup>
          </Col>
          <Col md={12}>
            <div id="cf-turnstile-widget" />
            <ProgressButton
              data-test="progressButton"
              type="submit"
              variant="danger"
              size="xLarge"
              fullWidth
              disabled={loading}
              progress={loading}
            >
              {labels.submit ?? defaultLabels.submit}
            </ProgressButton>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
}
