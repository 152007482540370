import { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import Shimmer from '../../../loading/Shimmer';
import { useCompanySavingsLazyQuery } from '../../../../operations/queries/companySavings';
import { DatetimeRangeInput } from '../../../../gql/graphql';
import { ReportTileStyles, ReportsTitle } from './ReportsTile';

type ReportsCompanySavingsProps = {
  dateFormatter: () => DatetimeRangeInput;
};

export default function ReportsCompanySavings({ dateFormatter }: ReportsCompanySavingsProps) {
  const [loadCompanySavings, { data: companySavingsData }] = useCompanySavingsLazyQuery({
    variables: {
      datetimeRange: dateFormatter(),
    },
  });

  useEffect(() => {
    loadCompanySavings({
      variables: {
        datetimeRange: dateFormatter(),
      },
    });
  }, [dateFormatter, loadCompanySavings]);

  return (
    <ReportTileStyles.ReportTileWrapper fullHeight={false}>
      <ReportsTitle data-testid="reports-company-savings-headline">
        Pirate Ship saved you
      </ReportsTitle>
      {!companySavingsData ? (
        <Shimmer.Rectangle height={188} />
      ) : (
        <ReportTileStyles.ReportSubtitle>
          {companySavingsData.companySavings && (
            <NumberFormat
              value={companySavingsData.companySavings}
              displayType="text"
              prefix="$"
              thousandsGroupStyle="thousand"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              data-testid="reports-company-savings-value"
            />
          )}
        </ReportTileStyles.ReportSubtitle>
      )}
      <ReportTileStyles.ReportTileFooter>
        <ReportTileStyles.Caption data-testid="reports-company-savings-subline">
          Total Savings vs Retail
        </ReportTileStyles.Caption>
      </ReportTileStyles.ReportTileFooter>
    </ReportTileStyles.ReportTileWrapper>
  );
}
