import styled from '@emotion/styled';
import { Formik, Form } from 'formik';
import { useMemo } from 'react';
import add from 'date-fns/add';
import * as yup from 'yup';
import { useQuery } from '@apollo/client';
import { mailTemplatesQuery } from '@ps/trackingEmails';
import { useUpdateEmailNotificationMutation } from '../../operations/mutations/updateEmailNotification';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import DatePickerField from '../form/dateTimePickers/DatePickerField';
import FormControl from '../form/FormControl';
import Select, { Option } from '../form/Select';
import { Row, Col } from '../layout/Grid';
import Modal, { ModalProps } from './Modal';
import { DATE_FORMAT } from '../../constants';
import ProgressButton from '../form/ProgressButton';
import roundToQuarterHour from '../../utils/roundToQuarterHour';
import useDateInUserTimezone from '../../hooks/useDateInUserTimezone';
import delayStringToDuration from '../../utils/delayStringToDuration';
import { useCancelEmailNotificationMutation } from '../../operations/mutations/cancelEmailNotification';
import newDate from '../../utils/newDate';

type ScheduleEmailNotificationFormValues = {
  date: Date;
  templateId: string;
};

type ScheduleEmailNotificationModalProps = {
  batchId: string;
  selectedTemplateId?: string;
  notifyRecipientsDate?: string | null;
} & Omit<ModalProps, 'children'>;

const Styled = {
  Label: styled.div`
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
    margin-bottom: ${SPACING.md};
  `,
  Buttons: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${SPACING.md};
  `,
};

const initValidationSchema = () =>
  yup.object<ScheduleEmailNotificationFormValues>().shape({
    date: yup.date().defined(),
    templateId: yup.string().defined(),
  });

function ScheduleEmailNotificationModal({
  batchId,
  selectedTemplateId,
  onClose,
  open,
  notifyRecipientsDate,
}: ScheduleEmailNotificationModalProps) {
  const [updateEmailNotification, { loading }] = useUpdateEmailNotificationMutation();
  const [cancelEmailNotification] = useCancelEmailNotificationMutation();
  const { loading: mailTemplatesLoading, data: mailTemplatesData } = useQuery(mailTemplatesQuery);
  const defaultTrackingEmailsDelay =
    mailTemplatesData?.company.settings.defaultTrackingEmailsDelay ?? '';

  const validationSchema = useMemo(initValidationSchema, []);
  const { formatDate, createDate } = useDateInUserTimezone();

  const dateLocalRounded = useMemo(() => {
    const dateLocal = createDate(notifyRecipientsDate ?? 'now', 'localFromUTC');
    // only add user's default tracking delay if no date has been set
    const delayDuration = notifyRecipientsDate
      ? { seconds: 0 }
      : delayStringToDuration(defaultTrackingEmailsDelay);
    return roundToQuarterHour(add(dateLocal, delayDuration));
  }, [createDate, notifyRecipientsDate, defaultTrackingEmailsDelay]);

  if (!mailTemplatesData || mailTemplatesLoading) return null;

  const mailTemplateOptions: Option<string>[] = mailTemplatesData.company.mailTemplates.map(
    (mailTemplate) => ({
      value: mailTemplate.id,
      title: mailTemplate.name,
    }),
  );

  const onSubmit = async (formValues: ScheduleEmailNotificationFormValues) => {
    await updateEmailNotification({
      variables: {
        batchId,
        mailTemplateId: formValues.templateId,
        scheduledDateTime: formatDate('local', formValues.date, DATE_FORMAT.dateTime24),
      },
    });
    onClose?.();
  };

  const initialTemplateId = selectedTemplateId || mailTemplateOptions[0]?.value;

  const onCancel = async () => {
    await cancelEmailNotification({
      variables: {
        batchId,
      },
    });
    onClose?.();
  };

  return (
    <Modal
      onClose={() => {
        onClose?.();
      }}
      open={open}
      title="Set Email Notification Date"
      width={900}
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{ date: dateLocalRounded, templateId: initialTemplateId }}
        onSubmit={onSubmit}
      >
        <Form>
          <Row>
            <Col spaceBelow>
              <Styled.Label>Schedule Tracking Email</Styled.Label>
              <FormControl
                name="date"
                minDate={newDate('now')}
                as={DatePickerField}
                timePicker
                localOrUTC="local"
              />
            </Col>
          </Row>
          <Row spaceBelow>
            <Col spaceBelow>
              <Styled.Label>Tracking Email Template</Styled.Label>
              <FormControl as={Select} name="templateId" options={mailTemplateOptions} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Styled.Buttons>
                {notifyRecipientsDate && (
                  <ProgressButton
                    onClick={onCancel}
                    disabled={loading}
                    progress={loading}
                    type="button"
                    size="medium"
                    variant="danger"
                  >
                    Cancel Tracking Emails
                  </ProgressButton>
                )}
                <ProgressButton
                  disabled={loading}
                  progress={loading}
                  type="submit"
                  size="medium"
                  variant="primary"
                >
                  Schedule
                </ProgressButton>
              </Styled.Buttons>
            </Col>
          </Row>
        </Form>
      </Formik>
    </Modal>
  );
}

export default ScheduleEmailNotificationModal;
