import Chart from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import HighchartsA11y from 'highcharts/modules/accessibility';
import { GREYSCALE } from '../../styles/colors';
import { standardChartOptions } from '../../styles/charts';
import { ChartProps } from './types';

HighchartsA11y(Highcharts);

export type LineChartProps = Omit<ChartProps, 'colors'> & {
  currentFilter?: string;
};

export default function LineChart({ currentFilter, data, labels, customOptions }: LineChartProps) {
  const options: Highcharts.Options = Highcharts.merge(
    {
      accessibility: {
        screenReaderSection: {
          beforeChartFormat:
            '<div>{typeDescription}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>',
        },
      },
      chart: {
        height: 220,
      },
      xAxis: {
        categories: [...labels],
        accessibility: {
          description: `dates, ranging over ${currentFilter}`,
        },
      },
      series: [
        {
          type: 'spline',
          data: [...data], // cannot accept readonly arrays
          showInLegend: false,
          lineWidth: 4,
          color: GREYSCALE.black,
          dashStyle: 'ShortDash',
          linecap: 'square',
          marker: {
            radius: 7,
            fillColor: GREYSCALE.black,
            lineWidth: 3,
            lineColor: GREYSCALE.white,
          },
          softThreshold: false,
        },
      ],
      tooltip: {
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          return `${this.x}: ${this.y}`;
        },
      },
    },
    standardChartOptions,
    customOptions,
  );
  return <Chart highcharts={Highcharts} options={options} />;
}
