import styled from '@emotion/styled';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import add from 'date-fns/add';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import { ReactNode, Ref, RefObject, forwardRef, useEffect, useState } from 'react';
import { Calendar as RdrCalendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import { LocalOrUTC, Weekday } from '../../../constants';
import useAbsolutelyCenteredPositioning from '../../../hooks/useAbsolutelyCenteredPositioning';
import useForwardedRef from '../../../hooks/useForwardedRef';
import useIsMobile from '../../../hooks/useIsMobile';
import useOffsetDates from '../../../hooks/useOffsetDates';
import useToTogglePositioning from '../../../hooks/useToTogglePositioning';
import { GREYSCALE } from '../../../styles/colors';
import { isSameDate, isSameMonth } from '../../../utils/compareDates';
import newDate from '../../../utils/newDate';
import roundToQuarterHour from '../../../utils/roundToQuarterHour';
import ScrollBlocker from '../../ScrollBlocker';
import CalendarStyler from './CalendarStyler';

const Styled = {
  MobileUnderlay: styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: ${GREYSCALE.black};
    opacity: 0.5;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  `,
};

type CalendarProps = {
  toggleRef: RefObject<HTMLDivElement>; // a ref of the element that the calendar should 'hang' off of
  value?: Date;
  localOrUTC: LocalOrUTC;
  maxDate?: Date;
  minDate?: Date;
  enabledDates?: Date[];
  disabledWeekdays?: Weekday[]; // a weekday number array, where sunday = 0, monday = 1 etc
  secondaryPicker?: ReactNode;
  onChange?: (date: Date) => void;
  onClose: () => void;
  closeOnSelect?: boolean;
};

const defaultMaxDate = add(newDate('now'), { years: 1 });
const defaultMinDate = newDate('2014-3-17');

function CalendarComponent(
  {
    toggleRef,
    minDate,
    maxDate,
    enabledDates,
    disabledWeekdays,
    secondaryPicker = null,
    onChange,
    value,
    localOrUTC,
    onClose,
    closeOnSelect = true,
  }: CalendarProps,
  ref: Ref<HTMLDialogElement>,
) {
  const forwardedRef = useForwardedRef(ref);
  const { applyOffset, revertOffset } = useOffsetDates(localOrUTC);
  // this date is the value passed in from above (the true form value)
  const [selectedDate, setSelectedDate] = useState<Date>(roundToQuarterHour(value));

  const { isMobile } = useIsMobile();

  const positioning = useToTogglePositioning(toggleRef, forwardedRef);
  const mobilePositioning = useAbsolutelyCenteredPositioning(forwardedRef);

  const [isAbbreviatedTwoMonthDisplay, setIsAbbreviatedTwoMonthDisplay] = useState(false);

  const internalMaxDate = maxDate ?? defaultMaxDate;
  const internalMinDate = minDate ?? defaultMinDate;
  const isSevenDaysOrLess = differenceInCalendarDays(internalMaxDate, internalMinDate) <= 7;

  // if value changes from above
  useEffect(() => {
    if (value) {
      setSelectedDate(roundToQuarterHour(value));
    }
  }, [value]);

  // UX: if our selectable range is one week or less and overflows to the next month, then we signal this to the calendar styler
  useEffect(() => {
    if (isSevenDaysOrLess && !isSameMonth(internalMaxDate, internalMinDate)) {
      setIsAbbreviatedTwoMonthDisplay(true);
    }
  }, [internalMaxDate, internalMinDate, isSevenDaysOrLess]);

  // NOTE: the external calendar library will show any dates given to it in BROWSER time. We want it to show in USER time.
  // this is why we apply() the correct offset when giving the calendar a date and revert() it when saving the value(s).
  // the form value will still always be in true UTC.
  return (
    <>
      {isMobile && <ScrollBlocker />}
      {isMobile && <Styled.MobileUnderlay onClick={onClose} />}
      <CalendarStyler
        open
        ref={forwardedRef}
        style={isMobile ? mobilePositioning : positioning}
        showPrevMonthIcon={!isSevenDaysOrLess}
        showNextMonthIcon={!isSevenDaysOrLess}
        showMonthPicker={!isSevenDaysOrLess}
        showYearPicker={!isSevenDaysOrLess}
        showTimePicker={!!secondaryPicker}
        showMonthName={
          isSevenDaysOrLess && differenceInCalendarMonths(internalMaxDate, internalMinDate) !== 0
        }
        isAbbreviatedTwoMonthDisplay={isAbbreviatedTwoMonthDisplay}
      >
        <RdrCalendar
          months={isAbbreviatedTwoMonthDisplay ? 2 : 1}
          // if the selection range crosses two months and the selected date is in the second month, render in 'backwards' mode to show the previous selectable dates
          calendarFocus={
            isAbbreviatedTwoMonthDisplay && !isSameMonth(selectedDate, internalMinDate)
              ? 'backwards'
              : 'forwards'
          }
          minDate={minDate ? applyOffset(minDate) : undefined}
          maxDate={maxDate ? applyOffset(maxDate) : undefined}
          disabledDay={(offsetDate) => {
            const revertedDate = revertOffset(offsetDate);
            if (disabledWeekdays?.some((weekday) => revertedDate.getUTCDay() === weekday)) {
              return true;
            }
            if (enabledDates === undefined) {
              return false;
            }
            if (enabledDates.some((enabledDate) => isSameDate(enabledDate, revertedDate))) {
              return false;
            }
            return true;
          }}
          showPreview={false}
          showDateDisplay={false}
          dragSelectionEnabled
          date={applyOffset(selectedDate)}
          color="transparent"
          weekdayDisplayFormat="EEEEEE"
          onChange={(newOffsetDate: Date) => {
            if (closeOnSelect) {
              onClose();
            }
            const returnOffsetDate = newDate(newOffsetDate);
            // Do not use setUTCMinutes here, the applyOffset will apply the correct offset
            returnOffsetDate.setMinutes(applyOffset(selectedDate).getMinutes());
            // Do not use setUTCHours here, the applyOffset will apply the correct offset
            returnOffsetDate.setHours(applyOffset(selectedDate).getHours());
            setSelectedDate(revertOffset(returnOffsetDate));
            onChange?.(revertOffset(returnOffsetDate)); // change the form value via the callback passed in from above (reverting the offset first!)
          }}
        />
        {/* the secondary picker onChange function does not change the internal state of the this component. Keep that in mind */}
        {secondaryPicker}
      </CalendarStyler>
    </>
  );
}

const Calendar = forwardRef(CalendarComponent);
export default Calendar;
