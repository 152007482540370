import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createPlaidValidationActivationMutation = gql(/* GraphQL */ `
  mutation CreatePlaidValidationActivationMutation($paymentSourceId: ID!, $origin: String!) {
    createPlaidValidationActivation(paymentSourceId: $paymentSourceId, origin: $origin) {
      handle
      linkToken
    }
  }
`);

export const useCreatePlaidValidationActivationMutation = () =>
  useMutation(createPlaidValidationActivationMutation);
