import newDate from '../../../utils/newDate';
import { ColumnDataType } from '../types';

export default function parseFilterValues(values: string[], dataType?: ColumnDataType) {
  return values.map((value) => {
    switch (dataType) {
      case 'date':
      case 'datetime':
        return newDate(value);

      default:
        return value;
    }
  });
}
