import useModal from '../../../../hooks/useModal';
import { Row, Col } from '../../../layout/Grid';
import FormControl from '../../../form/FormControl';
import Checkbox from '../../../form/Checkbox';
import { FakeLink } from '../../../Link';
import Styled from './SignupSharedStyles';

export default function SignupTerms({ isEu = false }: { isEu: boolean }) {
  const setModal = useModal();

  if (isEu) {
    return (
      <Row spaceBelow justify="center" data-testid="signup-terms-eu">
        <Col xs={12}>
          <Styled.Subheading>Your data is safe &amp; confidential</Styled.Subheading>
          <Styled.Paragraph>
            <span role="img" aria-label="European Union Flag">
              🇪🇺
            </span>{' '}
            Pirate Ship complies with the European Union&lsquo;s General Data Protection Law (GDPR)
          </Styled.Paragraph>
        </Col>
        <Col xs={12}>
          <FormControl
            as={Checkbox}
            type="checkbox"
            name="agree"
            label={
              <span>
                I have read and agree to the{' '}
                <FakeLink
                  onClick={(event) => {
                    event.preventDefault();
                    setModal('privacy');
                  }}
                >
                  Privacy Policy
                </FakeLink>{' '}
                and{' '}
                <FakeLink
                  onClick={(event) => {
                    event.preventDefault();
                    setModal('terms');
                  }}
                >
                  Terms of Use
                </FakeLink>{' '}
                including the{' '}
                <FakeLink
                  onClick={(event) => {
                    event.preventDefault();
                    setModal('dpa');
                  }}
                >
                  Data Processing Addendum
                </FakeLink>
              </span>
            }
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row justify="center" data-testid="signup-terms">
      <Col xs={12} style={{ textAlign: 'center' }}>
        <Styled.SmallParagraph>
          By creating an account you agree to the{' '}
          <FakeLink
            onClick={(event) => {
              event.preventDefault();
              setModal('terms');
            }}
          >
            Terms of Service
          </FakeLink>
        </Styled.SmallParagraph>
      </Col>
    </Row>
  );
}
