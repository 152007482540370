import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import batchAndShipmentIdsFromPathname from '../../utils/batchAndShipmentIdsFromPathname';
import PageLoading from '../loading/PageLoading';
import { batchIdFromShipmentQuery } from '../../operations/queries/batchIdFromShipment';
import ShipmentWorkflow from './ship/ShipmentWorkflow';
import { BatchAndShipmentIds } from '../../constants';

type ShipRoutesProps = {
  forwardedMailClassKey?: string;
};

function ShipRoutes({ forwardedMailClassKey }: ShipRoutesProps) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [getBatchIdFromShipment] = useLazyQuery(batchIdFromShipmentQuery);

  // GET IDS FROM THE URL //
  const { batchId: batchIdFromUrl, shipmentId: shipmentIdFromUrl }: BatchAndShipmentIds = (() => {
    // get the react params if we have them
    const reactIds: BatchAndShipmentIds = batchAndShipmentIdsFromPathname(location.pathname);

    // In legacy, _both_ have the searchParam name "id", but they come from different routes. Here, we name the id based on what it is for.
    const legacyIds: BatchAndShipmentIds = {
      shipmentId: location.pathname.includes('shipment')
        ? searchParams.get('id') ?? undefined
        : undefined,
      batchId: location.pathname.includes('batch')
        ? searchParams.get('id') ?? undefined
        : undefined,
    };

    return {
      batchId: legacyIds.batchId ?? reactIds?.batchId,
      shipmentId: legacyIds.shipmentId ?? reactIds?.shipmentId,
    };
  })();

  // special case: IFF we have _only_ the shipmentId, then we have come from the following legacy url: /ship/shipment?id=XXX
  // therefore we need to get the batch id to fit with our new routing.
  const [batchIdFromShipment, setBatchIdFromShipment] = useState<string>();
  useEffect(() => {
    const getBatchId = async (sid: string) => {
      const { data: responseData } = await getBatchIdFromShipment({
        variables: { shipmentId: sid },
      });
      if (responseData?.shipment) {
        setBatchIdFromShipment(responseData.shipment.batch.id);
      }
    };

    if (shipmentIdFromUrl && !batchIdFromUrl) {
      getBatchId(shipmentIdFromUrl);
    }
  }, [batchIdFromUrl, shipmentIdFromUrl, getBatchIdFromShipment]);

  const { batchId, shipmentId } = {
    batchId: batchIdFromUrl ?? batchIdFromShipment,
    shipmentId: shipmentIdFromUrl,
  };

  if (!batchId) return <PageLoading />;

  return (
    <Routes>
      {/* Legacy rerouting from old batch and shipment complete pages */}
      <Route path="/ship/batch" element={<Navigate to={`/batch/${batchId}`} replace />} />
      <Route
        path="/ship/shipment"
        element={<Navigate to={`/batch/${batchId}/shipment/${shipmentId}`} replace />}
      />

      {/* Legacy react routing */}
      <Route path="batch/buy/:batchId" element={<Navigate to={`/batch/${batchId}`} />} />

      {/* React routing */}
      <Route
        path="/batch/:batchId"
        element={
          <ShipmentWorkflow batchId={batchId} forwardedMailClassKey={forwardedMailClassKey} />
        }
      />
      <Route
        path="/batch/:batchId/shipment/:shipmentId"
        element={
          <ShipmentWorkflow
            batchId={batchId}
            shipmentId={shipmentId}
            forwardedMailClassKey={forwardedMailClassKey}
          />
        }
      />
    </Routes>
  );
}

export default ShipRoutes;
