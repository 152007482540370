import Select, { SelectProps } from './Select';

export type RegionSelectProps = Omit<SelectProps, 'options'> & {
  options?: ReadonlyArray<{
    regionCode: string;
    name: string;
  }>;
};

export default function RegionSelect({
  options,
  placeholder = 'State',
  ...others
}: RegionSelectProps) {
  return (
    <Select {...others} placeholder={placeholder}>
      {options &&
        options.map(({ regionCode, name }) => (
          <option key={regionCode} value={regionCode}>
            {name}
          </option>
        ))}
    </Select>
  );
}
