import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const updateBillingAddressMutation = gql(/* GraphQL */ `
  mutation UpdateBillingAddressMutation($billingAddress: AddressInput!) {
    updateBillingAddress(billingAddress: $billingAddress) {
      id
      company
      fullName
      address1
      address2
      city
      postcode
      countryCode
      regionCode
      valid
      phone
    }
  }
`);

export const useBillingAddressMutation = () => useMutation(updateBillingAddressMutation);
