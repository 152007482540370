import styled from '@emotion/styled';
import { GREYSCALE } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';

const Styled = {
  PageSubtitle: styled.h2`
    margin: ${SPACING.xl} ${SPACING.none};
    font-size: ${TYPOGRAPHY.heading.xl.fontSize};
    line-height: ${TYPOGRAPHY.heading.xl.lineHeight};
    font-weight: ${TYPOGRAPHY.heading.xl.fontWeight};
    font-style: normal;
    color: ${GREYSCALE.grey80};
  `,
};

export const PageSubtitleSelector = Styled.PageSubtitle;

type PageSubtitleProps = JSX.IntrinsicElements['h2'];

export default function PageSubtitle(props: PageSubtitleProps) {
  return <Styled.PageSubtitle {...props} />;
}
