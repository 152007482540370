import { setFlashMessage } from '../apollo/cache/flashMessages';
import generateLogId from '../utils/generateLogId';
import useLogger from './useLogger';

/**
 * Use this hook to log an error and show a generic error message with Log ID to the user.
 */
export default function useObfuscatedErrorLogger() {
  const logger = useLogger();

  return (message: string, error?: Error) => {
    const logId = generateLogId();
    logger.warn(message, { logId }, error);
    setFlashMessage(`An unexpected error occurred. Log-ID: ${logId}`, 'danger');
  };
}
