import ClipboardButton from '../form/ClipboardButton';
import Section, { SectionEntries } from './Section';

type RecipientDetailsSectionProps = {
  recipientDetails: { email?: string | null; phone?: string | null };
  hideIf?: boolean;
};

function RecipientDetailsSection({ recipientDetails, hideIf }: RecipientDetailsSectionProps) {
  const { email, phone } = recipientDetails ?? {};

  if (hideIf || (!email && !phone)) return null;

  const entries: SectionEntries = [
    [
      'Email Address',
      email ? (
        <>
          {email}
          <ClipboardButton value={email!} ariaTitle="email" />
        </>
      ) : null,
    ],
    ['Phone Number', phone],
  ];
  return <Section title="Recipient Details" entries={entries} />;
}

export default RecipientDetailsSection;
