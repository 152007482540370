import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '../../gql';
import {
  ShareDownloadUrlByShipmentQuery,
  ShareDownloadUrlByShipmentQueryVariables,
} from '../../gql/graphql';

export const shareDownloadUrlByShipmentQuery = gql(/* GraphQL */ `
  query ShareDownloadUrlByShipmentQuery($shipmentId: ID!) {
    shareDownloadUrlByShipment(shipmentId: $shipmentId)
  }
`);

export const useShareDownloadUrlByShipmentLazyQuery = (
  options: QueryHookOptions<
    ShareDownloadUrlByShipmentQuery,
    ShareDownloadUrlByShipmentQueryVariables
  >,
) => useLazyQuery(shareDownloadUrlByShipmentQuery, options);
