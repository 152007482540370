export type Order = {
  orderLink?: Link | null;
  subscriptionLink?: Link | null;
  shipmentLink?: Link | null;
};

type Link = { text: string; url: string };
/*
 * This function takes an order and returns a possible external link based on our business logic
 * the order of links is 'OrderLink' -> 'SubscriptionLink' -> 'ShipmentLink'
 */
export default function getShipmentOrderLink(order?: Order | null): Link | undefined {
  if (order?.orderLink?.url) {
    return order.orderLink;
  }
  if (order?.subscriptionLink?.url) {
    return order.subscriptionLink;
  }
  if (order?.shipmentLink?.url) {
    return order.shipmentLink;
  }
  return undefined;
}
