import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { SPACING } from '../styles/spacing';
import { BORDER_WIDTH } from '../styles/borders';
import { COLOR, GREYSCALE } from '../styles/colors';
import Icon from './Icon';

const Styled = {
  BoxBorder: styled.div`
    position: relative;
    margin-top: ${SPACING.xl};
    border: ${BORDER_WIDTH.lg} solid ${COLOR.green};
    background-color: ${GREYSCALE.grey10};
    // bottom spacing comes from form elements
    padding: ${SPACING.xl} ${SPACING.xl} ${SPACING.none};
  `,
  SecurityIcon: styled(Icon)`
    position: absolute;
    top: -14px;
    left: 22px;
    color: ${COLOR.green};
  `,
};

type SecurityBoxProps = PropsWithChildren<{}>;

export default function SecurityBox({ children }: SecurityBoxProps) {
  return (
    <Styled.BoxBorder>
      <Styled.SecurityIcon icon="lock" />
      {children}
    </Styled.BoxBorder>
  );
}
