import { REFUND_MESSAGES } from '../constants';

export function getBatchRefundSuccessMessage(
  canInstantRefundBatch: boolean,
  isUpsOnlyBatch: boolean,
  isUspsOnlyBatch: boolean,
) {
  if (canInstantRefundBatch) {
    return REFUND_MESSAGES.batch.instant.success;
  }
  if (isUpsOnlyBatch) {
    return REFUND_MESSAGES.batch.ups.success;
  }
  if (isUspsOnlyBatch) {
    return REFUND_MESSAGES.batch.usps.success;
  }
  return REFUND_MESSAGES.batch.default.success;
}

export function getShipmentRefundSuccessMessage(
  canInstantRefundShipment: boolean,
  isUpsShipment: boolean,
) {
  if (canInstantRefundShipment) {
    return REFUND_MESSAGES.shipment.instant.success;
  }
  if (isUpsShipment) {
    return REFUND_MESSAGES.shipment.ups.success;
  }

  return REFUND_MESSAGES.shipment.usps.success;
}
