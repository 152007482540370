import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERY } from '../styles/breakpoints';

export type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'; // tshirt size of screen
export default function useScreenSize(): ScreenSize {
  const [isXs, isSm, isMd, isLg] = [
    useMediaQuery({ maxWidth: MEDIA_QUERY.xsMax }),
    useMediaQuery({ maxWidth: MEDIA_QUERY.smMax }),
    useMediaQuery({ maxWidth: MEDIA_QUERY.mdMax }),
    useMediaQuery({ maxWidth: MEDIA_QUERY.lgMax }),
  ];

  if (isXs) {
    return 'xs';
  }
  if (isSm) {
    return 'sm';
  }
  if (isMd) {
    return 'md';
  }
  if (isLg) {
    return 'lg';
  }
  return 'xl';
}
