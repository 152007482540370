import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const rerateBatchMutation = gql(/* GraphQL */ `
  mutation RerateBatchMutation($id: ID!, $shipDate: DateTime) {
    rerateBatch(id: $id, shipDate: $shipDate) {
      id
      stepText
      shipDate
      shipDatePossibleNow
    }
  }
`);

export const useRerateBatchMutation = () => useMutation(rerateBatchMutation);
