import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const autoRefundedShipmentsSummaryQuery = gql(/* GraphQL */ `
  query AutoRefundedShipmentsSummaryQuery {
    autoRefundedShipmentsSummary {
      count
      amount
    }
  }
`);

export const useAutoRefundedShipmentsSummaryQuery = () =>
  useQuery(autoRefundedShipmentsSummaryQuery);
