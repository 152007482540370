import uriEncode from './uriEncode';

export default function convertObjectToUrlSearchParams(
  obj: Record<string, any> | ArrayLike<any>,
  prefix?: string,
): string {
  return Object.entries<any>(obj)
    .map(([key, value]) => {
      if (typeof value === 'object') {
        return convertObjectToUrlSearchParams(value, prefix ? `${prefix}[${key}]` : key);
      }

      return prefix ? `${prefix}[${key}]=${uriEncode(value)}` : `${key}=${uriEncode(value)}`;
    })
    .join('&');
}
