import { ColumnFiltersState } from '@tanstack/react-table';
import sub from 'date-fns/sub';
import newDate from './newDate';

const filterStartDate = sub(newDate('now'), { months: 3 });
// in cypress, setting the clock from external with cy.clock() does not work with the loading of the mock for the CDG.
// the configData object is undefined and the grid does not load. (idk why, and I never found out, even after days!)
// Why do I need to use the cy.clock() function? because apollo fails with "Failed to match 1 mock for this query...."
// the mock is different to the response because the date/time is 3 milliseconds after, and Apollo stops execution
// after days of searching I could not find the problem and I decided to just kill the milliseconds and seconds,
// nobody needs them anyway
// https://pillage.slack.com/archives/CBP0NBNN5/p1720778567045559

filterStartDate.setSeconds(0);
filterStartDate.setMilliseconds(0);

const status = {
  id: 'status',
  value: ['purchased', 'refund_declined', 'refund_reversed'],
};

type FilterTemplateName = 'new' | 'transit' | 'delivered' | 'delivery_issue';

function mapFilterTemplateNameToFilters(filter: FilterTemplateName | string): ColumnFiltersState {
  switch (filter) {
    case 'new':
      return [
        {
          id: 'tracking_status',
          value: ['unknown', 'not_found', 'new'],
        },
        status,
        {
          id: 'label_transaction_date_time',
          value: [filterStartDate],
        },
      ];
    case 'transit':
      return [
        {
          id: 'tracking_status',
          value: [
            'custom_recall',
            'forward',
            'scanned',
            'notice',
            'out_for_delivery',
            'available_for_pickup',
            'delivery_status_not_updated',
            'other',
          ],
        },
        status,
        {
          id: 'label_transaction_date_time',
          value: [filterStartDate],
        },
      ];
    case 'delivered':
      return [
        { id: 'tracking_status', value: ['delivered'] },
        status,
        {
          id: 'label_transaction_date_time',
          value: [filterStartDate],
        },
      ];
    case 'delivery_issue':
      return [
        {
          id: 'tracking_status',
          value: ['dead_letter', 'returned_to_sender', 'undeliverable', 'refunded'],
        },
        status,
        {
          id: 'label_transaction_date_time',
          value: [filterStartDate],
        },
      ];
    default:
      return [
        {
          id: 'label_transaction_date_time',
          value: [filterStartDate],
        },
      ];
  }
}
export default mapFilterTemplateNameToFilters;
