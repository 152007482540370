import styled from '@emotion/styled';
import { RateGroupSortOrder, CarrierKey } from '../../gql/graphql';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { COLOR } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';

const Styled = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      justify-content: flex-end;
    }
  `,
  SortOption: styled.button<{ selected: boolean; greyOut?: boolean }>`
    appearance: none;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: ${({ selected, greyOut }) => {
      if (selected) return 'black';
      if (greyOut) return 'grey';
      return COLOR.blue;
    }};
    text-decoration: ${({ selected, greyOut }) => (selected || greyOut ? 'none' : 'underline')};
    pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};
  `,
  SortLabel: styled.div`
    font-size: ${TYPOGRAPHY.fontSize.sm};
    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      padding-bottom: ${SPACING.sm};
    }
  `,
  OptionDivider: styled.span`
    padding: 0 ${SPACING.xs};

    &:after {
      content: '|';
    }
  `,
};

type RateGroupsSortOrderProps = {
  sortOrder: RateGroupSortOrder;
  onUpdateSortOrder: (so: RateGroupSortOrder) => void;
  carriersSet: Set<CarrierKey>;
};

export default function RateGroupsSortOrder({
  sortOrder,
  onUpdateSortOrder,
  carriersSet,
}: RateGroupsSortOrderProps) {
  return (
    <Styled.Wrapper>
      <Styled.SortLabel>
        <b>{'Sort by: '}</b>
        <Styled.SortOption
          selected={sortOrder === 'BEST'}
          onClick={() => onUpdateSortOrder('BEST')}
          data-dd-action-name="Sort by: Best"
        >
          Best
        </Styled.SortOption>
        <Styled.OptionDivider aria-hidden />
        <Styled.SortOption
          selected={sortOrder === 'CHEAPEST'}
          onClick={() => onUpdateSortOrder('CHEAPEST')}
          data-dd-action-name="Sort by: Cheapest"
        >
          Cheapest
        </Styled.SortOption>
        <Styled.OptionDivider aria-hidden />
        <Styled.SortOption
          selected={sortOrder === 'USPS'}
          onClick={() => onUpdateSortOrder('USPS')}
          greyOut={carriersSet.size === 1 && !carriersSet.has('usps')}
          data-dd-action-name="Sort by: USPS"
        >
          USPS
        </Styled.SortOption>
        <Styled.OptionDivider aria-hidden />
        <Styled.SortOption
          selected={sortOrder === 'UPS'}
          onClick={() => onUpdateSortOrder('UPS')}
          greyOut={carriersSet.size === 1 && !carriersSet.has('ups')}
          data-dd-action-name="Sort by: UPS"
        >
          UPS
        </Styled.SortOption>
      </Styled.SortLabel>
    </Styled.Wrapper>
  );
}
