import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const updatePreferredUpsMerchantAccountAddressMutation = gql(/* GraphQL */ `
  mutation UpdatePreferredUpsMerchantAccountAddressMutation($address: AddressInput!) {
    updatePreferredUpsMerchantAccountAddress(address: $address) {
      id
      company
      fullName
      address1
      address2
      city
      postcode
      countryCode
      regionCode
      valid
      phone
    }
  }
`);

export const useUpdatePreferredUpsMerchantAccountAddressMutation = () =>
  useMutation(updatePreferredUpsMerchantAccountAddressMutation);
