// move the startIndex-th element of array to endIndex position, scooching all others needed along
function reorderArray<T>(array: T[], startIndex: number, endIndex: number) {
  if (startIndex === endIndex) return array;
  const returnArray = [...array];
  const [removed] = returnArray.splice(startIndex, 1);
  returnArray.splice(endIndex, 0, removed);
  return returnArray;
}

export default reorderArray;
