import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const acceptTermsMutation = gql(/* GraphQL */ `
  mutation AcceptTermsMutation($accepted: Boolean!) {
    acceptTerms(accepted: $accepted) {
      ...BasicUserFragment
    }
  }
`);

export const useAcceptTermsMutation = () =>
  useMutation(acceptTermsMutation, {
    // make sure user roles are updated accordingly
    update(cache, { data }) {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query UpdateUserAfterAcceptTermsQuery {
              user {
                ...BasicUserFragment
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          return {
            user: data.acceptTerms,
          };
          // return {
          //   user: {
          //     ...cacheResult.user,
          //     roles: [...cacheResult.user.roles, data.acceptTerms.roles],
          //   },
          // };
        },
      );
    },
  });
