import { usePlaidLink } from 'react-plaid-link';
import { Ref, useEffect, useImperativeHandle, useRef } from 'react';
import useLogger from './useLogger';
import { addRumCustomAction } from '../utils/addRumCustomAttributes';
import usePlaidLinkToken, {
  PlaidActivationPayloadProps,
  PlaidLinkTokenImperativeHandleRef,
  PlaidLinkTokenProps,
} from './usePlaidLinkToken';
import usePlaidAccessToken, {
  PlaidAccessTokenImperativeHandleRef,
  PlaidAccessTokenProps,
} from './usePlaidAccessToken';

export type PlaidAccessAndLinkImperativeHandleRef = {
  onSuccess: (payload: PlaidActivationPayloadProps) => void;
  setLoading?: (loading: boolean) => void;
};

export type PlaidHookProps = {
  plaidAccessAndLinkImperativeHandleRef: Ref<PlaidAccessAndLinkImperativeHandleRef>;
};

export default function usePlaid({
  onEventCallback,
  onSuccessCallback,
  onTokenCallback,
  onErrorCallback,
  forceOpen = false,
  plaidAccessAndLinkImperativeHandleRef,
}: PlaidAccessTokenProps & PlaidLinkTokenProps & PlaidHookProps) {
  const accessTokenImperativeHandleRef = useRef<PlaidAccessTokenImperativeHandleRef>(null);
  const linkTokenImperativeHandleRef = useRef<PlaidLinkTokenImperativeHandleRef>(null);

  // using refs here bc of plaid iframe
  const linkToken = useRef<string>('');
  const handle = useRef<string>('');
  const logger = useLogger();

  const onPlaidTokenReady = (payload: PlaidActivationPayloadProps) => {
    linkToken.current = payload.token;
    handle.current = payload.handle;

    onTokenCallback?.(payload);
  };

  const {
    getPlaidLinkToken,
    plaidLinkTokenLoading,
    creatingPlaidActivation,
    creatingPlaidValidationActivation,
    plaidActivationErrorMessage,
    successMessage, // pass through to usePlaidAccessToken
  } = usePlaidLinkToken({
    onTokenCallback: onPlaidTokenReady,
  });

  const {
    getPlaidAccessToken,
    plaidAccessTokenLoading,
    creatingPlaidAccessToken,
    plaidAccessTokenError,
  } = usePlaidAccessToken({
    onSuccessCallback,
    onErrorCallback,
    successMessage, // used in BuyPage, passed via onSuccessCallback
  } as PlaidAccessTokenProps);

  const plaidOnEvent = (event: string) => {
    const loggingIgnoredEvents = ['TRANSITION_VIEW'];

    if (!loggingIgnoredEvents.includes(event)) {
      const e = `PLAID_${event}`;
      logger.info(e);
      addRumCustomAction(e, { event: e });
    }

    switch (event) {
      case 'EXIT':
        accessTokenImperativeHandleRef.current?.setLoading(false);
        linkTokenImperativeHandleRef.current?.setLoading(false);
        break;
      default:
    }

    onEventCallback?.(event);
  };

  const { open: openPlaidModal, ready: plaidModalReady } = usePlaidLink({
    token: linkToken.current,
    onSuccess: (accessToken: string) =>
      getPlaidAccessToken({ token: accessToken, handle: handle.current }),
    onEvent: plaidOnEvent,
  });

  useImperativeHandle(plaidAccessAndLinkImperativeHandleRef, () => ({
    onSuccess: getPlaidAccessToken,
  }));

  useEffect(() => {
    if (plaidModalReady && forceOpen) {
      openPlaidModal();
    }
  }, [openPlaidModal, plaidModalReady, forceOpen]);

  return {
    getPlaidLinkToken,
    plaidIsLoading: plaidLinkTokenLoading || plaidAccessTokenLoading,
    creatingPlaidActivation,
    creatingPlaidValidationActivation,
    creatingPlaidAccessToken,
    plaidAccessTokenError,
    plaidActivationErrorMessage,
  };
}
