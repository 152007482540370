import { useSuspenseQuery } from '@apollo/client';
import { getIn, useFormikContext } from 'formik';
import { regionsQuery } from '../../operations/queries/regions';
import FormControl from '../form/FormControl';
import RegionSelect from '../form/RegionSelect';
import TextField from '../form/TextField';
import { Col, Row } from '../layout/Grid';
import { NamespacedSubform } from './types';

export type AddressSubformValues = {
  fullName: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  regionCode: string;
  postcode: string;
  countryCode: string;
  phone?: string;
};

const defaultLabels: AddressSubformValues = {
  fullName: 'Full Personal Name',
  company: 'Company (optional)',
  address1: 'Address',
  address2: 'Apt / Unit / Suite / etc. (optional)',
  city: 'City',
  regionCode: 'State',
  postcode: 'Zipcode',
  countryCode: 'Country',
  phone: 'Phone',
};

export type AddressSubformProps<NS extends string> = {
  includePhone?: boolean;
  nameRequired?: boolean;
  customLabels?: Partial<AddressSubformValues>; // add key value pairs with the labels you want to overwrite
  onPaste?: () => void;
  columnLayout?: boolean;
} & NamespacedSubform<NS>;

export default function AddressSubform<NS extends string>({
  namespace,
  includePhone = false,
  nameRequired = false,
  customLabels = {},
  onPaste,
  columnLayout = true,
}: AddressSubformProps<NS>) {
  // We don't know about the type of 'values', we just know that the property
  // identified by 'namespace' is AddressSubformValues
  const { values } = useFormikContext<Record<string, any>>();
  const { countryCode = 'US' } = getIn(values, namespace) as AddressSubformValues;
  const { data: regionsData } = useSuspenseQuery(regionsQuery, { variables: { countryCode } });

  return (
    <Row>
      <Col xs={12} sm={columnLayout ? 6 : 12} spaceBelow>
        <FormControl
          name={`${namespace}.fullName`}
          as={TextField}
          label={
            customLabels.fullName ?? `${defaultLabels.fullName}${nameRequired ? '' : ' (optional)'}`
          }
          onPaste={onPaste}
          maxLength={40}
        />
      </Col>
      <Col xs={12} sm={columnLayout ? 6 : 12} spaceBelow>
        <FormControl
          name={`${namespace}.company`}
          as={TextField}
          label={customLabels.company ?? defaultLabels.company}
          onPaste={onPaste}
          maxLength={40}
        />
      </Col>
      <Col xs={12} sm={columnLayout ? 6 : 12} spaceBelow>
        <FormControl
          name={`${namespace}.address1`}
          as={TextField}
          label={customLabels.address1 ?? defaultLabels.address1}
          onPaste={onPaste}
          maxLength={35}
        />
      </Col>
      <Col xs={12} sm={columnLayout ? 6 : 12} spaceBelow>
        <FormControl
          name={`${namespace}.address2`}
          as={TextField}
          label={customLabels.address2 ?? defaultLabels.address2}
          onPaste={onPaste}
          maxLength={35}
        />
      </Col>
      <Col xs={4} md={columnLayout ? 2 : 4} spaceBelow>
        <FormControl
          name={`${namespace}.city`}
          as={TextField}
          label={customLabels.city ?? defaultLabels.city}
          onPaste={onPaste}
          maxLength={40}
        />
      </Col>
      <Col xs={4} md={columnLayout ? 2 : 4} spaceBelow>
        <FormControl
          name={`${namespace}.regionCode`}
          as={RegionSelect}
          options={regionsData.regions}
          placeholder={customLabels.regionCode ?? defaultLabels.regionCode}
          onPaste={onPaste}
        />
        <FormControl name={`${namespace}.countryCode`} type="hidden" as="input" onPaste={onPaste} />
      </Col>
      <Col xs={4} md={columnLayout ? 2 : 4} spaceBelow>
        <FormControl
          name={`${namespace}.postcode`}
          as={TextField}
          label={customLabels.postcode ?? defaultLabels.postcode}
          onPaste={onPaste}
          maxLength={10}
        />
      </Col>
      {includePhone && (
        <Col xs={12} sm={columnLayout ? 6 : 12} spaceBelow>
          <FormControl
            name={`${namespace}.phone`}
            as={TextField}
            label={customLabels.phone ?? defaultLabels.phone}
            onPaste={onPaste}
            maxLength={10}
          />
        </Col>
      )}
    </Row>
  );
}
