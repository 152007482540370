import { QueryHookOptions, useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { ReceiptQuery, ReceiptQueryVariables } from '../../gql/graphql';

export const receiptQuery = gql(/* GraphQL */ `
  query ReceiptQuery($id: ID!) {
    receipt(paymentId: $id) {
      billingAddress {
        fullName
        company
        address1
        address2
        city
        postcode
        regionCode
        timeZone
        zip4
      }
      payment {
        title
        total
        createdAt
        status
        chargeError
        refundTotal
      }
      transactions {
        paymentId
        batchId
        shipmentId
        reconciliationRunId
        createdAt
        transactionType
        title
        amount
        balance
      }
      reversals {
        action {
          actionType
          newPaymentStatus
          createdAt
        }
        amount
        coveringAccountingTransaction {
          paymentId
        }
      }
      startBalance
      endBalance
      paymentSuccessful
      isCharged
    }
  }
`);

export const useGetReceiptQuery = (
  options?: QueryHookOptions<ReceiptQuery, ReceiptQueryVariables>,
) => useQuery(receiptQuery, options);
