// words that we want to show in all caps to the user
export const capsWords = ['ID', 'HTML', 'OK'];

/**
 * Capitalize the first letter of each word in a string and decapitalize the rest
 * (unless it is an explicitly allowed all-caps word from the capsWords array imported above)
 */
export default function capitalize(s: string): string {
  const words = s.split(' ');
  return words
    .map((w) =>
      capsWords.some((cw) => cw.toUpperCase() === w.toUpperCase())
        ? `${w.toUpperCase()}`
        : `${w.charAt(0).toUpperCase()}${w.slice(1).toLowerCase()}`,
    )
    .join(' ');
}
