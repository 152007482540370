import { useState } from 'react';
import convertObjectToUrlSearchParams from '../utils/convertObjectToUrlSearchParams';

declare global {
  interface Window {
    cfTurnstilePublicKey: string;
  }
}

type XhrHandlerParams = {
  method: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
  url: string;
  attrs: Record<string, any>;
};

export default function useCloudflareChallengeHandlingXhr<ResponseValues>() {
  let currentTurnstileWidgetId: string | null | undefined;
  const [loading, setLoading] = useState<boolean>(false);

  async function doRequest({ method, url, attrs }: XhrHandlerParams) {
    return fetch(url, {
      method,
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: convertObjectToUrlSearchParams(attrs),
    });
  }

  async function handler({ method, url, attrs }: XhrHandlerParams) {
    setLoading(true);

    let response = await doRequest({ url, method, attrs });

    if (response.headers.get('cf-mitigated') === 'challenge') {
      response = await new Promise((resolve, reject) => {
        currentTurnstileWidgetId = turnstile.render('#cf-turnstile-widget', {
          sitekey: window.cfTurnstilePublicKey,
          callback: () => {
            try {
              resolve(doRequest({ url, method, attrs }));
              if (currentTurnstileWidgetId) {
                turnstile.remove(currentTurnstileWidgetId);
              }
            } catch (error) {
              reject(error);
            }
          },
          theme: 'light',
        });
      });
    }

    setLoading(false);

    if (!response.ok) {
      throw new Error('Something went wrong!');
    }

    return (await response.json()) as ResponseValues;
  }

  return { handler, loading };
}
