import { client, Venmo, venmo } from 'braintree-web';
import { VenmoPaymentMethodUsage } from 'braintree-web/venmo';
import { useBraintreeClientTokenQuery } from '../operations/queries/braintreeToken';
import useObfuscatedErrorLogger from './useObfuscatedErrorLogger';

type UseVenmoProps = {
  onSuccessCallback: (instance: Venmo) => void;
};

export default function useVenmo({ onSuccessCallback }: UseVenmoProps) {
  const logError = useObfuscatedErrorLogger();
  const { data: token } = useBraintreeClientTokenQuery();

  const createVenmoInstance = async () => {
    try {
      const clientInstance = await client.create({
        authorization: token?.braintreeClientToken || '',
      });

      const instance = await venmo.create({
        client: clientInstance,
        allowDesktop: true,
        allowDesktopWebLogin: false,
        mobileWebFallBack: true,
        paymentMethodUsage: 'multi_use' as VenmoPaymentMethodUsage,
      });

      onSuccessCallback(instance);
    } catch (error) {
      logError('Could not create Braintree instance for Venmo', error as Error);
    }
  };

  return { createVenmoInstance };
}
