import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '../../gql';
import { DataGridExportStatusQuery, DataGridExportStatusQueryVariables } from '../../gql/graphql';

export const dataGridExportStatusQuery = gql(/* GraphQL */ `
  query DataGridExportStatusQuery($shipmentId: ID!) {
    shipment(id: $shipmentId) {
      trackingDataExportUrl {
        exportId
        url
        downloadable
        filename
        expire
      }
    }
  }
`);

export const useDataGridExportStatusLazyQuery = (
  options?: LazyQueryHookOptions<DataGridExportStatusQuery, DataGridExportStatusQueryVariables>,
) => useLazyQuery(dataGridExportStatusQuery, options);
