import { useState, useRef, useEffect, RefObject } from 'react';
import { createPortal } from 'react-dom';
import 'react-date-range/dist/styles.css';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { startOfDay } from 'date-fns';
import useOutsideClickEffect from '../../../hooks/useOutsideClickEffect';
import usePortalContainer from '../../../hooks/usePortalContainer';
import TimePicker from './TimePicker';
import Calendar from './Calendar';
import TextField from '../TextField';
import InputGroup from '../InputGroup';
import useFormatDatePickerText from '../../../hooks/useFormatDatePickerText';
import { LocalOrUTC, Weekday } from '../../../constants';
import roundToQuarterHour from '../../../utils/roundToQuarterHour';
import {
  DatePickerFieldToggle,
  DatePickerFieldToggleWrapper,
  DatePickerFieldWrapper,
} from './DatePickerFieldSharedComponents';
import newDate from '../../../utils/newDate';
import useCurrentUser from '../../../hooks/useCurrentUser';

export type InputSize = 'small' | 'large';

export type DatePickerFieldProps = {
  calendarRef?: RefObject<HTMLDialogElement>;
  value?: Date;
  localOrUTC: LocalOrUTC;
  defaultTimeToMidnightOfUserTimezone?: boolean;
  maxDate?: Date;
  minDate?: Date;
  enabledDates?: Date[];
  disabledWeekdays?: Weekday[];
  timePicker?: boolean;
  disabled?: boolean;
  onChange?: (date: Date) => void;
  size?: InputSize;
  closeOnSelect?: boolean;
};

function DatePickerField({
  calendarRef: externalCalendarRef,
  minDate,
  maxDate,
  enabledDates,
  timePicker = false,
  disabled = false,
  disabledWeekdays = [],
  onChange,
  closeOnSelect,
  value,
  localOrUTC,
  defaultTimeToMidnightOfUserTimezone = false,
  size = 'large',
  ...others
}: DatePickerFieldProps) {
  const [currentUser] = useCurrentUser();
  const [selectedDate, setSelectedDate] = useState<Date>(
    defaultTimeToMidnightOfUserTimezone
      ? zonedTimeToUtc(
          startOfDay(utcToZonedTime(newDate(value ?? 'now'), currentUser?.timezone ?? 'UTC')),
          currentUser?.timezone ?? 'UTC',
        )
      : roundToQuarterHour(value),
  ); // this date is the value passed in from above (the true form value)
  const formatDatePickerText = useFormatDatePickerText(localOrUTC);
  const [open, setOpen] = useState(false);
  const toggleRef = useRef<HTMLDivElement>(null);
  const internalCalendarRef = useRef<HTMLDialogElement>(null);
  const calendarRef = externalCalendarRef ?? internalCalendarRef;
  const textFieldRef = useRef<HTMLInputElement>(null);
  const portalContainer = usePortalContainer();

  useEffect(() => {
    if (value) setSelectedDate(roundToQuarterHour(value));
  }, [value]);

  useOutsideClickEffect([toggleRef, calendarRef], () => {
    setOpen(false);
  });

  return (
    <DatePickerFieldWrapper disabled={disabled}>
      <DatePickerFieldToggleWrapper disabled={disabled} ref={toggleRef}>
        <DatePickerFieldToggle onClick={() => setOpen(!open)}>
          <InputGroup
            suffixIcon="calendar"
            iconSize={size}
            onSuffixClick={() => {
              if (!open) {
                textFieldRef.current?.focus();
              }
            }}
          >
            <TextField
              small={size === 'small'}
              ref={textFieldRef}
              readOnly
              value={value ? formatDatePickerText(selectedDate, timePicker) : ''}
              data-testid="date-input"
              {...others}
            />
          </InputGroup>
        </DatePickerFieldToggle>
      </DatePickerFieldToggleWrapper>
      {portalContainer &&
        open &&
        createPortal(
          <Calendar
            toggleRef={toggleRef}
            ref={calendarRef}
            closeOnSelect={closeOnSelect ?? !timePicker}
            minDate={minDate}
            maxDate={maxDate}
            enabledDates={enabledDates}
            disabledWeekdays={disabledWeekdays}
            value={selectedDate}
            localOrUTC={localOrUTC}
            onChange={(date: Date) => {
              setSelectedDate(date);
              onChange?.(date);
            }}
            onClose={() => setOpen(false)}
            secondaryPicker={
              timePicker && (
                <TimePicker
                  onChange={(date) => {
                    setSelectedDate(date);
                    onChange?.(date);
                    if (closeOnSelect) {
                      setOpen(false);
                    }
                  }}
                  value={selectedDate}
                  localOrUTC={localOrUTC}
                />
              )
            }
          />,
          portalContainer,
        )}
    </DatePickerFieldWrapper>
  );
}

export default DatePickerField;
