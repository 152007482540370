import { CSSProperties, RefObject, useLayoutEffect, useState } from 'react';
import useWindowSize from './useWindowSize';

/**
 *
 * A function that takes an element and positions it vertically and horizontally centered in the window
 * (currently used for the Calendar component)
 */
export default function useAbsolutelyCenteredPositioning(
  ref: RefObject<HTMLElement>,
): CSSProperties {
  const [positioning, setPositioning] = useState<CSSProperties>({});
  const [windowWidth, windowHeight] = useWindowSize();
  useLayoutEffect(() => {
    if (ref.current) {
      const { scrollY } = window;
      const offset = scrollY - 6; // spacing.sm
      const { width: elementWidth, height: elementHeight } = ref.current.getBoundingClientRect();
      setPositioning({
        top: (windowHeight - elementHeight) / 2 + offset,
        left: (windowWidth - elementWidth) / 2,
      });
    }
  }, [ref, windowHeight, windowWidth]);

  return positioning;
}
