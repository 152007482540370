import type { CellContext } from '@tanstack/react-table';
import useDateInUserTimezone from '../../../hooks/useDateInUserTimezone';
import { DATE_FORMAT, LocalOrUTC } from '../../../constants';

export type DateCellSettings = {
  showWeekday?: boolean;
  localOrUTC?: LocalOrUTC;
};

export default function DateTimeCell<TData, TValue extends unknown>(
  props: CellContext<TData, TValue>,
) {
  const { formatDate, createDate } = useDateInUserTimezone();

  const value = props.getValue() as string | Date;
  const cellSettings = (props.column.columnDef.meta?.cellSettings ?? {}) as DateCellSettings;
  const localOrUTC = cellSettings.localOrUTC ?? 'UTC';
  if (!value) {
    return '';
  }

  const dateValue = createDate(value);
  const dateFormat = cellSettings.showWeekday ? DATE_FORMAT.dateWithWeekday : DATE_FORMAT.usDate;

  // if the underlying value has the timezone baked in to the timestamp, don't add it for display (otherwise, do)
  return formatDate(localOrUTC, dateValue, dateFormat);
}
