import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Logger } from '../services/logging';

export type AuthLinkOptions = {
  onInvalidAuth?: () => void;
  logger?: Logger;
};

// Handle errors, especially auth errors
const createErrorLink = ({ onInvalidAuth }: AuthLinkOptions) =>
  onError(({ graphQLErrors }) => {
    // If there are no errors, we are not interested
    if (!graphQLErrors) {
      return undefined;
    }

    // Look for Authentication errors
    const hasAuthError = graphQLErrors.some(
      (graphQLError) => graphQLError.message === 'You need to be logged to access this field',
    );

    // Stop error handling if no auth errors were found
    if (!hasAuthError) {
      return undefined;
    }

    if (onInvalidAuth) {
      onInvalidAuth();
    }
    return undefined;
  });

export const createAuthLink = (options: AuthLinkOptions) => {
  // Handles auth errors and re-authenticates
  const errorLink = createErrorLink(options);

  return ApolloLink.from([errorLink]);
};
