import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createVenmoPaymentSourceMutation = gql(/* GraphQL */ `
  mutation CreateVenmoPaymentSourceMutation($nonce: String!) {
    createVenmoPaymentSource(nonce: $nonce) {
      isDefault
      paymentSource {
        ...PaymentSourceFragment
      }
    }
  }
`);

export const useCreateVenmoPaymentSourceMutation = () =>
  useMutation(createVenmoPaymentSourceMutation, {
    // Add newly created entry to list
    update(cache, { data }) {
      if (!data) {
        return;
      }

      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query UpdateVenmoSourceAfterCreateQuery {
              company {
                id
                settings {
                  defaultPaymentSourceId
                }
                paymentSources {
                  ...PaymentSourceFragment
                }
                settings {
                  defaultPaymentSourceId
                }
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          return {
            company: {
              ...cacheResult.company,
              settings: {
                ...cacheResult.company.settings,
                defaultPaymentSourceId: data.createVenmoPaymentSource.isDefault
                  ? data.createVenmoPaymentSource.paymentSource.id
                  : cacheResult.company.settings.defaultPaymentSourceId,
              },
              paymentSources: [
                ...cacheResult.company.paymentSources,
                data.createVenmoPaymentSource.paymentSource,
              ],
            },
          };
        },
      );
    },
  });
