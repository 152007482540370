import { ShipmentStatus } from '../../gql/graphql';
import ServiceSummaryPrices from './ServiceSummaryPrices';
import ServiceSummaryTable from './StyledServiceSummaryTable';

type TotalCostRowProps = {
  previousCost?: number | null;
  currentCost: number | null;
  status?: ShipmentStatus;
};

export default function TotalCostRow({ previousCost, currentCost, status }: TotalCostRowProps) {
  return (
    <ServiceSummaryTable.Row>
      <ServiceSummaryTable.Col>
        <b>Total Cost</b>
      </ServiceSummaryTable.Col>
      <ServiceSummaryTable.Col alignRight>
        <b>
          <ServiceSummaryPrices
            currentPrice={currentCost}
            previousPrice={previousCost}
            status={status}
          />
        </b>
      </ServiceSummaryTable.Col>
    </ServiceSummaryTable.Row>
  );
}
