import { gql } from '../../gql';

export const batchIdFromShipmentQuery = gql(/* GraphQL */ `
  query BatchIdFromShipmentQuery($shipmentId: ID!) {
    shipment(id: $shipmentId) {
      id
      batch {
        id
      }
    }
  }
`);
