import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const applicationInformationQuery = gql(/* GraphQL */ `
  query ApplicationInformationQuery {
    applicationHostname
    applicationEnvironment
  }
`);

export const useApplicationInformationQuery = () => useQuery(applicationInformationQuery);
