import { setFlashMessage } from '../apollo/cache/flashMessages';
import useLogger from './useLogger';

export default function useReportError() {
  const logger = useLogger();

  // error reporting function
  return (message: string, errors: ReadonlyArray<Error> = []) => {
    // Compose error message for multiple errors
    let errorMessage = message;
    if (errors.length !== 0) {
      errorMessage += '\nDetails:\n';
      errors.forEach((error) => {
        errorMessage += `${error.message}\n`;
      });
    }

    setFlashMessage(errorMessage, 'danger');

    // PP-8522: all logs with severity >= WARN are reduced for now
    logger.info(errorMessage);
  };
}
