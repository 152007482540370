import styled from '@emotion/styled';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import { SPACING } from '../../styles/spacing';

const PageContent = styled.div`
  margin: ${SPACING.xl} ${SPACING.md};
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) 0;

  @media (min-width: ${MEDIA_QUERY.smMin}) {
    margin: ${SPACING.xxl};
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
      env(safe-area-inset-left);
  }
  @media print {
    margin: 0;
  }
`;

export default PageContent;
