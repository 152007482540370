import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { MEDIA_QUERY } from '../../styles/breakpoints';
import useIsMobile from '../../hooks/useIsMobile';
import { SPACING } from '../../styles/spacing';
import BottomRowSecondaryButton from '../BottomRowSecondaryButton';
import { ButtonProps } from './Button';
import ProgressButton, { ProgressButtonProps } from './ProgressButton';

export type BottomRowActionVariant = 'primary' | 'danger';

export type SecondaryActionProps = SecondaryButtonProps | SecondaryLinkProps;

type SecondaryLinkProps = {
  title: string;
  disabled?: ButtonProps['disabled'];
  href: string;
  variant: BottomRowActionVariant;
  datadogAction?: string;
};

type SecondaryButtonProps = {
  title: string;
  disabled?: ButtonProps['disabled'];
  onClick: ButtonProps['onClick'];
  variant: BottomRowActionVariant;
  datadogAction?: string;
};

export type BottomRowActionsProps = {
  primaryAction: ProgressButtonProps;
  secondaryActions?: SecondaryActionProps[];
  noSpaceAbove?: boolean;
};

const Styled = {
  Wrapper: styled.div<{ noSpaceAbove: boolean }>`
    margin-top: ${({ noSpaceAbove }) => (noSpaceAbove ? SPACING.none : SPACING.xxl)};
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      flex-direction: column;
      width: 100%;
      text-align: center;
      margin-top: ${SPACING.xl};

      > * {
        width: 100%;
      }
    }
  `,
  SecondaryActionsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      margin-top: ${SPACING.xl};
    }
  `,
};

export function BottomRowActions({
  primaryAction: {
    type,
    onClick,
    title,
    variant = 'primary',
    size = 'large',
    disabled,
    progress,
    datadogAction,
  },
  secondaryActions,
  noSpaceAbove = false,
}: BottomRowActionsProps) {
  const { isMobile } = useIsMobile();

  return (
    <Styled.Wrapper noSpaceAbove={noSpaceAbove}>
      <ProgressButton
        type={type}
        onClick={onClick}
        variant={variant}
        size={isMobile ? 'large' : size}
        disabled={disabled}
        progress={progress}
        data-dd-action-name={datadogAction}
      >
        {title}
      </ProgressButton>
      {secondaryActions && (
        <Styled.SecondaryActionsWrapper>
          {getSecondaryActions(secondaryActions)}
        </Styled.SecondaryActionsWrapper>
      )}
    </Styled.Wrapper>
  );
}

function getSecondaryActions(secondaryActions: SecondaryActionProps[]) {
  return secondaryActions.map((secondaryAction) => getLinkOrButton(secondaryAction));
}

function getLinkOrButton(secondaryAction: SecondaryActionProps) {
  if ('href' in secondaryAction) {
    return secondaryLink(secondaryAction);
  }
  if ('onClick' in secondaryAction) {
    return secondaryButton(secondaryAction);
  }
  return null;
}

function secondaryButton({
  title,
  variant,
  disabled,
  onClick,
  datadogAction,
}: SecondaryButtonProps) {
  return (
    <BottomRowSecondaryButton
      key={title}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      data-dd-action-name={datadogAction}
      type="button"
    >
      {title}
    </BottomRowSecondaryButton>
  );
}

function secondaryLink({ title, variant, disabled, href, datadogAction }: SecondaryLinkProps) {
  return (
    <Link key={title} to={href}>
      <BottomRowSecondaryButton
        disabled={disabled}
        variant={variant}
        data-dd-action-name={datadogAction}
      >
        {title}
      </BottomRowSecondaryButton>
    </Link>
  );
}
