import ServiceSummaryTable from './StyledServiceSummaryTable';

export default function HeaderRow() {
  return (
    <ServiceSummaryTable.Row>
      <ServiceSummaryTable.Col>
        <b>Service</b>
      </ServiceSummaryTable.Col>
      <ServiceSummaryTable.Col alignRight>
        <b>Cost</b>
      </ServiceSummaryTable.Col>
    </ServiceSummaryTable.Row>
  );
}
