import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { useLazyQuery } from '@apollo/client';
import { mapQuery } from '../operations/queries/map';
import { BREAKPOINT, MEDIA_QUERY } from '../styles/breakpoints';
import { GREYSCALE } from '../styles/colors';
import useScreenSize, { ScreenSize } from '../hooks/useScreenSize';

const Styled = {
  MapWrapper: styled.div`
    display: flex;
    margin: auto;
    background-color: ${GREYSCALE.grey10};

    min-height: 100px;
    max-height: 360px;
    aspect-ratio: 4;

    @media (max-width: ${MEDIA_QUERY.lgMax}) {
      aspect-ratio: 3.5;
    }

    @media (max-width: ${MEDIA_QUERY.mdMax}) {
      aspect-ratio: 3;
    }

    @media (max-width: ${MEDIA_QUERY.smMax}) {
      aspect-ratio: 2.75;
    }

    @media (max-width: ${MEDIA_QUERY.xsMax}) {
      aspect-ratio: 2.56;
    }
  `,
  Map: styled.div`
    flex: 1;
    background-size: cover;
    background-position: center;
  `,
};

type ShipmentMapProps = {
  shipmentId: string | undefined;
};

const screenSizeToMapSizeMap = new Map<ScreenSize, { width: number; height: number }>([
  ['xs', { width: BREAKPOINT.xs, height: BREAKPOINT.xs / 2.56 }],
  ['sm', { width: BREAKPOINT.sm, height: BREAKPOINT.sm / 2.75 }],
  ['md', { width: BREAKPOINT.md, height: BREAKPOINT.md / 3 }],
  ['lg', { width: BREAKPOINT.lg, height: BREAKPOINT.lg / 3.5 }],
  ['xl', { width: BREAKPOINT.xl, height: BREAKPOINT.xl / 4 }],
]);

export default function ShipmentMap({ shipmentId }: ShipmentMapProps) {
  const [, setMounted] = useState(false);
  const [getMap, { data }] = useLazyQuery(mapQuery);
  const debouncedGetMap = useMemo(() => debounce(getMap, 100), [getMap]);
  const screenSize = useScreenSize();

  // Trigger re-render after mount, at which point we have a reference to the MapWrapper to get the dimensions
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (shipmentId) {
      const { width, height } = screenSizeToMapSizeMap.get(screenSize) ?? {
        width: 100,
        height: 100,
      }; // this fallback is only to appease Typescript, it will never happen.

      debouncedGetMap({
        variables: {
          id: shipmentId,
          width: Math.round(width),
          height: Math.round(height),
        },
      });
    }
  }, [shipmentId, debouncedGetMap, screenSize]);

  // if the map returns specificially an empty string, we want to show nothing instead of a placeholder
  if (data?.shipment.map === '' || !data) {
    return null;
  }

  return (
    <Styled.MapWrapper>
      <Styled.Map style={{ backgroundImage: `url("${data.shipment.map}")` }} />
    </Styled.MapWrapper>
  );
}
