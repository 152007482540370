import { ChangeEventHandler, useRef } from 'react';
import styled from '@emotion/styled';
import { HeaderContext } from '@tanstack/react-table';
import { RawCheckbox } from '../../form/Checkbox';

const Styled = {
  ColumnMenuHeader: styled.div`
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    input[type='checkbox'] {
      margin: 0 auto;
    }
  `,
};

type SelectColumnHeaderProps<TData, TValue> = {
  headerContext: HeaderContext<TData, TValue>;
  onChange?: () => ChangeEventHandler<HTMLInputElement>;
  isChecked?: boolean;
  isIndeterminate?: boolean;
};

export default function SelectColumnHeader<TData, TValue>({
  headerContext,
  onChange = headerContext.table.getToggleAllPageRowsSelectedHandler,
  isChecked = headerContext.table.getIsAllPageRowsSelected(),
  isIndeterminate = headerContext.table.getIsSomeRowsSelected(),
}: SelectColumnHeaderProps<TData, TValue>) {
  const headerRef = useRef<HTMLDivElement>(null);

  return (
    <Styled.ColumnMenuHeader ref={headerRef}>
      <RawCheckbox
        type="checkbox"
        indeterminate={isIndeterminate}
        checked={isChecked}
        onChange={onChange()}
      />
    </Styled.ColumnMenuHeader>
  );
}
