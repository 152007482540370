import { embed } from 'pdfobject';

export default function showPdf(url: string, height: string, fallbackLink: string) {
  if (!url) return;
  /* anchor to the pdf-preview-wrapper id */
  const pdfPreviewWrapper = document.querySelector('#pdf-preview-wrapper');
  if (pdfPreviewWrapper) {
    pdfPreviewWrapper.innerHTML = '<div id="pdf-preview"></div>';
    /* embed the PDF inside the pdfPreviewWrapper */
    embed(url, '#pdf-preview', { height, fallbackLink });
  }
}
