import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const braintreeClientTokenQuery = gql(/* GraphQL */ `
  query BraintreeClientTokenQuery {
    braintreeClientToken
  }
`);

export const useBraintreeClientTokenQuery = () => useQuery(braintreeClientTokenQuery);
