import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../../styles/borders';
import { MEDIA_QUERY } from '../../../styles/breakpoints';
import { GREYSCALE, COLOR } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import { TYPOGRAPHY } from '../../../styles/typography';
import 'react-date-range/dist/styles.css';
import withOpacity from '../../../utils/withOpacity';
import Z_INDEX from '../../../styles/zindex';

// this component wraps the react-date-range library components we use
// (both the calendar and the range-picker) and styles the CSS classes

type ReactDateRangeWrapperProps = {
  showPrevMonthIcon: boolean;
  showNextMonthIcon: boolean;
  showMonthPicker: boolean;
  showYearPicker: boolean;
  showTimePicker: boolean;
  hideStaticRanges?: boolean;
  showMonthName: boolean;
  isAbbreviatedTwoMonthDisplay: boolean;
};

export default styled.dialog<ReactDateRangeWrapperProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: ${SPACING.sm} ${SPACING.none} ${SPACING.none};
  padding: 0;
  border: 0;
  background: none;
  z-index: ${Z_INDEX.popover};
  .rdrCalendarWrapper {
    color: ${GREYSCALE.white};
    background-color: ${GREYSCALE.black};
    font-family: ${TYPOGRAPHY.font.primary.fontFamily};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    padding-bottom: ${(props) =>
      props.showMonthPicker || props.showYearPicker ? ` ${SPACING.sm}` : ` ${SPACING.none}`};
    border-top-left-radius: ${BORDER_RADIUS.sm};
    border-top-right-radius: ${BORDER_RADIUS.sm};
    border-bottom-left-radius: ${(props) =>
      props.showTimePicker ? `${BORDER_RADIUS.none}` : `${BORDER_RADIUS.sm}`};
    border-bottom-right-radius: ${(props) =>
      props.showTimePicker ? `${BORDER_RADIUS.none}` : `${BORDER_RADIUS.sm}`};
    max-width: 300px;
  }
  .rdrDateDisplayWrapper {
    background-color: ${GREYSCALE.black};
  }
  .rdrDateDisplay {
    margin: ${SPACING.sm};
  }
  .rdrDateDisplayItem {
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
    border: 1px solid transparent;
    input {
      cursor: pointer;
      height: 2.5em;
      line-height: 2.5em;
      border: 0px;
      background: transparent;
      width: 100%;
      color: #f80763;
    }
  }
  .rdrDateDisplayItemActive {
    border-color: currentColor;
  }
  .rdrDateDisplayItemActive {
    input {
      color: ${GREYSCALE.grey30};
    }
  }
  .rdrMonthAndYearWrapper {
    align-items: center;
    justify-content: center;
    height: ${(props) =>
      props.showMonthPicker ||
      props.showYearPicker ||
      props.showNextMonthIcon ||
      props.showPrevMonthIcon
        ? 'auto'
        : '0'}px;
    padding: ${(props) =>
      props.showYearPicker || props.showMonthPicker ? `${SPACING.md} 10px` : `${SPACING.sm} 10px`};
  }
  .rdrMonthAndYearPickers {
    font-weight: ${TYPOGRAPHY.fontWeight.regular};
    font-size: ${TYPOGRAPHY.fontSize.md};
    select {
      appearance: none;
      -webkit-appearance: none;
      border: 0;
      background: transparent;
      border-radius: ${BORDER_RADIUS.sm};
      outline: 0;
      color: ${GREYSCALE.white};
      background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>")
        no-repeat;
      background-position: right 8px center;
      cursor: pointer;
      &:hover {
        background-color: ${withOpacity(GREYSCALE.black, 0.07)};
      }
    }
  }
  .rdrMonthPicker {
    visibility: ${(props) => (props.showMonthPicker ? 'visible' : 'hidden')};
    padding: ${SPACING.none} ${SPACING.md};
  }
  .rdrYearPicker {
    visibility: ${(props) => (props.showYearPicker ? 'visible' : 'hidden')};
    padding: ${SPACING.none} ${SPACING.md};
  }
  .rdrMonthPicker,
  .rdrYearPicker {
    select {
      &:hover {
        color: ${COLOR.blue};
      }
    }
  }
  .rdrNextPrevButton {
    display: block;
    width: 32px;
    height: 32px;
    padding: 0;
    border: 1px solid ${GREYSCALE.black};
    border-radius: ${BORDER_RADIUS.sm};
    background: ${GREYSCALE.grey80};
    &:hover {
      border-color: ${COLOR.blue};
    }
    i {
      display: block;
      width: 0;
      height: 0;
      padding: 0;
      text-align: center;
      border-style: solid;
      margin: auto;
      transform: translate(-3px, 0px);
    }
  }
  .rdrPprevButton {
    visibility: ${(props) => (props.showPrevMonthIcon ? 'visible' : 'hidden')};
    i {
      border-width: 4px 6px 4px 4px;
      border-color: transparent ${GREYSCALE.white} transparent transparent;
      transform: translate(-3px, 0px);
    }
  }
  .rdrNextButton {
    visibility: ${(props) => (props.showNextMonthIcon ? 'visible' : 'hidden')};
    i {
      border-width: 4px 4px 4px 6px;
      border-color: transparent transparent transparent ${GREYSCALE.white};
      transform: translate(3px, 0px);
    }
  }
  .rdrWeekDays {
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    padding: ${SPACING.none} ${SPACING.xs} ${SPACING.sm};
  }
  .rdrMonth {
    .rdrWeekDays {
      padding: 0;
    }
    @media (min-width: ${MEDIA_QUERY.xsMin}) {
      max-width: 300px;
    }
  }
  // UX: hide the first three weeks of current month and last two weeks of next month if the selectable range is <= 7 days and crosses over to the next month
  ${({ isAbbreviatedTwoMonthDisplay }) =>
    isAbbreviatedTwoMonthDisplay &&
    css`
      .rdrMonths.rdrMonthsVertical .rdrMonth:first-of-type .rdrDays .rdrDay:nth-of-type(-n + 21) {
        display: none;
      }
      .rdrMonths.rdrMonthsVertical .rdrMonth:last-of-type .rdrDays .rdrDay:nth-of-type(n + 21) {
        display: none;
      }
    `}
  .rdrMonth {
    height: ${({ isAbbreviatedTwoMonthDisplay }) =>
      isAbbreviatedTwoMonthDisplay ? 'auto !important' : ''};
  }
  .rdrMonths .rdrMonthsVertical .rdrMonth:first-of-type .rdrMonthName {
    display: none;
  }
  .rdrInfiniteMonths {
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
  // Mon Tue Wed Thu Fri Sat
  .rdrWeekDay {
    color: ${GREYSCALE.white};
  }
  .rdrDay {
    background: transparent;
    user-select: none;
    border: 0;
    padding: 0;
    line-height: 3em;
    height: 3em;
    text-align: center;
    &:focus {
      outline: 0;
    }
  }
  .rdrDayNumber {
    outline: 0;
    font-weight: ${TYPOGRAPHY.fontWeight.light};
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rdrDayToday .rdrDayNumber span {
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
    &:after {
      content: '';
      position: absolute;
      bottom: 4px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 18px;
      height: 2px;
      border-radius: ${BORDER_RADIUS.sm};
      background: ${GREYSCALE.white};
    }
  }
  .rdrDayToday:not(.rdrDayPassive) {
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected {
      & ~ .rdrDayNumber span:after {
        background: #fff;
      }
    }
  }
  .rdrDay:not(.rdrDayPassive) {
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected {
      & ~ .rdrDayNumber {
        span {
          color: ${GREYSCALE.white};
          font-weight: ${TYPOGRAPHY.fontWeight.bold};
        }
      }
    }
  }
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background: ${COLOR.blue};
    position: absolute;
    top: 4px;
    left: 0;
    right: 0;
    bottom: 4px;
  }
  .rdrSelected {
    left: 0px;
    right: 0px;
  }
  .rdrInRange {
  }
  .rdrStartEdge {
    border-top-left-radius: ${BORDER_RADIUS.sm};
    border-bottom-left-radius: ${BORDER_RADIUS.sm};
    left: 2px;
  }
  .rdrEndEdge {
    border-top-right-radius: ${BORDER_RADIUS.sm};
    border-bottom-right-radius: ${BORDER_RADIUS.sm};
    right: 2px;
  }
  .rdrSelected {
    border-radius: ${BORDER_RADIUS.sm};
  }
  .rdrDays {
    padding: ${SPACING.none} ${SPACING.xs};
  }
  .rdrDayStartOfMonth,
  .rdrDayStartOfWeek {
    .rdrInRange,
    .rdrEndEdge {
      border-top-left-radius: ${BORDER_RADIUS.sm};
      border-bottom-left-radius: ${BORDER_RADIUS.sm};
      left: 2px;
    }
  }
  .rdrDayEndOfMonth,
  .rdrDayEndOfWeek {
    .rdrInRange,
    .rdrStartEdge {
      border-top-right-radius: ${BORDER_RADIUS.sm}0;
      border-bottom-right-radius: ${BORDER_RADIUS.sm};
      right: 2px;
    }
  }
  .rdrDayStartOfMonth,
  .rdrDayStartOfWeek {
    .rdrDayInPreview,
    .rdrDayEndPreview {
      border-top-left-radius: ${BORDER_RADIUS.sm};
      border-bottom-left-radius: ${BORDER_RADIUS.sm};
      border-left-width: 0px;
      left: 0px;
    }
  }
  .rdrDayEndOfMonth,
  .rdrDayEndOfWeek {
    .rdrDayInPreview,
    .rdrDayStartPreview {
      border-top-right-radius: ${BORDER_RADIUS.sm};
      border-bottom-right-radius: ${BORDER_RADIUS.sm};
      border-right-width: 0px;
      right: 0px;
    }
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    background: ${withOpacity(GREYSCALE.white, 0.9)};
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    border: 0px solid transparent;
    z-index: 1;
  }
  .rdrDayStartPreview {
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: ${BORDER_RADIUS.sm};
    border-bottom-left-radius: ${BORDER_RADIUS.sm};
    left: 0px;
  }
  .rdrDayInPreview {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  .rdrDayEndPreview {
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: ${BORDER_RADIUS.sm};
    border-bottom-right-radius: ${BORDER_RADIUS.sm};
    right: 2px;
    right: 0px;
  }
  .rdrDayNumber span {
    color: ${GREYSCALE.white};
  }
  .rdrStaticRangeSelected {
    .rdrStaticRangeLabel {
      color: ${COLOR.blue};
    }
  }
  .rdrStaticRanges {
    font-size: 12px;
    width: 226px;
    padding-right: ${({ hideStaticRanges }) => (hideStaticRanges ? '0px' : '1px')};
    display: ${({ hideStaticRanges }) => (hideStaticRanges ? 'none' : 'auto')};
    background: #fff;
    .rdrStaticRangeSelected {
      color: ${COLOR.blue};
      font-weight: ${TYPOGRAPHY.fontWeight.bold};
    }
  }
  .rdrStaticRange {
    border: 0;
    cursor: pointer;
    display: block;
    outline: 0;
    border-bottom: 1px solid ${GREYSCALE.grey50};
    color: ${GREYSCALE.white};
    font-family: ${TYPOGRAPHY.font.primary.fontFamily};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    padding: 0;
    background: ${GREYSCALE.black};
    &:hover {
      .rdrStaticRangeLabel {
        background: ${COLOR.blue};
        color: ${GREYSCALE.white};
      }
    }
    &:focus {
      .rdrStaticRangeLabel {
        background: ${COLOR.blue};
        color: ${GREYSCALE.white};
      }
    }
    :first-of-type {
      border-radius: ${BORDER_RADIUS.sm} ${BORDER_RADIUS.sm} 0 0;
      .rdrStaticRangeLabel {
        border-radius: ${BORDER_RADIUS.sm} ${BORDER_RADIUS.sm} 0 0;
      }
    }
    :last-child {
      border-radius: 0 0 ${BORDER_RADIUS.sm} ${BORDER_RADIUS.sm};
      .rdrStaticRangeLabel {
        border-radius: 0 0 ${BORDER_RADIUS.sm} ${BORDER_RADIUS.sm};
      }
    }
  }
  .rdrStaticRangeLabel {
    display: block;
    outline: 0;
    line-height: 18px;
    padding: 10px 20px;
    text-align: left;
  }
  .rdrInputRanges {
    padding: 10px 0;
    display: none;
  }
  .rdrInputRange {
    align-items: center;
    padding: 5px 20px;
  }
  .rdrInputRangeInput {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: ${BORDER_RADIUS.sm};
    text-align: center;
    border: solid 1px rgb(222, 231, 235);
    margin-right: 10px;
    color: rgb(108, 118, 122);
    &:focus,
    &:hover {
      border-color: rgb(0, 135, 197);
      outline: 0;
      color: #333;
    }
  }
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
    content: '';
    border: ${BORDER_WIDTH.xs} solid ${COLOR.blue};
    border-radius: ${BORDER_RADIUS.sm};
    position: absolute;
    top: ${SPACING.none};
    bottom: ${SPACING.none};
    left: ${SPACING.none};
    right: ${SPACING.none};
    margin: -${BORDER_WIDTH.xs} ${SPACING.none};
    background: transparent;
  }
  .rdrDayPassive {
    pointer-events: none;
    .rdrDayNumber span {
      color: ${GREYSCALE.black};
    }
    .rdrDayNumber span:after {
      color: ${GREYSCALE.black};
      background-color: ${GREYSCALE.black};
    }
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected,
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
      display: none;
    }
  }
  .rdrDayDisabled {
    background-color: ${GREYSCALE.black};
    border-radius: ${(props) =>
      props.showTimePicker ? `${BORDER_RADIUS.none}` : `${BORDER_RADIUS.sm}`};
    .rdrDayNumber span {
      color: ${GREYSCALE.grey80};
    }
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected,
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
      filter: grayscale(100%) opacity(60%);
    }
  }
  .rdrDayPassive.rdrDayDisabled {
    .rdrDayNumber span {
      background-color: ${GREYSCALE.black};
      color: ${GREYSCALE.black};
    }
  }
  .rdrMonthName {
    text-align: left;
    font-weight: ${TYPOGRAPHY.fontWeight.bold};
    color: #849095;
    padding: ${SPACING.md};
    display: none;
  }
`;
