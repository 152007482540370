import { useEffect, useState } from 'react';

// a hook that returns the click handler for the hidden batch file input
// in web-client this element sits in the sidebar drawer, in legacy hidden in the top left of the page
export default function useClickUpload(): () => void {
  const [hiddenInputElement, setHiddenInputElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setHiddenInputElement(document.getElementsByName('batch[file]')[0]);
  }, []);
  return hiddenInputElement ? () => hiddenInputElement.click() : () => {};
}
