import { fatalErrorVar } from '../cache';

let timeouts: number[] = [];

// dismiss all messages
export const dismissFatalError = () => {
  fatalErrorVar(null);
};

const addTimeout = (dismissAfterMs: number) => {
  const timeoutId = window.setTimeout(() => {
    dismissFatalError();
    timeouts = timeouts.filter((id) => id !== timeoutId);
  }, dismissAfterMs);

  timeouts.push(timeoutId);
};

// set one message and dismiss all others
export const setFatalError = (error: Error, dismissAfterMs = -1) => {
  fatalErrorVar(error);

  if (dismissAfterMs >= 0) {
    addTimeout(dismissAfterMs);
  }
};
