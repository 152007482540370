import { Dispatch, SetStateAction } from 'react';
import { LinkButton } from '../../Link';

type DetailToggleProps = {
  showPaymentDetails: boolean;
  setShowPaymentDetails: Dispatch<SetStateAction<boolean>>;
  idToControl: string;
};

function DetailToggle({
  showPaymentDetails,
  setShowPaymentDetails,
  idToControl,
}: DetailToggleProps) {
  return (
    <LinkButton
      type="button"
      id="payment-details-toggle"
      aria-controls={idToControl}
      aria-expanded={showPaymentDetails}
      onClick={() => setShowPaymentDetails(!showPaymentDetails)}
    >
      {showPaymentDetails ? <>Hide Payment Details</> : <>Show Payment Details</>}
      <input
        type="checkbox"
        checked={showPaymentDetails}
        onChange={() => setShowPaymentDetails(!showPaymentDetails)}
        name={showPaymentDetails ? 'Hide Payment Details' : 'Show Payment Details'}
        hidden
        style={{ position: 'absolute' }}
      />
    </LinkButton>
  );
}

export default DetailToggle;
