import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const setBatchToNewMutation = gql(/* GraphQL */ `
  mutation SetBatchToNewMutation($id: ID!) {
    setBatchToNew(id: $id) {
      id
    }
  }
`);

export const useSetBatchToNewMutation = () => useMutation(setBatchToNewMutation);
