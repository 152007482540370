import { forwardRef, Ref } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import Link, { LinkProps } from '../../Link';
import menuIconUspsUrl from '../../../images/menu-logo-usps.svg';
import menuIconUrlBox from '../../../images/menu-ship.png';
import menuIconUrlCoins from '../../../images/menu-rates.png';
import menuIconUrlLogbook from '../../../images/menu-reports.png';
import menuIconUrlWheel from '../../../images/menu-settings.png';
import menuIconUrlLifesaver from '../../../images/menu-support.png';
import menuIconUrlPlank from '../../../images/menu-logout.png';
import { SIDEBAR_MENU_ITEM_HEIGHT, SIDEBAR_MENU_ITEM_ICON_SIZE } from '../../../constants';
import { MEDIA_QUERY } from '../../../styles/breakpoints';
import { GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import { TYPOGRAPHY } from '../../../styles/typography';

// TODO why are using width also for height?
const iconSize = SIDEBAR_MENU_ITEM_ICON_SIZE.width;

type MenuLabel = 'USPS' | 'Ship' | 'Rates' | 'Reports' | 'Settings' | 'Support' | 'Logout';

// rebrand icons
const menuIconMap = new Map<MenuLabel, string>([
  ['USPS', menuIconUspsUrl],
  ['Ship', menuIconUrlBox],
  ['Rates', menuIconUrlCoins],
  ['Reports', menuIconUrlLogbook],
  ['Settings', menuIconUrlWheel],
  ['Support', menuIconUrlLifesaver],
  ['Logout', menuIconUrlPlank],
]);

export const StyledMenuItem = styled(Link)<{ selected: boolean }>`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: ${SIDEBAR_MENU_ITEM_HEIGHT};
  cursor: pointer;
  font-size: ${TYPOGRAPHY.fontSize.xl};
  font-weight: ${TYPOGRAPHY.fontWeight.bold};
  color: ${GREYSCALE.grey50};
  padding-left: ${SPACING.lg};
  filter: grayscale(100%);

  // checks for any hover or pointer (e.g. mouse). Disables hovering styles for touch devices, which caused issues for left hand people scrolling the app
  // https://googlechrome.github.io/samples/media-hover-pointer/
  @media (any-pointer: fine) {
    &:hover {
      filter: none;
      color: ${GREYSCALE.black};
    }
  }
  &:focus {
    filter: none;
    color: ${GREYSCALE.black};
  }

  ${({ selected }) =>
    selected &&
    `
    filter: none;
    color: ${GREYSCALE.black};
    background-color: ${GREYSCALE.white};
`}
  @media (max-width: ${MEDIA_QUERY.xsMax}) {
    padding-left: 10px;
  }
`;

export const StyledMenuIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledMenuLabel = styled.div`
  padding-left: ${SPACING.lg};
  padding-top: ${SPACING.none};
`;

const Styled = {
  MenuItemWrapper: styled.div``,
  MenuIcon: styled.img`
    width: ${iconSize};
    height: ${iconSize};
  `,
};

type SidebarMenuItemProps = {
  label: MenuLabel;
  selectedRoutes?: Array<string>;
} & LinkProps;

function SidebarMenuItemComponent(
  { label, to, bridgeHref, selectedRoutes, ...others }: SidebarMenuItemProps,
  ref: Ref<HTMLDivElement>,
) {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Styled.MenuItemWrapper ref={ref}>
      <StyledMenuItem
        selected={!selectedRoutes ? false : selectedRoutes.some((a) => pathname.indexOf(a) === 0)}
        to={to}
        bridgeHref={bridgeHref}
        {...others}
      >
        <StyledMenuIconWrapper>
          <Styled.MenuIcon src={menuIconMap.get(label)} />
        </StyledMenuIconWrapper>
        <StyledMenuLabel>{label}</StyledMenuLabel>
      </StyledMenuItem>
    </Styled.MenuItemWrapper>
  );
}
const SidebarMenuItem = forwardRef(SidebarMenuItemComponent);
export default SidebarMenuItem;
