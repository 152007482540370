import { FilterTagContext } from '../types';
import useDateInUserTimezone from '../../../hooks/useDateInUserTimezone';
import { DATE_FORMAT } from '../../../constants';

export type DateFilterTagSettings = {};

// this is rendered with the flexRender function
export default function DateTimeFilterTag<TData, TValue>(
  filterTagContext: FilterTagContext<TData, TValue>,
) {
  const { formatDate } = useDateInUserTimezone();
  const { filterValue } = filterTagContext;
  if (!filterValue) return '';
  return formatDate('UTC', filterValue, DATE_FORMAT.usDate);
}
