import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const paymentModalQuery = gql(/* GraphQL */ `
  query PaymentModalQuery {
    chargeAmountOptions
    user {
      id
      email
    }
    company {
      id
      accountBalance
      activePaymentMethods
      features {
        key
        value
      }
    }
    paymentApiMethods {
      ...PaymentApiMethodFragment
    }
  }
`);

export const usePaymentModalQuery = () => useQuery(paymentModalQuery, { partialRefetch: true });
