import { CountryMap } from '../../utils/createCountryMap';
import { Address, addressToLines } from '../../utils/formatAddress';
import Section, { SectionEntries, SHOW_VALUE_ONLY } from './Section';

type ShipFromAddressSectionProps = {
  isWarehouseShy: boolean;
  title: string;
  address: Address;
  countryMap: CountryMap;
};

function ShipFromAddressSection({
  isWarehouseShy,
  title,
  address,
  countryMap,
}: ShipFromAddressSectionProps) {
  const entries: SectionEntries = addressToLines(address, countryMap).map((value) => [
    SHOW_VALUE_ONLY,
    value,
  ]);
  return (
    <Section title={`Ship From Address${isWarehouseShy ? '' : `: ${title}`}`} entries={entries} />
  );
}

export default ShipFromAddressSection;
