import { MEDIA_QUERY } from './breakpoints';
import { SPACING, BASE_SPACE } from './spacing';

const LANDLUBBER_LAYOUT_MAX_WIDTH = '1180px';

export const LANDLUBBER_LAYOUT = {
  maxWidth: LANDLUBBER_LAYOUT_MAX_WIDTH,
  navbar: {
    breakpoint: MEDIA_QUERY.mdMin,
    maxWidth: LANDLUBBER_LAYOUT_MAX_WIDTH,
    height: {
      xs: '64px',
      sm: '72px',
    },
  },
  main: {
    spacing: {
      top: {
        xs: `${BASE_SPACE * 1.5}px`,
        breakpoint: SPACING.xxxl,
      },
      bottom: {
        xs: `${BASE_SPACE * 1.5}px`,
        breakpoint: SPACING.xxxl,
      },
    },
  },
};

export const ON_DECK_LAYOUT = {};
