import styled from '@emotion/styled';
import { ReactNode, useId, useMemo } from 'react';
import { MEDIA_QUERY } from '../../../styles/breakpoints';
import { COLOR, GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import { TYPOGRAPHY } from '../../../styles/typography';
import { addRumCustomAction } from '../../../utils/addRumCustomAttributes';
import formatPaymentSourceTitle from '../../../utils/formatPaymentSourceTitle';
import { FakeLink, LinkButton } from '../../Link';
import Tooltip from '../../form/Tooltip';
import { PurchaseDescriptionSubLabel, PurchaseNumberFormat } from '../../layout/PurchaseGrid';
import { PaymentSources } from '../FinishPurchaseSubform';

type FinishPurchaseBottomRowProps = {
  hasPaymentSource: boolean;
  toogleBtnComponent: ReactNode | ReactNode[];
  addPaymentMethodHandler: () => void;
  accountBalanceErrorMessage: string | undefined;
  selectedPaymentSource: PaymentSources[number];
  totalCharge: number;
  totalCost: number;
};

type FinishPurchasePromoProps = {
  addPaymentMethodHandler: () => void;
};

const Styled = {
  Span: styled.span`
    padding-right: ${SPACING.sm};
  `,
  PromoFlexLine: styled.div`
    display: flex;
    align-items: center;
    gap: ${SPACING.sm};
    @media (max-width: ${MEDIA_QUERY.smMax}) {
      gap: ${SPACING.md};
    }
  `,
  PromoLineEmoji: styled.span`
    font-size: ${TYPOGRAPHY.fontSize.xl};
    margin: calc(${TYPOGRAPHY.fontSize.sm} - ${TYPOGRAPHY.fontSize.xl}) 0;
  `,
  PromoLink: styled.a`
    color: ${GREYSCALE.grey50};
    &:hover,
    &:active {
      color: ${COLOR.darkBlue};
    }
    svg {
      margin-inline: 0.4em;
      margin-bottom: -2px;
    }
  `,
};

export function FinishPurchasePromo({ addPaymentMethodHandler }: FinishPurchasePromoProps) {
  const tooltipId = useId();

  useMemo(() => {
    addRumCustomAction('plaid_3%_promo_display', { event: 'plaid_3%_promo_display' });
  }, []);

  const svgPath = (
    <path
      fill="currentColor"
      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
    />
  );
  return (
    <Styled.PromoFlexLine>
      <Styled.PromoLineEmoji>😀</Styled.PromoLineEmoji>
      <span data-testid="promo-line">
        Save 3% on your first Pay by Bank payment
        <Styled.PromoLink
          id={tooltipId}
          href="https://plaid.com/promo-details/"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            aria-label="Click to find out more about the Plaid promotion. Opens in new tab."
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            height="14"
            viewBox="0 0 512 512"
          >
            {svgPath}
          </svg>
          <Tooltip anchorSelector={`#${CSS.escape(tooltipId)}`}>
            Click
            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 512 512">
              {svgPath}
            </svg>
            to find out more
          </Tooltip>
        </Styled.PromoLink>
        <LinkButton onClick={addPaymentMethodHandler} data-dd-action-name="plaid_3%_promo_click">
          Change payment method
        </LinkButton>
      </span>
    </Styled.PromoFlexLine>
  );
}

export default function FinishPurchaseBottomRow({
  hasPaymentSource,
  addPaymentMethodHandler,
  toogleBtnComponent,
  accountBalanceErrorMessage,
  selectedPaymentSource,
  totalCharge,
  totalCost,
}: FinishPurchaseBottomRowProps) {
  // 1. user has no payment method in settings
  if (!hasPaymentSource) {
    return (
      <PurchaseDescriptionSubLabel>
        <Styled.Span data-testid="purchase-row-message">
          You need to add a <FakeLink onClick={addPaymentMethodHandler}>Payment Method</FakeLink>
        </Styled.Span>{' '}
        {toogleBtnComponent}
      </PurchaseDescriptionSubLabel>
    );
  }

  // 2. account balance error message is present
  if (accountBalanceErrorMessage !== undefined) {
    return (
      <PurchaseDescriptionSubLabel isError>
        <Styled.Span data-testid="purchase-row-message">{accountBalanceErrorMessage}</Styled.Span>
        {toogleBtnComponent}
      </PurchaseDescriptionSubLabel>
    );
  }

  // 3. Rating failed & no account balance error message
  if (totalCost === 0) {
    return (
      <PurchaseDescriptionSubLabel data-testid="purchase-row-message">
        {toogleBtnComponent}
      </PurchaseDescriptionSubLabel>
    );
  }

  // 4. user has payment method but totalcharge is 0
  if (totalCharge === 0) {
    return (
      <PurchaseDescriptionSubLabel>
        <span data-testid="purchase-row-message">
          We&apos;re going to deduct <PurchaseNumberFormat value={totalCost} /> from your account
          balance
        </span>{' '}
        {toogleBtnComponent}
      </PurchaseDescriptionSubLabel>
    );
  }

  // 5. standard case
  return (
    <PurchaseDescriptionSubLabel>
      <Styled.Span data-testid="purchase-row-message">
        You&apos;re going to be charged <PurchaseNumberFormat value={totalCharge} /> via{' '}
        {formatPaymentSourceTitle(selectedPaymentSource)}
      </Styled.Span>
      {toogleBtnComponent}
    </PurchaseDescriptionSubLabel>
  );
}
