import { useQuery } from '@apollo/client';
import { gql } from '../../gql';
import { CarrierKey } from '../../gql/graphql';

export const preferredMerchantAccountAddress = gql(/* GraphQL */ `
  query PreferredMerchantAccountAddress($carrierKey: CarrierKey!) {
    company {
      id
      preferredMerchantAccountAddress(carrierKey: $carrierKey) {
        id
        company
        fullName
        address1
        address2
        city
        postcode
        countryCode
        regionCode
        valid
        phone
      }
    }
  }
`);

export const usePreferredMerchantAccountAddressQuery = (carrierKey: CarrierKey) =>
  useQuery(preferredMerchantAccountAddress, { variables: { carrierKey } });
