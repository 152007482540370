import isEmpty from 'lodash.isempty';
import Section, { SectionEntries, SHOW_VALUE_ONLY } from './Section';

export type OrderItemsSectionProps = {
  hideIf?: boolean;
  orderItems?: readonly {
    quantity: number;
    title: string;
    sku: string;
  }[];
};
function OrderItemsSection({ hideIf, orderItems }: OrderItemsSectionProps) {
  if (!orderItems || isEmpty(orderItems) || hideIf || !orderItems.length) return null;

  const entries: SectionEntries = orderItems.map((item) => [
    SHOW_VALUE_ONLY,
    `${item.quantity}x ${item.title} (${item.sku})`,
  ]);
  return <Section title="Order Items" entries={entries} />;
}
export default OrderItemsSection;
