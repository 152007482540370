import styled from '@emotion/styled';
import format from 'date-fns/format';
import addHours from 'date-fns/addHours';
import subHours from 'date-fns/subHours';
import { ChangeEvent, useEffect, useState } from 'react';
import { BORDER_WIDTH } from '../../../styles/borders';
import { COLOR, GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';
import roundToQuarterHour from '../../../utils/roundToQuarterHour';
import useOffsetDates from '../../../hooks/useOffsetDates';
import { LocalOrUTC } from '../../../constants';
import TimePickerWrapper from './TimePickerSharedComponents';

const Styled = {
  Colon: styled.div`
    color: ${GREYSCALE.white};
  `,
  HoursSelect: styled.select`
    margin: ${SPACING.none} ${SPACING.xs};
  `,
  MinutesSelect: styled.select`
    margin: ${SPACING.none} ${SPACING.xs};
  `,
  AnteMeridiemToggle: styled.div`
    color: ${GREYSCALE.white};
    cursor: pointer;
    width: 30px;
    margin: ${SPACING.none} ${SPACING.xs};
    border-bottom: ${BORDER_WIDTH.sm} dotted ${COLOR.blue};
    &:hover {
      color: ${COLOR.blue};
    }
  `,
};

export type TimePickerProps = {
  value?: Date;
  onChange?: (date: Date) => void;
  localOrUTC: LocalOrUTC;
};

function TimePicker({ value, onChange, localOrUTC, ...others }: TimePickerProps) {
  const { applyOffset, revertOffset } = useOffsetDates(localOrUTC);
  const [selectedDate, setSelectedDate] = useState<Date>(roundToQuarterHour(value)); // this date is the value passed in from above (the true form value)

  // if value changes from above
  useEffect(() => {
    if (value) {
      setSelectedDate(roundToQuarterHour(value));
    }
  }, [applyOffset, value]);

  // NOTE: the external calendar library will show any dates given to it in BROWSER time. We want it to show in USER time.
  // We emulate this behaviour here with the standard format() function so we have one simple implementation.
  // this is why we apply() the correct offset when giving the calendar a date and revert() it when saving the value(s).
  // the form value will still always be in true UTC.
  return (
    // className is defined to prevent style being overwritten in bridge
    <TimePickerWrapper className="timePickerWrapper" {...others}>
      <Styled.HoursSelect
        name="hoursSelect"
        value={format(applyOffset(selectedDate), 'h')}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          const offsetDate = new Date(applyOffset(selectedDate));
          const isAM = format(offsetDate, 'a') === 'AM';
          if (+e.target.value === 12) {
            // offsetDate is already in the correct time
            offsetDate.setHours(isAM ? 0 : 12); // special case: don't flip AM/PM when setting hour to 12
          } else {
            // offsetDate is already in the correct time
            offsetDate.setHours(isAM ? +e.target.value : +e.target.value + 12);
          }
          setSelectedDate(revertOffset(offsetDate));
          onChange?.(revertOffset(offsetDate)); // change the form value via the callback passed in from above (reverting the offset first!)
        }}
      >
        {[12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((h) => (
          <option key={h}>{h}</option>
        ))}
      </Styled.HoursSelect>
      <Styled.Colon>:</Styled.Colon>
      <Styled.MinutesSelect
        name="minutesSelect"
        value={format(applyOffset(selectedDate), 'mm')}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          const newOffsetDate = new Date(applyOffset(selectedDate));
          // Do not use setUTCMinutes here, the applyOffset will apply the correct offset
          newOffsetDate.setMinutes(+e.target.value);
          setSelectedDate(revertOffset(newOffsetDate));
          onChange?.(revertOffset(newOffsetDate)); // change the form value via the callback passed in from above (reverting the offset first!)
        }}
      >
        {['00', '15', '30', '45'].map((m) => (
          <option key={m}>{m}</option>
        ))}
      </Styled.MinutesSelect>
      <Styled.AnteMeridiemToggle
        id="anteMeridiemToggle"
        onClick={() => {
          let newOffsetDate = new Date(applyOffset(selectedDate));
          if (format(newOffsetDate, 'a') === 'AM') {
            newOffsetDate = addHours(newOffsetDate, 12);
          } else {
            newOffsetDate = subHours(newOffsetDate, 12);
          }
          setSelectedDate(revertOffset(newOffsetDate));
          onChange?.(revertOffset(newOffsetDate)); // change the form value via the callback passed in from above (reverting the offset first!)
        }}
      >
        {format(applyOffset(selectedDate), 'a')}
      </Styled.AnteMeridiemToggle>
    </TimePickerWrapper>
  );
}

export default TimePicker;
