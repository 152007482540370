import type { SortingState } from '@tanstack/react-table';

// converts the sorting rules (actually only ever an array of size max 1 as we don't allow multi column sorting)
// to the format that we upload to the backend
export default function convertSortingForQuery(rules: SortingState) {
  return rules.map((rule) => ({
    field: rule.id,
    direction: rule.desc ? 'DESC' : 'ASC',
  }));
}
