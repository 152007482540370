import { useQuery } from '@apollo/client';
import { gql } from '../../gql';

export const countriesQuery = gql(/* GraphQL */ `
  query CountriesQuery {
    countries {
      id
      countryCode
      name
      parentCountryId
    }
  }
`);

export const useCountriesQuery = () => useQuery(countriesQuery);
