import { InputHTMLAttributes, ReactNode } from 'react';
import styled from '@emotion/styled';
import DropdownSelect, {
  DropdownSelectOption,
  StyledDropdownSelectWrapper,
  StyledDropdownSelectTitle,
  StyledDropdownDescription,
} from './DropdownSelect';
import carrierKeyToIconUrl from '../../utils/carrierKeyToIconUrl';
import { formatCurrencyNoFractionDigits, formatCurrency } from '../../utils/currency';
import { GREYSCALE } from '../../styles/colors';
import { FakeLink } from '../Link';
import upsLogoUrl from '../../images/ups-logo.svg';
import Badge, { BadgeSelector } from '../Badge';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../styles/borders';
import { TYPOGRAPHY } from '../../styles/typography';
import { SPACING } from '../../styles/spacing';
import useIntercomArticle from '../../hooks/useIntercomArticle';
import { IntercomArticleIconLink } from '../IntercomArticleLink';
import CrossedOutPrice from '../rates/CrossedOutPrice';
import { CarrierKey, RateGroupTrait } from '../../gql/graphql';
import convertBbcode from '../../utils/convertBbcode';
import CarrierLogo from '../rates/CarrierLogo';
import { CarrierInfoOption } from '../../constants';

const SummaryPrice = styled.div`
  color: ${GREYSCALE.black};
  font-size: 27px;
  line-height: 27px;
  font-weight: ${TYPOGRAPHY.fontWeight.bold};
`;

const Styled = {
  RateGroupSelectWrapper: styled.div`
    ${StyledDropdownSelectWrapper} {
      border-radius: ${BORDER_RADIUS.sm} ${BORDER_RADIUS.sm} ${BORDER_RADIUS.none}
        ${BORDER_RADIUS.none};
    }
  `,
  UpsAccountDropdownOption: styled.div`
    padding: ${SPACING.md} 0;
  `,
  SummaryWrapper: styled.div<SummeryWrapperProps>`
    display: flex;
    flex: 1;
    justify-content: space-between;
  `,
  SummaryContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: ${SPACING.md};
    /* flex-grow: 4; */
  `,
  SummaryHeaderWrapper: styled.div<SummaryHeaderWrapperProps>`
    display: flex;
    align-items: start;
    line-height: 24px;

    ${CarrierLogo} {
      height: ${({ carrierKey }) => {
        switch (carrierKey) {
          case 'ups':
            return '22px';

          default:
            return '16px';
        }
      }};
      margin: ${({ carrierKey }) => {
        switch (carrierKey) {
          case 'ups':
            return '0px 4px 0 0;';

          default:
            return '3px 4px 0 0;';
        }
      }};
    }
  `,
  SummaryMainWrapper: styled.div`
    margin: 7px 0 9px 0; // more space under text to distinguish footer text if line breaks
    line-height: 20px; // consistent line-heights
    ${BadgeSelector} {
      margin-top: -2px;
    }
  `,
  SummaryFooterWrapper: styled.div`
    display: flex;
    ${StyledDropdownDescription} {
      line-height: 20px; // consistent line-heights
    }
  `,
  CarrierNotAvailableOption: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    margin-right: -45px; // this offsets and recenters the padding-right in DropdownSelect's StyledDropdownOption
  `,
  CarrierNotAvailableOptionText: styled.span`
    margin-top: ${SPACING.sm};
  `,
  SummaryPriceWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: ${SPACING.xs};
  `,
  SummaryPrice,
  SurchargePrice: styled.div`
    align-self: flex-end;
    color: ${GREYSCALE.black};
  `,
  SurchargePriceWrapper: styled.div`
    display: flex;
    padding: ${SPACING.md};
    justify-content: space-between;
    border: solid;
    border-color: ${GREYSCALE.grey30};
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs};
  `,
  SummaryBreakdownWrapper: styled.div`
    color: ${GREYSCALE.grey60};
    font-size: ${TYPOGRAPHY.fontSize.xs};
    /* line-height: 1em; */
    font-weight: ${TYPOGRAPHY.fontSize.sm};
  `,
  SummaryBreakdownPriceWrapper: styled.div`
    display: flex;
    padding: ${SPACING.md};
    justify-content: space-between;
    border: solid;
    border-color: ${GREYSCALE.grey30};
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs};
  `,
  SummaryBreakdownPrice: styled.div`
    align-self: flex-end;
    color: ${GREYSCALE.black};
  `,
  SummaryPriceCrossedOut: styled(StyledDropdownDescription)`
    text-decoration: line-through;
    white-space: nowrap;
  `,
  SummaryPriceNotAvailable: styled(SummaryPrice)`
    font-size: ${TYPOGRAPHY.fontSize.md};
  `,
  CompletePriceWrapper: styled.div<CompletePriceWrapperProps>`
    display: flex;
    justify-content: space-between;
    padding: ${SPACING.md};
    background-color: ${GREYSCALE.grey10};
    font-size: ${TYPOGRAPHY.fontSize.sm};
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
    border: solid;
    color: ${GREYSCALE.black};
    border-color: ${GREYSCALE.grey30};
    border-width: ${BORDER_WIDTH.none} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs} ${BORDER_WIDTH.xs};
    border-top-right-radius: ${BORDER_RADIUS.none};
    border-top-left-radius: ${BORDER_RADIUS.none};
    border-bottom-left-radius: ${BORDER_RADIUS.sm};
    border-bottom-right-radius: ${({ hasUpsell }) =>
      hasUpsell ? `${BORDER_RADIUS.none}` : `${BORDER_RADIUS.sm} !important`};
  `,
  UpsLogo: styled.img`
    height: 22px;
    margin: 4px 4px 5px;
  `,
  UpsAccountHeadlineWrapper: styled.div`
    display: flex;
    align-self: baseline;
    align-items: center;
    justify-content: center;
  `,
  UpsAccountHeadline: styled.div`
    padding-left: 4px;
  `,
  UpsAccountDescription: styled.div`
    font-size: ${TYPOGRAPHY.fontSize.sm};
    color: ${GREYSCALE.grey50};
    text-align: center;
  `,
};

type SummeryWrapperProps = {
  disabled: boolean;
};

type SummaryHeaderWrapperProps = {
  carrierKey: string;
};

type CompletePriceWrapperProps = {
  hasUpsell: boolean;
};

export type RateSummarySurcharge = {
  readonly surchargeKey: string;
  readonly title: string;
  readonly price: number;
  readonly helpLink: string | null;
  readonly crossedPrice?: number | null;
};

export type RateSummary = {
  readonly mailClassTitle: string;
  readonly serviceTitle: string;
  readonly firstMailClass: {
    readonly __typename: 'CarrierMailClass';
    readonly mailClassKey: string;
  };
  readonly cheapest: boolean;
  readonly best: boolean;
  readonly fastest: boolean;
  readonly carrier: {
    readonly __typename: 'Carrier';
    readonly carrierKey: CarrierKey;
    readonly title: string;
  };
  readonly basePrice: number;
  readonly averageBasePrice: number;
  readonly crossedTotalPrice: number;
  readonly averageTotalPrice: number;
  readonly totalPrice: number;
  readonly flatPrice: boolean;
  readonly ratePeriodEndDate: string | null;
  readonly ratePeriodStartDate: string | null;
  readonly errorMessage: string | null;
  readonly savings: string;
  readonly shipmentCount: number;
  readonly surcharges: ReadonlyArray<RateSummarySurcharge>;
  readonly uniqueId: string;
  readonly firstZone: string;
  readonly maxWeightOz: number;
  readonly upsellRateSummary: {
    readonly __typename: 'RateSummary';
    readonly carrier: {
      readonly __typename: 'Carrier';
      readonly carrierKey: CarrierKey;
      readonly title: string;
    };
    readonly firstMailClass: {
      readonly __typename: 'CarrierMailClass';
      readonly mailClassKey: string;
    };
    readonly mailClassTitle: string;
    readonly totalPrice: number;
    readonly uniqueId: string;
  } | null;
};

export type RateGroup = {
  readonly maximumShipments: number;
  readonly numberOfCheapestOrBestServices: number;
  readonly affectedByUpsRateLimit: boolean;
  readonly rateSummaries: ReadonlyArray<RateSummary>;
  readonly groupKey: {
    readonly __typename: 'RateGroupKey';
    readonly traits: ReadonlyArray<RateGroupTrait>;
    readonly string: string;
  };
};

export type RateSummarySelectProps = Omit<InputHTMLAttributes<HTMLSelectElement>, 'onChange'> & {
  rateGroup: RateGroup;
  onChange?: (value: string) => void;
  onRequestUpsMerchantCreation?: () => void;
  options: DropdownSelectOption<string, RateSummary>[];
  hasUpsell: boolean;
};

export default function RateSummarySelect(props: RateSummarySelectProps) {
  const openIntercomArticle = useIntercomArticle();
  const {
    rateGroup: { groupKey, maximumShipments: shipmentsCount, numberOfCheapestOrBestServices },
    disabled,
    value, // the uniqueId of the selected RateSummary
    onChange,
    onRequestUpsMerchantCreation,
    options,
    hasUpsell,
  } = props;
  const selectedRateSummary = options.find((option) => option.value === value)?.data;

  const medianDescription = selectedRateSummary?.flatPrice ? 'each' : 'average';
  const hasMultipleShipments = shipmentsCount > 1;

  const handleOnChange = (val: string) => {
    if (val === CarrierInfoOption.UpsRateLimited) {
      if (onRequestUpsMerchantCreation) {
        onRequestUpsMerchantCreation();
      }
    } else if (val === CarrierInfoOption.NoUps || val === CarrierInfoOption.NoUsps) {
      const href =
        'http://support.pirateship.com/en/articles/5594479-why-isn-t-the-carrier-i-want-to-use-available-for-my-shipment';
      openIntercomArticle(href);
    } else if (onChange) {
      const newSelectedRateSummary = options.find((option) => option.data?.uniqueId === val)?.data;
      if (newSelectedRateSummary !== undefined && newSelectedRateSummary.totalPrice > 0) {
        onChange(newSelectedRateSummary.uniqueId);
      }
    }
  };

  const renderOption = (option: DropdownSelectOption<string, RateSummary>): ReactNode => {
    if (option.value === CarrierInfoOption.UpsRateLimited) {
      return (
        <Styled.UpsAccountDropdownOption>
          <Styled.UpsAccountHeadlineWrapper>
            <Styled.UpsLogo src={upsLogoUrl} alt="UPS Logo" />
            <Styled.UpsAccountHeadline>To see more UPS rates,</Styled.UpsAccountHeadline>
            <FakeLink onClick={onRequestUpsMerchantCreation}>
              <Styled.UpsAccountHeadline>
                click here to agree to the UPS terms
              </Styled.UpsAccountHeadline>
            </FakeLink>
          </Styled.UpsAccountHeadlineWrapper>
          <Styled.UpsAccountDescription>
            Save up to 76% - Instant access
          </Styled.UpsAccountDescription>
        </Styled.UpsAccountDropdownOption>
      );
    }
    if (option.value === CarrierInfoOption.NoUps || option.value === CarrierInfoOption.NoUsps) {
      const [, carrierKey] = option.value.split('-');
      return (
        <Styled.CarrierNotAvailableOption>
          <CarrierLogo src={carrierKeyToIconUrl(carrierKey, '')} />
          <Styled.CarrierNotAvailableOptionText>
            {`${carrierKey.toUpperCase()} is not available for this shipment. `}
            <FakeLink>Why not?</FakeLink>
          </Styled.CarrierNotAvailableOptionText>
        </Styled.CarrierNotAvailableOption>
      );
    }

    // get entire rate summary object from the value of its corresponding option
    const rateSummary = option.data;

    if (!rateSummary) {
      return null;
    }

    const {
      mailClassTitle,
      firstMailClass,
      cheapest,
      best,
      carrier,
      savings,
      crossedTotalPrice,
      averageTotalPrice,
      totalPrice,
      fastest,
      serviceTitle,
      ratePeriodEndDate,
      ratePeriodStartDate,
      shipmentCount,
      firstZone,
    } = rateSummary;

    const isOptionDisabled = totalPrice === 0;

    const renderBadge = () => {
      if (options.length <= 1 || isOptionDisabled) {
        return null;
      }
      if (best) {
        return <Badge variant="best">Best</Badge>;
      }
      if (best && cheapest && numberOfCheapestOrBestServices > 1) {
        return <Badge variant="best">Best</Badge>;
      }
      if (fastest) {
        return <Badge variant="fastest">Fastest</Badge>;
      }
      if (cheapest) {
        return <Badge variant="cheapest">Cheapest</Badge>;
      }
      return null;
    };

    return (
      <Styled.SummaryWrapper
        className={`rate[${groupKey.string}]`}
        disabled={isOptionDisabled}
        data-rateperiodstartdate={ratePeriodStartDate}
        data-rateperiodenddate={ratePeriodEndDate}
      >
        <Styled.SummaryContentWrapper>
          <Styled.SummaryHeaderWrapper carrierKey={carrier.carrierKey}>
            <CarrierLogo
              src={carrierKeyToIconUrl(carrier.carrierKey, firstMailClass.mailClassKey)}
              alt={carrier.carrierKey}
            />
            <StyledDropdownSelectTitle>{mailClassTitle}</StyledDropdownSelectTitle>
          </Styled.SummaryHeaderWrapper>
          <Styled.SummaryMainWrapper>
            {renderBadge()}
            {!isOptionDisabled && (
              <StyledDropdownDescription>
                {carrier.carrierKey === 'usps' && shipmentCount === 1 ? `Zone ${firstZone} • ` : ''}
                {convertBbcode(serviceTitle)}
              </StyledDropdownDescription>
            )}
          </Styled.SummaryMainWrapper>
          <Styled.SummaryFooterWrapper>
            <StyledDropdownDescription>
              {isOptionDisabled ? 'Not available for all addresses in your batch' : savings}
            </StyledDropdownDescription>
          </Styled.SummaryFooterWrapper>
        </Styled.SummaryContentWrapper>
        {!isOptionDisabled && (
          <Styled.SummaryPriceWrapper>
            {crossedTotalPrice !== averageTotalPrice && (
              <Styled.SummaryPriceCrossedOut>
                {formatCurrency(crossedTotalPrice)} retail
              </Styled.SummaryPriceCrossedOut>
            )}
            <Styled.SummaryPrice>{formatCurrency(averageTotalPrice)}</Styled.SummaryPrice>
            {hasMultipleShipments && (
              <StyledDropdownDescription>{medianDescription}</StyledDropdownDescription>
            )}
          </Styled.SummaryPriceWrapper>
        )}
        {isOptionDisabled && (
          <Styled.SummaryPriceWrapper>
            <Styled.SummaryPriceNotAvailable>Not Available</Styled.SummaryPriceNotAvailable>
          </Styled.SummaryPriceWrapper>
        )}
      </Styled.SummaryWrapper>
    );
  };

  const directionLayer = groupKey.traits.find(
    (trait: RateGroupTrait) => trait.layer === 'Direction',
  );
  const isReturnLabel = directionLayer?.value === 'RETURN';

  return (
    <Styled.RateGroupSelectWrapper>
      <DropdownSelect
        name={`rate[${groupKey.string}]`}
        className={`rate rate-${groupKey.string}`}
        onRenderOption={renderOption}
        onChange={(e) => handleOnChange(e as string)}
        value={selectedRateSummary?.uniqueId}
        disabled={disabled}
        options={options}
        datadogAction="Rategroup Dropdown"
      />
      {selectedRateSummary !== undefined && (
        <Styled.SummaryBreakdownWrapper className={`group-prices-${groupKey.string}`}>
          {selectedRateSummary.surcharges.length > 0 && (
            <Styled.SummaryBreakdownPriceWrapper
              className={`mailclass-price-${groupKey.string}`}
              data-testid="mailclass-price-breakdown"
            >
              {shipmentsCount} &times; {selectedRateSummary.mailClassTitle} @{' '}
              {formatCurrency(selectedRateSummary.averageBasePrice)}{' '}
              {hasMultipleShipments && medianDescription}{' '}
              <Styled.SummaryBreakdownPrice className="mailclass-price">
                {formatCurrency(selectedRateSummary.basePrice)}
              </Styled.SummaryBreakdownPrice>
            </Styled.SummaryBreakdownPriceWrapper>
          )}
          {selectedRateSummary.surcharges.map(
            ({ surchargeKey, title, price, helpLink, crossedPrice }) => (
              <Styled.SurchargePriceWrapper
                key={title}
                className={`${surchargeKey}_${groupKey.string}`}
                data-testid="surcharge-price-breakdown"
              >
                <span>
                  {shipmentsCount} &times; {title} @ {formatCurrency(price / shipmentsCount)}{' '}
                  {hasMultipleShipments && 'average'}
                  {helpLink && (
                    <span>
                      <IntercomArticleIconLink url={helpLink} title="Surcharges Help" />
                    </span>
                  )}
                </span>
                <Styled.SurchargePrice className="surcharge_price">
                  {crossedPrice &&
                  surchargeKey.startsWith('usps_holiday_increase_') &&
                  price === 0.0 ? (
                    <CrossedOutPrice compareWithFreePrice crossedOutPrice={crossedPrice} />
                  ) : (
                    formatCurrency(price)
                  )}
                </Styled.SurchargePrice>
              </Styled.SurchargePriceWrapper>
            ),
          )}
          {!isReturnLabel && (
            <Styled.CompletePriceWrapper hasUpsell={hasUpsell}>
              <div>
                {shipmentsCount} {hasMultipleShipments ? 'labels' : 'label'}
              </div>
              {formatCurrency(selectedRateSummary.totalPrice)}
            </Styled.CompletePriceWrapper>
          )}
          {isReturnLabel && (
            <Styled.CompletePriceWrapper
              hasUpsell={hasUpsell}
              className={`return-label-charged-text-${groupKey.string}`}
            >
              <div>
                {shipmentsCount} return {hasMultipleShipments ? 'labels' : 'label'} (will be charged
                when used)
              </div>
              {`${formatCurrencyNoFractionDigits(0)} out of ${formatCurrency(
                selectedRateSummary.totalPrice,
              )} max`}
            </Styled.CompletePriceWrapper>
          )}
        </Styled.SummaryBreakdownWrapper>
      )}
    </Styled.RateGroupSelectWrapper>
  );
}
