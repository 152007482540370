import styled from '@emotion/styled';
import { CellContext } from '@tanstack/react-table';
import { RawCheckbox } from '../../form/Checkbox';

const Styled = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
  `,
};

export default function RowSelectCell<TData, TValue extends unknown>(
  props: CellContext<TData, TValue>,
) {
  const { row } = props;
  return (
    <Styled.Wrapper>
      <RawCheckbox
        type="checkbox"
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()} // may be better to hide checkbox altogether instead of disabling it?
        onChange={row.getToggleSelectedHandler()}
      />
    </Styled.Wrapper>
  );
}
