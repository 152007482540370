import { gql, useMutation } from '@apollo/client';
import { UploadShipmentFileMutation, UploadShipmentFileMutationVariables } from '../../gql/graphql';

export const uploadShipmentFileMutation = gql`
  mutation uploadShipmentFile($file: Upload!) {
    uploadShipmentFile(file: $file) {
      id
      filename
      fileFormat
      uploadStatus
      errorMessage
      suggestedMapping {
        valueOrMappingKey
        shipmentKey
        userFriendlyShipmentKey
      }
    }
  }
`;

export function useUploadShipmentFileMutation() {
  return useMutation<UploadShipmentFileMutation, UploadShipmentFileMutationVariables>(
    uploadShipmentFileMutation,
  );
}
