import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const disputeAdjustmentsMutation = gql(/* GraphQL */ `
  mutation DisputeAdjustmentsMutation($shipmentId: ID!, $disputeReason: String!) {
    disputeAdjustments(shipmentId: $shipmentId, disputeReason: $disputeReason) {
      id
      disputedAt
      disputeStatus
    }
  }
`);

export const useDisputeAdjustmentMutation = () => useMutation(disputeAdjustmentsMutation);
