import styled from '@emotion/styled';
import { MEDIA_QUERY } from '../../../styles/breakpoints';

const Styled = {
  PageSizeInfo: styled.span`
    grid-column-start: pagesizeinfo;
    text-align: right;
    @media (max-width: ${MEDIA_QUERY.mdMax}) {
      margin-right: 0.2em;
    }
  `,
};

export type PageSizeInfoProps = {
  pageSize: number;
  loading: boolean;
  pageIndex: number;
  totalItemCount?: number;
  totalPageCount?: number;
  currentRowCount: number;
  hidePagerInfo?: boolean;
};

export default function PageSizeInfo({
  pageSize,
  loading,
  pageIndex,
  totalItemCount,
  totalPageCount,
  currentRowCount = 0,
  hidePagerInfo = false,
}: PageSizeInfoProps) {
  if (hidePagerInfo) {
    return null;
  }
  return (
    <Styled.PageSizeInfo>
      {(() => {
        if (loading) {
          return 'Loading…';
        }

        const lowerBound = pageIndex * pageSize + 1;
        const upperBound = pageIndex * pageSize + currentRowCount;

        if (upperBound === 0) {
          return 'No items';
        }

        if (totalItemCount) {
          return `${lowerBound} – ${upperBound} of ${totalItemCount} items`;
        }

        if (totalPageCount) {
          return `${lowerBound} – ${upperBound} of ~${totalPageCount * pageSize} items`;
        }

        return `${lowerBound} – ${upperBound}`;
      })()}
    </Styled.PageSizeInfo>
  );
}
