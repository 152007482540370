function delayStringToDuration(defaultTrackingEmailsDelay: string): Duration {
  const delayValue = +(defaultTrackingEmailsDelay.match(/\d+/) || ['0'])[0];
  if (defaultTrackingEmailsDelay.includes('hour')) {
    return { hours: delayValue };
  }
  if (defaultTrackingEmailsDelay.includes('days')) {
    return { days: delayValue };
  }
  return { seconds: 1 };
}

export default delayStringToDuration;
