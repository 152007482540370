import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const cancelEmailNotificationMutation = gql(/* GraphQL */ `
  mutation cancelEmailNotification($batchId: ID!) {
    cancelEmailNotification(id: $batchId) {
      id
      notifyRecipientsDate
      emailNotificationTemplate {
        id
      }
    }
  }
`);

export const useCancelEmailNotificationMutation = () =>
  useMutation(cancelEmailNotificationMutation);
