import { useMemo } from 'react';
import { useLazyQuery, gql as gqlFuncAliasNotCodegenParsed } from '@apollo/client';
import { LazyQueryHookOptions } from '@apollo/client/react/types/types';
import {
  DataGridFilterInput,
  DataGridSortingInput,
  DataGridMetaData,
} from '../../../../gql/graphql';
import constructGridQueryBody from '../../utils/constructGridQueryBody';
import constructGridQueryDefinition from '../../utils/constructGridQueryDefinition';

export interface ConnectedGridRowsData {
  readonly nodes: ReadonlyArray<{}>;
  readonly totalCount: number;
  readonly checkableIds?: string[];
}

export interface ConnectedGridRowsQueryResult {
  readonly __typename: 'DataGridResult';
  readonly id: string;
  readonly rows: ConnectedGridRowsData;
  readonly metaData: DataGridMetaData[];
}
export interface ConnectedGridRowsQuery {
  [key: string]: ConnectedGridRowsQueryResult;
}

// these variables apply to _any_ connected grid query, i.e are not query specific
type GenericConnectedGridRowsQueryVariables = {
  readonly searchTerm?: string | null;
  readonly filters?: ReadonlyArray<DataGridFilterInput> | null;
  readonly sorting?: ReadonlyArray<DataGridSortingInput> | null;
  readonly limit?: number | null;
  readonly offset?: number | null;
};

// utility map type: take properties in an object and map them as readonly (the result being a similar type to the generic variables above)
type ReadonlyPropertyMap<T> = {
  readonly [Property in keyof T]: unknown;
};

// a union of the generic connected grid variables and any extras specifically required by the query
// export type ConnectedGridRowsQueryVariables = ReadonlyPropertyMap<GridQueryVariableWithType> &
//   GenericConnectedGridRowsQueryVariables;
export type ConnectedGridRowsQueryVariables = ReadonlyPropertyMap<Record<string, unknown>> &
  GenericConnectedGridRowsQueryVariables;

// if the named query in the connected grid requires variables to work (like an ID), use this to define the variables name, type and value
export type GridQueryVariable = {
  variableName: string; // the name of the variable
  type: string; // the variable's graphql type, e.g 'String!' or '[DataGridFilterInput!]'
  value: unknown; // the value of the variable for the request, e.g '13'
  forField: string; // which field in the query this variable should be attached to. e.g. 'batch'
};

export type GridQueryVariables = GridQueryVariable[];

// the set of variables with type used in the generic connected data grid query (value not needed here as the values are not parsed)
const genericGridQueryDefinitionVariables = [
  { variableName: 'searchTerm', type: 'String' },
  { variableName: 'filters', type: '[DataGridFilterInput!]' },
  { variableName: 'sorting', type: '[DataGridSortingInput!]' },
  { variableName: 'limit', type: 'Int' },
  { variableName: 'offset', type: 'Int' },
];

const rowsInnerQuery = `rows(
  searchTerm: $searchTerm
  filters: $filters
  sorting: $sorting
  limit: $limit
  offset: $offset
)
{
  totalCount
  nodes
  checkableIds
}
metaData {
  key
  value
}`;

export const makeConnectedGridRowsQuery = (
  queryName: string, // a colon-separated string to the field we need to query to get our grid result. E.g 'reportsGrid' or 'batch:trackingGrid'
  gridQueryVariables?: GridQueryVariables,
) => {
  const queryDefinition = constructGridQueryDefinition([
    ...(gridQueryVariables?.length ? gridQueryVariables : []),
    ...genericGridQueryDefinitionVariables,
  ]);
  return gqlFuncAliasNotCodegenParsed(`
    query ConnectedGridRowsQuery${queryDefinition}
    {
      ${constructGridQueryBody(queryName, gridQueryVariables, rowsInnerQuery)}
    }
  `);
};

export default function useConnectedGridRowsLazyQuery(
  queryName: string,
  gridQueryVariables?: GridQueryVariables,
  queryOptions?: LazyQueryHookOptions<ConnectedGridRowsQuery, ConnectedGridRowsQueryVariables>,
) {
  const query = useMemo(
    () => makeConnectedGridRowsQuery(queryName, gridQueryVariables),
    [queryName, gridQueryVariables],
  );
  return useLazyQuery<ConnectedGridRowsQuery, ConnectedGridRowsQueryVariables>(query, queryOptions);
}
