import Section, { SectionEntries } from './Section';

type ShipmentPassthroughDataSectionProps = {
  hideIf?: boolean;
  passthroughFields: readonly {
    fieldName: string;
    value: string;
  }[];
};
function ShipmentPassthroughDataSection({
  hideIf,
  passthroughFields,
}: ShipmentPassthroughDataSectionProps) {
  const entries: SectionEntries = passthroughFields.map((field) => [field.fieldName, field.value]);
  return <Section title="Passthrough Data" hideIf={hideIf} entries={entries} />;
}

export default ShipmentPassthroughDataSection;
