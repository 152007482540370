import { RateGroup, RateSummary } from '../components/form/RateSummarySelect';

type CarrierMailClass =
  | 'upsNextDayAirEarlyAM'
  | 'upsNextDayAir'
  | 'upsNextDayAirSaver'
  | 'upsSecondDayAirAM'
  | 'upsSecondDayAir'
  | 'upsThreeDaySelect'
  | 'upsStandard'
  | 'upsGroundSaver'
  | 'uspsPriorityExpress'
  | 'uspsGroundAdvantage'
  | 'uspsGroundAdvantageCubic';

const carrierMailClassMap = new Map<CarrierMailClass, string>([
  ['upsNextDayAirEarlyAM', '14'],
  ['upsNextDayAir', '01'],
  ['upsNextDayAirSaver', '13'],
  ['upsSecondDayAirAM', '59'],
  ['upsSecondDayAir', '02'],
  ['upsThreeDaySelect', '12'],
  ['upsStandard', '11'],
  ['upsGroundSaver', '93'],
  ['uspsPriorityExpress', 'PriorityExpress'],
  ['uspsGroundAdvantage', 'GroundAdvantage'],
  ['uspsGroundAdvantageCubic', 'GroundAdvantage_Cubic'],
]);

// given ONE rate summary and the rateGroup it belongs to, determine which OTHER summary
// is the appropriate one to upsell to.
export default function determineUpsellRateSummary(
  rateSummary: RateSummary | undefined,
  rateGroup: RateGroup,
  useCheapest: boolean,
): RateSummary | undefined {
  if (!rateSummary) return undefined;

  // we only want to upsell to the other carrier, so we can filter out all summaries with the same carrier key
  // and invalid rates too (ones with a total price of 0)
  const otherCarrierRateSummaries = rateGroup.rateSummaries
    .filter((rs) => rs.carrier.carrierKey !== rateSummary.carrier.carrierKey)
    .filter((rs) => !!rs.totalPrice);

  // if the rateSummary is any of the UPS rates listed below, upsell to USPS Priority Express
  if (
    [
      carrierMailClassMap.get('upsNextDayAirEarlyAM'),
      carrierMailClassMap.get('upsNextDayAir'),
      carrierMailClassMap.get('upsNextDayAirSaver'),
      carrierMailClassMap.get('upsSecondDayAirAM'),
      carrierMailClassMap.get('upsSecondDayAir'),
    ].includes(rateSummary.firstMailClass.mailClassKey as CarrierMailClass)
  ) {
    const candidate = otherCarrierRateSummaries.find(
      (rs) => rs.firstMailClass.mailClassKey === carrierMailClassMap.get('uspsPriorityExpress'),
    );
    if (candidate) return candidate;
  }

  // conversely, if the rateSummary is USPS Priority Express, upsell to the first available in the following list:
  // UPS_NEXT_DAY_AIR_SAVER > UPS_NEXT_DAY_AIR > UPS_SECOND_DAY_AIR > UPS_THREE_DAY_SELECT
  if (rateSummary.firstMailClass.mailClassKey === carrierMailClassMap.get('uspsPriorityExpress')) {
    const candidate =
      otherCarrierRateSummaries.find(
        (rs) => rs.firstMailClass.mailClassKey === carrierMailClassMap.get('upsNextDayAirSaver'),
      ) ??
      otherCarrierRateSummaries.find(
        (rs) => rs.firstMailClass.mailClassKey === carrierMailClassMap.get('upsNextDayAir'),
      ) ??
      otherCarrierRateSummaries.find(
        (rs) => rs.firstMailClass.mailClassKey === carrierMailClassMap.get('upsSecondDayAir'),
      ) ??
      otherCarrierRateSummaries.find(
        (rs) => rs.firstMailClass.mailClassKey === carrierMailClassMap.get('upsThreeDaySelect'),
      );
    if (candidate) return candidate;
  }

  const otherCarrierRateSummariesAscendingPrice = otherCarrierRateSummaries.sort(
    (rsa, rsb) => rsa.totalPrice - rsb.totalPrice,
  );
  // upsell to other carrier's cheapest rate if "Cheapest" sort order is selected
  if (useCheapest) {
    return otherCarrierRateSummariesAscendingPrice[0];
  }

  // upsell to other carriers cheapest if "Best" is selected, but ignore upsStandard and depending on the weight,
  // also ignore USPS Ground Advantage
  const excludedServices =
    rateSummary.maxWeightOz >= 16.0
      ? [
          carrierMailClassMap.get('uspsGroundAdvantage'),
          carrierMailClassMap.get('uspsGroundAdvantageCubic'),
          carrierMailClassMap.get('upsStandard'),
          carrierMailClassMap.get('upsGroundSaver'),
        ]
      : [carrierMailClassMap.get('upsStandard')];

  return otherCarrierRateSummariesAscendingPrice.find(
    (rs) => !excludedServices.includes(rs.firstMailClass.mailClassKey as CarrierMailClass),
  );
}
