import { useCountriesQuery } from '../operations/queries/countries';
import createCountryMap, { CountryMap } from '../utils/createCountryMap';

export default function useMappedCountries(): CountryMap {
  const { data, loading } = useCountriesQuery();
  if (loading || !data) {
    return {};
  }
  return createCountryMap(data.countries);
}
