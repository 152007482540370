import { useMutation } from '@apollo/client';
import { gql } from '../../gql';
import {
  UpdateDataGridUserSettingsMutation,
  UpdateDataGridUserSettingsMutationVariables,
} from '../../gql/graphql';
import dataGridUserSettings from '../fragments/dataGridResultUserSettingsFragment';

export const updateDataGridUserSettingsMutation = gql(/* GraphQL */ `
  mutation UpdateDataGridUserSettingsMutation($gridId: String!, $settings: DataGridSettingsInput!) {
    updateDataGridUserSettings(gridId: $gridId, settings: $settings) {
      ...DataGridSettingsFragment
    }
  }
`);

export const useUpdateDataGridUserSettingsMutation = (cacheId?: string) =>
  useMutation<UpdateDataGridUserSettingsMutation, UpdateDataGridUserSettingsMutationVariables>(
    updateDataGridUserSettingsMutation,
    {
      update: (cache, { data }) => {
        if (!data) {
          return;
        }

        if (!cacheId) {
          return;
        }

        // Update cached grid result with new settings
        cache.writeFragment({
          id: cacheId,
          fragment: dataGridUserSettings,
          // Required to specify which fragment should be used by name,
          // as Codegen will also inject the DataGridSettingsFragment fragment, I guess
          fragmentName: 'DataGridResultUserSettingsFragment',
          data: {
            __typename: 'DataGridResult',
            userSettings: data.updateDataGridUserSettings,
          },
        });
      },
      // Assume saving will be successful to immediately reflect the changes in cache
      optimisticResponse: ({ settings }) => ({
        updateDataGridUserSettings: {
          __typename: 'DataGridSettings',
          columns: settings.columns.map((col) => ({
            __typename: 'DataGridColumnOverride',
            ...col,
          })),
          columnOrder: settings.columnOrder,
          sorting: settings.sorting.map((rule) => ({
            __typename: 'DataGridSorting',
            ...rule,
          })),
          pageSize: settings.pageSize,
        },
      }),
    },
  );
