import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const addCreditToPaymentSourceMutation = gql(/* GraphQL */ `
  mutation AddCreditToPaymentSource($addCreditAmount: Float!, $paymentSourceId: ID!) {
    addCreditToPaymentSource(addCreditAmount: $addCreditAmount, paymentSourceId: $paymentSourceId) {
      id
      accountBalance
    }
  }
`);

export const useAddCreditToPaymentSource = () => useMutation(addCreditToPaymentSourceMutation);
