import { gql } from '../../gql';

export const mapQuery = gql(/* GraphQL */ `
  query MapQuery($id: ID!, $width: Int!, $height: Int!) {
    shipment(id: $id) {
      id
      map(width: $width, height: $height)
    }
  }
`);
