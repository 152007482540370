import roundFloat from './roundFloat';

/**
 * Convert any string with a number representation (decimal, mixed fraction, etc) to number
 * e.g.
 *  ''      = 0
 * '2 1/4'  = 2.25
 * '2 /4'   = 2.25
 * '2-4/16' = 2.25
 * '5/8'    = 0.63
 * '.63'    = 0.63
 * '12'     = 12
 * '12"'    = 12
 * '12.20'  = 12.2
 * '2.5 1/4'= 2.75
 * Note: this will not work on uninterpretable inputs like 1/3/4 or 2./3 or blah.foo bat/bar
 */
export default function stringToNumber(input: string, maxDP: number = 2): number {
  if (input === '') {
    return 0;
  }
  if (input.indexOf('/') === -1) {
    return roundFloat(parseFloat(input), maxDP); // if no fraction in the string, parse float and round
  }
  const fractionString = input.match(/(\d*)?\/\d*/)!; // extract the fraction part as string
  const [nonFractionString] = input.match(/.*[ -]/)! || ['0']; // extract the non-fraction part as string
  const nonFraction = parseFloat(nonFractionString);
  const [numerator, denominator] = fractionString[0].split('/');
  const fraction = parseInt(numerator || '1') / parseInt(denominator);
  return roundFloat(nonFraction + fraction, maxDP);
}
