import { useNavigate } from 'react-router';
import environment from '../utils/environment';
import navigation from '../utils/navigation';

type NavigateWithBridgeFunction = (to: string, bridgeHref?: string) => void;

export default function useNavigateOrHref(): NavigateWithBridgeFunction {
  const navigate = useNavigate();

  // if only one link is provided, the bridge & react route is identical
  // two links have to be provided, if the react and bridge link is different
  return (to, bridgeHref) => {
    if (environment.isBridge()) {
      navigation.redirect(bridgeHref ?? to);
    } else {
      navigate(to);
    }
  };
}
