import styled from '@emotion/styled';
import { BORDER_WIDTH } from '../../../styles/borders';
import { GREYSCALE, COLOR } from '../../../styles/colors';
import { OPACITY } from '../../../styles/opacities';
import { SPACING } from '../../../styles/spacing';
import { TYPOGRAPHY } from '../../../styles/typography';
import Icon from '../../Icon';

export const DatePickerLinkWrapper = styled.div<{ disabled: boolean }>`
  text-align: right;
  opacity: ${({ disabled }) => (disabled ? OPACITY.disabled : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
`;
export const DatePickerLinkToggle = styled.div<{ name?: string | null; larger?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
  font-size: ${({ larger }) => (larger ? TYPOGRAPHY.fontSize.md : TYPOGRAPHY.fontSize.sm)};
  user-select: none;
`;
export const DatePickerLinkDate = styled.div`
  white-space: nowrap;
`;
export const DatePickerLinkDateAndCaret = styled.div`
  display: flex;
  border-bottom: ${BORDER_WIDTH.sm} dotted;
`;
export const DatePickerLinkCalendarIcon = styled(Icon)`
  font-size: ${TYPOGRAPHY.fontSize.xs};
  padding: ${SPACING.xs} ${SPACING.sm};
  align-self: flex-start;
`;
export const DatePickerLinkCaretIcon = styled(Icon)`
  padding: ${SPACING.none} ${SPACING.sm};
`;
export const DatePickerLinkToggleWrapper = styled.div<{ disabled: boolean }>`
  display: inline-block;
  color: ${({ disabled }) => (disabled ? GREYSCALE.grey50 : COLOR.blue)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
