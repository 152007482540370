import Checkbox from '../../../../form/Checkbox';
import FormControl from '../../../../form/FormControl';

export default function SignupUseOriginAsReturnCheckbox() {
  return (
    <FormControl
      as={Checkbox}
      type="checkbox"
      label="Use this address as the Return Address on my shipping labels"
      name="useOriginAsReturnAddress"
    />
  );
}
