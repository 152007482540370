/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { ApolloError } from '@apollo/client';
import * as yup from 'yup';
import styled from '@emotion/styled';
import PageSubtitle from '../../layout/PageSubtitle';
import { Col, PageContainer, Row } from '../../layout/Grid';
import FormControl from '../../form/FormControl';
import Label from '../../form/Label';
import Select, { Option } from '../../form/Select';
import InputGroup from '../../form/InputGroup';
import TextField from '../../form/TextField';
import ProgressButton from '../../form/ProgressButton';
import { formatCurrency } from '../../../utils/currency';
import { billingPageQuery, useBillingPageQuery } from '../../../operations/queries/billingPage';
import { useAddCreditToPaymentSource } from '../../../operations/mutations/addCreditToPaymentSource';
import { addFlashMessage, setFlashMessage } from '../../../apollo/cache/flashMessages';
import PageLoading from '../../loading/PageLoading';
import PageHeader from '../../layout/PageHeader';
import usePlaid, { PlaidHookProps } from '../../../hooks/usePlaid';
import { TYPOGRAPHY } from '../../../styles/typography';
import { SPACING } from '../../../styles/spacing';
import { GREYSCALE } from '../../../styles/colors';
import useAriaAnnouncer from '../../../hooks/useAriaAnnouncer';
import environment from '../../../utils/environment';
import {
  PlaidAccessTokenProps,
  PlaidSuccessCallbackProps,
} from '../../../hooks/usePlaidAccessToken';
import { PlaidLinkTokenProps } from '../../../hooks/usePlaidLinkToken';
import PlaidRelinkingHelpModal from '../../modals/PlaidRelinkingHelpModal';
import loggingService from '../../../services/logging';
import ChangePaymentSourceModal from '../../modals/ChangePaymentSourceModal';
import { PaymentSource } from '../../../gql/graphql';
import useNavigateOrHref from '../../../hooks/useNavigateOrHref';
import formatPaymentSourceTitle from '../../../utils/formatPaymentSourceTitle';

const Styled = {
  Col: styled(Col)`
    margin-block-start: calc(
      (${TYPOGRAPHY.body.fontSize} * ${TYPOGRAPHY.body.lineHeight}) + (2 * ${SPACING.sm})
    );
  `,
  CurrencyInput: styled(FormControl)`
    text-align: right;
  `,
  ShiftedSubheading: styled(PageSubtitle)`
    color: ${GREYSCALE.grey60};
    font-size: ${TYPOGRAPHY.fontSize.md};
    font-weight: normal;
    margin-top: -${SPACING.xl};
  `,
};

type CreditValues = {
  paymentSourceId: string;
  addCreditAmount: number | '';
};

function AddCreditPage() {
  const navigate = useNavigateOrHref();
  const logger = loggingService.getLogger();
  const formikRef = useRef<FormikProps<CreditValues> | null>(null);
  const announce = useAriaAnnouncer();
  const { data, loading } = useBillingPageQuery();
  const [creditInProgress, setCreditInProgress] = useState<boolean>(false);
  const [enablePlaidRelinkingHelpModal, setEnablePlaidRelinkingHelpModal] = useState(false);
  const [plaidRelinkingHelpModalOpen, setPlaidRelinkingHelpModalOpen] = useState(false);
  const [plaidPaymentSourceId, setPlaidPaymentSourceId] = useState<string>();
  const [failedPaymentSourceId, setFailedPaymentSourceId] = useState<string | null>(null);
  const [addCreditToPaymentSource] = useAddCreditToPaymentSource();

  const [formValues, setFormValues] = useState<CreditValues>();

  const onPlaidSuccess = (payload: PlaidSuccessCallbackProps) => {
    if (payload?.successMessage) {
      setFlashMessage(payload.successMessage, 'success');
      announce(payload.successMessage, 'polite');
    }

    if (formValues) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      performAddCredit(formValues);
    }
  };

  useEffect(() => {
    setEnablePlaidRelinkingHelpModal(
      !!data?.company?.features.find(
        (f: { key: string }) => f?.key === 'Plaid.relinking.help.modal',
      )?.value,
    );
  }, [data?.company?.features, data?.company?.paymentSources]);

  const onPlaidError = (msg: string) => {
    setFlashMessage(msg, 'danger');
    announce(msg, 'assertive');
    setCreditInProgress(false);
  };

  const onPlaidEvent = (event: string) => {
    if (event === 'EXIT') {
      setCreditInProgress(false);
    }
  };

  const { getPlaidLinkToken } = usePlaid({
    onSuccessCallback: onPlaidSuccess,
    onErrorCallback: onPlaidError,
    onEventCallback: onPlaidEvent,
    forceOpen: true,
  } as PlaidAccessTokenProps & PlaidLinkTokenProps & PlaidHookProps);

  const onPlaidRelinkingHelpModalSuccess = () => {
    if (!plaidPaymentSourceId) {
      logger.error('Plaid payment source id not found, abort relinking process');
      return;
    }
    setPlaidRelinkingHelpModalOpen(false);
    getPlaidLinkToken('add_credit_page', plaidPaymentSourceId);
  };

  if (!data || loading)
    return (
      <PageContainer>
        <PageLoading />
      </PageContainer>
    );

  const { accountBalance } = data.company;
  // do not show Offline payment for add credit
  const accountPaymentSourceOptions: Option[] = data.company.paymentSources
    .filter((paymentSource) => paymentSource.paymentMethodType !== 'manual')
    .map(
      (paymentSource) =>
        ({
          value: paymentSource.id,
          title: formatPaymentSourceTitle(paymentSource),
        } as Option),
    );

  const initialPaymentSourceId = (() => {
    if (accountPaymentSourceOptions.length > 1) {
      return data.company.settings.defaultPaymentSourceId;
    }
    if (accountPaymentSourceOptions.length === 1) {
      return String(accountPaymentSourceOptions[0].value);
    }
    return '';
  })();

  const initialValues: CreditValues = {
    paymentSourceId: initialPaymentSourceId,
    addCreditAmount: '',
  };

  const performAddCredit = async (values: CreditValues) => {
    setCreditInProgress(true);
    try {
      await addCreditToPaymentSource({
        variables: {
          paymentSourceId: values.paymentSourceId,
          addCreditAmount: Number(values.addCreditAmount),
        },
        refetchQueries: [billingPageQuery],
        awaitRefetchQueries: true,
        context: { errorHandled: true },
      });
      const message = `Y'ee successfully added an amount of ${formatCurrency(
        Number(values.addCreditAmount),
      )} from ${
        accountPaymentSourceOptions.find((option) => option.value === values.paymentSourceId)?.title
      }.`;
      addFlashMessage(message, 'success');
      announce(message, 'polite');
      setCreditInProgress(false);
      formikRef.current?.setFieldValue('addCreditAmount', '', false);
    } catch (error) {
      if (error instanceof ApolloError) {
        error.graphQLErrors.forEach(({ extensions, message }) => {
          if (environment.isBridge() && extensions?.redirect) {
            window.location.reload();
            return;
          }

          if (extensions?.failureReason === 'PLAID_UNLINKED_ACCOUNT') {
            setPlaidPaymentSourceId(values.paymentSourceId);

            // I see no other way here
            setFormValues(values);

            if (enablePlaidRelinkingHelpModal) {
              setPlaidRelinkingHelpModalOpen(true);
            } else {
              getPlaidLinkToken('add_credit_page', plaidPaymentSourceId);
            }

            return;
          }

          if (
            extensions?.failureReason === 'PAYMENT_SOURCE_DECLINED' ||
            extensions?.failureReason === 'PLAID_SERVER_UNREACHABLE'
          ) {
            setFailedPaymentSourceId(values.paymentSourceId);
            setCreditInProgress(true);

            return;
          }

          setFlashMessage(message, 'danger');
          announce(message, 'assertive');
          setCreditInProgress(false);
        });
      }
    }
  };

  return (
    <PageContainer>
      {enablePlaidRelinkingHelpModal && (
        <PlaidRelinkingHelpModal
          open={plaidRelinkingHelpModalOpen}
          onSuccess={onPlaidRelinkingHelpModalSuccess}
          onClose={() => {
            setPlaidRelinkingHelpModalOpen(false);
            setCreditInProgress(false);
          }}
        />
      )}
      <PageHeader title="Add Credit to your Pirate Ship account" />
      <Styled.ShiftedSubheading>
        Current balance: {formatCurrency(accountBalance)}
      </Styled.ShiftedSubheading>

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={yup.object<CreditValues>({
          paymentSourceId: yup.string().required(),
          addCreditAmount: yup.number().min(1).max(99999).required(),
        })}
        onSubmit={(values: CreditValues, { setFieldValue }) => {
          performAddCredit(values);
        }}
      >
        {({ values }) => (
          <Form>
            {failedPaymentSourceId !== null && (
              <ChangePaymentSourceModal
                open
                selectedPaymentSourceId={failedPaymentSourceId}
                paymentSources={data?.company.paymentSources as PaymentSource[]}
                submitTitle="Add Credit"
                submitButtonVariant="primary"
                onPaymentSourceChange={(paymentSourceId: string) => {
                  setFailedPaymentSourceId(null);
                  // update formik paymentSourceId with newly created id
                  // formikRef.current?.setFieldValue does not work :-(
                  // eslint-disable-next-line no-param-reassign
                  values.paymentSourceId = paymentSourceId;
                  performAddCredit(values);
                }}
                onPaymentSourceAddRequest={() => {
                  navigate('/settings/billing');
                }}
                onClose={() => {
                  setFailedPaymentSourceId(null);
                  setCreditInProgress(false);
                }}
              />
            )}
            <Row>
              <Col spaceBelow md={6} xs={12}>
                <Label>Payment Source</Label>
                <FormControl
                  name="paymentSourceId"
                  as={Select}
                  options={accountPaymentSourceOptions}
                />
              </Col>
              <Col md={4} xs={12}>
                <Label secondary="- minimum $1.00">Amount</Label>
                <InputGroup prefixIcon="dollar-sign">
                  <Styled.CurrencyInput
                    name="addCreditAmount"
                    as={TextField}
                    type="number"
                    placeholder="1.00"
                  />
                </InputGroup>
              </Col>
              <Styled.Col md={2} xs={12}>
                <ProgressButton
                  variant="primary"
                  size="medium"
                  type="submit"
                  disabled={creditInProgress}
                  progress={creditInProgress}
                >
                  Add Credit
                </ProgressButton>
              </Styled.Col>
            </Row>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
}

export default AddCreditPage;
