import { toWeightObject } from './formatWeight';
import sortDims from './sortDims';

export type PackageDimensionsAndWeight = {
  dimensionX: number;
  dimensionY: number;
  dimensionZ: number;
  weight: number;
};

type ThresholdValues = {
  lengthPlusGirth: number;
  length: number;
  weight: number;
};

type ThresholdConditions = { [Property in keyof ThresholdValues]: boolean };

type PackageThresholds = Record<
  'standard' | 'groundSaver',
  { nearMax: ThresholdValues; max: ThresholdValues }
>;

export const PACKAGE_THRESHOLDS: PackageThresholds = {
  standard: {
    nearMax: {
      lengthPlusGirth: 160,
      length: 104,
      weight: 2320, // 145lbs
    },
    max: {
      lengthPlusGirth: 165,
      length: 108,
      weight: 2400, // 150lbs
    },
  },
  groundSaver: {
    nearMax: {
      lengthPlusGirth: 124,
      length: 56,
      weight: 960, // 60lbs
    },
    max: {
      lengthPlusGirth: 130,
      length: 60,
      weight: 1120, // 70lbs
    },
  },
};

const link =
  'https://support.pirateship.com/en/articles/5613663-maximum-size-and-weight-limits-for-ups-services';

export default function getNearMaxWarning(
  { dimensionX, dimensionY, dimensionZ, weight }: PackageDimensionsAndWeight,
  mailClass: 'standard' | 'groundSaver',
  hasMultipleShipments: boolean,
) {
  const [longSide, middleSide, shortSide] = sortDims([dimensionX, dimensionY, dimensionZ]);

  // 1. Get nearMax thresholds based on package type
  const { nearMax, max } = PACKAGE_THRESHOLDS[mailClass];

  // 2. Check if any dimension above their nearMax value
  const isNearMax: ThresholdConditions = {
    length: longSide >= nearMax.length,
    weight: weight >= nearMax.weight,
    lengthPlusGirth: longSide + (middleSide + shortSide) * 2 >= nearMax.lengthPlusGirth,
  };

  // 3. Determine message
  const startOfMessage = hasMultipleShipments
    ? 'One or more of your packages are near the maximum allowed'
    : 'Your package is near the maximum allowed';

  const messageContent = (() => {
    const forService = mailClass === 'groundSaver' ? ' for Ground Saver' : '';
    const weightInPounds = toWeightObject(max.weight).pounds;
    if (isNearMax.weight && !isNearMax.length && !isNearMax.lengthPlusGirth) {
      return `weight of ${weightInPounds} lbs${forService}. If your package is found to be heavier than that during transit, you may be charged`;
    }
    if (!isNearMax.weight && isNearMax.length && !isNearMax.lengthPlusGirth) {
      return `length of ${max.length}”${forService}. If your package squishes, bulges, or somehow becomes larger than you’ve entered during transit, you may be charged`;
    }
    if (!isNearMax.weight && isNearMax.lengthPlusGirth) {
      return `length + girth of ${max.lengthPlusGirth}”${forService}. If your package squishes, bulges, or somehow becomes larger than you’ve entered during transit, you may be charged`;
    }
    if (isNearMax.weight && (isNearMax.length || isNearMax.lengthPlusGirth)) {
      return `dimensions (length of ${max.length}” / length + girth of ${max.lengthPlusGirth}”) and maximum allowed weight of ${weightInPounds} lbs${forService}. If your package squishes, bulges, or somehow becomes larger than you’ve entered during transit, or is found to be heavier than that during transit, you may be charged`;
    }
    return '';
  })();

  if (!messageContent) {
    return false;
  }

  return `[b]Warning: [/b]${startOfMessage} ${messageContent} [link=${link}]a very expensive “Over Maximum Limit” adjustment[/link]. By proceeding, you accept this risk.`;
}
