import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const updateRateGroupSortOrderMutation = gql(/* GraphQL */ `
  mutation UpdateRateGroupSortOrder($sortOrder: RateGroupSortOrder!) {
    updateRateGroupSortOrder(sortOrder: $sortOrder) {
      id
      rateGroupSortOrder
    }
  }
`);

export const useUpdateRateGroupSortOrderMutation = () =>
  useMutation(updateRateGroupSortOrderMutation);
