type PostCodeAndZip4 = {
  postcode: string;
  zip4: string;
};

// the validation for a correct address is in usZip validator
export default function getPostcodeAndZip4(postalCode: string): PostCodeAndZip4 {
  if (postalCode.match(/\d{5}-\d{4}/)) {
    const [postcode, zip4] = postalCode.split('-', 2);
    return { postcode, zip4 };
  }
  return { postcode: postalCode, zip4: '' };
}
