import { Ref, useImperativeHandle, useRef, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useCreatePlaidActivationMutation } from '../operations/mutations/createPlaidActivationMutation';
import { useCreatePlaidValidationActivationMutation } from '../operations/mutations/createPlaidValidationActivationMutation';

export type PlaidLinkTokenImperativeHandleRef = {
  setLoading: (loading: boolean) => void;
};

export type PlaidActivationPayloadProps = {
  token: string;
  handle: string;
};

export type PlaidLinkTokenProps = {
  onTokenCallback?: (payload: PlaidActivationPayloadProps) => void;
  imperativeHandleRef?: Ref<PlaidLinkTokenImperativeHandleRef>;
  forceOpen?: boolean;
};

export default function usePlaidLinkToken({
  onTokenCallback,
  imperativeHandleRef,
  forceOpen = false,
}: PlaidLinkTokenProps) {
  const [plaidLinkTokenLoading, setPlaidLinkTokenLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>('');
  const [plaidActivationErrorMessage, setPlaidActivationErrorMessage] = useState<string | null>('');

  const [createPlaidActivation, { loading: creatingPlaidActivation }] =
    useCreatePlaidActivationMutation();
  const [createPlaidValidationActivation, { loading: creatingPlaidValidationActivation }] =
    useCreatePlaidValidationActivationMutation();

  // using refs here bc of plaid iframe
  const handle = useRef<string>('');
  const linkToken = useRef<string>('');

  const getPlaidLinkToken = async (origin: string, paymentSourceId?: string | null) => {
    if (forceOpen) {
      setPlaidLinkTokenLoading(true);
    }
    if (paymentSourceId) {
      try {
        const token = await createPlaidValidationActivation({
          variables: {
            paymentSourceId,
            origin,
          },
        });
        if (!token || !token.data) {
          throw new Error('Plaid link token could not be loaded!');
        }
        setSuccessMessage('Your bank account was relinked successfully.');
        handle.current = token.data.createPlaidValidationActivation.handle;
        linkToken.current = token.data.createPlaidValidationActivation.linkToken;
      } catch (error) {
        if (error instanceof ApolloError) {
          error.graphQLErrors.forEach((err) => {
            if (err.extensions?.failureReason === 'PLAID_SERVER_UNREACHABLE') {
              setPlaidActivationErrorMessage(err.message);
            }
          });
        }
      } finally {
        setPlaidLinkTokenLoading(false);
      }
    } else {
      try {
        const token = await createPlaidActivation({
          variables: {
            origin,
          },
        });
        if (!token || !token.data) {
          throw new Error('Plaid link token could not be loaded!');
        }
        setSuccessMessage('Your bank account was created successfully.');
        handle.current = token.data.createPlaidActivation.handle;
        linkToken.current = token.data.createPlaidActivation.linkToken;
      } catch (error) {
        if (error instanceof ApolloError) {
          error.graphQLErrors.forEach((err) => {
            if (err.extensions?.failureReason === 'PLAID_SERVER_UNREACHABLE') {
              setPlaidActivationErrorMessage(err.message);
            }
          });
        }
      } finally {
        setPlaidLinkTokenLoading(false);
      }
    }
    onTokenCallback?.({ token: linkToken.current, handle: handle.current });
  };

  const setLoading = (loading: boolean) => {
    setPlaidLinkTokenLoading(loading);
  };

  /*
    Due to the nature of the PlaidEmbeddedLink lib we need to have a handle to call
    the usePlaid success handler from outside. Basically PlaidEmbeddedLink is a functional component
    <PlaidEmbeddedLink> in the dom, I can't separate its onSuccess functionality from the view, but I want to keep
    our usePlaid hook. So this is the only drawback there is. But since the PlaidEmbeddedLink is beta (Jan 24)
    maybe there are changes one day that make it possible to extract the functionality somehow
  */
  useImperativeHandle(imperativeHandleRef, () => ({
    setLoading,
  }));

  return {
    getPlaidLinkToken,
    plaidLinkTokenLoading,
    creatingPlaidActivation,
    creatingPlaidValidationActivation,
    plaidActivationErrorMessage,
    successMessage,
  };
}
