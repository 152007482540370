import styled from '@emotion/styled';
import { BORDER_RADIUS, BORDER_WIDTH } from '../../../styles/borders';
import { COLOR, GREYSCALE } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';

const TimePickerWrapper = styled.div`
  background-color: ${GREYSCALE.black};
  border-bottom-left-radius: ${BORDER_RADIUS.sm};
  border-bottom-right-radius: ${BORDER_RADIUS.sm};
  padding: ${SPACING.sm} ${SPACING.none} ${SPACING.md};
  display: flex;
  justify-content: center;

  select {
    color: ${COLOR.blue};
    cursor: pointer;
    appearance: none;
    border: 0;
    background: transparent;
    outline: 0;
    color: ${GREYSCALE.white};
    text-align: center;
    border-bottom: ${BORDER_WIDTH.sm} dotted ${COLOR.blue};
    &:hover {
      color: ${COLOR.blue};
    }
  }
`;
export default TimePickerWrapper;
