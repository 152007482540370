// minimal type for this function
type BatchWithShipments = {
  shipments: ReadonlyArray<{
    carrierKey: string;
  }>;
};

// helper function to determine if a bought batch contains shipments from a single carrier only
function isSingleCarrierBatch(batch?: BatchWithShipments) {
  const batchesWithCarrierKeys = batch?.shipments.filter((shipment) => shipment.carrierKey !== '');
  const isUpsOnlyBatch =
    batchesWithCarrierKeys?.every((shipment) => shipment.carrierKey === 'ups') ?? false;
  const isUspsOnlyBatch =
    batchesWithCarrierKeys?.every((shipment) => shipment.carrierKey === 'usps') ?? false;
  return { isUspsOnlyBatch, isUpsOnlyBatch };
}

export default isSingleCarrierBatch;
