import { MouseEventHandler } from 'react';
import { ExternalLink } from './Link';
import ClipboardButton from './form/ClipboardButton';

type TrackingNumberProps = {
  value: string;
  url: string;
  toolTip?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

export default function TrackingNumber({ value, url, toolTip, onClick }: TrackingNumberProps) {
  return (
    <>
      <ExternalLink href={url} onClick={onClick}>
        {value}
      </ExternalLink>
      <ClipboardButton toolTip={toolTip} value={value} ariaTitle="tracking number" />
    </>
  );
}
