import Section, { SectionEntries } from './Section';

export type RubberStampsBatchSectionProps = {
  rubberStamps: readonly {
    userFriendlyShipmentKey: string;
    valueOrMappingKey: string;
  }[];
};

function RubberStampsBatchSection({ rubberStamps }: RubberStampsBatchSectionProps) {
  const entries: SectionEntries = rubberStamps.map((rs) => [
    rs.userFriendlyShipmentKey,
    rs.valueOrMappingKey,
  ]);

  return <Section title="Rubber Stamps" entries={entries} />;
}
export default RubberStampsBatchSection;
