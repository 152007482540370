import { Column } from '../operations/queries/connectedGridConfig';
import reorderArray from './reorderArray';

type ColSettings = ReadonlyArray<{
  readonly field: string;
  readonly width: number;
  readonly hidden: boolean;
}>;

type ColumnMapper = (columns: readonly Column[] | ColSettings) => ColSettings;

// if for some reason, a column has been delivered with no width, set it to 80 here
const ensureValidColumnWidths: ColumnMapper = (columns) =>
  columns.map(({ field, width, hidden }) => ({
    field,
    width: Math.round(width ?? 80), // only integer column widths are accepted in the grid settings
    hidden,
  }));

// if for some reason every column comes in from the backend as 'hidden', we should unhide one as a safety check in the front end so the user is not stuck.
const unhideAColumnIfAllAreHidden: ColumnMapper = (columns) => {
  const mappedCols = columns.map((column) => ({
    ...column,
    width: Number(column.width),
  }));
  const allColsAreHidden = mappedCols.every((c) => !!c.hidden);
  return allColsAreHidden
    ? [{ ...mappedCols[0], hidden: false }, ...mappedCols.slice(1)]
    : mappedCols;
};

export const safelyParseColumns: ColumnMapper = (columns) =>
  ensureValidColumnWidths(unhideAColumnIfAllAreHidden(columns));

// ensure utility columns are not being thrown around and stay nicely on the left
export const safelyParseColumnOrder = (order: readonly string[]): readonly string[] => {
  const rowActionsIndex = order.indexOf('row_actions');
  const rowSelectIndex = order.indexOf('row_select');

  // if neither is present, do nothing
  if (rowActionsIndex === -1 && rowSelectIndex === -1) {
    return order;
  }
  // row actions are present but no row select
  if (rowActionsIndex > -1 && rowSelectIndex === -1) {
    return reorderArray([...order], rowActionsIndex, 0);
  }
  // row select are present but no row actions
  if (rowSelectIndex > -1 && rowActionsIndex === -1) {
    return reorderArray([...order], rowSelectIndex, 0);
  }
  // both are present: set row_select to position 0 and actions to position 1
  const rowSelectFirstArray = reorderArray([...order], rowSelectIndex, 0);
  const newRowActionsIndex = rowSelectFirstArray.indexOf('row_actions'); // now the array has changed once, get the new index of the actions column
  return reorderArray(rowSelectFirstArray, newRowActionsIndex, 1);
};
