import TextField from '../../../../form/TextField';
import FormControl from '../../../../form/FormControl';

type SignupPasswordProps = {
  onPaste: () => void;
};

export default function SignupPassword({ onPaste }: SignupPasswordProps) {
  return (
    <FormControl
      name="password"
      type="password"
      as={TextField}
      label="Create a password (at least 12 characters)"
      onPaste={onPaste}
    />
  );
}
