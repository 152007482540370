import styled from '@emotion/styled';
import isEmpty from 'lodash.isempty';
import { ReactNode } from 'react';
import { COLOR, GREYSCALE, PirateShipColor } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';
import decamelize from '../../utils/decamelize';

export const SHOW_KEY_ONLY = 'SHOW_KEY_ONLY';
export const SHOW_VALUE_ONLY = 'SHOW_VALUE_ONLY';

export type SectionEntryOptions = { color?: PirateShipColor };
export type SectionEntries = [string, ReactNode, SectionEntryOptions?][];

export type SectionDataProps = {
  entries: SectionEntries;
  hideIf?: boolean;
};

type SectionProps = {
  title: string;
  hideIf?: boolean;
} & SectionDataProps;

const Styled = {
  Header: styled.div`
    font-size: ${TYPOGRAPHY.fontSize.sm};
    padding-bottom: ${SPACING.sm};
    color: ${GREYSCALE.grey60};
    font-weight: ${TYPOGRAPHY.fontWeight.medium};
  `,
  Text: styled.div`
    font-size: ${TYPOGRAPHY.fontSize.sm};
    color: ${GREYSCALE.grey50};
    padding-bottom: ${SPACING.lg};
  `,
  Entry: styled.div<{ color?: PirateShipColor }>`
    color: ${({ color }) => color ?? 'inherit'};
  `,
  TextStrong: styled.strong``,
  TextSpan: styled.span``,
};

/**
 * A generic section component for the Shipment Details boxes.
 * takes an entries array of [key, value] tuples and renders them as *Key:* value.
 * if there is no value, the entry is not rendered.
 * if the value is SHOW_KEY_ONLY, only the key is rendered.
 * if there is no key or the key is SHOW_VALUE_ONLY, then just the value is rendered.
 */
function Section({ title, entries, hideIf = false }: SectionProps) {
  if (hideIf || isEmpty(entries)) return null;

  return (
    <div className="generic-section">
      <Styled.Header>{title}</Styled.Header>
      <Styled.Text>
        <>
          {entries.map(([key, value, options]) => {
            if (value === '' || value == null) {
              return null;
            }
            const isError = !!key.match(/^error/i);
            const showValue = value !== SHOW_KEY_ONLY;
            const showKey = key !== SHOW_VALUE_ONLY;
            return (
              <Styled.Entry key={`${key}${value}`} color={isError ? COLOR.red : options?.color}>
                {showKey && (
                  <Styled.TextStrong>{`${decamelize(key)}${
                    !!key && showValue ? ': ' : ''
                  }`}</Styled.TextStrong>
                )}
                {!!value && showValue && <Styled.TextSpan>{value}</Styled.TextSpan>}
              </Styled.Entry>
            );
          })}
        </>
      </Styled.Text>
    </div>
  );
}

export default Section;
