import Chart from 'highcharts-react-official';
import * as Highcharts from 'highcharts/highmaps';
import highchartsMap from 'highcharts/modules/map';
import HighchartsA11y from 'highcharts/modules/accessibility';
import mapDataWorld from '@highcharts/map-collection/custom/world.topo.json';
import { GREYSCALE } from '../../styles/colors';
import { standardMapChartOptions } from '../../styles/charts';
import { MapChartProps } from './types';

highchartsMap(Highcharts);
HighchartsA11y(Highcharts);

function WorldMapChart({ data, customOptions }: MapChartProps) {
  const options: Highcharts.Options = Highcharts.merge(
    {
      chart: {
        map: mapDataWorld,
      },
      series: [
        {
          type: 'map',
          data: data.map(({ key, percent }) => ({
            key,
            value: percent,
          })),
          borderColor: GREYSCALE.white,
          joinBy: ['hc-key', 'key', 'hc-a2'],
          name: 'Top International Countries',
          dataLabels: {
            enabled: false,
          },
        },
      ],
    },
    standardMapChartOptions,
    customOptions,
  );
  return <Chart highcharts={Highcharts} constructorType="mapChart" options={options} />;
}

export default WorldMapChart;
