import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import Modal from '../../components/modals/Modal';
import FlashMessages from '../../components/FlashMessages';
import { flashMessagesVar } from '../../apollo/cache';
import { clearFlashMessages } from '../../apollo/cache/flashMessages';
import { Row, Col, Container } from '../../components/layout/Grid';
import ProgressButton from '../../components/form/ProgressButton';
import PageLayoutSelect from './PageLayoutSelect';
import PageLoading from '../../components/loading/PageLoading';
import useWindowSize from '../../hooks/useWindowSize';
import downloadUrl from '../utils/downloadUrl';
import { PageLayout } from '../../gql/graphql';
import { useDownloadLabelByShipmentLazyQuery } from '../operations/downloadLabelByShipment.query';
import ShareLink from './ShareLink';
import { useShareDownloadUrlByShipmentLazyQuery } from '../operations/shareDownloadUrlByShipment.query';
import PdfPreview from './PdfPreview';

type ShipmentPrintModalProps = {
  open: boolean;
  onClose: () => void;
  shipmentId: string;
};

export default function ShipmentPrintModal({ open, onClose, shipmentId }: ShipmentPrintModalProps) {
  const [, windowHeight] = useWindowSize();
  const [polling, setPolling] = useState(false);
  const [pageLayout, setPageLayout] = useState<PageLayout>();
  const [createShareLink, { data: shareLinkData }] = useShareDownloadUrlByShipmentLazyQuery({
    variables: { shipmentId },
  });

  const [downloadLabelByShipment, { data, loading, error, startPolling, stopPolling }] =
    useDownloadLabelByShipmentLazyQuery({
      variables: {
        shipmentId,
      },
    });

  useEffect(() => {
    if (open) {
      setPolling(true);
    } else {
      stopPolling();
    }
  }, [open, stopPolling]);

  // once we get the data, we initialise the filePageLayout
  useEffect(() => {
    if (data && !pageLayout) {
      setPageLayout(data.downloadLabelByShipment.pageLayout ?? undefined);
    }
  }, [data, pageLayout]);

  useEffect(() => {
    if (polling) {
      downloadLabelByShipment({
        variables: {
          shipmentId,
          pageLayout,
        },
      });
      startPolling(1000);
    }
    if (
      error ||
      data?.downloadLabelByShipment?.status === 'FINISHED' ||
      data?.downloadLabelByShipment?.status === 'ERROR'
    ) {
      setPolling(false);
      stopPolling();
    }
  }, [
    data?.downloadLabelByShipment?.status,
    downloadLabelByShipment,
    error,
    pageLayout,
    polling,
    shipmentId,
    startPolling,
    stopPolling,
  ]);

  const flashMessages = useReactiveVar(flashMessagesVar);

  const onModalClose = () => {
    clearFlashMessages();
    onClose?.();
  };

  const renderPreview = () => {
    if (flashMessages.length) {
      return null;
    }
    if (
      !loading &&
      pageLayout &&
      // correct status to show the print preview
      data?.downloadLabelByShipment.fileFormat &&
      data.downloadLabelByShipment.url &&
      data?.downloadLabelByShipment?.status === 'FINISHED'
    ) {
      const { fileFormat, url } = data.downloadLabelByShipment;
      return (
        <>
          <Row>
            <Col md={12}>
              <PageLayoutSelect
                pageLayout={pageLayout}
                fileFormat={fileFormat}
                onPageLayoutChange={(layout) => {
                  setPageLayout(layout);
                  setPolling(true);
                }}
              />
            </Col>
            <PdfPreview shipmentLabelFileFormat={fileFormat} url={url} />
          </Row>
          <Row>
            <Col md={12} spaceBelow>
              <ProgressButton
                data-testid="download-label-button"
                variant="success"
                fullWidth
                onClick={(event) => {
                  event.preventDefault();
                  downloadUrl(url);
                }}
              >
                Download Label
              </ProgressButton>
            </Col>

            <Col xl={12}>
              <ShareLink
                onClick={() => createShareLink()}
                url={shareLinkData?.shareDownloadUrlByShipment}
              />
            </Col>
          </Row>
        </>
      );
    }
    return <PageLoading height={`${windowHeight / 2}px`} color="white" />;
  };

  return (
    <Modal theme="dark" open={open} width={950} onClose={onModalClose}>
      <Container noGutter>
        <Row>
          <Col md={12}>
            <FlashMessages />
          </Col>
        </Row>
        {renderPreview()}
      </Container>
    </Modal>
  );
}
