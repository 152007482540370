import { QueryHookOptions, LazyQueryHookOptions, useQuery, useLazyQuery } from '@apollo/client';
import { gql } from '../../gql';
import { BatchProcessStatusQuery, BatchProcessStatusQueryVariables } from '../../gql/graphql';

export const batchProcessStatusQuery = gql(/* GraphQL */ `
  query BatchProcessStatusQuery($id: ID!) {
    batch(id: $id) {
      id
      status
      ...RunningProcessFragment
      canInstantRefundBatch
      shipments {
        id
        carrierKey
      }
    }
  }
`);

export const useBatchProcessStatusQuery = (
  options?: QueryHookOptions<BatchProcessStatusQuery, BatchProcessStatusQueryVariables>,
) => useQuery(batchProcessStatusQuery, options);

export const useLazyBatchProcessStatusQuery = (
  options?: LazyQueryHookOptions<BatchProcessStatusQuery, BatchProcessStatusQueryVariables>,
) => useLazyQuery(batchProcessStatusQuery, options);
