import Section, { SectionEntries } from './Section';

type LabelDetailsSectionProps = {
  labelSize: string;
  labelFileFormat: string;
};

function LabelDetailsSection({ labelSize, labelFileFormat }: LabelDetailsSectionProps) {
  const entries: SectionEntries = [
    ['Label Size', `${labelSize.replace('SIZE_', '')}"`],
    ['Label Filetype', labelFileFormat.toUpperCase()],
  ];
  return <Section title="Label Details" entries={entries} />;
}

export default LabelDetailsSection;
