import Label from '../../../../form/Label';
import AddressSubform from '../../../../subforms/AddressSubform';
import { SignupFormAddresses } from '../SignupFormValues';

type SignupReturnAddressProps = {
  onPaste: () => void;
  columnLayout?: boolean;
};

export default function SignupReturnAddress({
  onPaste,
  columnLayout = false,
}: SignupReturnAddressProps) {
  return (
    <>
      <Label
        tooltip="You can choose to have a different address printed on your shipping labels, such as a PO Box."
        data-testid="signup-label-return"
      >
        What address would you like printed on the label?
      </Label>
      <AddressSubform<keyof SignupFormAddresses>
        namespace="returnAddress"
        onPaste={onPaste}
        columnLayout={columnLayout}
      />
    </>
  );
}
