import { useMutation } from '@apollo/client';
import { gql } from '../../gql';

export const createCcPaymentSourceMutation = gql(/* GraphQL */ `
  mutation CreateCcPaymentSourceMutation($ccReference: String!, $cp: Boolean!) {
    createCcPaymentSource(ccReference: $ccReference, cp: $cp) {
      isDefault
      paymentSource {
        ...PaymentSourceFragment
      }
    }
  }
`);

export const useCreateCcPaymentSourceMutation = () =>
  useMutation(createCcPaymentSourceMutation, {
    // Add newly created entry to list
    update(cache, { data }) {
      if (!data) {
        return;
      }
      cache.updateQuery(
        {
          query: gql(/* GraphQL */ `
            query UpdateCcPaymentSourceAfterCreateQuery {
              company {
                id
                settings {
                  defaultPaymentSourceId
                }
                paymentSources {
                  ...PaymentSourceFragment
                }
                settings {
                  defaultPaymentSourceId
                }
              }
            }
          `),
        },
        (cacheResult) => {
          if (!cacheResult) {
            return undefined;
          }

          return {
            company: {
              ...cacheResult.company,
              settings: {
                ...cacheResult.company.settings,
                defaultPaymentSourceId: data.createCcPaymentSource.isDefault
                  ? data.createCcPaymentSource.paymentSource.id
                  : cacheResult.company.settings.defaultPaymentSourceId,
              },
              paymentSources: [
                ...cacheResult.company.paymentSources,
                data.createCcPaymentSource.paymentSource,
              ],
            },
          };
        },
      );
    },
  });
