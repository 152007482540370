import * as yup from 'yup';
import stringToNumber from '../utils/stringToNumber';
import {
  Validator,
  letters,
  emailAddress,
  packageLength,
  packageWeight,
  packageDimensions2d,
  packageDimensions3d,
  packageDimensions2dPublicRates,
  packageDimensions3dPublicRates,
  usZip,
  noPoBox,
  canadaZip,
  isTrue,
  fullName,
  lettersSpaces,
  futureDate,
  mmyy,
  endDateAfterStartDate,
  Dimensions2Value,
  Dimensions3Value,
  customsWeightsPackageWeight,
  WeightValue,
  maxAverageWeight,
  unique,
  maxPassthroughFields,
  oneNameType,
  requiredFields,
  namesOrCompany,
  validCharacters,
  invalidCharacters,
  hsCode,
  internationalTaxId,
  hsCodeBlacklist,
  customsDescriptionBlacklist,
} from './validators';

type Maybe<T> = T | null | undefined;

type ValueExtractor<T> = (context: yup.TestContext) => T | undefined;
type AllValuesExtractor = (context: yup.TestContext) => Record<string, string>;

export type ValidatorConfig<T> = T;

// NOTE: do NOT use virtual fields here to determine the extracted values.
// this does not work if working outside a formik context. its a bit anti-pattern.

// how to:
// if we have a fake value that depends on other values, use ".when" as the condition for evaluation.

const dimensionsExtractor2d: ValueExtractor<Dimensions2Value> = (context: yup.TestContext) => {
  if (context.parent.dimensionX && context.parent.dimensionY) {
    return {
      length: stringToNumber(context.parent.dimensionX),
      width: stringToNumber(context.parent.dimensionY),
    };
  }
  return undefined;
};
const dimensionsExtractor3d: ValueExtractor<Dimensions3Value> = (context: yup.TestContext) => {
  if (context.parent.dimensionX && context.parent.dimensionY && context.parent.dimensionZ) {
    return {
      length: stringToNumber(context.parent.dimensionX),
      width: stringToNumber(context.parent.dimensionY),
      height: stringToNumber(context.parent.dimensionZ),
    };
  }
  return undefined;
};
const dimensionsExtractor2dPublicRates: ValueExtractor<Dimensions2Value> = (
  context: yup.TestContext,
) => {
  if (context.parent.dimensionX || context.parent.dimensionY) {
    return {
      length: context.parent.dimensionX ? stringToNumber(context.parent.dimensionX) : 0,
      width: context.parent.dimensionY ? stringToNumber(context.parent.dimensionY) : 0,
    };
  }
  return undefined;
};
const dimensionsExtractor3dPublicRates: ValueExtractor<Dimensions3Value> = (
  context: yup.TestContext,
) => {
  if (context.parent.dimensionX || context.parent.dimensionY || context.parent.dimensionZ) {
    return {
      length: context.parent.dimensionX ? stringToNumber(context.parent.dimensionX) : 0,
      width: context.parent.dimensionY ? stringToNumber(context.parent.dimensionY) : 0,
      height: context.parent.dimensionZ ? stringToNumber(context.parent.dimensionZ) : 0,
    };
  }
  return undefined;
};
const weightObjectExtractor: ValueExtractor<WeightValue> = (context: yup.TestContext) => ({
  weightPounds: context.parent.weightPounds,
  weightOunces: context.parent.weightOunces,
});

// if other values are needed for evaluation, extract them with this function
// note: these other values are used to test against the "main" value and do not replace it, as above
const allValuesExtractor: AllValuesExtractor = (context: yup.TestContext) => ({
  ...context.parent,
});

function validate<T, C = void>(
  validator: Validator<T, C>,
  config?: ValidatorConfig<C>, // optional config (argument 1 passed to validation schema)
  extractor?: ValueExtractor<T>, // use a custom extractor to get value if field is fake
  useAllValues?: boolean, // for select fields, we need to see all other values
): yup.TestFunction<Maybe<T>> {
  return async function testWrapper(value) {
    const context = this;
    const realValue = extractor ? extractor(context) : value;
    const allValues = useAllValues ? allValuesExtractor(context) : undefined;
    const result = await validator(realValue, config, allValues);
    return result ? this.createError({ message: result }) : true;
  };
}

function wrapStringValidator<S extends yup.StringSchema, C = void>(
  methodName: string,
  validator: Validator<string, C>,
  valueExtractor?: ValueExtractor<string>,
) {
  return function wrapper(this: S, config: ValidatorConfig<C>) {
    return this.test(methodName, '', validate(validator, config, valueExtractor));
  };
}

// our select validators in the mapping page need to know all other values to function
function wrapSelectValidator<S extends yup.StringSchema, C = void>(
  methodName: string,
  validator: Validator<string, C>,
) {
  return function wrapper(this: S, config: ValidatorConfig<C>) {
    return this.test(methodName, '', validate(validator, config, undefined, true));
  };
}

function wrapNumberValidator<S extends yup.NumberSchema, C = void>(
  methodName: string,
  validator: Validator<number, C>,
  valueExtractor?: ValueExtractor<number>,
) {
  return function wrapper(this: S, config: ValidatorConfig<C>) {
    return this.test(methodName, '', validate(validator, config, valueExtractor));
  };
}

function wrapBooleanValidator<S extends yup.BooleanSchema, C = void>(
  methodName: string,
  validator: Validator<boolean, C>,
  valueExtractor?: ValueExtractor<boolean>,
) {
  return function wrapper(this: S, config: ValidatorConfig<C>) {
    return this.test(methodName, '', validate(validator, config, valueExtractor));
  };
}

function wrapObjectValidator<S extends yup.ObjectSchema<T>, T extends object, C = void>(
  methodName: string,
  validator: Validator<T, C>,
  valueExtractor?: ValueExtractor<T>, // If checking a "virtual" field (i.e. one where the values are in the context and not the value variable itself) extract the values needed with this
) {
  return function wrapper(this: S, config: ValidatorConfig<C>) {
    return this.test(methodName, '', validate(validator, config, valueExtractor));
  };
}

export default function addCustomMethods() {
  // String
  yup.addMethod(yup.string, 'letters', wrapStringValidator('letters', letters));
  yup.addMethod(yup.string, 'lettersSpaces', wrapStringValidator('lettersSpaces', lettersSpaces));
  yup.addMethod(yup.string, 'packageLength', wrapStringValidator('packageLength', packageLength));
  yup.addMethod(yup.string, 'usZip', wrapStringValidator('usZip', usZip));
  yup.addMethod(yup.string, 'noPoBox', wrapStringValidator('noPoBox', noPoBox));
  yup.addMethod(yup.string, 'canadaZip', wrapStringValidator('canadaZip', canadaZip));
  yup.addMethod(yup.string, 'fullName', wrapStringValidator('fullName', fullName));
  yup.addMethod(yup.string, 'futureDate', wrapStringValidator('futureDate', futureDate));
  yup.addMethod(yup.string, 'mmyy', wrapStringValidator('mmyy', mmyy));
  yup.addMethod(yup.string, 'emailAddress', wrapStringValidator('emailAddress', emailAddress));
  yup.addMethod(yup.string, 'hsCode', wrapStringValidator('hsCode', hsCode));
  yup.addMethod(
    yup.string,
    'internationalTaxId',
    wrapStringValidator('internationalTaxId', internationalTaxId),
  );
  yup.addMethod(
    yup.string,
    'validCharacters',
    wrapStringValidator('validCharacters', validCharacters),
  );
  yup.addMethod(
    yup.string,
    'invalidCharacters',
    wrapStringValidator('invalidCharacters', invalidCharacters),
  );
  yup.addMethod(
    yup.string,
    'hsCodeBlacklist',
    wrapStringValidator('hsCodeBlacklist', hsCodeBlacklist),
  );
  yup.addMethod(
    yup.string,
    'customsDescriptionBlacklist',
    wrapStringValidator('customsDescriptionBlacklist', customsDescriptionBlacklist),
  );

  // Select (as string)
  yup.addMethod(yup.string, 'unique', wrapSelectValidator('unique', unique));
  yup.addMethod(
    yup.string,
    'maxPassthroughFields',
    wrapSelectValidator('maxPassthroughFields', maxPassthroughFields),
  );
  yup.addMethod(yup.string, 'oneNameType', wrapSelectValidator('oneNameType', oneNameType));

  // Number
  yup.addMethod(
    yup.number,
    'maxAverageWeight',
    wrapNumberValidator('maxAverageWeight', maxAverageWeight),
  );

  // Boolean
  yup.addMethod(yup.boolean, 'isTrue', wrapBooleanValidator('isTrue', isTrue));

  // Object (fake fields for combined validation errors)
  yup.addMethod(
    yup.object,
    'packageDimensions2d',
    wrapObjectValidator('packageDimensions2d', packageDimensions2d, dimensionsExtractor2d),
  );
  yup.addMethod(
    yup.object,
    'packageDimensions3d',
    wrapObjectValidator('packageDimensions3d', packageDimensions3d, dimensionsExtractor3d),
  );
  yup.addMethod(
    yup.object,
    'packageDimensions2dPublicRates',
    wrapObjectValidator(
      'packageDimensions2dPublicRates',
      packageDimensions2dPublicRates,
      dimensionsExtractor2dPublicRates,
    ),
  );
  yup.addMethod(
    yup.object,
    'packageDimensions3dPublicRates',
    wrapObjectValidator(
      'packageDimensions3dPublicRates',
      packageDimensions3dPublicRates,
      dimensionsExtractor3dPublicRates,
    ),
  );
  yup.addMethod(
    yup.object,
    'customsWeightsPackageWeight',
    wrapObjectValidator(
      'customsWeightsPackageWeight',
      customsWeightsPackageWeight,
      weightObjectExtractor,
    ),
  );
  yup.addMethod(
    yup.object,
    'packageWeight',
    wrapObjectValidator('packageWeight', packageWeight, weightObjectExtractor),
  );
  yup.addMethod(
    yup.object,
    'requiredFields',
    wrapObjectValidator('requiredFields', requiredFields, allValuesExtractor),
  );
  yup.addMethod(
    yup.object,
    'namesOrCompany',
    wrapObjectValidator('namesOrCompany', namesOrCompany, allValuesExtractor),
  );

  // Object (real fields with object values)
  yup.addMethod(
    yup.object,
    'endDateAfterStartDate',
    wrapObjectValidator('endDateAfterStartDate', endDateAfterStartDate),
  );
}
