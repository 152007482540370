import { useState, useEffect } from 'react';
import { FilterTagContext } from '../types';
import {
  FetchMultiCheckOptionsCallbackType,
  MultiCheckOption,
} from '../filterInterfaceRenderer/MultiCheckFilterInterface';

export interface MultiCheckFilterTagSettings<TData, TValue> {
  fetchMultiCheckOptions?: FetchMultiCheckOptionsCallbackType<TData, TValue>;
}

export default function MultiCheckFilterTag<TData, TValue>(
  filterTagContext: FilterTagContext<TData, TValue>,
) {
  const { table, column, filterValue, settings } = filterTagContext;
  const allColumns = table.getAllLeafColumns();
  const { columnFilters, globalFilter } = table.getState();
  const { fetchMultiCheckOptions } = (settings as MultiCheckFilterTagSettings<TData, TValue>) ?? {
    fetchMultiCheckOptions: undefined,
  };
  const [options, setOptions] = useState<MultiCheckOption[]>([]);
  if (!column) {
    throw new Error('Column is missing');
  }

  useEffect(() => {
    let cancelled = false;

    if (!fetchMultiCheckOptions) {
      throw new Error('MultiCheckFilter is missing fetchMultiCheckOptions setting');
    }

    fetchMultiCheckOptions({
      column,
      columnFilters,
      globalFilter,
      allColumns,
    }).then((opts) => {
      if (!cancelled) {
        setOptions(opts);
      }
    });

    return () => {
      cancelled = true;
    };
  }, [column, columnFilters, globalFilter, fetchMultiCheckOptions, allColumns]);

  // No filter value OR options not loaded yet
  if (!(filterValue instanceof Array) || !filterValue.length || !options.length) {
    return '';
  }

  // Only one selected -> show value
  if (filterValue.length === 1) {
    return (
      options.find((option) => option.value === filterValue[0])?.label || filterValue[0] || null
    );
  }

  // All selected
  if (filterValue.length === options.length) {
    return 'All selected';
  }

  return `${filterValue.length} selected`;
}
