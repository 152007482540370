import { FilterTagContext } from '../types';

const CUTOFF_LENGTH = 50;

export default function TextFilterTag<TData, TValue>(
  filterTagContext: FilterTagContext<TData, TValue>,
) {
  const { filterValue } = filterTagContext;
  if (filterValue?.length > CUTOFF_LENGTH) {
    return `${filterValue.slice(0, CUTOFF_LENGTH)}…`;
  }

  return filterValue;
}
